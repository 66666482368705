<template>
  <div :class="$style.root">
    <div :class="$style.innerroot">
      <div :class="$style.bg">
      <div :class="$style.top">選擇寄送方式</div>
      <div :class="$style.main">
        <div
          v-for="(item, i) in addressList"
          :key="i"
          :class="$style.outout"
        >
          <div :class="$style.raido">
            <input
              type="radio"
              v-model="method"
              :value="i"
              :class="$style.radio"
            />
            <div :class="$style.text" @click="method = Number(i)">
              <div>
                {{ Number(i) === 0
                    ? '宅配'
                    : Number(i) === 1
                      ? '7-Eleven'
                      : Number(i) === 2
                        ? '萊爾富 Hi-Life'
                        : '全家 FamilyMart'
                }}
              </div>
              <div>
              $ {{ Number(i) === 0
                  ? '100 (離島 $ 180)'
                  : '65'
                }}
              </div>
            </div>
          </div>
          <p :class="$style.textstyle">
            預定發貨時間 2～5天
          </p>
          <div v-if="method === Number(i)" :class="$style.outside">
            <div
              :class="$style.add"
              v-for="(item2, ii) in addressList[i]"
              :key="ii"
            >
              <div :class="$style.text2">
                <input
                  type="radio"
                  v-model="checkAddress"
                  :value="ii"
                  :class="$style.radio"
                />
                <div style="padding-left: 10px;">
                  {{ item2.city + item2.street[0] + item2.street[1] }}
                </div>
              </div>
              <div :class="$style.text2">
                {{ item2.firstname + ' - ' + item2.telephone }}
              </div>
            </div>
            <div
              :class="$style.btnn"
              @click="addaddressFn(i)"
            >
              <i class="el-icon-plus" style="color: #ff6100;"/>
              新增地址
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.out">
        <div :class="$style.no" @click="$emit('close')">取消</div>
        <div :class="[$style.no, $style.yes]" @click="pickAddressFn">
          確定
        </div>
      </div>
    </div>
    </div>
    <addaddress
      v-on="$listeners"
      v-if="addaddress"
      @close="addaddress = false"
      :addNumber="addNumber"
      :info="info"
      :pickAddressId="pickAddressId"
    />
  </div>
</template>

<script>
import addaddress from '@/components/user/address.vue'
export default {
  components: {
    addaddress
  },
  props: {
    info: Object,
    pickAddressId: Number
  },
  data () {
    return {
      method: 0,
      checkAddress: 0,
      addaddress: false,
      addNumber: null
    }
  },
  watch: {
    method () {
      this.checkAddress = 0
    }
  },
  computed: {
    addressList () {
      if (this.info.addressList) {
        const home = this.info.addressList.filter(ele => ele.street[2] === 'home')
        const eleven = this.info.addressList.filter(ele => ele.street[2] === '7eleven')
        const hilife = this.info.addressList.filter(ele => ele.street[2] === 'hilife')
        const family = this.info.addressList.filter(ele => ele.street[2] === 'familymart')
        return [
          home, eleven, hilife, family
        ]
      } else {
        return []
      }
    }
  },
  methods: {
    async pickAddressFn () {
      this.$emit('loading', true)
      this.$store.commit({ type: 'csvData', csvData: null })
      try {
        await this.$store.dispatch({
          type: 'customJsonFn',
          action: (this.pickAddressId >= 0) ? 'updateCustomJson' : 'addCustomJson',
          key: 'LastTimeData',
          value: this.addressList[this.method][this.checkAddress].id
        })
        await this.$store.dispatch({
          type: 'customJsonFn',
          action: 'getCustomJson',
          key: 'LastTimeData'
        })
        this.$emit('close')
      } catch {
        this.$message.error('請確認填妥寄送資料')
      } finally {
        this.$emit('loading', false)
      }
    },
    addaddressFn (value) {
      this.addNumber = value
      this.addaddress = true
    }
  },
  created () {
    if (this.pickAddressId >= 0) {
      const url = this.info.addressList.find(ele => ele.id === this.pickAddressId)
      switch (url.street[2]) {
        case 'home':
          this.method = 0
          break
        case '7eleven':
          this.method = 1
          break
        case 'hilife':
          this.method = 2
          break
        case 'familymart':
          this.method = 3
          break
      }
      this.checkAddress = this.addressList[this.method].findIndex(ele => ele.id === this.pickAddressId)
    }
  }
}
</script>

<style lang="scss" module>
.innerroot {
  display: flex;
  position: fixed;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
  padding: 50px 0;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  width: 510px;
  min-height: 500px;
  border-radius: 10px;
  border: solid 2px #ff6100;
  background: #ffffff;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 2px solid #ccc;
  font-size: 16px;
}

.main {
  padding: 20px 10px;
  grid-template-columns: 1fr;
  font-size: 16px;
  min-height: 400px;
}

.out {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #000000;
}

.title2 {
  font-size: 14px;
  color: #ff6100;
}

.outout {
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.toptext {
  font-size: 12px;
  color: #828282;
}

.no {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff6100;
  font-size: 15px;
  font-weight: 400;
  width: 47%;
  height: 40px;
  border-radius: 999rem;
  border: solid 1px #ff6100;
  cursor: pointer;

  &.yes {
    color: #fff;
    background-color: #ff6100;
  }
}

.raido {
  width: 100%;
  display: flex;
  align-items: center;
}

.outside {
  border-bottom: 2px solid #e8e8e8;
}

.text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 12px;
  cursor: pointer;
}

.textstyle {
  font-size: 12px;
  color: #828282;
  padding-left: 30px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e8e8e8;
}

.add {
  margin-left: 30px;
  border-bottom: 1px solid #ff6100;
  padding-bottom: 10px;
}

.text2 {
  width: 100%;
  display: flex;
  font-size: 13px;
  padding-top: 10px;
}

.btnn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  height: 24px;
  border-radius: 999rem;
  border: solid 1px #ff6100;
  font-size: 13px;
  cursor: pointer;
  margin: 15px 0 15px 30px;
}

.radio {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media (max-width: 1356px) {
  .innerroot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    width: 90%;
    max-width: 500px;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    font-size: 12px;
    color: #828282;
  }

  .main {
    padding: 10px;
    box-sizing: border-box;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    padding-left: 12px;
  }

  .textstyle {
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #828282;
    padding-left: 30px;
  }
}

.noo {
  background-color: #ccc !important;
  border: 1px solid #ccc;
  cursor: not-allowed;
}
</style>
