<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.top">
        <p>
          剩餘數量：
          {{ childlist.stock[childlist.ChildSkuData[picker].chirdSku] || 0 }}
        </p>
        <i :class="['el-icon-close', $style.iconclose]" @click="$emit('close')"/>
      </div>
      <div :class="$style.listout">
        <div
          v-for="(item, i) in childlist.ChildSkuData"
          :key="i"
          :class="[$style.pick, {[$style.picker]: picker === Number(i)}, 'clickCss']"
          @click="picker = Number(i)"
        >
          {{ Boolean(item.chirdSkuName.split(childlist.description + '-')[1])
              ? item.chirdSkuName.split(childlist.description + '-')[1]
              : item.chirdSkuName
          }}
        </div>
      </div>
      <div
        :class="['center', $style.btn, $style.btn2, 'clickCss']"
        @click="fixSku()"
      >
        確定
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    childlist: Object
  },
  data () {
    return {
      picker: null
    }
  },
  methods: {
    async fixSku () {
      this.$emit('loading', true)
      await this.$store.dispatch({
        type: 'itemAction',
        action: 'updateItem',
        catchList: true,
        bodyObj: {
          parentSku: this.childlist.parentSku,
          idx: this.childlist.info.idxNumber,
          qty: this.childlist.info.number,
          sku: this.childlist.ChildSkuData[this.picker].chirdSku
        }
      })
      this.$emit('close')
      this.$emit('loading', false)
    }
  },
  created () {
    const childNumber = this.childlist.ChildSkuData
      .map(ele => ele.chirdSku)
      .indexOf(this.childlist.info.sku)
    this.picker = childNumber
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  @keyframes cssA {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -10%, 0);
      transform: translate3d(0, -10%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

@media (max-width: 1365px) {
  @keyframes cssA {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.root {
  top: 24px;
  position: absolute;
  z-index: 5;
}

.top {
  display: flex;
  justify-content: space-between;
}

.iconclose {
  color: #aaa;
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
}

.rootinner {
  display: flex;
  flex-direction: column;
  width: 340px;
  border: 2px solid #FF6100;
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  height: 300px;
  position: relative;
  animation-name: cssA;
  animation-duration: 0.3s;
}

.listout {
  width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px 0;
  margin: 10px 0;
  box-sizing: border-box;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.nopick {
  cursor: not-allowed !important;
}

.pick {
  padding: 2px 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  height: min-content;

  &.picker {
    border: 1px solid #FF6100;
  }
}

.outbtn {
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 100%;
  height: 35px;
  border: 1px solid #FF6100;
  border-radius: 3px;
  color: #FF6100;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;

  &.btn2{
    background: #FF6100;
    color: #fff;
    margin-right: 0px;
  }

  &.no {
    background: #ccc;
    border: 1px solid #ccc;
    cursor: not-allowed;
  }

  &:active {
    background: #d85200;
  }
}

@media (max-width: 1365px) {
  .root {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    padding-right: 20px;
    box-sizing: border-box;
  }

  .rootinner {
    padding: 30px;
    box-sizing: border-box;
    width: 90%;
    max-width: 725px;
    min-height: 500px;
    font-size: 16px;
    animation-duration: 0.2s;
  }
}

@media (max-width: 767px) {
  .root {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    padding: 0;
    box-sizing: border-box;
  }

  .rootinner {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 725px;
    min-height: 300px;
    font-size: 16px;
  }

  .listout {
    min-height: 100px;
  }
}
</style>
