import { groupApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'

const messageJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    message: null
  },
  mutations: {
    // 我的最愛
    message (state, payload) {
      state.message = payload.message
    }
  },
  actions: {
    async getMessage (store, payload) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const bodyObj = { pageSize: 8, currentPage: payload.currentPage }
      const data = await groupApiFunciton('getMessageByCustomerId', token, bodyObj)
      store.commit({ type: 'message', message: data })
    }
  },
  getters: {
    messageUser: (state) => {
      return state.message
        ? state.message
        : { total: 0, data: [] }
    }
  }
}

export default {
  messageJs
}
