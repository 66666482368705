<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div
        :class="[$style.bg, , 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']"
      >
      <div :class="$style.top">
        <p :class="$style.title">退貨申請</p>
        <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
      </div>
      <div :class="$style.one">
        <div :class="$style.green">1</div>
        <div :class="$style.title3">退貨商品</div>
        <div :class="$style.px12">
          訂單編號：{{ listData.orderId }}
        </div>
      </div>
      <productlist :listData="listData.products"/>
      <div :class="$style.one">
        <div :class="$style.green">2</div>
        <div :class="$style.title3">退貨描述</div>
      </div>
      <textarea
        v-model="info.message"
        :class="$style.inputstyle"
        placeholder="請填寫退貨原因描述"
      />
      <div :class="$style.one">
        <div :class="$style.green">3</div>
        <div :class="$style.title3">退貨資訊</div>
      </div>
      <div :class="$style.out2">
        <div :class="$style.border">姓名</div>
        <input
          v-model="info.name"
          :class="$style.input"
          placeholder="請填寫姓名"
        />
        <div :class="$style.border">手機</div>
        <input
          :class="$style.input"
          placeholder="請填寫手機"
          v-model="info.phone"
        />
        <div :class="$style.border">地址</div>
        <div :class="[$style.border, $style.selectstyle]">
          <select :class="$style.selectt" v-model="city">
            <option
              v-for="(item, i) in taiwanDistrict"
              :key="i"
              :value="i"
            >
              {{ item.name }}
            </option>
          </select>
          <select :class="$style.selectt" v-model="area">
            <option
              v-for="(item, i) in taiwanDistrict[city].districts"
              :key="i"
              :value="i"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div :class="$style.border"></div>
        <input
          :class="$style.input"
          placeholder="請填寫完整地址"
          v-model="address"
        />
      </div>
      <div :class="$style.one">
        <div :class="$style.green">4</div>
        <div :class="$style.title3">退款資訊</div>
      </div>
      <div :class="$style.out2">
        <div :class="$style.border">方式</div>
        <div :class="$style.border">
          {{ listData.paymentInfo.PaymentType }}
        </div>
        <div
          :class="$style.border"
          v-if="listData.paymentInfo.PaymentType !== 'Credit_CreditCard'"
        >
          銀行
        </div>
        <div
          :class="$style.outbank"
          v-if="listData.paymentInfo.PaymentType !== 'Credit_CreditCard'"
        >
          <div :class="[$style.bankcode, $style.input]" @click="bankcode = !bankcode">
            {{ bankcodelist[codeNumber].code }}
          </div>
          一
          <div v-if="bankcode" :class="$style.bankcodeout">
            <div
              v-for="(item, i) in bankcodelist" :key="i"
              :class="[$style.listcss, {[$style.listcssactive]: codeNumber === i}]"
              @click="codeNumber = i"
              @dblclick="codeNumber = i
                bankcode = !bankcode
              "
            >
              {{ item.code + ' - ' + item.name }}
            </div>
          </div>
          <input
            type="number"
            :class="[$style.input, $style.bankinput]"
            placeholder="請輸入金融卡卡號"
            v-model="card"
          />
        </div>
        <div :class="$style.border">金額</div>
        <div :class="$style.border">＄{{ listData.total }}</div>
      </div>
      <div :class="$style.one">
        <div :class="$style.green">5</div>
        <div :class="$style.title3">現金退還</div>
      </div>
      <p :class="$style.textpoint">點數：{{ listData.getredPoint }}</p>
      <div
        :class="[$style.loginbtn, 'clickCss']"
        @click="returnMethods"
      >
        送出
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import bankcodelist from '@/address/bankcode.json'
import taiwanDistrict from '@/address/taiwan_districts.json'
import productlist from '@/components/user/listpage/productlist.vue'
export default {
  components: {
    productlist
  },
  props: {
    listData: Object
  },
  data () {
    return {
      codeNumber: 0,
      city: 0,
      area: 0,
      address: '',
      info: {},
      card: '',
      bankcode: false,
      taiwanDistrict,
      bankcodelist
    }
  },
  computed: {
    addressAll () {
      const city = this.taiwanDistrict[this.city]
      return city.name + city.districts[this.area].name + this.address
    },
    messageReturn () {
      let message = `${'姓名：' + this.info.name + '/電話：' + this.info.phone +
        '/退貨描述：' + this.info.name + '/地址：' + this.addressAll}`
      if (this.listData.paymentInfo.PaymentType !== 'Credit_CreditCard') {
        message = message + '/銀行帳戶：' + this.bankcodelist[this.codeNumber].code +
        this.bankcodelist[this.codeNumber].name + this.card
      } else {
        message = message + '/退款方式：信用卡退款'
      }
      return message
    }
  },
  methods: {
    async returnMethods () {
      if (this.listData.paymentInfo.PaymentType !== 'Credit_CreditCard' && this.card !== '') {
        await this.returnFunction()
      } else if (this.listData.paymentInfo.PaymentType === 'Credit_CreditCard') {
        await this.returnFunction()
      } else {
        this.$notify({
          type: 'error',
          message: '資料尚未填寫完全，請確認後再進行退貨。'
        })
      }
    },
    async returnFunction () {
      if (this.address !== '' && this.info.name && this.info.phone) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'returnMethods',
            orderId: this.listData.orderId,
            message: this.messageReturn
          })
          this.$message.success({ message: '退貨成功！' })
        } catch {
          this.$message.error({ message: '退貨失敗！請確認資料是否填寫正確，或是通知客服。' })
        } finally {
          await this.$store.dispatch({
            type: 'getOrdersList',
            userId: this.$route.params.userId,
            currentPage: this.$route.params.current
          })
          this.$emit('loading', false)
          this.$emit('close')
          this.$emit('refreshList')
        }
      } else {
        this.$notify({
          type: 'error',
          message: '資料尚未填寫完全，請確認後再進行退貨。'
        })
      }
    }
  }
}
</script>

<style lang="scss" module>
textarea {
  resize: none;
}

.bankcodeout::-webkit-scrollbar-thumb {
  height: 50px;
}

.listcss {
  font-size: 14px;
  padding: 10px 20px;
  box-sizing: border-box;
  border-bottom: 2px solid #f2f2f2;
  color: #aaa;
  cursor: pointer;

  &.listcssactive {
    color: #000;
    border-bottom: 2px solid #00d77f;
  }
}

.outbank {
  display: flex;
  align-items: center;
  position: relative;
}

.bankcodeout {
  width: 200px;
  height: 150px;
  background: #fff;
  border: 1px solid #aaa;
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

.bankcode {
  height: 100%;
  width: 80px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background-image: url(../../../assets/img/botton_arrow.png);
  background-size: 26px 26px;
  background-position: 50px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bankinput {
  height: 100%;
  flex: 1;
}

.rootinner {
  padding: 100px 0;
  box-sizing: border-box;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  padding: 28px;
  box-sizing: border-box;
  width: 727px;
  height: auto;
  border-radius: 10px;
  border: solid 2px #00d77f;
  background-color: #ffffff;
}

.top {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #C4C4C4;
}

.inn {
  justify-self: center;
  align-self: center;
  width: 18px;
  height: 18px;
}

.titleinner {
  padding-bottom: 12px;
  font-size: 14px;
  border-bottom: 1px solid #C4C4C4;
  display: grid;
  grid-template-columns: 340px 1fr 1fr 1fr;
  padding: 0 30px 12px 30px;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.title2 {
  cursor: pointer;
  color: #888;
  font-size: 22px;
  font-weight: 400;
}

.thingout {
  display: grid;
  grid-template-columns: 340px 1fr 1fr 1fr;
  height: 60px;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 30px;
}

.img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.textthing {
  color: #000;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  font-size: 14px;
}

.textinn {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-self: center;
}

.moneyout {
  width: 100%;
  height: 100%;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.midden {
  justify-self: center;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}

.truemoney {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.inputsize {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.one {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 24px 66px 1fr;
  grid-column-gap: 16px;
  grid-template-rows: 24px;
}

.green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: #00d77f;
}

.title3 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.px12 {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
}

.inputstyle {
  text-align: left;
  border: none;
  font-size: 14px;
  padding: 18px 24px;
  box-sizing: border-box;
  width: 593px;
  height: 160px;
  margin-left: 42px;
  background-color: #f7f7f7;
}

.btn {
  overflow-x: auto;
  display: flex;
}

.btnn {
  height: 17px;
  border-radius: 999rem;
  background-color: rgba(0, 215, 127, 0.3);
  margin-right: 10px;
}

.textpoint {
  padding: 0 42px;
  font-size: 14px;
}

.loginbtn {
  width: 240px;
  height: 40px;
  border-radius: 999rem;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  display: flex;
  margin: 50px auto 10px auto;
  justify-content: center;
  align-items: center;
  background-color: #00d77f;
  cursor: pointer;
}

.out {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-auto-rows: 60px;
  grid-column-gap: 12px;
}

.out2 {
  padding: 0 42px;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-auto-rows: 40px;
  grid-column-gap: 40px;
  grid-row-gap: 16px;
}

.input {
  border: none;
  background-color: #f7f7f7;
  padding: 0 20px;
  box-sizing: border-box;
}

.selectt {
  border: none;
  background: #f7f7f7;
  border-radius: 0px;
  width: 45%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.border {
  font-size: 14px;
  color: #000000;
  display: flex;
  align-items: center;

  &.selectstyle {
    justify-content: space-between;
  }
}

@media (max-width: 1365px) {
  .root {
    padding: 60px 0 60px 0;
    box-sizing: border-box;
    overflow-y: auto;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    max-width: 720px;
    width: 80%;
    height: auto;
    border-radius: 10px;
    border: solid 2px #00d77f;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ccc;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .title2 {
    color: #888;
    font-size: 26px;
    font-weight: 400;
  }

  .thingout {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 48px 1fr 0.3fr;
    grid-template-rows: 48px 1fr;
    grid-gap: 10px;
    width: 100%;
    height: 100px;
    padding: 10px 0 0 0;
    border-bottom: 1px solid #f2f2f2;
  }

  .img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .textthing {
    color: #000;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.8fr 1fr;
    font-size: 14px;
  }

  .textinn {
    overflow: hidden;
    line-height: 16px;

    &.format {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
      color: #828282;
    }
  }

  .moneyout {
    width: 100%;
    height: 100%;
    font-size: 13px;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .number {
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    font-size: 13px;
  }

  .truemoney {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .inputsize {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .one {
    padding: 13px 0;
    display: grid;
    grid-template-columns: 17px 60px 1fr;
    grid-gap: 12px;
    grid-template-rows: 17px;
  }

  .green {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    height: 100%;
    background-color: #00d77f;
  }

  .title3 {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .px12 {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 12px;
  }

  .inputstyle {
    margin: 0;
    font-size: 14px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
  }

  .btn {
    overflow-x: auto;
    display: flex;
  }

  .btnn {
    height: 17px;
    border-radius: 999rem;
    background-color: rgba(0, 215, 127, 0.3);
    margin-right: 10px;
  }

  .textpoint {
    font-size: 13px;
    padding-top: 10px;
  }

  .loginbtn {
    width: 30%;
    margin: 30px auto;
    height: 45px;
    border-radius: 999rem;
    color: #fff;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00d77f;
  }

  .out {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-auto-rows: 28px;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
  }

  .input {
    border: none;
    background-color: #f7f7f7;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .selectt {
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 0px;
    width: 45%;
    height: 100%;
  }

  .border {
    font-size: 13px;
    color: #000000;
    display: flex;
    align-items: center;

    &.selectstyle {
      justify-content: space-between;
    }
  }
}

@media (max-width: 767px) {
  .out2 {
    padding: 0;
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-auto-rows: minmax(40px, auto);
    grid-column-gap: 15px;
  }

  .input {
    width: 100%;
  }

  .selectstyle {
    flex-direction: column;
    height: 90px;
  }

  .selectt {
    max-height: 40px;
    width: 100%;
  }

  .loginbtn {
    width: 80%;
  }

  .outbank {
    flex-direction: column;
    min-height: 100px;
  }

  .bankcode {
    height: 40px;
    background-position: 90%;
  }
}

textarea {
  resize: none;
}
</style>
