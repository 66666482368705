<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <i :class="['el-icon-error', $style.icon]" @click="$emit('close')"/>
      <img
        :src="image"
        :class="[$style.text, 'animate__animated', 'animate__fadeIn', 'animate__faster']"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: null
  }
}
</script>

<style lang="scss" module>
.icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  font-size: 40px;
  right: 20px;
  color: #fff;
}

@media (min-width: 1366px) {
  .rootinner {
    z-index: 4;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 42px 22px 42px;
    box-sizing: border-box;
    width: 500px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .text {
    object-fit: contain;
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    margin-top: 44px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .rootinner {
    z-index: 4;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 42px 22px 42px;
    box-sizing: border-box;
    width: 500px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .icon {
    cursor: pointer;
    position: absolute;
    top: 20px;
    font-size: 30px;
    right: 20px;
    color: #fff;
  }

  .text {
    object-fit: contain;
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    margin-top: 44px;
  }
}

@media (max-width: 767px) {
  .rootinner {
    z-index: 4;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 42px 22px 42px;
    box-sizing: border-box;
    width: 80%;
    background: #FFFFFF;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    margin-top: 44px;
  }

  .text {
    object-fit: contain;
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }
}
</style>
