import { request } from '@/request/request'
import { webApiFunciton, openWebApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'

const couponJs = {
  state: {
    couponList: null,
    groupCouponList: null,
    productCoupon: null,
    userCouponList: null,
    couponBySku: null,
    userCouponListHavePage: null
  },
  mutations: {
    couponList (state, payload) {
      state.couponList = payload.couponList
    },
    groupCouponList (state, payload) {
      state.groupCouponList = payload.groupCouponList
    },
    productCoupon (state, payload) {
      state.productCoupon = payload.productCoupon
    },
    userCouponList (state, payload) {
      state.userCouponList = payload.userCouponList
    },
    userCouponListHavePage (state, payload) {
      state.userCouponListHavePage = payload.userCouponListHavePage
    },
    couponBySku (state, payload) {
      state.couponBySku = payload.couponBySku
    }
  },
  actions: {
    // 使用優惠券
    async useCouponPoint (store, payload) {
      const url = `/carts/mine/coupons/${payload.couponCode}`
      await request.put(url, {}, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async getCouponList ({ commit }, payload) {
      // getCouponList getGroupingCouponList getCouponBySku
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = payload.action !== 'getCouponBySku'
        ? await webApiFunciton(payload.action, token, payload.bodyObj)
        : await openWebApiFunciton(payload.action, token, payload.bodyObj)
      const typeObj = {
        all: 'couponList',
        allGroup: 'groupCouponList',
        forProduct: 'productCoupon'
      }[payload.typeList]
      commit({ type: typeObj, [typeObj]: data })
    },
    async userCouponAction ({ commit }, payload) {
      // 列表 getMyCoupon, 儲存 receiveCoupon, 刪除 deleteMyCoupon getMyCouponWithPager
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await webApiFunciton(payload.action, token, payload.bodyObj)
      payload.action === 'getMyCouponWithPager'
        ? commit({ type: 'userCouponListHavePage', userCouponListHavePage: data })
        : commit({ type: 'userCouponList', userCouponList: data })
    },
    async couponBySku ({ commit }, payload) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await openWebApiFunciton(payload.action, token, payload.bodyObj)
      commit({ type: 'couponBySku', couponBySku: data })
    }
  },
  getters: {
    homeList: (state) => {
      if (state.couponList && state.groupCouponList && state.userCouponList) {
        const list = []
        list.push(...state.couponList, ...state.groupCouponList)
        const cmp = (o1, o2) => o1 === o2
        const userCouponList = state.userCouponList.map(ele => ele.coupon_id)
        return list.map(ele => {
          let isGet
          userCouponList.some(ele2 => cmp(ele2, ele.coupon_id))
            ? isGet = true
            : isGet = false
          let isExpired
          !ele.to_date
            ? isExpired = false
            : isExpired = new Date(new Date(ele.to_date) - new Date()).getDate() <= 7
          return {
            ...ele,
            isGet,
            isExpired
          }
        })
      } else {
        return []
      }
    },
    productCouponList: (state) => {
      if (state.productCoupon && state.userCouponList) {
        const list = []
        list.push(...state.productCoupon)
        const cmp = (o1, o2) => o1 === o2
        const userCouponList = state.userCouponList.map(ele => ele.coupon_id)
        return list.map(ele => {
          let isGet
          userCouponList.some(ele2 => cmp(ele2, ele.coupon_id))
            ? isGet = true
            : isGet = false
          let isExpired
          !ele.to_date
            ? isExpired = false
            : isExpired = new Date(new Date(ele.to_date) - new Date()).getDate() <= 7
          return {
            ...ele,
            isGet,
            isExpired
          }
        })
      } else {
        return []
      }
    },
    userCouponListHavePage: (state) => {
      if (state.userCouponListHavePage) {
        return state.userCouponListHavePage
      } else {
        return { items: [], total: 0 }
      }
    },
    userCouponList: (state) => {
      if (state.userCouponList) {
        return state.userCouponList.length > 0 ? state.userCouponList : undefined
      } else {
        return undefined
      }
    },
    couponBySku: (state) => {
      if (state.couponBySku) {
        return state.couponBySku.length > 0 ? state.couponBySku : undefined
      } else {
        return undefined
      }
    }
  }
}

export default {
  couponJs
}
