<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.bg">
        <p :class="$style.text">{{ message }}</p>
        <div :class="$style.btn" @click="$emit('close')">確認</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: null
  }
}
</script>

<style lang="scss" module>
.rootinner {
  z-index: 4;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px 42px 22px 42px;
  box-sizing: border-box;
  width: 500px;
  background: #FFFFFF;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #FF6100;
  border-radius: 999rem;
  color: #fff;
  margin-top: 44px;
}

@media (max-width: 1365px) {
}

@media (max-width: 767px) {
  .bg {
    width: 80%;
  }
}
</style>
