import { getProduct, productListData } from '@/request'
import { request } from '@/request/request'

const listJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    commonList: null,
    groupList: null,
    employeeList: null,
    discountList: null,
    listProducts: null,
    hotList: null
  },
  mutations: {
    commonList (state, payload) {
      state.commonList = payload.commonList
    },
    groupList (state, payload) {
      state.groupList = payload.groupList
    },
    employeeList (state, payload) {
      state.employeeList = payload.employeeList
    },
    discountList (state, payload) {
      state.discountList = payload.discountList
    },
    listProducts (state, payload) {
      state.listProducts = payload.listProducts
    },
    hotList (state, payload) {
      state.hotList = payload.hotList
    }
  },
  actions: {
    async getHotList (store, payload) {
      const url = '/products'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'type_id',
          'searchCriteria[filterGroups][0][filters][0][value]': 'configurable',
          'searchCriteria[filterGroups][1][filters][0][field]': 'sell_type',
          'searchCriteria[filterGroups][1][filters][0][value]': 5643,
          'searchCriteria[filterGroups][2][filters][0][field]': 'category_id',
          'searchCriteria[filterGroups][2][filters][0][value]': 61,
          'searchCriteria[filterGroups][3][filters][0][field]': 'status',
          'searchCriteria[filterGroups][3][filters][0][value]': 1,
          'searchCriteria[pageSize]': 16,
          'searchCriteria[currentPage]': payload.currentPage
        }
      })
      store.commit({ type: 'hotList', hotList: response.data })
    },
    async getListProducts ({ state, commit }, { typeValue, pageSize }) {
      const data = await getProduct('/products', state.adminToken, typeValue, pageSize)
      switch (typeValue) {
        case 5643:
          commit({ type: 'commonList', commonList: data })
          break
        case 5644:
          commit({ type: 'groupList', groupList: data })
          break
        case 5645:
          commit({ type: 'employeeList', employeeList: data })
          break
        case 5646:
          commit({ type: 'discountList', discountList: data })
          break
      }
    },
    async getLists ({ state, commit }, { typeValue, pageSize, currentPage }) {
      const data = await getProduct('/products', state.adminToken, typeValue, pageSize, currentPage)
      commit({ type: 'listProducts', listProducts: data })
    },
    async searchList (store, payload) {
      const response = await request.get('/products', {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'type_id',
          'searchCriteria[filterGroups][0][filters][0][value]': 'configurable',
          'searchCriteria[filterGroups][1][filters][0][field]': 'name',
          'searchCriteria[filterGroups][1][filters][0][value]': payload.search,
          'searchCriteria[filterGroups][1][filters][0][conditionType]': 'like',
          'searchCriteria[filterGroups][2][filters][0][field]': 'sell_type',
          'searchCriteria[filterGroups][2][filters][0][value]': 5645,
          'searchCriteria[filterGroups][2][filters][0][conditionType]': (payload.online === 5645) ? 'in' : 'nin',
          'searchCriteria[filterGroups][3][filters][0][field]': 'status',
          'searchCriteria[filterGroups][3][filters][0][value]': 1,
          'searchCriteria[pageSize]': 16,
          'searchCriteria[currentPage]': payload.page
        }
      })
      store.commit({ type: 'listProducts', listProducts: response.data })
    },
    async searchListClass (store, payload) {
      const response = await request.get('/products', {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'type_id',
          'searchCriteria[filterGroups][0][filters][0][value]': 'configurable',
          'searchCriteria[filterGroups][1][filters][0][field]': 'sell_type',
          'searchCriteria[filterGroups][1][filters][0][value]': payload.online,
          'searchCriteria[filterGroups][2][filters][0][field]': 'category_id',
          'searchCriteria[filterGroups][2][filters][0][value]': payload.category_id,
          'searchCriteria[filterGroups][3][filters][0][field]': 'status',
          'searchCriteria[filterGroups][3][filters][0][value]': 1,
          'searchCriteria[pageSize]': 16,
          'searchCriteria[currentPage]': payload.page
        }
      })
      store.commit({ type: 'listProducts', listProducts: response.data })
    }
  },
  getters: {
    commonList: (state) => {
      return productListData(state.commonList)
    },
    groupList: (state) => {
      return productListData(state.groupList)
    },
    employeeList: (state) => {
      return productListData(state.employeeList)
    },
    discountList: (state) => {
      return productListData(state.discountList)
    },
    listProducts: (state) => {
      return productListData(state.listProducts)
    },
    hotList: (state) => {
      return productListData(state.hotList)
    }
  }
}

export default {
  listJs
}
