import store from '@/store'
import Home from '../../views/home.vue'
import Shareturn from '../../views/shareturn.vue'

const isEmployee = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({
      name: 'employeelist',
      params: {
        current: 1
      }
    })
  } else {
    next()
  }
}

export default [{
  path: 'home',
  name: 'home',
  component: Home,
  beforeEnter: isEmployee,
  meta: { search: true, pluin: true }
}, {
  path: 'shareturn/:sharePersonalId/:sharePersonalName',
  name: 'shareturn',
  component: Shareturn,
  beforeEnter: isEmployee
}]
