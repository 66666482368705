import store from '@/store'
import List from '../../views/product/list.vue'
import Product from '../../views/product/product.vue'
import KolList from '../../views/kol/kollist.vue'
import Hotlist from '../../views/product/hotlist.vue'
import Personal from '../../views/kol/personal.vue'
// import ProductNotMulti from '../../views/product/productNotMulti.vue'

const isEmployee = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else {
    next()
  }
}

export default [{
  path: 'commonList/commonProduct/:skuId/:Id/:current',
  name: 'commonProduct',
  component: Product,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'common',
    number: 5643,
    pluin: true
  }
}, {
  path: 'commonList/:current',
  name: 'commonList',
  component: List,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'common',
    number: 5643,
    pluin: true
  }
}, {
  path: 'kolList/:current',
  name: 'kolList',
  component: KolList,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'kol',
    number: 5642,
    pluin: true
  }
}, {
  path: 'kolList/kolProduct/:skuId/:Id/:current',
  name: 'kolProduct',
  component: Product,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'kol',
    number: 5642,
    pluin: true
  }
}, {
  path: 'kolpersonal/:kolId/:current',
  name: 'personal',
  component: Personal,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    pluin: true
  }
}, {
  path: 'hotlist/:current',
  name: 'hotlist',
  component: Hotlist,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'hot',
    pluin: true
  }
}, {
  path: 'searchlist/:typeWord/:current',
  name: 'searchList',
  component: List,
  query: {
    text: null,
    category_id: null
  },
  meta: {
    search: true,
    type: 'search',
    pluin: true
  }
}, {
  path: 'groupList/:current',
  name: 'groupList',
  component: List,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'group',
    number: 5644,
    pluin: true
  }
}, {
  path: 'groupList/groupProduct/:skuId/:Id/:current/:groupcurrent',
  name: 'groupProduct',
  component: Product,
  beforeEnter: isEmployee,
  query: {
    openId: null
  },
  meta: {
    search: true,
    type: 'group',
    number: 5644,
    pluin: true
  }
}, {
  path: 'discountList/:current',
  name: 'discountList',
  component: List,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'discount',
    number: 5646,
    pluin: true
  }
}, {
  path: 'discountList/discountProduct/:skuId/:Id/:current',
  name: 'discountProduct',
  component: Product,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'discount',
    number: 5646,
    pluin: true
  }
}]
