<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.inner">
        <div
          :class="[$style.bg,
            'animate__animated',
            'animate__faster',
            'animate__fadeInDownBig']"
        >
          <div :class="$style.top">
            <p :class="$style.title">
              <slot name="title"/>
            </p>
            <i
              :class="['el-icon-close', $style.title2]"
              @click="$emit('close')"
            />
          </div>
          <div :class="$style.main">
            <slot name="main"/>
            <slot name="btn"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    window.document.body.style.overflow = 'hidden'
  },
  destroyed () {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" module>
.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  width: 520px;
  border-radius: 12px;
  background-color: #ffffff;
}

.top {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #F7F8F9;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.main {
  padding: 40px 30px;
  box-sizing: border-box;
  min-height: 328px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.title2 {
  cursor: pointer;
  right: 22px;
  position: absolute;
  color: #C4C4C4;
  font-size: 30px;
  font-weight: 900;
  align-self: center;
}

@media (max-width: 767px) {
  .bg {
    max-width: 350px;
    width: 80%;
  }
}
</style>
