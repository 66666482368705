<template>
  <div :class="$style.root">
    <div
      :class="$style.rootinner"
      v-if="online === 'common'
    ">
      <div :class="[$style.inner, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']">
        <div :class="$style.closeout">
          <div :class="[$style.title, $style.title2]">VIP會員</div>
          <i class="el-icon-close" :class="$style.close" @click="$emit('close')"></i>
        </div>
          <p :class="$style.text">
            資格：一年內累積消費金額 $ {{ allTotalLevel[2].price }}
          </p>
          <p :class="$style.text">
            優惠：訂單現金可享 {{ allTotalLevel[2].discount * 100 }} ％ 回饋
          </p>
          <div :class="[$style.title, $style.title3]">白金VIP會員</div>
          <p :class="$style.text">
            資格：一年內累積消費金額 $ {{ allTotalLevel[3].price }}
          </p>
          <p :class="$style.text">
            優惠：訂單現金可享 {{ allTotalLevel[3].discount * 100 }} ％ 回饋
          </p>
      </div>
    </div>
    <div :class="$style.rootinner" v-if="online === 'employee'">
      <div :class="[$style.inner, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']">
        <div :class="$style.closeout">
          <div :class="[$style.title, $style.title7]">三級</div>
          <i class="el-icon-close" :class="$style.close" @click="$emit('close')"></i>
        </div>
          <p :class="$style.text">
            資格：一年內累積消費金額 $ {{ allTotalLevel[7].price }}
          </p>
          <p :class="$style.text">
            優惠：下一階段員購享 {{ allTotalLevel[7].discount * 100 }} 折
          </p>
          <div :class="[$style.title, $style.title8]">二級</div>
          <p :class="$style.text">
            資格：一年內累積消費金額 $ {{ allTotalLevel[8].price }}
          </p>
          <p :class="$style.text">
            優惠：下一階段員購享 {{ allTotalLevel[8].discount * 100 }} 折
          </p>
          <div :class="[$style.title, $style.title9]">一級</div>
          <p :class="$style.text">
            資格：一年內累積消費金額 $ {{ allTotalLevel[9].price }}
          </p>
          <p :class="$style.text">
            優惠：下一階段員購享 {{ allTotalLevel[9].discount * 100 }} 折
          </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allTotalLevel: Object
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" module>
.rootinner {
  z-index: 4;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner {
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 494px;
  min-height: 214px;
  border-radius: 10px;
  background-color: #ffffff;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 80px;
  height: 26px;
  border-radius: 999rem;
  font-weight: 400;
  font-size: 13px;
  background-color: #ff6100;

  &.title2 {
    color: #ff6100;
    background-image: linear-gradient(to top, #ffe143, #fffdf2, #ffe143);
  }

  &.title3 {
    background-image: linear-gradient(to top, #0075ff, #92c4ff, #0075ff);
  }

  &.title7 {
    margin-bottom: 15px;
    color: #FF6100;
    background-image: linear-gradient(180deg, #FFE143 0%, #FFFDF2 51.56%, #FFE143 100%);
  }

  &.title8 {
    margin: 15px 0;
    background-image: linear-gradient(180deg, #00D77F 0%, #95F8CF 51.56%, #00D77F 100%);
  }

  &.title9 {
    margin: 15px 0;
    background-image: linear-gradient(180deg, #0075FF 0%, #92C4FF 53.12%, #0075FF 100%);
  }
}

.text {
  font-size: 13px;
}

.close {
  cursor: pointer;
  color: #FF4B00;
  font-size: 20px;
}

.closeout {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1365px) {
  .rootinner {
    z-index: 4;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .inner {
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 310px;
    height: 300px;
    border-radius: 10px;
    border: solid 2px #ffc000;
    background-color: #ffffff;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 80px;
    height: 26px;
    border-radius: 999rem;
    font-weight: 400;
    font-size: 13px;
    background-color: #ff6100;

    &.title2 {
      color: #ff6100;
      background-image: linear-gradient(to top, #ffe143, #fffdf2, #ffe143);
    }

    &.title3 {
      background-image: linear-gradient(to top, #0075ff, #92c4ff, #0075ff);
    }

    &.title7 {
      margin-bottom: 15px;
      color: #FF6100;
      background-image: linear-gradient(180deg, #FFE143 0%, #FFFDF2 51.56%, #FFE143 100%);
    }

    &.title8 {
      margin: 15px 0;
      background-image: linear-gradient(180deg, #00D77F 0%, #95F8CF 51.56%, #00D77F 100%);
    }

    &.title9 {
      margin: 15px 0;
      background-image: linear-gradient(180deg, #0075FF 0%, #92C4FF 53.12%, #0075FF 100%);
    }
  }

  .text {
    font-size: 13px;
  }

  .close {
    font-size: 20px;
  }

  .closeout {
    display: flex;
    justify-content: space-between;
  }
}
</style>
