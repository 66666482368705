import Layout from '@/views/bottom/layout.vue'
import Onlyhtml from '@/views/bottom/onlyhtml.vue'
import Listhtml from '@/views/bottom/listhtml.vue'
import Problem from '../../views/bottom/problem.vue'

export default [{
  path: 'content',
  name: 'content',
  component: Layout,
  redirect: { name: 'contentme' },
  children: [{
    path: 'contentme',
    name: 'contentme',
    component: Onlyhtml,
    meta: {
      search: true,
      list: 'contentUser',
      pluin: true
    }
  }, {
    path: 'addme',
    name: 'addme',
    component: Listhtml,
    meta: {
      search: true,
      list: 'contentUser',
      pluin: true
    }
  }]
}, {
  path: 'aboutme',
  name: 'aboutme',
  component: Layout,
  redirect: { name: 'info' },
  children: [{
    path: 'info',
    name: 'info',
    component: Onlyhtml,
    meta: {
      search: true,
      list: 'aboutMe',
      pluin: true
    }
  }, {
    path: 'shoppingguide',
    name: 'shoppingguide',
    component: Listhtml,
    meta: {
      search: true,
      list: 'aboutMe',
      pluin: true
    }
  }, {
    path: 'problem',
    name: 'problem',
    component: Problem,
    meta: {
      search: true,
      list: 'aboutMe',
      pluin: true
    }
  }]
}, {
  path: 'privacy',
  name: 'privacy',
  component: Layout,
  redirect: { name: 'policy' },
  children: [{
    path: 'policy',
    name: 'policy',
    component: Onlyhtml,
    meta: {
      search: true,
      list: 'information',
      pluin: true
    }
  }, {
    path: 'declaration',
    name: 'declaration',
    component: Onlyhtml,
    meta: {
      search: true,
      list: 'information',
      pluin: true
    }
  }]
}]
