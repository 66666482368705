<template>
  <div :class="$style.root" v-if="homeList.length > 0">
    <div :class="$style.rootinner" v-if="webwatch">
      <div :class="['swiper-button-prev', $style.bg]"/>
      <swiper
        :class="['swiper', $style.outweb]"
        :options="swiperOptionvou"
        ref="mySwiper"
      >
        <swiper-slide
          :class="$style.imgout"
          v-for="(item, i) in homeList"
          :key="i"
        >
          <div
            :class="[$style.svg, {[$style.svg2]: item.isExpired }, {[$style.svg3]: item.isGet }]"
            @click="!item.isGet ? windowopenFn(i) : ''"
          >
            <span :class="$style.money">
              <span :class="$style.iconmoney">$</span>
              {{ Math.floor(item.discount_amount) }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
      <windowview
        :conpondata="conponData"
        v-if="windowopen"
        @close="close"
      />
      <div :class="['swiper-button-next', $style.bg]"/>
    </div>
    <div :class="$style.rootinner" v-else>
      <div :class="$style.left"/>
      <div :class="$style.out">
        <div :class="$style.imgout" v-for="(item, i) in homeList" :key="i">
          <div
            @click="!item.isGet ? windowopenFn(i) : ''"
            :class="[$style.svg, {[$style.svg2]: item.isExpired }, {[$style.svg3]: item.isGet }]"
          >
            <span :class="$style.money">
              <span :class="$style.iconmoney">$</span>
              {{ Math.floor(item.discount_amount) }}
            </span>
          </div>
        </div>
      </div>
      <div :class="$style.right"/>
      <windowview
        :conpondata="conponData"
        v-if="windowopen"
        @close="close"
      />
    </div>
  </div>
</template>

<script>
import windowview from '@/components/voucher/windowview.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapGetters } from 'vuex'
export default {
  components: {
    windowview,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      windowopen: false,
      conponData: '',
      swiperOptionvou: {
        slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'homeList'
    ])
  },
  methods: {
    close () {
      this.windowopen = false
    },
    windowopenFn (value) {
      this.conponData = this.homeList[value]
      this.windowopen = true
    }
  },
  async created () {
    if (this.online) {
      await Promise.all([
        this.$store.dispatch({
          type: 'getCouponList',
          action: 'getCouponList',
          typeList: 'all'
        }),
        this.$store.dispatch({
          type: 'getCouponList',
          action: 'getGroupingCouponList',
          typeList: 'allGroup'
        }),
        this.$store.dispatch({
          type: 'userCouponAction',
          action: 'getMyCoupon'
        })
      ])
    }
  }
}
</script>

<style lang="scss" module>
.rootinner {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 1110px;
  height: 100%;
  background: #f7f8f9;
}

.outweb {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  &:global(.swiper-wrapper) {
    justify-content: center;
  }
}

.svg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/img/conpon1.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 293px;
  height: 140px;
  cursor: pointer;
  background-size: contain;

  &.svg2 {
    background-image: url('../../assets/img/conpon3.png');
  }

  &.svg3 {
    background-image: url('../../assets/img/conpon2.png');
    cursor: not-allowed;
  }
}

.money {
  font-size: 63px;
  color: #fff;
  margin-left: -18px;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.67);

  .iconmoney {
    font-size: 22px;
    margin-right: -16px;
  }
}

.imgout {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  &:global(.swiper-button-next) {
    position: unset;
    padding-top: 20px;
    padding-left: 51px;
    color: #898989;
    z-index: 0;
  }

  &:global(.swiper-button-prev) {
    position: unset;
    padding-top: 20px;
    padding-right: 51px;
    color: #898989;
    z-index: 0;
  }
}

@media (max-width: 1365px) {
  .rootinner {
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    width: 768px;
    height: 100%;
    background: #fff;
  }

  .out {
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    -ms-overflow-style: none;
    overflow-x: auto;
  }

  .out::-webkit-scrollbar {
    display: none;
  }

  .svg {
    width: 223px;
    height: 140px;
    margin-right: 17px;
    cursor: pointer;
  }

  .left {
    position: absolute;
    width: 40px;
    height: 100%;
    background: -webkit-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .right {
    right: 0;
    position: absolute;
    width: 40px;
    height: 100%;
    background: -webkit-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    transform: (-1, 0, 0, 1, 0, 0);
  }

  .money {
    font-size: 50px;
    margin-right: -15px;

    .iconmoney {
      font-size: 20px;
      margin: -13px;
    }
  }
}

@media (max-width: 767px) {
  .rootinner {
    width: 100%;
    height: 125px;
  }

  .out {
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    -ms-overflow-style: none;
    overflow-x: auto;
  }

  .out::-webkit-scrollbar {
    display: none;
  }

  .money {
    font-size: 31px;
    margin: 0;

    .iconmoney {
      margin: -8px;
      font-size: 14px;
    }
  }

  .svg {
    width: 153px;
  }
}
</style>
