import { request } from '@/request/request'
import path from '../path'
// const [resp1, resp2] = await Promise.all([req1, req2])

const kolproduts = {
  state: {
    kolProdutsList: null,
    kolInfopro: null,
    kolInfoSelf: null,
    kolMainProduct: null,
    ...path
  },
  mutations: {
    kolProdutsList (state, payload) {
      state.kolProdutsList = payload.kolProdutsList
    },
    kolInfopro (state, payload) {
      if (payload.kolInfopro) {
        state.kolInfopro = payload.kolInfopro.map(ele => ele.data)
      } else {
        state.kolInfopro = null
      }
    },
    kolInfoSelf (state, payload) {
      state.kolInfoSelf = payload.kolInfoSelf
    },
    kolMainProduct (state, payload) {
      state.kolMainProduct = payload.kolMainProduct
    }
  },
  actions: {
    // 主打商品列表
    async kolProdutsList (store, payload) {
      const url = '/products'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'type_id',
          'searchCriteria[filterGroups][0][filters][0][value]': 'configurable',
          'searchCriteria[filterGroups][1][filters][0][field]': 'sell_type',
          'searchCriteria[filterGroups][1][filters][0][value]': 5642,
          // [kol_promote, 1] => filter 1打商品
          // [kol_id, 32] => filter Kol商品
          'searchCriteria[filterGroups][2][filters][1][field]': payload.field,
          'searchCriteria[filterGroups][2][filters][1][value]': payload.fieldValue,
          'searchCriteria[filterGroups][3][filters][0][field]': 'status',
          'searchCriteria[filterGroups][3][filters][0][value]': 1,
          // 筆數
          'searchCriteria[pageSize]': payload.pageSize,
          // 分頁
          'searchCriteria[currentPage]': payload.currentPage
        }
      })
      await store.commit({ type: 'kolProdutsList', kolProdutsList: response.data })
      store.dispatch('kolInfopro')
    },
    // 主打商品開團資料
    async kolInfopro (store, payload) {
      const data = await Promise.all(
        store.getters.kolProdutsList.list.map(ele => {
          const url = `${'/customers/' + ele.kolId}`
          const promise = request.get(url, {
            headers: {
              Authorization: `Bearer ${store.state.adminToken}`
            }
          })
          return promise
        })
      )
      store.commit({ type: 'kolInfopro', kolInfopro: data })
    },
    // 個人頁主打商品和其餘商品
    async mainKolProducts (store, payload) {
      const url = '/products'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'type_id',
          'searchCriteria[filterGroups][0][filters][0][value]': 'configurable',
          'searchCriteria[filterGroups][1][filters][0][field]': 'sell_type',
          'searchCriteria[filterGroups][1][filters][0][value]': 5642,
          'searchCriteria[filterGroups][2][filters][0][field]': 'kol_promote',
          'searchCriteria[filterGroups][2][filters][0][value]': payload.kol_promote,
          'searchCriteria[filterGroups][3][filters][0][field]': 'kol_id',
          'searchCriteria[filterGroups][3][filters][0][value]': payload.kol_id,
          'searchCriteria[filterGroups][4][filters][0][field]': 'status',
          'searchCriteria[filterGroups][4][filters][0][value]': 1,
          // 筆數
          'searchCriteria[pageSize]': payload.pageSize,
          // 分頁
          'searchCriteria[currentPage]': payload.currentPage
        }
      })
      if (payload.kol_promote === 0) {
        // 非主打
        store.commit({ type: 'kolProdutsList', kolProdutsList: response.data })
      } else if (payload.kol_promote === 1) {
        // 主打商品
        store.commit({ type: 'kolMainProduct', kolMainProduct: response.data })
      }
    },
    // 個人頁資料
    async getKolInfoSelf (store, payload) {
      const url = `${'/customers/' + payload.kolId}`
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'kolInfoSelf', kolInfoSelf: response.data })
    }
  },
  getters: {
    kolProdutsList: (state) => {
      if (state.kolProdutsList) {
        const total = state.kolProdutsList.total_count
        const list = state.kolProdutsList.items.map(ele => {
          const name = ele.name ? ele.name : undefined
          let image = ele.media_gallery_entries[0]
          image = image ? (state.imgUrl + image.file) : require('../../assets/img/cvimall_comingsoon_small.png')
          let oldPrizeNum = ele.custom_attributes.find(ele2 => ele2.attribute_code === 'originalprice')
          oldPrizeNum = oldPrizeNum ? Math.floor(oldPrizeNum.value) : 0
          let newPrizeNum = ele.custom_attributes.find(ele => ele.attribute_code === 'newprice')
          newPrizeNum = newPrizeNum ? Math.floor(newPrizeNum.value) : oldPrizeNum
          let description = ele.custom_attributes.find(ele => ele.attribute_code === 'meta_description')
          description = description ? description.value : '目前沒有說明唷！'
          let kolId = ele.custom_attributes.find(ele => ele.attribute_code === 'kol_id')
          kolId = kolId ? kolId.value : undefined
          let time = ele.custom_attributes.find(ele => ele.attribute_code === 'kol_promotedate')
          time = time ? time.value : 0
          const sku = ele.sku ? ele.sku : undefined
          const id = ele.id ? ele.id : undefined
          return {
            name,
            image,
            oldPrizeNum,
            newPrizeNum,
            sku,
            description,
            kolId,
            time,
            id
          }
        })
        return {
          list,
          total
        }
      } else {
        return {
          list: [],
          total: 0
        }
      }
    },
    kolInfopro: (state) => {
      // 取得kol資訊
      if (state.kolInfopro) {
        const data = state.kolInfopro.map(ele => {
          if (ele.custom_attributes) {
            let name = ele.custom_attributes.find(ele => ele.attribute_code === 'user_nickname')
            name = name ? name.value : '未設定'
            let image = ele.custom_attributes.find(ele => ele.attribute_code === 'kol_image')
            image = image ? (state.userPhoto + image.value) : require('../../assets/img/cvimall_comingsoon_small.png')
            return {
              name,
              image
            }
          } else {
            return {
              name: '未設定',
              image: require('../../assets/img/cvimall_comingsoon_small.png')
            }
          }
        })
        return data
      } else {
        return undefined
      }
    },
    mainProduct: (state) => {
      if (state.kolMainProduct && state.kolMainProduct.items[0]) {
        const url = state.kolMainProduct.items[0]
        const name = url.name ? url.name : '未設定'
        let image = url.media_gallery_entries
        image = image ? image.map(ele => state.imgUrl + ele.file) : []
        if (image.length < 3) {
          for (let i = image.length; i < 3; i++) {
            image[i] = require('../../assets/img/cvimall_comingsoon_small.png')
          }
        }
        let description = url.custom_attributes.find(ele => ele.attribute_code === 'meta_description')
        description = description ? description.value : '目前未有說明'
        let time = url.custom_attributes.find(ele => ele.attribute_code === 'kol_promotedate')
        time = time ? time.value : 0
        const sku = url.sku ? url.sku : undefined
        const id = url.id ? url.id : undefined
        return {
          name,
          image,
          sku,
          description,
          time,
          id
        }
      } else {
        return {
          name: '未設定',
          image: [require('../../assets/img/cvimall_comingsoon_small.png'),
            require('../../assets/img/cvimall_comingsoon_small.png'),
            require('../../assets/img/cvimall_comingsoon_small.png')
          ],
          sku: undefined,
          description: '未設定',
          time: 0
        }
      }
    },
    kolInfoSelfList: (state) => {
      // 取得kol自己的資料
      if (state.kolInfoSelf) {
        const url = state.kolInfoSelf.custom_attributes
        const kolName = url.find(ele => ele.attribute_code === 'user_nickname').value
        let kolImage = url.find(ele => ele.attribute_code === 'kol_image')
        kolImage = kolImage ? state.userPhoto + kolImage.value : require('../../assets/img/cvimall_comingsoon_small.png')
        let userImage = url.find(ele => ele.attribute_code === 'customer_image')
        userImage = userImage ? state.userPhoto + userImage.value : require('../../assets/img/cvimall_comingsoon_small.png')
        let fbUrl = url.find(ele => ele.attribute_code === 'kol_fb')
        fbUrl = fbUrl ? fbUrl.value : undefined
        let igUrl = url.find(ele => ele.attribute_code === 'kol_ig')
        igUrl = igUrl ? igUrl.value : undefined
        let utbUrl = url.find(ele => ele.attribute_code === 'kol_utube')
        utbUrl = utbUrl ? utbUrl.value : undefined
        let postId = url.find(ele => ele.attribute_code === 'kol_postid')
        postId = postId ? postId.value : undefined
        return {
          kolName,
          kolImage,
          userImage,
          fbUrl,
          igUrl,
          utbUrl,
          postId
        }
      } else {
        return {
          kolName: '',
          kolImage: require('../../assets/img/cvimall_comingsoon_small.png'),
          userImage: require('../../assets/img/cvimall_comingsoon_small.png'),
          fbUrl: undefined,
          igUrl: undefined,
          utbUrl: undefined,
          postId: undefined
        }
      }
    }
  }
}

export default {
  kolproduts
}
