<template>
  <div :class="$style.root">
    <div :class="$style.date">
      <div
        v-for="(item, i) in progressDataList"
        :key="i"
      >
      {{ item.created_at
          ? item.created_at.split(' ')[0].split('-')[1]
            + '/' +
            item.created_at.split(' ')[0].split('-')[2]
          : '未開始'
      }}
    </div>
    </div>
    <div :class="$style.progresss">
      <div :class="$style.progresss2">
        <div :class="$style.one"></div>
        <div :class="$style.line"></div>
        <div :class="[$style.one, {[$style.no]: progressAction <= 1}]"></div>
        <div :class="[$style.line, {[$style.no]: progressAction <= 1}]"></div>
        <div :class="[$style.one, {[$style.no]: progressAction <= 2}]"></div>
        <div :class="[$style.line, {[$style.no]: progressAction <= 2}]"></div>
        <div :class="[$style.one, {[$style.no]: progressAction <= 3}]"></div>
        <div :class="[$style.line, {[$style.no]: progressAction <= 3}]"></div>
        <div :class="[$style.one, {[$style.no]: progressAction <= 4}]"></div>
      </div>
    </div>
    <div :class="[$style.date, $style.date2]">
      <p>受理退貨</p>
      <p>退貨處理中</p>
      <p>退貨完成</p>
      <p>退款中</p>
      <p>退款退貨完成</p>
    </div>
    <div :class="$style.text">
      <div
        :class="$style.textinner"
        v-for="(item, i) in progressData"
        :key="i"
      >
        <div :class="$style.datetext">
          {{ item.created_at ? item.created_at.split(' ')[0] : '' }}
        </div>
        <div>{{ item.name ? item.name : '' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progressData: Array
  },
  computed: {
    progressDataList () {
      let array = this.progressData
      if (this.progressData.length < 5) {
        for (let i = this.progressData.length; i < 5; i++) {
          array[i] = { created_at: undefined, name: undefined }
        }
      }
      return array
    },
    // Array 要五個都有資料才是真的進度跑完 沒有就是後台操作有問題
    progressAction () {
      return this.progressDataList.map(ele => ele.created_at).indexOf(undefined) !== -1
        ? this.progressDataList.map(ele => ele.created_at).indexOf(undefined)
        : 5
    }
  }
}
</script>

<style lang="scss" module>
.innerroot {
  position: fixed;
  padding: 110px 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  padding: 28px;
  box-sizing: border-box;
  width: 740px;
  height: auto;
  border-radius: 10px;
  border: solid 2px #00d77f;
  background-color: #ffffff;
}

.top {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
}

.top2 {
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #ccc;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.titlething {
  padding: 0 17px;
  display: grid;
  grid-template-columns: 441px 1fr 1fr 1fr;
  grid-template-rows: 42px;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
}

.icon {
  width: 20px;
  height: 20px;
  background: #00d77f;
}

.date {
  padding: 32px 52px 0 52px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #000;

  &.date2 {
    padding: 0 28px 52px 46px;
    justify-content: space-between;
  }
}

.progresss {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progresss2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}

.one {
  width: 20px;
  height: 20px;
  background-color: #00d77f;

  &.no {
    background-color: #ccc;
  }
}

.line {
  width: 114px;
  height: 5px;
  background-color: #00d77f;

  &.no {
    background-color: #ccc;
  }
}

.imgout {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 60px;
  grid-column-gap: 16px;
  align-items: center;
}

.title2 {
  cursor: pointer;
  color: #888;
  font-size: 26px;
  font-weight: 400;
}

.title3 {
  cursor: pointer;
  color: #000;
  font-size: 20px;
  font-weight: 900;
}

.outside {
  font-size: 14px;
  padding: 17px;
}

.title5 {
  font-weight: 500;
  padding: 20px 0;
}

.textinner {
  display: flex;
  margin-bottom: 10px;
}

.datetext {
  margin-right: 25px;
}

.textinnerrr {
  font-size: 14px;
}

.text {
  font-size: 14px;
  padding: 0 32px;
  margin-bottom: 32px;
}

.menu {
  padding: 10px;
}

.px12 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 17px;
}

.textcenter {
  justify-self: center;
}

.thingout {
  padding: 0 17px;
  display: grid;
  grid-template-columns: 441px 1fr 1fr 1fr;
  grid-template-rows: 92px;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
}

.img {
  width: 60px;
  height: 60px;
  background-color: #c4c4c4;
}

.textthing {
  font-size: 14px;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.textinn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inputsize {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.number {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.truemoney {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 1365px) {
  .progresss2 {
    width: 100%;
    padding: 30px 52px;
    box-sizing: border-box;
  }

  .line {
    flex: 1 0;
  }
}

@media (max-width: 767px) {
  .date {
    padding: 0;
    margin-top: 10px;

    &.date2 {
      flex-direction: column;
      padding: 0 10px;
      height: 140px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid #00d77f;
    }
  }

  .progresss2 {
    padding: 10px;
  }

  .line {
    flex: 1 0;
  }

  .text {
    padding: 0 10px;
    margin: 0;
  }

  .textinner {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
