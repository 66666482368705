<template>
  <div :class="$style.root">
    <div :class="$style.titletext" v-if="webwatch">開團資訊</div>
    <div :class="$style.listitle" v-if="webwatch">
      <div>日期 / 項目</div>
      <div>商品編號 / 名稱</div>
      <div>開團日期</div>
      <div>當日銷售</div>
    </div>
    <div :class="$style.listout">
      <div
        :class="$style.list"
        v-for="(item, i) in visibleOpenkol.list"
        :key="i"
      >
      <div :class="$style.listinner">{{ item.start.split(' ')[0] }}</div>
      <div :class="$style.nodiv">
        <div :class="[$style.listinner, $style.mar]">
          {{ item.id }}
        </div>
        <div :class="[$style.listinner, $style.mar]">
          {{ item.name }}
        </div>
      </div>
      <div v-if="webwatch">
        <div :class="$style.listinner">
          <span :class="$style.colortext">起</span>
          {{ item.start }}
        </div>
        <div :class="$style.listinner">
          <span :class="$style.colortext2">訖</span>
          {{ item.end }}
        </div>
      </div>
      <div :class="$style.listinner" v-else>
        開始：{{ item.start }} - 結束：{{ item.end }}
      </div>
      <div :class="$style.innerno">
        <div :class="$style.sell">售出 {{ kolproductNumber[i] }} 個</div>
        <div :class="$style.sell">收入 {{ kolproductNumber[i] * Math.floor(item.price) }}</div>
      </div>
    </div>
    </div>
    <page
      v-show="openkol.total > 0"
      :pageNumber="openkol.total"
      :pageSize="8"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import page from '@/components/page.vue'
export default {
  components: {
    page
  },
  watch: {
    async openkol () {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'kolproductNumber'
        })
      } finally {
        this.$emit('loading', false)
      }
    }
  },
  computed: {
    ...mapGetters([
      'openkol',
      'kolproductNumber',
      'visibleOpenkol'
    ])
  },
  methods: {
    async pageFn (value) {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'openkol',
          kolId: this.$route.params.userId,
          currentPage: value
        })
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
    // showSth () {
    //   console.log('openkol', this.openkol)
    //   console.log('kolproductNumber', this.kolproductNumber)
    //   console.log('visibleOpenkol', this.visibleOpenkol)
    // }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .titletext {
    display: flex;
    align-items: center;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    padding-left: 12px;
    box-sizing: border-box;
    border-left: 8px solid #00D77F;
  }

  .listitle {
    margin-top: 61px;
    width: 100%;
    border-top: 2px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
    padding-left: 21px;
    display: grid;
    align-items: center;
    grid-template-columns: 132px 256px 221px 1fr;
    grid-template-rows: 64px;
    font-size: 14px;
    box-sizing: border-box;
  }

  .list {
    border-bottom: 1px solid #E5E5E5;
    padding-left: 21px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    grid-template-columns: 132px 256px 221px 1fr;
    grid-template-rows: 90px;
    grid-column-gap: 10px;
    font-size: 14px;
  }

  .listinner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.mar {
      padding-right: 60px;
    }
  }

  .listout {
    min-height: 560px;
  }

  .colortext {
    color: #FFC000;
  }

  .colortext2 {
    color: #00D77F;
  }
}

@media (max-width: 1365px) {
  .root {
    min-height: calc(100vh - 52px - 32px);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 720px;
    align-self: center;
  }

  .nodiv {
    display: contents;
  }

  .inner {
    padding: 15px;
    box-sizing: border-box;
  }

  .list {
    border-top: 2px solid #E5E5E5;
    border-bottom: 2px solid #E5E5E5;
    margin-bottom: 30px;
  }

  .listinner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    min-height: 30px;
    border-bottom: 1px solid #E5E5E5;
    font-size: 14px;
    line-height: 20px;
  }

  .innerno {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .listout {
    min-height: calc(100vh - 230px);
    margin-top: 50px;
  }
}
</style>
