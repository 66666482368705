<template>
  <div class="root">
    <div class="innerkol">
      <div class="top">
        <div class="title">
          KOL精選
        </div>
        <div
          v-if="kolProdutsList.total"
          @click="goList"
          class="watch"
        >
          查看全部
          <i class="el-icon-arrow-right"/>
        </div>
      </div>
      <div class="mainout" v-if="kolProdutsList.total">
        <div class="main">
        <div
          v-for="(item, i) in kolList" :key="i"
          class="bottominner"
        >
          <div
            @click="kolInfoGo(item.kolId)"
            class="imgout imgcss"
            :style="{
              backgroundImage: `url(${ item.kolInfo.image })`
            }"
          />
          <div class="name">
            <p
              @click="kolInfoGo(item.kolId)"
              class="nametitle"
            >
              {{ item.kolInfo.name }}
            </p>
            <div class="time">
              <i class="el-icon-time" style="margin-right: 5px;"/>
              <p v-if="webwatch">
                時間 :
              </p>
              <div v-if="time[i]">
                {{ time[i].day ? time[i].day + '日' : '' }}
                {{ time[i].hours ? time[i].hours + '時' : '' }}
                {{ time[i].minutes ? time[i].minutes + '分' : '' }}
                {{ time[i].seconds ? time[i].seconds + '秒' : '' }}
                {{ time[i].seconds ? '' : '已結束' }}
              </div>
              <div v-else>已結束</div>
            </div>
          </div>
          <div
            class="btm"
            @click="kolproduts(item)"
          >
            <div
              class="groupimg imgcss"
              :style="{ backgroundImage: `url(${ item.image })` }"
            />
            <div class="btmtext">
              <p class="btmtitle">{{ item.name }}</p>
              <div class="btmtitle2">
                <p :class="webwatch ? 'textlangthree' : 'textlang'">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-else :class="['noproduct', 'noborder']">
        目前沒有商品喔！
      </div>
    </div>
  </div>
</template>

<script>
import koljs from '@/js/kollistjs.js'
import '@/css/kollist.scss'
import '@/css/image.scss'
export default {
  mixins: [
    koljs
  ]
}
</script>
