<template>
  <div :class="$style.root">
    <div :class="$style.line"/>
    <div :class="$style.inner" v-if="webwatch">
      <div :class="$style.area">
        <img src="../../assets/img/ok.png" :class="$style.iconstyle"/>
      </div>
      <div :class="$style.area">
        <p :class="$style.title">謝謝您！您的訂單已經成立！</p>
        <p :class="$style.text">訂單編號: {{ this.$route.params.orderId }}</p>
        <p :class="$style.text2">訂單確認電郵已經發送到您的電子信箱：</p>
        <p :class="$style.text2">{{ commonUserInfo.email }}</p>
        <div :class="$style.textbig">
          請於
          <span style="border-bottom: 1px solid rgba(255, 97, 0, 1);">
            週四 12:30前
          </span>
          <p>至財會繳款 並於 週五 取貨</p>
        </div>
        <router-link
          :to="{ name: 'home'}"
          tag="div"
          :class="$style.btn"
        >
          確認
        </router-link>
      </div>
    </div>
    <div :class="$style.inner" v-else>
      <div :class="$style.area">
        <img src="../../assets/img/ok.png" :class="$style.iconstyle"/>
      </div>
      <div :class="$style.area">
        <p :class="$style.title">謝謝您！您的訂單已經成立！</p>
        <p :class="$style.text">訂單編號: {{ this.$route.params.orderId }}</p>
        <p :class="$style.text">訂單確認電郵已經發送到您的電子信箱：</p>
        <p :class="$style.text">{{ commonUserInfo.email }}</p>
        <div :class="$style.textbig">
          請於
          <span style="border-bottom: 1px solid rgba(255, 97, 0, 1);">
            週四 12:30前
          </span>
          <p>至財會繳款 並於 週五 取貨</p>
        </div>
        <router-link
          :to="{ name: 'home'}"
          tag="div"
          :class="$style.btn"
        >
          確認
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'commonUserInfo'
    ])
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    width: 1110px;
    padding: 67px 0 80px 0;
    box-sizing: border-box;
  }

  .area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    padding: 42px 0 28px 0;
  }

  .text {
    padding-bottom: 28px;
    font-size: 14px;
    color: #000;
  }

  .text2 {
    padding-bottom: 8px;
    font-size: 14px;
  }

  .iconstyle {
    width: 100px;
    height: 100px;
  }

  .textbig {
    margin-top: 28px;
    padding: 18px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    box-sizing: border-box;
    background: rgba(255, 97, 0, 0.1);
    font-size: 14px;
    color: #FF6100;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    margin-top: 36px;
  }
}

@media (max-width: 1365px) {
  .line {
    height: 1px;
    background: #ccc;
    position: sticky;
    top: 0;
  }

  .root {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 52px);
  }

  .inner {
    padding: 15px;
    box-sizing: border-box;
    margin: auto auto;
  }

  .area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 22px;
    font-weight: 400;
    color: #000000;
    padding: 25px;
  }

  .text {
    padding: 5px;
    font-size: 20px;
    color: #000000;
  }

  .iconstyle {
    padding-top: 30px;
    width: 200px;
    height: 200px;
  }

  .textbig {
    margin-top: 43px;
    text-align: center;
    width: 100%;
    padding: 15px 28px;
    box-sizing: border-box;
    background: rgba(255, 97, 0, 0.1);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #FF6100;
    line-height: 30px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    margin: 26px 0 30px 0;
  }
}

@media (max-width: 767px) {
  .line {
    height: 1px;
    background: #ccc;
    position: sticky;
    top: 0;
  }

  .root {
    display: flex;
    flex-direction: column;
  }

  .inner {
    padding: 15px;
    box-sizing: border-box;
    margin: auto auto;
  }

  .area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    padding: 25px;
  }

  .text {
    padding: 5px;
    font-size: 14px;
    color: #000000;
  }

  .iconstyle {
    padding-top: 30px;
    width: 68px;
    height: 68px;
  }

  .textbig {
    margin-top: 43px;
    text-align: center;
    width: 100%;
    padding: 15px 28px;
    box-sizing: border-box;
    background: rgba(255, 97, 0, 0.1);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #FF6100;
    line-height: 30px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 31px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    margin: 26px 0 30px 0;
  }
}
</style>
