<template>
  <div :class="$style.root">
    <div :class="$style.inner" v-if="webwatch">
      <img src="../assets/img/weberror.png" :class="$style.size"/>
    </div>
    <div :class="$style.inner" v-else>
      <p :class="$style.error">404</p>
      <img src="../assets/img/error.png" :class="$style.size"/>
      <p :class="$style.error2">此頁面不存在...</p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 52px);
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
  }

  .size  {
    width: 574px;
    height: auto;
  }
}

@media (max-width: 1365px) {
  .root {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 52px);
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
  }

  .size  {
    width: 300px;
    height: auto;
  }

  .error {
    position: absolute;
    top: 28%;
    left: 14%;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    color: #C8D6CA;
  }

  .error2 {
    position: absolute;
    bottom: 28%;
    right: 14%;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #C8D6CA;
  }
}
</style>
