<template>
  <div :class="$style.root">
    <p :class="$style.title">
      重新設定密碼
    </p>
    <div :class="$style.main" v-if="fix">
      <form :class="$style.form" >
        <div :class="$style.variCode">
          <span :class="$style.titlesize">手機號碼</span>
          <input
            :class="$style.input"
            placeholder="請輸入您的手機號碼"
            v-model="mobileNum"
            :disabled="pressed"
          />
        </div>
        <button type="button" :class="[$style.vaributton, $style.vaributton__1, pressed? $style.disabled : '']" @click="pressed? '' : sendSMS()">
          發送手機驗證碼
        </button>
      </form>
      <form v-if="pressed" :class="$style.form" @submit.prevent="validateConfirm">
        <div :class="$style.variCode">
          <span :class="$style.titlesize">驗證碼</span>
          <input
            :class="$style.input"
            placeholder="請輸入手機驗證碼"
            v-model="verificationInput"
          />
        </div>
        <div :class="$style.variButtons">
          <button :class="[$style.vaributton, $style.vaributton__2]">
            確認送出
          </button>
          <button :class="[$style.vaributton, $style.vaributton__2,counting? $style.disabled : '']" type="button" :disabled="counting" @click="startCountdown">
              <validate-countdown :class="$style.countdown" v-if="counting" :time="60000" @end="onCountdownEnd" v-slot="{ totalSeconds }">
                重發驗證碼<span :class="$style.seconds"> {{ totalSeconds }} </span>
              </validate-countdown>
            <span v-else>重發驗證碼</span>
          </button>
        </div>
      </form>
    </div>
    <div :class="$style.main" v-else>
      <img src="../../assets/img/fixok.png" :class="$style.imgsize1">
      <div :class="$style.text2">
        已修改成功並傳送確認信至
        <p>{{ email }}</p>
      </div>
      <div
        :class="$style.btn"
        @click="$router.push({ name: 'home' })"
      >
        確定
      </div>
    </div>
    <success v-if="success" @close="success = false" :regist="regist">
    </success>
  </div>
</template>

<script>
import success from '@/views/login/success.vue'

export default {
  components: {
    success
  },
  data () {
    return {
      regist: true,
      pressed: false,
      counting: false,
      input: NaN,
      verificationInput: '',
      refresh: undefined,
      fix: true,
      mobileNum: '',
      verificationCode: '',
      newPassword: '',
      success: false
    }
  },
  methods: {
    async startCountdown () {
      await this.$store.dispatch({
        type: 'sendSMS',
        mobileNumber: this.mobileNum
      })
      this.verificationCode = this.$store.state.register.mobileNum
      this.counting = true
      this.$message.success('已發送驗證碼至您的手機，請查收簡訊。')
      console.log('手機驗證碼', this.verificationCode)
    },
    async sendSMS () {
      await this.$store.dispatch({
        type: 'getEmailByMobile',
        mobileNum: this.mobileNum
      })
      if (this.$store.state.register.registMobileIsUsed) {
        this.pressed = true
        await this.$store.dispatch({
          type: 'sendSMS',
          mobileNumber: this.mobileNum
        })
        this.verificationCode = this.$store.state.register.mobileNum
        this.counting = true
        this.$message.success('已發送驗證碼至您的手機，請查收簡訊。')
        console.log('手機驗證碼', this.verificationCode)
      } else {
        this.$message.error('查無此手機會員資料。')
      }
    },
    onCountdownEnd () {
      this.counting = false
    },
    async validateConfirm () {
      if (this.verificationCode === this.verificationInput) {
        await this.$store.dispatch({
          type: 'resetPasswordSMS',
          mobileNumber: this.mobileNum
        })
        this.$message.success('新密碼已發送至您的手機簡訊')
        this.success = true
        this.newPassword = this.$store.state.register.newPassword
        console.log(this.newPassword)
      } else {
        this.$message.error('認證碼錯誤')
      }
    }
  }
}
</script>

<style lang="scss" module>

.input {
  color: #828282;
  font-size: 14px;
  padding-right: 20px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  color: #000;
}
.titlesize {
  width: 80px;
}

.variCode {
  display: grid;
  grid-template-columns: 80px 1fr;
  padding-bottom: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #F7F8F9;
}

.variButtons {
  display: flex;
  justify-content: space-between;
}

.vaributton {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  border-radius: 999rem;
  color: #fff;
  margin-bottom: 24px;
  background: #ff6100;
  border: none;

  &__1 {
  width: 320px;

  }

  &__2 {
  width: 150px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 320px;
}

.disabled {
  background: #ccc !important;
  cursor: default !important;
}
.countdown {
  color: white;
}
.root {
  width: 100%;
  min-height: calc(100vh - 52px - 306px);
}

.rootinner {
  flex: 1;
}

.verification {
  height: 40px;
}

.imgsize {
  width: 30px;
  height: 30px;
  font-size: 30px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #828282;

  &:active {
    color: #ccc;
  }
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  width: 520px;
  border-radius: 12px;
  background-color: #ffffff;
}

.top {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #F7F8F9;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.ex2 {
  display: contents;
}

.main {
  padding: 0px 26px 20px 26px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 0 50px 0;
  box-sizing: border-box;
  width: 520px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  padding-top: 80px;
  text-align: center;
  border-top: 1px solid #ccc;
  padding-bottom: 10px;
}

.right {
  width: 174px;
}

.texttitle {
  width: 320px;
  font-size: 14px;
  color: #000000;
  padding-bottom: 10px;
}

.title2 {
  cursor: pointer;
  right: 22px;
  position: absolute;
  color: #FF4B00;
  font-size: 30px;
  font-weight: 900;
  align-self: center;
}

.iconstyle {
  cursor: pointer;
  font-size: 20px;
  color: #C4C4C4;
  justify-self: center;
  align-self: center;
}

.inputstyle {
  border: 0;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
}

.inputout {
  width: 320px;
  height: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 1px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.ex {
  display: grid;
  grid-template-columns: 1fr 110px 30px;
  grid-column-gap: 10px;
  width: 320px;
  height: 40px;
  align-items: center;
}

.eximg {
  width: 90px;
  background-color: gray;
}

.extext {
  padding: 0 12px;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  border: 1px solid #CCCCCC;
  font-size: 14px;
}

.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 320px;
  height: 40px;
  background: #FF6100;
  border-radius: 999rem;
  font-weight: 500;
  font-size: 16px;
}

.imgsize1 {
  width: 140px;
  height: auto;
  padding-bottom: 34px;
}

.text2 {
  font-size: 14px;
  text-align: center;
  color: #828282;
}

@media (max-width: 1365px) {
  .root {
    width: 100%;
    min-height: calc(100vh - 52px - 200px);
  }
}

@media (max-width: 767px) {
  .root {
    width: 100%;
    min-height: calc(100vh - 52px - 200px);
  }

  .bg {
    max-width: 350px;
    width: 80%;
  }

  .texttitle {
    width: 100%;
  }

  .inputout {
    width: 100%;
  }

  .ex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: unset;
  }

  .verification {
    width: 80%;
  }

  .extext {
    height: 40px;
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .main2 {
    width: 100%;
    padding: 20px 30px;
  }

  .ex2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
  }
}
</style>
