<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.inner" v-if="fix">
        <div
          :class="[$style.bg,
            'animate__animated',
            'animate__faster',
            'animate__fadeInDownBig']"
        >
          <div :class="$style.top">
            <p :class="$style.title">修改密碼</p>
            <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
          </div>
          <div :class="$style.main">
            <div :class="$style.inputout">
              <input
                v-model="oldpassword"
                ref="oldpassword"
                :class="$style.inputstyle"
                placeholder="請輸入舊密碼"
                type="password"
              />
              <i
                :class="['el-icon-view', $style.iconstyle]"
                @click="passwordFn('oldpassword')"
              />
            </div>
            <div :class="$style.inputout">
              <input
                v-model="newpassword"
                ref="newpassword"
                :class="$style.inputstyle"
                placeholder="請輸入新密碼"
                type="password"
              />
              <i
                :class="['el-icon-view', $style.iconstyle]"
                @click="passwordFn('newpassword')"
              />
            </div>
            <div :class="$style.inputout">
              <input
                v-model="repeat"
                ref="repeat"
                :class="$style.inputstyle"
                placeholder="請確認新密碼"
                type="password"
              />
              <i
                :class="['el-icon-view', $style.iconstyle]"
                @click="passwordFn('repeat')"
              />
            </div>
            <div :class="$style.ex">
              <input
                :class="$style.extext"
                placeholder="請填寫驗證碼"
                v-model="verification"
              />
              <div :class="$style.ex2">
                <verification
                  :class="$style.verification"
                  :refresh="refresh"
                  @input="input = $event"
                />
                <i
                  :class="[$style.imgsize, 'el-icon-refresh']"
                  @click="refresh = !refresh"
                />
              </div>
            </div>
            <div :class="$style.btn" @click="fixPassword">確定</div>
            <p :class="$style.errortext" v-if="message">{{ message }}</p>
          </div>
        </div>
      </div>
      <div :class="$style.inner" v-else>
      <div :class="$style.bg">
        <div :class="$style.top">
          <p :class="$style.title">修改密碼</p>
          <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
        </div>
        <div :class="$style.main2">
          <img src="../../assets/img/fixok.png" :class="$style.imgsize1">
          <div :class="$style.text2">
            密碼已修改成功！
          </div>
          <div :class="$style.btn" @click="$emit('close')">確定</div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import verification from '@/components/allproductplug/verification.vue'
export default {
  components: {
    verification
  },
  props: {
    email: null
  },
  data () {
    return {
      verification: '',
      refresh: undefined,
      oldpassword: '',
      newpassword: '',
      repeat: '',
      fix: true,
      message: null
    }
  },
  methods: {
    passwordFn (value) {
      switch (value) {
        case 'oldpassword':
          if (this.$refs.oldpassword.type === 'password') {
            this.$refs.oldpassword.type = 'text'
          } else {
            this.$refs.oldpassword.type = 'password'
          }
          break
        case 'newpassword':
          if (this.$refs.newpassword.type === 'password') {
            this.$refs.newpassword.type = 'text'
          } else {
            this.$refs.newpassword.type = 'password'
          }
          break
        case 'repeat':
          if (this.$refs.repeat.type === 'password') {
            this.$refs.repeat.type = 'text'
          } else {
            this.$refs.repeat.type = 'password'
          }
          break
      }
    },
    async fixPassword () {
      if (this.newpassword === this.repeat) {
        if (this.verification === this.input) {
          this.$emit('loading', true)
          try {
            await this.$store.dispatch({
              type: 'fixPassword',
              oldpassword: this.oldpassword,
              newpassword: this.newpassword
            })
            this.fix = false
          } catch (err) {
            this.message = '密碼格式輸入錯誤'
          } finally {
            this.$emit('loading', false)
          }
        } else {
          this.message = '驗證碼錯誤'
        }
      } else {
        this.message = '新密碼兩者不相同，請確定後再進行一次。'
      }
    }
  }
}
</script>

<style lang="scss" module>
.errortext {
  text-align: center;
  color: red;
  margin-top: 10px;
}

.ex {
  display: grid;
  grid-template-columns: 1fr 110px 30px;
  grid-column-gap: 10px;
  width: 320px;
  height: 40px;
  align-items: center;
}

.ex2 {
  display: contents;
}

.extext {
  padding: 0 12px;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  border: 1px solid #CCCCCC;
  font-size: 14px;
}

.verification {
  height: 40px;
}

.imgsize {
  width: 30px;
  height: 30px;
  font-size: 30px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #828282;

  &:active {
    color: #ccc;
  }
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  width: 520px;
  border-radius: 12px;
  background-color: #ffffff;
}

.top {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #F7F8F9;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.main {
  padding: 45px 26px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 0;
  box-sizing: border-box;
  width: 520px;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.right {
  width: 174px;
}

.texttitle {
  font-size: 14px;
  color: #000000;
  padding-bottom: 10px;
}

.title2 {
  cursor: pointer;
  right: 22px;
  position: absolute;
  color: #FF4B00;
  font-size: 30px;
  font-weight: 900;
  align-self: center;
}

.iconstyle {
  cursor: pointer;
  font-size: 20px;
  color: #C4C4C4;
  justify-self: center;
  align-self: center;
}

.inputstyle {
  border: 0;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 14px;
}

.inputout {
  display: grid;
  width: 320px;
  grid-template-columns: 290px 30px;
  grid-template-rows: 40px;
  border: 1px solid #CCCCCC;
  border-radius: 1px;
  margin-bottom: 16px;
  justify-content: center;
}

.eximg {
  width: 90px;
  background-color: gray;
}

.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
  color: #fff;
  width: 320px;
  height: 40px;
  background: #FF6100;
  border-radius: 999rem;
  font-weight: 500;
  font-size: 16px;
}

.imgsize1 {
  width: 140px;
  height: auto;
  padding-bottom: 34px;
}

.text2 {
  font-size: 16px;
  text-align: center;
  color: #828282;
}

@media (max-width: 767px) {
  .bg {
    max-width: 300px;
    width: 80%;
  }

  .inputout {
    width: 100%;
    grid-template-columns: 1fr 30px;
  }

  .btn {
    width: 100%;
    margin-top: 28px;
  }

  .ex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 40px;
    height: unset;
  }

  .extext {
    width: 100%;
    height: 40px;
  }

  .verification {
    width: 80%;
  }

  .ex2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
  }

  .main {
    padding: 21px 26px;
  }

  .main2 {
    width: 100%;
    padding: 21px 26px;
  }

  .text2 {
    font-size: 14px;
  }
}
</style>
