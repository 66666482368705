<template>
  <div :class="$style.root">
    <div :class="$style.innerlist">
      <div :class="$style.imgout">
        <div :class="$style.imgoutbefore" ref="imgoutbefore"/>
        <carousel
          :imgList="imgListinner"
          :class="$style.carousel"
          :style="{ height: `${ carouselHeight }px` }"
          @imageIndex="imageIndex = $event"
        />
        <div :class="$style.imgoutafter" ref="imgoutafter"/>
      </div>
      <div class="outside">
        <div class="top">
          <div class="toptitle">
            {{ typeData.title }}
          </div>
        </div>
        <div class="productmain list-innerlist" v-if="listProducts.total">
          <div
            v-for="(item, i) in listProducts.list"
            :key="i"
            class="productinner"
            @click="getProductFn(item.sku, item.id)"
          >
            <div
              class="productimg"
              :style="{ backgroundImage: `url(${ imgUrl + item.image })` }"
            />
            <div class="producttextout">
              <p class="textlang">{{ item.newName }}</p>
            </div>
            <div class="productoutside">
              <p class="productextdel">$ {{ item.oldPrizeNum }}</p>
              <p class="productextmain">$ {{ item.newPrizeNum }}</p>
            </div>
          </div>
        </div>
        <div
          v-else
          :class="['noanything', 'noanythingplug']"
        >
          <img
            :class="['noanythingimg', 'noanythingimgwidth']"
            src="../../assets/img/noting.png"
          />
          抱歉！搜尋不到您的商品唷！
        </div>
      </div>
    </div>
    <page
      v-show="listProducts.total > 0"
      :class="$style.page"
      @Number="numberFn($event)"
      :pageSize="16"
      :pageNumber="listProducts.total"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import carousel from '@/components/carousel/carousel.vue'
import { mapGetters } from 'vuex'
import listimg from '@/js/listimg.js'
export default {
  mixins: [
    listimg
  ],
  components: {
    carousel,
    page
  },
  data () {
    return {
      watch: false,
      active: 0,
      imageIndex: 0
    }
  },
  watch: {
    async $route (to, from) {
      if (to.name === from.name) {
        if (to.query.category_id !== from.query.category_id || to.query.text !== from.query.text) {
          await this.numberFn(1)
        }
      } else {
        await this.numberFn(1)
      }
    },
    imageIndex (newVal) {
      var beforeVal = newVal - 1
      if (newVal === 0) {
        beforeVal = this.imgListinner.length - 1
      }
      var afterVal = newVal + 1
      if (this.imgListinner.length - 1 === newVal) {
        afterVal = 0
      }
      this.$refs.imgoutbefore.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[beforeVal].img})`
      this.$refs.imgoutafter.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[afterVal].img})`
    }
  },
  computed: {
    ...mapGetters([
      'imgListinner',
      'listProducts'
    ]),
    typeData () {
      const type = this.$route.meta.type
      let title, productNamePage
      switch (type) {
        case 'common':
          title = '精選商品'
          productNamePage = 'commonProduct'
          break
        case 'kol':
          title = 'KOL精選'
          productNamePage = 'kolProduct'
          break
        case 'hot':
          title = '熱門商品'
          break
        case 'group':
          title = '拚團商品'
          productNamePage = 'groupProduct'
          break
        case 'discount':
          title = '優惠組合商品'
          productNamePage = 'discountProduct'
          break
        case 'employee':
          title = '員工商品'
          productNamePage = 'employeeProduct'
          break
        case 'search':
          title = '搜尋結果'
          productNamePage = 'commonProduct'
          break
      }
      return { title, productNamePage }
    }
  },
  methods: {
    async numberFn (value) {
      this.$emit('loading', true)
      try {
        await this.getApi(value)
        this.$router.push({
          params: { current: value },
          query: { ...this.$route.query }
        })
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    },
    async getApi (currentPage) {
      if (this.$route.name !== 'searchList') {
        await Promise.all([
          this.$store.dispatch({
            type: 'getLists',
            typeValue: this.$route.meta.number,
            pageSize: 16,
            currentPage: currentPage
          }),
          this.$store.dispatch({
            type: 'getBanner',
            banner: this.$route.meta.type
          })
        ])
      } else {
        if (this.$route.query.category_id || this.$route.query.category_id === 0) {
          await this.$store.dispatch({
            type: 'searchListClass',
            page: currentPage,
            category_id: this.$route.query.category_id,
            online: this.$route.params.typeWord === 'employee' ? 5645 : 5643
          })
        } else {
          await this.$store.dispatch({
            type: 'searchList',
            page: currentPage,
            search: '%' + this.$route.query.text + '%',
            online: this.$route.params.typeWord === 'employee' ? 5645 : 5643
          })
        }
      }
    },
    getProductFn (value, id) {
      this.$router.push({
        name: this.typeData.productNamePage,
        params: {
          skuId: value,
          Id: id,
          current: 1,
          groupcurrent: 1
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .innerlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 130px);
  }

  .page {
    padding-bottom: 40px;
    width: 1110px;
    margin: 0 auto;
  }

  .carousel {
    z-index: 0;
    width: 950px;
    height: 450px;
    margin: 0 auto;
  }

  .imgout {
    width: 100%;
    height: 450px;
    position: relative;

    .imgoutbefore {
      position: absolute;
      top: 0;
      left: -475px;
      width: 50%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
    }

    .imgoutafter {
      position: absolute;
      top: 0;
      right: 0px;
      width: 25%;
      height: 100%;
      z-index: -3;
      opacity: 0.2;
    }
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .innerlist {
    width: 768px;
    margin: 0 auto;
    min-height: calc(100vh - 193px - 101px);
  }

  .carousel {
    margin: 28px 0;
    width: 768px;
    height: 384px;
  }

  .page {
    padding: 30px 0;
  }
}

@media (max-width: 767px) {
  .innerlist {
    min-height: calc(100vh - 193px - 101px);
  }

  .carousel {
    z-index: 0;
    width: 100%;
  }

  .page {
    padding: 30px 0;
  }
}
</style>
