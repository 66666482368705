<template>
  <div :class="$style.root">
    <div
      :class="$style.heart"
      @click="likeActive()"
    >
      <img
        :class="$style.iconsizelike"
        :src="favoriteBoolean
          ? require('../../assets/img/productpage_botton_like_actived.png')
          : require('../../assets/img/productpage_botton_like_default.png')"
      />
      {{ favoriteBoolean ? '已加入' : '加入最愛' }}
    </div>
    <div :class="$style.iconinner">
      <img
        src="../../assets/img/productpage_icon_facebook.png"
        :class="[$style.iconstyle, 'clickCss']"
        @click="share"
      />
      <img
        src="../../assets/img/productpage_icon_messanger.png"
        :class="[$style.iconstyle, 'clickCss']"
        @click="sharemessage"
      />
      <img
        ref="copyButton"
        :data-clipboard-text="copy"
        src="../../assets/img/productpage_icon_sharelink.png"
        :class="[$style.iconstyle, 'clickCss']"
      />
      <img
        v-if="!webwatch"
        @click="phoneshare"
        src="../../assets/img/icon_circleshare.png"
        :class="$style.iconstyle"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Clipboard from 'clipboard'
export default {
  props: {
    chikdSku: null
  },
  data () {
    return {
      copy: window.location.href,
      copyBtn: null,
      icon: [{
        img: require('../../assets/img/productpage_icon_facebook.png')
      }, {
        img: require('../../assets/img/productpage_icon_line.png')
      }, {
        img: require('../../assets/img/productpage_icon_messanger.png')
      }, {
        img: require('../../assets/img/productpage_icon_sharelink.png')
      }]
    }
  },
  watch: {
    async chikdSku () {
      if (this.online && this.chikdSku) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'favoriteAction',
            action: 'getWishlistBySku',
            bodyObj: {
              sku: this.chikdSku.sku
            },
            noPage: true
          })
        } catch {
          this.$message({
            message: '操作失敗，重新整理後再進行操作。'
          })
        } finally {
          this.$emit('loading', false)
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'momProductList',
      'favoriteBoolean'
    ])
  },
  methods: {
    phoneshare () {
      if (navigator.share) {
        navigator.share({
          title: `${'最獨特的新型態電商平台，我們以「策略合作」為核心價值，「創造多贏」為經營目標，帶給消費者「全新的購物體驗」-cvimall-' +
            this.name}`,
          text: `${'最獨特的新型態電商平台，我們以「策略合作」為核心價值，創造多贏」為經營目標，帶給消費者「全新的購物體驗」-cvimall-' +
            this.name}`,
          url: `${window.location.href}`
        })
      } else {
        this.$message.error('目前裝置不支援唷！請使用其他分享方式分享給你的好友吧！')
      }
    },
    share () {
      window.FB.ui({
        method: 'share',
        href: `${window.location.href}`
      }, function (response) {})
    },
    sharemessage () {
      window.FB.ui({
        method: 'send',
        link: `${window.location.href}`
      })
    },
    initClipboard () {
      const clipboard = new Clipboard(this.$refs.copyButton)
      clipboard.on('success', (e) => {
        this.$message.success('複製成功！')
      })
    },
    // 最愛
    async likeActive () {
      if (this.online && this.online !== 'kol') {
        // addWishlist delWishlist
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'favoriteAction',
            action: this.favoriteBoolean ? 'delWishlist' : 'addWishlist',
            noPage: true,
            bodyObj: {
              idx: this.favoriteBoolean ? this.favoriteBoolean.idx : undefined,
              sku: this.chikdSku.sku,
              skuname: this.chikdSku.name,
              image: this.momProductList.productImage[0].img,
              parentSku: this.$route.params.skuId,
              parentId: this.momProductList.id,
              parentSellType: Number(this.momProductList.sellType)
            }
          })
          await this.$store.dispatch({
            type: 'favoriteAction',
            action: 'getWishlistBySku',
            bodyObj: {
              sku: this.chikdSku.sku
            },
            noPage: true
          })
          this.$message({
            message: this.favoriteBoolean ? '成功加入最愛！' : '成功移除我的最愛！',
            type: this.favoriteBoolean ? 'success' : 'info'
          })
        } finally {
          this.$emit('loading', false)
        }
      } else {
        this.$message({
          message: this.online === 'kol'
            ? '請先登入一般會員才能加入最愛喔！'
            : '請先登入才能將您的寶貝加入最愛喔',
          type: 'warning'
        })
      }
    }
  },
  mounted () {
    this.initClipboard()
  }
}
</script>

<style lang="scss" module>
.heart {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #828282;
  cursor: pointer;
}

.iconinner {
  display: flex;
  flex-direction: row;
}

.iconstyle {
  width: 50px;
  height: 50px;
  padding-left: 8px;
  cursor: pointer;
}

.iconsizelike {
  width: 30px;
  height: auto;
  margin-right: 12px;
}

@media (max-width: 1365px) {
  .heart {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #828282;
  }

  .iconinner {
    display: flex;
    flex-direction: row;
  }

  .iconstyle {
    width: 45px;
    height: 45px;
    padding-left: 0;
    padding-right: 5px;
  }

  .iconsizelike {
    width: 20px;
    margin-right: 10px;
  }
}
</style>
