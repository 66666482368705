<template>
  <div :class="$style.root">
    <div :class="$style.innerroot">
      <div :class="[$style.bg, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']">
        <div :class="$style.top">
          <p :class="$style.title">退貨進度</p>
          <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
        </div>
      <progresss :progressData="data.rtndata"/>
        <div :class="$style.top2" @click="open = !open">
          <p :class="$style.title">退貨資訊</p>
          <i :class="[open ? 'el-icon-arrow-down' : 'el-icon-arrow-up' , $style.title3]"/>
        </div>
      <div :class="$style.menu" v-if="open">
        <p>訂單編號：{{ data.orderId }}</p>
        <div :class="$style.titlething" v-if="webwatch">
          <div>商品資料/規格</div>
          <div :class="$style.textcenter">價格</div>
          <div :class="$style.textcenter">數量</div>
          <div :class="$style.textcenter">小計</div>
        </div>
        <div
          :class="$style.thingout"
          v-for="(item, i) in data.products.child"
          :key="i"
        >
          <div :class="$style.imgout">
            <div
              :class="[$style.img, 'imgcss']"
              :style="{ backgroundImage: `url(${
                data.products.parent[item.parent_id].image
                  ? imgUrl + data.products.parent[item.parent_id].image
                  : require('../../../assets/img/cvimall_comingsoon_small.png')
              })` }"
            />
            <div :class="$style.textthing">
              <p :class="$style.textinn">
                {{ data.products.parent[item.parent_id].name }}
              </p>
              <p :class="[$style.textinn, $style.textthing2]">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div :class="$style.outtext">
            <div :class="[$style.outtext, $style.outtext2]">
              <div :class="$style.textcenter">
                ＄{{ Math.floor(item.price) }}
              </div>
              <div :class="$style.textcenter">
                {{ Math.floor(item.qty_ordered) }}
              </div>
            </div>
            <div :class="$style.textcenter">
              ＄{{ Math.floor(item.price) * Math.floor(item.qty_ordered) }}
            </div>
          </div>
        </div>
        <div :class="$style.outside">
          <div :class="$style.title5">退貨描述</div>
          <div :class="$style.textinnerrr">
            {{ returnData[2] }}
          </div>
          <div :class="$style.title5">退貨地址</div>
          <div :class="$style.textinnerrr">
            {{ returnData[3] }}
          </div>
          <div :class="$style.title5">退款資訊</div>
          <div :class="$style.textinnerrr">
            {{ returnData[4] }}
          </div>
          <div :class="$style.textinnerrr">
            {{ returnData[0] }}
          </div>
          <div :class="$style.textinnerrr">
            {{ returnData[1] }}
          </div>
          <div :class="$style.textinnerrr">
            退款金額：＄{{ data.oldMoney }}
          </div>
          <div :class="$style.title5">現金退還</div>
          <div :class="$style.textinnerrr">
            點數：{{ data.getredPoint }}
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import progresss from '@/components/user/listpage/progresscom.vue'
import { mapGetters } from 'vuex'
import '@/css/image.scss'
export default {
  components: {
    progresss
  },
  props: {
    data: Object
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters([
      'returnData'
    ])
  },
  async created () {
    await this.$store.dispatch({
      type: 'returnData',
      bodyObj: {
        orderId: this.data.orderId
      }
    })
  }
}
</script>

<style lang="scss" module>
.innerroot {
  position: fixed;
  padding: 110px 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  padding: 28px;
  box-sizing: border-box;
  max-width: 740px;
  width: 80%;
  height: auto;
  border-radius: 10px;
  border: solid 2px #00d77f;
  background-color: #ffffff;
}

.top {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
}

.top2 {
  padding: 10px 10px 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #ccc;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.titlething {
  padding: 0 17px;
  display: grid;
  grid-template-columns: 441px 1fr 1fr 1fr;
  grid-template-rows: 42px;
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
}

.icon {
  width: 20px;
  height: 20px;
  background: #00d77f;
}

.date {
  padding: 32px 52px 0 52px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #000;

  &.date2 {
    padding: 0 46px 52px 46px;
    justify-content: space-between;
  }
}

.progresss {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.progresss2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}

.one {
  width: 20px;
  height: 20px;
  background-color: #00d77f;
}

.line {
  width: 114px;
  height: 5px;
  background-color: #00d77f;
}

.imgout {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-auto-rows: minmax(60px, auto);
  grid-column-gap: 16px;
  align-items: center;
}

.title2 {
  cursor: pointer;
  color: #888;
  font-size: 26px;
  font-weight: 400;
}

.title3 {
  cursor: pointer;
  color: #000;
  font-size: 20px;
  font-weight: 900;
}

.outside {
  font-size: 14px;
}

.title5 {
  font-weight: 500;
  padding: 20px 0;
  border-bottom: 2px solid #00d77f;
  margin-bottom: 20px;
  font-size: 16px;
}

.textinnerrr {
  font-size: 14px;
  color: #696969;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  padding: 0 32px;
  display: grid;
  grid-template-columns: 75px 1fr;
  grid-auto-rows: 22px;
  grid-column-gap: 24px;
  grid-row-gap: 14px;
  margin-bottom: 32px;
}

.menu {
  padding: 10px;
}

.px12 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 17px;
}

.textcenter {
  justify-self: center;
}

.thingout {
  padding: 0 17px;
  display: grid;
  grid-template-columns: 441px 1fr 1fr 1fr;
  grid-auto-rows: minmax(93px, auto);
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
}

.img {
  width: 60px;
  height: 60px;
}

.textthing {
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(30px, auto);
  padding: 10px 0;
  box-sizing: border-box;

  .textthing2 {
    font-size: 13px;
    color: #ccc;
  }
}

.inputsize {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.number {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.truemoney {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.outtext {
  display: contents;
}

@media (max-width: 1365px) {
  .menu {
    border-top: 2px solid #ccc;
    margin-top: 10px;
  }

  .thingout {
    grid-template-columns: 5fr 1fr 1fr 1fr;
  }

  .title5 {
    padding: 10px 0;
  }
}

@media (max-width: 767px) {
  .bg {
    padding: 14px;
  }

  .top {
    padding-bottom: 8px;
  }

  .thingout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .imgout {
    width: 100%;
  }

  .outtext {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
    color: #696969;

    &.outtext2 {
      flex-direction: column;
      width: unset;
    }
  }
}
</style>
