<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="[$style.inner, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']">
        <div :class="$style.top">
          <p :class="$style.title">優惠券</p>
          <i
            :class="['el-icon-close', $style.title2]"
            @click="$emit('close')"
          />
        </div>
        <div :class="$style.bottom">
          <div :class="$style.name">
            {{ conpondata.name }}
          </div>
          <div :class="$style.text">
            {{ conpondata.description }}
          </div>
          <div
            :class="$style.svg"
          >
            <span :class="$style.money">
              <span :class="$style.iconmoney">$</span>
              {{ Math.floor(conpondata.discount_amount) }}
            </span>
          </div>
          <div
            :class="$style.checktext"
            @click="receiveCoupon(conpondata)"
          >
            點擊優惠券領取
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coupon from '@/js/coupon.js'
export default {
  mixins: [
    coupon
  ],
  props: {
    conpondata: null
  }
}
</script>

<style lang="scss" module>
.rootinner {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
}

.loading {
  width: 30px;
  height: 30px;
}

.svg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/img/conpon1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 300px;
  height: 150px;

  &.svg2 {
    background-image: url('../../assets/img/expiring.svg');
  }
}

.money {
  font-size: 65px;
  color: #fff;
  margin-left: -20px;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);

  .iconmoney {
    font-size: 30px;
    margin-right: -16px;
  }
}

.inner {
  width: 800px;
  height: auto;
  background: #FFFFFF;
  border-radius: 10px;
}

.top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 82px;
  border-bottom: 2px solid #F7F8F9;
}

.title {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.title2 {
  position: absolute;
  right: 32px;
  color: #00D77F;
  font-size: 26px;
  cursor: pointer;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 40px 0;
  box-sizing: border-box;
}

.name  {
  padding-bottom: 28px;
  font-weight: 400;
  font-size: 16px;
}

.text {
  padding-bottom: 35px;
  font-size: 14px;
}

.checktext {
  height: 30px;
  font-size: 15px;
  color: #00D77F;
  text-decoration: underline;
  padding-top: 18px;
  cursor: pointer;
  &:active {
    color: #065a38;
  }
}

@media (max-width: 1365px) {
  .inner {
    width: 520px;
  }

  .svg {
    width: 200px;
    height: 95px;
  }

  .money {
    font-size: 40px;
    margin: 0;

    .iconmoney {
      margin: -8px;
      font-size: 18px;
    }
  }

  .bottom {
    padding: 40px 0;
  }
}

@media (max-width: 767px) {
  .inner {
    width: 80%;
  }
}
</style>
