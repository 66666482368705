const reciprocalJs = {
  data () {
    return {
      time: []
    }
  },
  watch: {
    timeData () {
      this.reciprocal()
    }
  },
  computed: {
    timeData () {
      return (this.openGroupList
        ? this.openGroupList.data
        : [])
        .map(ele => {
          const date = ele.end_dt.split(' ')[0].split('-')
          const time = ele.end_dt.split(' ')[1].split(':')
          return new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]), Number(time[0]), Number(time[1]), Number(time[2])).getTime()
        })
    }
  },
  methods: {
    reciprocal () {
      setInterval(this.myTimer, 1000)
    },
    clearReciprocal () {
      clearInterval(this.myTimer)
    },
    myTimer () {
      this.time = this.timeData.map(t => {
        const milliseconds = Math.max(t - Date.now(), 0)
        if (milliseconds <= 0) {
          return 0
        } else {
          const day = Math.floor(milliseconds / (24 * 60 * 60 * 1000)) || 0
          const hours = Math.floor(milliseconds / (60 * 60 * 1000)) - day * 24 || 0
          const minutes = Math.floor(milliseconds / (60 * 1000)) - (hours * 60) - (day * 24 * 60) || 0
          const seconds = Math.floor(milliseconds / 1000) - (minutes * 60) - (hours * 60 * 60) - (day * 24 * 60 * 60) || 0
          return { day: day, hours: hours, minutes: minutes, seconds: seconds }
        }
      })
    }
  },
  destroyed () {
    this.clearReciprocal()
  }
}

export default reciprocalJs
