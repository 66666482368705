var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{class:_vm.$style.inner},[_c('div',{class:_vm.$style.imgout},[_c('div',{ref:"imgoutbefore",class:_vm.$style.imgoutbefore}),_c('carousel',{class:_vm.$style.carousel,style:({ height: (_vm.carouselHeight + "px") }),attrs:{"imgList":_vm.imgListinner},on:{"imageIndex":function($event){_vm.imageIndex = $event}}}),_c('div',{ref:"imgoutafter",class:_vm.$style.imgoutafter})],1),(_vm.online && _vm.online !== 'kol')?_c('voucher',{class:_vm.$style.voucher}):_vm._e(),_c('kol',{class:_vm.$style.kol}),_c('product',{class:[_vm.$style.product, _vm.$style.productsmall],attrs:{"list":{
        title: '優惠組合商品',
        list: _vm.discountList,
        name: 'discountList',
        productName: 'discountProduct'
      }}}),_c('product',{class:[_vm.$style.product, _vm.$style.productsmall, _vm.$style.noline],attrs:{"list":{
        title: '精選商品',
        list: _vm.commonList,
        name: 'commonList',
        productName: 'commonProduct'
      }}})],1),(_vm.loading)?_c('loading'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }