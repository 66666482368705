<template>
  <div :class="$style.root">
    <div :class="$style.send">
      <div :class="$style.sendtop">
        <p :class="$style.titit">
          寄送方式
        </p>
        <p
          :class="$style.lineee"
          @click="comName = 'sendmethods'"
        >
          變更
        </p>
      </div>
      <div :class="$style.shop">
        <div
          :class="[$style.text, $style.bordertitle, { ['red']: pickAddressId < 0 }]"
        >
          {{ sendMethod.methods }}
        </div>
        <div :class="$style.textout" v-if="sendMethod.address">
          <div :class="[$style.text, $style.bordertitle]">
            收件地址：{{ sendMethod.address }}
          </div>
          <p :class="$style.bordertitle">收件人：{{ sendMethod.name }}</p>
          <p :class="$style.bordertitle">收件人電話：{{ sendMethod.phone }}</p>
          <div :class="$style.text2">
            預定發貨時間 2～5天
          </div>
        </div>
      </div>
      <div :class="$style.sendtop">
        <p :class="$style.titit">
          帳單地址、統編、公司抬頭
        </p>
        <p
          :class="$style.lineee"
          @click="comName = 'billingaddress'"
        >
          變更
        </p>
      </div>
      <div
        :class="[$style.text, { ['red']: pickAddressId < 0 }]"
        v-if="!billingData"
      >
        尚未設定
      </div>
      <div :class="$style.text" v-else>
        <p :class="$style.bordertitle">
          姓名：{{ billingData.lastname }}
        </p>
        <p :class="$style.bordertitle">
          聯絡方式：{{ billingData.telephone }}
        </p>
        <p :class="$style.bordertitle">
          帳單地址：
          {{ billingData.city + billingData.street[0] + billingData.street[1] }}
        </p>
        <p :class="$style.bordertitle">
          {{ billingData.firstname }}
        </p>
      </div>
    </div>
    <sendmethods
      :info="commonUserInfo"
      :pickAddressId="pickAddressId"
      v-if="comName === 'sendmethods'"
      @close="comName = null"
      @loading="$emit('loading', $event)"
    />
    <billingaddress
      :info="commonUserInfo"
      :pickAddressId="pickAddressId"
      :LastTimebilling="LastTimebilling"
      v-if="comName === 'billingaddress'"
      v-on="$listeners"
      @billing="billingData = $event"
      @close="comName = null"
    />
  </div>
</template>

<script>
import sendmethods from '@/views/shopping/sendmethods.vue'
import billingaddress from '@/views/shopping/billingaddress.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    sendmethods,
    billingaddress
  },
  data () {
    return {
      comName: null,
      billingData: null,
      pickAddressId: -1
    }
  },
  watch: {
    LastTimeData () {
      if (this.LastTimeData) {
        this.pickAddressId = this.LastTimeData
        this.getCsvData()
      }
    },
    LastTimebilling () {
      if (this.LastTimebilling) {
        this.billingData = this.LastTimebilling
        this.$emit('billing', this.LastTimebilling)
      }
    }
  },
  computed: {
    ...mapGetters([
      'commonUserInfo',
      'LastTimeData',
      'LastTimebilling',
      'csvData'
    ]),
    sendMethod () {
      const addressList = this.commonUserInfo.addressList
      const methods = addressList.find(ele => ele.id === this.pickAddressId)
      if (methods && addressList && addressList.length > 0 && this.pickAddressId >= 0) {
        return {
          methods: methods.street[2] === 'home'
            ? '宅配'
            : methods.street[2] === '7eleven'
              ? '7-Eleven'
              : methods.street[2] === 'hilife'
                ? '萊爾富 Hi-Life'
                : '全家 FamilyMart',
          address: methods.street[2] === 'home'
            ? methods.city + methods.street[0] + methods.street[1]
            : methods.city + methods.street[0] + '-' + methods.street[1],
          name: methods.firstname,
          phone: methods.telephone
        }
      } else if (!methods && this.pickAddressId >= 0) {
        this.unPickAddressId()
        return { methods: '尚未設定地址', address: null, name: null, phone: null }
      } else {
        return { methods: '尚未設定地址', address: null, name: null, phone: null }
      }
    }
  },
  methods: {
    async unPickAddressId () {
      try {
        await this.$store.dispatch({
          type: 'customJsonFn',
          action: 'deleteCustomJson',
          key: 'LastTimeData'
        })
        this.pickAddressId = -1
      } catch {
        this.$emit.error('購物車載入失敗，請稍後在進行購物。')
        this.$router.push({ name: 'home' })
      }
    },
    async getCsvData () {
      const url = this.commonUserInfo.addressList.find(ele => ele.id === this.pickAddressId)
      if (url && url.street[2] !== 'home') {
        try {
          await this.$store.dispatch({
            type: 'customJsonFn',
            action: 'getCustomJson',
            key: url.street[1].split('-')[1]
          })
        } catch {
          this.$emit.error('購物車載入失敗，請稍後在進行購物。')
          this.$router.push({ name: 'home' })
        }
      }
    }
  },
  async created () {
    try {
      await Promise.all([
        this.$store.dispatch({
          type: 'customJsonFn',
          action: 'getCustomJson',
          key: 'LastTimeData'
        }),
        this.$store.dispatch({
          type: 'customJsonFn',
          action: 'getCustomJson',
          key: 'LastTimebilling'
        })
      ])
    } catch {
      this.$emit.error('購物車載入失敗，請稍後在進行購物。')
      this.$router.push({ name: 'home' })
    }
  },
  beforeDestroy () {
    this.$store.commit({ type: 'LastTimeData', LastTimeData: null })
    this.$store.commit({ type: 'LastTimebilling', LastTimebilling: null })
  }
}
</script>

<style lang="scss" module>
.hidden {
  display: none;
}

.rootinner {
  width: 1104px;
  margin: 0 auto;
}

.btmout {
  width: 100%;
}

.bordertitle {
  position: relative;
  padding-bottom: 8px;
}

.listtitle {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  display: grid;
  grid-template-columns: 690px 1fr 1fr 1fr;
  grid-template-rows: 66px;
  align-items: center;
  padding-top: 65px;
  border-bottom: 2px solid #C4C4C4;
  margin-bottom: 20px;
}

.border {
  display: grid;
  grid-template-columns: 347px 1fr 1fr;
  grid-template-rows: 50px;
  grid-column-gap: 74px;
  font-size: 14px;
  align-items: center;
}

.inputout {
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 52px;
  background: #fff;
  z-index: 5;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.listinner {
  box-sizing: border-box;
  padding-left: 26px;
  width: 100%;
  display: grid;
  grid-template-columns: 664px 1fr 1fr 1fr;
  grid-template-rows: 50px;
  padding-bottom: 12px;
  align-items: center;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: contain;
}

.inner {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  font-size: 14px;
  color: #000000;
}

.overflowout {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 50px;
  height: 28px;
  border-radius: 5px;
  background-color: #ffc000;
}

.innerbtmm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.pluin {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 5;
}

.title2 {
  font-size: 14px;
  color: #000;
  padding: 20px 0;
}

.inputstyle {
  font-size: 14px;
  text-align: left;
  border: none;
  padding: 9px 16px;
  box-sizing: border-box;
  height: 40px;
  flex: 1;
  background-color: #f7f7f7;
  resize: none;
}

.send {
  border-bottom: 1px solid #ccc;
  padding: 0px 26px 18px 0;
  box-sizing: border-box;
}

.send2 {
  border-bottom: 1px solid #ccc;
  padding: 0 26px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.out {
  margin-top: 8px;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  display: flex;
  align-items: center;
  height: 96px;
  font-size: 14px;
  padding: 26px;
  box-sizing: border-box;
}

.sendtop {
  color: #989898;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 15px 0;
  border-bottom: 2px solid #ff6100;
  font-size: 15px;
  margin-bottom: 15px;

  &.sendno {
    padding-bottom: 0;
  }
}

.sendtop22 {
  min-width: 320px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-bottom: 20px;

  &.sendno {
    padding-bottom: 0;
  }
}

.text {
  font-size: 14px;
}

.shop {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.textout {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  line-height: 20px;
}

.text2 {
  color: #828282;
}

.lineee {
  cursor: pointer;
  color: #828282;
  text-decoration: underline;
  padding-left: 40px;
}

.orange {
  font-weight: 500;
  font-size: 18px;
  color: #ff6100;
}

.orange2 {
  padding-left: 10px;
  font-weight: 500;
  font-size: 40px;
  color: #ff6100;
}

.sendtop2 {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #000000;
}

.orangebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff6100;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  border: solid 1px #ff6100;
  margin-right: 48px;
  cursor: pointer;
}

.orangebtn2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ff6100;
  border: solid 1px #ff6100;
}

.orangebtn2no {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ccc;
  border: solid 1px #ccc;
}

.sendtop3 {
  padding: 37px 0 80px 0;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 16px;
}

.number {
  font-size: 14px;
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

.centertext {
  justify-self: center;
}

@media (min-width: 768px) and (max-width: 1365px) {
  .rootinner {
    width: 720px;
    height: 100%;
    margin: 0 auto;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .top {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 20px 20px 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 35px 1fr 35px 1fr 35px;
    grid-template-rows: 35px;
    width: 80%;
  }

  .icon2 {
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(130, 130, 130, 0.1);
  }

  .lineout {
    align-items: center;
    display: flex;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sendtop22 {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 56px;
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    background: #ccc;
    width: 100%;
    height: 56px;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    box-sizing: border-box;
  }

  .out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    border-bottom: 1px solid #C4C4C4;
  }

  .title {
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;
  }

  .sendtop {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .shop {
    font-size: 13px;
    display: flex;
    margin-bottom: 20px;
  }

  .textout {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    min-height: 40px;
  }

  .text2 {
    font-size: 12px;
    color: #828282;
  }

  .titit {
    font-size: 13px;
    color: #000000;
  }

  .lineee {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 20px;
  }

  .orange {
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
  }

  .orange2 {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 18px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 50px;
  }

  .orangebtn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
  }

  .sendtop3 {
    padding: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: #000000;
  }

  .number {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .rootinner {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .lineout {
    align-items: center;
    display: flex;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 56px;
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    background: #ccc;
    width: 100%;
    height: 56px;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    box-sizing: border-box;
  }

  .out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    border-bottom: 1px solid #C4C4C4;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .title {
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;
  }

  .sendtop {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .shop {
    font-size: 13px;
    display: flex;
    margin-bottom: 20px;
  }

  .textout {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    min-height: 40px;
  }

  .text2 {
    font-size: 12px;
    color: #828282;
  }

  .titit {
    font-size: 13px;
    color: #000000;
  }

  .lineee {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 20px;
  }

  .orange {
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
  }

  .orange2 {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 18px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .sendtop3 {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: #000000;
  }

  .sendtop22 {
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    &.sendno {
      padding-bottom: 0;
    }
  }

  .number {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
  }
}
</style>
