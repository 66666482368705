<template>
  <div :class="$style.root">
    <div :class="$style.outside" v-if="webwatch">
      <div/>
      <div :class="$style.rootinner">
        <button
          :class="$style.btnline"
          @click="current = 1"
          :disabled="current === 1"
        >
          <i class="el-icon-d-arrow-left"></i>
        </button>
        <button
          :class="$style.btnline"
          @click="current = current - 1"
          :disabled="current === 1"
        >
          <i class="el-icon-arrow-left"></i>
        </button>
        <button
          v-for="item in numbers"
          :key="item"
          :class="[$style.btnline, { [$style.current]: item === current }]"
          @click="current = item"
        >
          {{ item }}
        </button>
        <button
          :class="$style.btnline"
          @click="current = current + 1"
          :disabled="current === page"
        >
          <i class="el-icon-arrow-right"></i>
        </button>
        <button
          :class="$style.btnline"
          @click="current = page"
          :disabled="current === page"
        >
          <i class="el-icon-d-arrow-right"></i>
        </button>
      </div>
      <div>
        第
        <select
          :class="[$style.select, 'selectstyle']"
          v-model="selectNumber"
          @change="current = selectNumber"
        >
          <option
            :class="$style.option2"
            v-for="(item, i) in page"
            :key="i"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
        頁
      </div>
    </div>
    <div v-else :class="$style.outside">
      <button
        :class="$style.btn"
        :disabled="current === 1"
        @click="current = current - 1"
      >
        上一頁
      </button>
      <button
        :class="$style.btn"
        :disabled="current === page"
        @click="current = current + 1"
      >
        下一頁
      </button>
      <div :class="[$style.btn, $style.btnselect]">
        第
        <select
          :class="[$style.select, 'selectstyle']"
          v-model="selectNumber"
          @change="current = selectNumber"
        >
          <option
            :class="$style.option2"
            v-for="(item, i) in page"
            :key="i"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
        頁
      </div>
    </div>
  </div>
</template>

<script>
import '@/css/inputstyle.scss'
export default {
  props: {
    pageNumber: Number,
    pageSize: Number
  },
  data () {
    return {
      current: null,
      selectNumber: 1
    }
  },
  computed: {
    page () {
      const url = this.pageNumber
      if (url && url > this.pageSize) {
        return Math.ceil(url / this.pageSize)
      } else {
        return 1
      }
    },
    numbers () {
      const n = this.current
      if ((this.page >= 3) && (n === 1)) {
        return [1, 2, 3]
      } else if ((this.page >= 3) && (this.page === n)) {
        return [n - 2, n - 1, n]
      } else if ((this.page >= 2) && (this.page === n)) {
        return [1, 2]
      } else if ((this.page >= 2) && (n === 1)) {
        return [1, 2]
      } else if (this.page === 1) {
        return [1]
      } else {
        return [n - 1, n, n + 1]
      }
    }
  },
  watch: {
    current (current) {
      this.selectNumber = current
      this.$emit('Number', current)
    }
  },
  created () {
    this.current = Number(this.$route.params.current)
  }
}
</script>

<style lang="scss" module>
.outside {
  font-size: 14px;
  display: grid;
  grid-template-columns: 110px 1fr 110px;
  align-items: center;
}

.select {
  cursor: pointer;
  width: 60px;
  border: 0;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 3px;
  margin: 0 5px;
  padding: 0px 8px;
  height: 35px;
  border-radius: 5px;
}

@media (min-width: 1366px) {
  .rootinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  .btnline {
    border: 0;
    margin-right: 15px;
    font-weight: bolder;
    color: #444;
    width: 35px;
    height: 35px;
    font-weight: 900;
    font-size: 20px;

    &.current {
      color: #FF6100;
    }
  }

  .btnline:active {
    color: #FF6100;
  }

  .btnline[disabled]{
    color: #aaa !important;
    cursor: not-allowed;
  }

  .line {
    height: 20px;
    width: 2px;
    background: #000;
  }
}

@media (max-width: 1365px) {
  .outside {
    width: 720px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 95px;
    align-self: center;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 5px;
    background: #ff6100;
    max-width: 200px;
    height: 35px;
    width: 47%;
    color: #fff;
    font-size: 16px;
    border: 0;

    &.btnselect {
      background: #fff;
      border: 1px solid  #ff6100;
      color: #000;
    }

    &[disabled] {
      color: #fff!important;
      background: #ccc;
    }

    .select {
    }
  }

  .rootinner {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .btnline {
    border: 0;
    margin-right: 15px;
    font-weight: bolder;
    color: #444;
    width: 35px;
    height: 35px;
    font-weight: 900;
    font-size: 16px;

    &.current {
      color: #FF6100;
    }
  }

  .btnline:active {
    color: #FF6100;
  }

  .btnline[disabled]{
    color: #aaa !important;
    cursor: not-allowed;
  }

  .line {
    height: 20px;
    width: 2px;
    background: #000;
  }
}

@media (max-width: 1365px) {
  .root {
    padding-top: 20px;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .rootinner {
    margin-bottom: 20px;
  }

  .outside {
    width: 100%;
    max-width: 720px;
    flex-wrap: wrap;
  }
}

</style>
