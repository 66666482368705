<template>
  <div :class="$style.root">
    <img src="../../assets/img/fail.svg" :class="$style.img"/>
    <p :class="$style.text">Oops！訂單下訂失敗</p>
    <p :class="$style.text2">請放心，並無扣款，請於訂單列表取消訂單即可</p>
    <div :class="$style.btn" @click="toListData">前往訂單列表</div>
  </div>
</template>

<script>
export default {
  methods: {
    toListData () {
      this.$router.push({
        name: 'list',
        params: { current: 1, userId: this.$store.state.loginInfo.userData.id }
      })
    }
  }
}
</script>

<style lang="scss" module>
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 130px - 306px);
}

.img {
  width: 300px;
  height: auto;
  margin-top: 88px;
}

.text {
  font-size: 18px;
  color: #FF6100;
  margin-top: 44px;
  margin-bottom: 16px;
}

.text2 {
  font-size: 16px;
  margin-bottom: 40px;
}

.btn {
  width: 180px;
  height: 48px;
  color: #fff;
  background: #FF6100;
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  cursor: pointer;
}

@media (max-width: 1365px) {
  .root {
    min-height: calc(100vh - 250px);
  }

  .text2 {
    width: 80%;
    text-align: center;
  }

  .img {
    width: 230px;
  }
}

 @media (max-width: 767px) {
  .root {
    min-height: calc(100vh - 130px);
  }

  .text2 {
    width: 80%;
    text-align: center;
  }

  .img {
    width: 230px;
  }
}
</style>
