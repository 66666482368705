<template>
  <div :class="$style.root">
    <div :class="$style.innerroot">
      <div :class="$style.thingtitle" v-if="webwatch">
        <div>商品資料</div>
        <div>規格</div>
        <div>價格</div>
        <div>數量</div>
        <div>小計</div>
      </div>
      <div
        :class="$style.thingout"
        v-for="(item, i) in orderData.products.child"
        :key="i"
      >
        <img
          :src=" orderData.products.parent[item.parent_id]
            ? orderData.products.parent[item.parent_id].image
              ? imgUrl + orderData.products.parent[item.parent_id].image
              : require('../../assets/img/cvimall_comingsoon_small.png')
            : require('../../assets/img/cvimall_comingsoon_small.png')
          "
          @click="productRouter(i)"
          :class="$style.img"
        />
        <div
          :class="$style.textthing"
          @click="webwatch ? '' : productRouter(i)"
        >
          <p :class="$style.textinn">
            {{ orderData.products.parent[item.parent_id]
                ? orderData.products.parent[item.parent_id].name
                : '商品已下架'
            }}
          </p>
          <p :class="[$style.textinn, $style.format]">
            {{ item.name }}
          </p>
        </div>
        <div :class="$style.moneyout">
          <p :class="$style.delline">
            ＄{{ Math.floor(Number(item.original_price)) }}
          </p>
          <p>＄{{ Math.floor(Number(item.price)) }}</p>
        </div>
        <div :class="$style.number">
          <span v-if="!webwatch">
            數量：
          </span>
          {{  Math.floor(Number(item.qty_ordered)) }}
        </div>
        <div :class="$style.nodiv"/>
        <div :class="$style.truemoney">
          ＄ {{ Math.floor(Number(item.qty_ordered)) * Math.floor(Number(item.price)) }}
        </div>
      </div>
      <div :class="$style.area2">
        <div :class="$style.textinner">小計</div>
        <div :class="[$style.textinner, $style.right]">
          ＄{{ orderData.oldMoney }}
        </div>
        <div :class="$style.textinner2">折扣合計</div>
        <div :class="[$style.textinner, $style.right]">
          -＄{{ Math.abs(orderData.discount) }}
        </div>
        <div :class="$style.textinner2">現金折抵</div>
        <div :class="[$style.textinner, $style.right]">
          -＄{{  Math.abs(orderData.redPoint) }}
        </div>
        <div :class="$style.textinner">運費</div>
        <div :class="[$style.textinner, $style.right]">
          ＄{{ orderData.sendMoney }}
        </div>
        <div :class="[$style.line, $style.nodiv2]"/>
        <div :class="[$style.line, $style.nodiv2]"/>
        <div :class="$style.textinner3">合計</div>
        <div :class="[$style.textinner3, $style.right]">
          ＄{{ orderData.total }}
        </div>
      </div>
      <groupdata v-if="orderData.group"/>
      <div :class="[$style.threeinner, $style.nodiv]" v-if="online !== 'employee'">
        <div :class="$style.area3">
          <div :class="$style.title">訂單資訊</div>
          <div :class="$style.textinner4"></div>
          <div :class="$style.textinner4">訂單編號</div>
          <div :class="$style.textinner4">
            {{ orderData.orderId }}
          </div>
          <div :class="$style.textinner4">下單時間</div>
          <div :class="$style.textinner4">
            {{ orderData.time }}
          </div>
          <div :class="$style.textinner4">總金額</div>
          <div :class="$style.textinner4">
            {{ orderData.total }}
          </div>
          <div :class="$style.textinner4">訂單狀態</div>
          <div :class="$style.textinner4">{{ orderData.statetext }}</div>
        </div>
        <div :class="$style.area3left">
          <div :class="$style.title">客戶資訊</div>
          <div :class="$style.textinner4"></div>
          <div :class="$style.textinner4">姓名</div>
          <div :class="$style.textinner4">
            {{ commonUserInfo.name }}
          </div>
          <div :class="$style.textinner4">聯絡電話</div>
          <div :class="$style.textinner4">
            {{ commonUserInfo.phone }}
          </div>
        </div>
        <div :class="$style.area3">
          <div :class="$style.title">
            送貨資訊
          </div>
          <div :class="$style.textinner4"/>
          <div :class="$style.textinner4">送貨方式</div>
          <div :class="$style.textinner4">
            {{ orderData.sendMethods }}
          </div>
          <div :class="$style.textinner4">送貨狀態</div>
          <div :class="$style.textinner4">
            {{ orderData.paymentInfo
                ? orderData.shipmentInfo
                  ? orderData.shipmentInfo.RtnMsg
                  : '物流尚未開始'
                : '未付款，付款後會盡快為您出貨。'
            }}
          </div>
          <div :class="$style.textinner4">
            {{ orderData.sendMethods === '宅配'
            ? '宅配住址'
            : '門市名稱' }}
          </div>
          <div :class="$style.textinner4">
            {{ orderData.sendAddress }}
          </div>
          <div :class="$style.textinner4">收件人名稱</div>
          <div :class="$style.textinner4">
            {{ orderData.sendName }}
          </div>
          <div
            :class="[$style.textinner4, $style.textstyle, 'red']"
          >
            *需與身分證姓名相同
          </div>
          <div :class="$style.textinner4"></div>
          <div :class="$style.textinner4">收件人手機</div>
          <div :class="$style.textinner4">
            {{ orderData.sendPhone }}
          </div>
        </div>
        <div :class="$style.area3left">
          <div :class="$style.title">付款資訊</div>
          <div :class="$style.textinner4">
            <span :class="$style.orange" v-if="!orderData.paymentInfo">
              等待付款
            </span>
          </div>
          <div :class="$style.textinner4">付款方式</div>
          <div :class="$style.textinner4">
            {{ orderData.paymentInfo
                ? orderData.paymentInfo.PaymentType  === 'Credit_CreditCard'
                  ? '信用卡（經由綠界）'
                  : orderData.paymentInfo.PaymentType
                : '尚未付款' }}
          </div>
          <div :class="$style.textinner4">付款狀態</div>
          <div :class="$style.textinner4">
            {{ orderData.paymentInfo ? orderData.paymentInfo.RtnMsg : '尚未付款' }}
          </div>
          <div :class="$style.textinner4">綠界交易編號</div>
          <div :class="$style.textinner4">
            {{ orderData.paymentInfo ? orderData.paymentInfo.TradeNo : '尚未付款' }}
          </div>
        </div>
        <paydata
          v-if="orderData.paymentInfo && orderData.paymentInfo.PaymentType !== 'Credit_CreditCard'"
          :orderData="orderData"
        />
      </div>
      <div :class="$style.title" v-if="online !== 'employee'">訂單備註</div>
      <div :class="[$style.textinner, $style.remark]" v-if="online !== 'employee'">
        {{ orderData.note ? orderData.note : '無' }}
      </div>
    </div>
  </div>
</template>

<script>
import groupdata from '@/components/group/groupdata.vue'
import paydata from '@/components/user/paydata.vue'
import { productPageFn } from '@/js'
export default {
  components: {
    groupdata,
    paydata
  },
  props: {
    orderData: Object,
    commonUserInfo: Object
  },
  methods: {
    productRouter (listNumber) {
      const id = this.orderData.products.child[listNumber].parent_id
      const data = this.orderData.products.parent[id]
      // 如果母沒有資料表示商品已經下架
      if (data) {
        const product = { tag: data.sell_type, momSku: data.sku, parentId: data.parent_id }
        productPageFn(product)
      } else {
        this.$message('商品已下架。')
      }
    }
  },
  async created () {
    if (this.orderData.group) {
      await Promise.all([
        this.$store.dispatch({
          type: 'getGroupFunction',
          action: 'getGroupbuyAvailableById',
          bodyObj: {
            id: this.orderData.group
          }
        }),
        this.$store.dispatch({
          type: 'getGroupFunction',
          action: 'getGroupbuyAvailableImageById',
          bodyObj: {
            id: this.orderData.group
          }
        })
      ])
    }
  }
}
</script>

<style lang="scss" module>
.delline {
  text-decoration: line-through;
}

.orange {
  color: #FF6100;
  background: #FF6100;
  padding: 0 10px;
  border-radius: 999rem;
  height: 20px;
  color: #fff;
}

.noorange {
  color: #fff;
  cursor: not-allowed;
  background: #ccc;
  padding: 2px 10px;
  border-radius: 999rem;
  height: 20px;
}

.nodiv {
  display: contents;
}

.innerroot {
  padding: 30px;
  box-sizing: border-box;
  border: 2px solid #F2F2F2;
  border-radius: 0 0 50px 50px;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.textthing {
  display: contents;
}

.textinn {
  cursor: pointer;
  font-size: 14px;
  overflow: hidden;

  &.format {
    cursor: auto;
    overflow: hidden;
    color: #828282;
  }
}

.moneyout {
}

.textline {
  padding-top: 5px;
  text-decoration: line-through;
}

.thingtitle {
  display: grid;
  grid-template-columns: 313px 119px 58px 58px 58px;
  grid-column-gap: 20px;
  width: 100%;
  height: 30px;
  font-weight: 500;
  font-size: 16px;
}

.thingout {
  display: grid;
  grid-template-columns: 60px 233px 119px 58px 58px 58px;
  grid-template-rows: 60px;
  grid-column-gap: 19px;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #C4C4C4;
}

.threeinner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0;
  box-sizing: border-box;
  border-top: 1px solid #C4C4C4;
}

.number {
  display: flex;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.textinner {
  padding: 0 30px;
  box-sizing: border-box;
  overflow: hidden;

  &.right {
    text-align: right;
  }

  &.textstyle {
    white-space: nowrap;
  }

  &.remark {
    padding-top: 10px;
  }
}

.textinner4 {
  box-sizing: border-box;
  font-size: 14px;
  word-break: break-all;

  &.right {
    text-align: right;
  }

  &.textstyle {
    white-space: nowrap;
  }

  &.remark {
    padding-top: 10px;
  }
}

.textinner2 {
  padding: 0 60px;
  box-sizing: border-box;
  overflow: hidden;
}

.textinner3 {
  font-weight: 500;
  font-size: 16px;
  padding: 0 30px;
  color: #FF6100;
  box-sizing: border-box;
  overflow: hidden;

  &.right {
      text-align: right;
  }
}

.line {
  height: 1px;
  background: #000;
  align-self: center;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.totle {
  font-size: 14px;
  font-weight: bold;
  align-self: center;

  &.right {
      text-align: right;
  }
}

.area2 {
  padding: 30px 0;
  box-sizing: border-box;
  min-height: 273px;
  display: grid;
  grid-template-columns: 220px 220px;
  grid-auto-rows: 23px;
  justify-content: flex-end;
  font-size: 14px;
  grid-row-gap: 20px;
}

.area3 {
  width: 371px;
  display: grid;
  grid-column-gap: 26px;
  grid-row-gap: 12px;
  grid-auto-rows: minmax(23px, auto);
  grid-template-columns: 130px 1fr;
  height: auto;
  padding-bottom: 26px;
}

.area3left {
  width: 341px;
  display: grid;
  grid-column-gap: 26px;
  grid-row-gap: 12px;
  grid-auto-rows: minmax(23px, auto);
  grid-template-columns: 130px 1fr;
  height: auto;
  padding-bottom: 52px;
}

.icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 20px;
  font-weight: 900;
  padding-bottom: 22px;
}

@media (max-width: 1365px) {
  .innerroot {
    padding: 30px 15px;
    box-sizing: border-box;
    border: 2px solid #F2F2F2;
    border-radius: 0 0 50px 50px;
    width: 100%;
    height: auto;
    margin-bottom: 45px;
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .return {
    padding-left: 10px;
    font-size: 13px;
    text-decoration: underline;
    color: #828282;
  }

  .line {
    border-top: 2px solid #c4c4c4;
    border-bottom: 2px solid #c4c4c4;
    width: 100%;
  }

  .text {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 29px;
    font-size: 14px;
    border-bottom: 1px solid #c4c4c4;

    &.text2 {
      border-bottom: none;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .btnwatch {
    margin: 10px 0;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    width: 72px;
    height: 30px;
    border-radius: 999rem;
    border: solid 2px #ff6100;

    &.btnwatchactive {
      color: #fff;
      width: 72px;
      height: 30px;
      border-radius: 25px;
      border: solid 2px #ff6100;
      background-color: #ff6100;
    }
  }

  .threeinner {
    border: 0;
    padding: 10px 0;
  }

  .nodiv {
    display: block;
  }

  .area3 {
    grid-auto-rows: minmax(8px, auto);
    width: 100%;
    padding-bottom: 20px;
  }

  .area3left {
    width: 100%;
    grid-auto-rows: minmax(8px, auto);
    padding-bottom: 20px;
  }

  .details {
    border: 2px solid #f2f2f2;
    padding: 30px 20px;
    margin-bottom: 50px;
  }

  .thingout {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 48px 1fr 0.3fr;
    grid-template-rows: 48px 1fr;
    grid-gap: 10px;
    width: 100%;
    height: 100px;
    padding: 10px 0 0;
    border-bottom: 1px solid #f2f2f2;
  }

  .textthing {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.8fr 1fr;
    font-size: 14px;
  }

  .textinn {
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 16px;

    &.format {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 13px;
      color: #828282;
    }
  }

  .moneyout {
    width: 100%;
    height: 100%;
    font-size: 13px;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .textline {
    padding-top: 5px;
    text-decoration: line-through;
  }

  .number {
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    font-size: 13px;
  }

  .truemoney {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .textinner4 {
    align-self: center;
    font-size: 13px;
    word-break: break-all;

    &.right {
      text-align: right;
    }

    &.textstyle {
      white-space: nowrap;
    }

    &.remark {
      padding-top: 10px;
    }
  }

  .textinner {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: center;
    font-size: 13px;
    padding: 0;

    &.right {
      text-align: right;
    }

    &.textstyle {
      white-space: nowrap;
    }

    &.remark {
      padding-top: 10px;
    }
  }

  .textinner2 {
    padding: 0;
  }

  .title {
    align-self: center;
    font-size: 14px;
    font-weight: 500;
  }

  .nodiv2 {
    display: none;
  }

  .textinner3 {
    color: #000;
    font-size: 16px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
  }

  .totle {
    font-size: 14px;
    font-weight: bold;
    align-self: center;

    &.right {
        text-align: right;
    }
  }

  .area2 {
    font-size: 13px;
    padding: 15px 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 150px;
    display: grid;
    grid-gap: 5px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 80px 1fr;
    border-bottom: 1px solid #f2f2f2;
  }

  .area3 .area3left {
    padding: 10px 0;
    width: 100%;
    display: grid;
    grid-gap: 5px;
    grid-auto-rows: 30px;
    grid-template-columns: 110px 1fr;
  }
}
</style>
