<template>
  <div :class="$style.ValidCode">
    <span
      @click="refresh = !refresh"
      :class="$style.bg"
      v-for="(item, index) in codeList"
      :key="index"
      :style="getStyle(item)"
    >
      {{ item.code }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ValidCode',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    length: {
      type: Number,
      default: 4
    },
    refresh: {
      type: Boolean
    }
  },
  data () {
    return {
      codeList: []
    }
  },
  watch: {
    refresh () {
      this.createdCode()
    }
  },
  mounted () {
    this.createdCode()
  },
  methods: {
    refreshCode () {
      this.createdCode()
    },
    createdCode () {
      const len = this.length
      const codeList = []
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789'
      const charsLen = chars.length
      // 生成
      for (let i = 0; i < len; i++) {
        const rgb = [Math.round(Math.random() * 110), Math.round(Math.random() * 110), Math.round(Math.random() * 110)]
        codeList.push({
          code: chars.charAt(Math.floor(Math.random() * charsLen)),
          color: `rgb(${rgb})`,
          fontSize: `${10 + (+[Math.floor(Math.random() * 10)] + 6)}px`,
          padding: `${[Math.floor(Math.random() * 10)]}px`,
          transform: `rotate(${Math.floor(Math.random() * 90) - Math.floor(Math.random() * 90)}deg)`
        })
      }
      this.codeList = codeList
      this.$emit('input', codeList.map(item => item.code).join(''))
    },
    getStyle (data) {
      return `color: ${data.color}; font-size: ${data.fontSize}; padding: ${data.padding}; transform: ${data.transform}`
    }
  }
}
</script>

<style lang="scss" module>
.ValidCode {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ccc;
  user-select: none;
  span{
    display: inline-block;
  }
}

.bg {
}
</style>
