<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div
        :class="[$style.bg, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']"
      >
      <div :class="$style.top">
        <p :class="$style.title">
          訂單評價
        </p>
        <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"/>
      </div>
      <div :class="[$style.top, $style.top2]">
        <p :class="$style.title">購買商品</p>
        <p :class="$style.px12">訂單編號：{{ listData.orderId }}</p>
      </div>
      <div
        :class="$style.borderbotmm"
        v-for="(item, i) in listData.products.child"
        :key="i"
      >
        <div :class="$style.thingout">
          <img
            @click="productRouter(i)"
            :class="$style.img"
            :src="imgUrl + listData.products.parent[item.parent_id].image"
          />
          <div :class="$style.textthing" @click="productRouter(i)">
            <p :class="$style.texttitle">
              {{ listData.products.parent[item.parent_id].name }}
            </p>
            <p :class="[$style.textinn, $style.format]">
              {{ item.name }}
            </p>
          </div>
          <div :class="$style.moneyout">
            <p :class="$style.text">
              $ {{ Math.floor(item.original_price) }}
            </p>
            <p>$ {{ Math.floor(item.price) }}</p>
          </div>
          <div :class="$style.number">
            <span v-if="!webwatch">數量：</span>
            {{ Math.floor(item.qty_ordered) }}
          </div>
          <div v-if="!webwatch"/>
          <div :class="$style.number">
            ＄{{ Math.floor(item.qty_ordered) * item.price }}
          </div>
        </div>
        <p :class="$style.startitle">評價</p>
        <div :class="$style.starout">
          <el-rate
            v-model="fakelistData[i].star"
            :class="$style.star"
          />
        </div>
        <div :class="$style.btn">
          <div
            v-for="(item, idx) in btnList"
            :class="$style.btnn"
            @click="textFn(i, idx)"
            :key="idx"
          >
            {{ item.title }}
          </div>
        </div>
        <textarea
          v-model="fakelistData[i].othertext"
          :class="$style.inputstyle"
          placeholder="告訴別人您對此商品的評價"
        />
      </div>
      <div :class="$style.checkstyle" @click="checkbox = !checkbox">
        <input
          type="checkbox"
          v-model="checkbox"
          :class="$style.boxsize"
        />
        <span :class="$style.boxtext">使用匿名評價</span>
      </div>
      <div
        :class="[$style.loginbtn, 'clickCss']"
        @click="postProductScore"
      >
        送出
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { productPageFn } from '@/js'
export default {
  props: {
    listData: Object
  },
  data () {
    return {
      fakelistData: null,
      btnList: [{
        title: '產品品質佳',
        text: '商品很棒！品質超好ＤＥＲ！'
      }, {
        title: '出貨速度快',
        text: '出貨超快！馬上就到手！'
      }, {
        title: '商品CP值高',
        text: '便宜又好用！超讚啦'
      }, {
        title: '推薦給親友',
        text: '推推！會想推薦給親友！'
      }],
      checkbox: false
    }
  },
  computed: {
    addlistData () {
      return this.listData && this.listData.products.child.map(ele => {
        const star = 0
        const othertext = ''
        return {
          star,
          othertext,
          ...ele
        }
      })
    },
    assessGo () {
      if (this.addlistData) {
        return this.addlistData.every(ele => ele.star > 0)
      } else {
        return false
      }
    }
  },
  methods: {
    productRouter (listNumber) {
      const id = this.listData.products.child[listNumber].parent_id
      const data = this.listData.products.parent[id]
      const product = { tag: data.sell_type, momSku: data.sku, parentId: data.parent_id }
      productPageFn(product)
    },
    async postProductScore () {
      if (this.assessGo) {
        this.$emit('loading', true)
        try {
          for (let i = 0; i < this.fakelistData.length; i++) {
            await this.$store.dispatch({
              type: 'postProductScore',
              id: this.fakelistData[i].parent_id,
              title: this.fakelistData[i].name,
              detail: this.fakelistData[i].othertext,
              star: this.fakelistData[i].star,
              nickname: this.checkbox ? '匿名' : 'no',
              customer_id: this.checkbox ? undefined : Number(this.$route.params.userId)
            })
          }
          this.$emit('success')
          this.$emit('close')
        } catch {
          this.$message.error('評價失敗！請重新送出。')
        } finally {
          this.$emit('loading', false)
        }
      } else {
        this.$message.error('評價、內容都必須填寫唷！')
      }
    },
    textFn (i, idx) {
      this.fakelistData[i].othertext = this.btnList[idx].text
    }
  },
  created () {
    this.fakelistData = this.addlistData
  }
}
</script>

<style lang="scss" module>
textarea {
  resize: none;
}

.img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.thingout {
  display: grid;
  align-items: center;
  grid-template-columns: 60px 363px 1fr 1fr 1fr;
  grid-auto-rows: minmax(60px, auto);
  grid-gap: 15px;
  width: 100%;
  padding: 16px 0 20px 0;
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 10px;
}

.text {
  text-decoration: line-through;
}

.borderbotmm {
  padding-bottom: 36px;
  box-sizing: border-box;
}

.textthing {
  cursor: pointer;
}

.texttitle {
  font-size: 14px;
}

.textinn {
  font-size: 12px;
  color: #828282;
}

.loginbtn {
  cursor: pointer;
  width: 240px;
  height: 40px;
  border-radius: 999rem;
  color: #fff;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #00d77f;
  font-weight: 500;
  font-size: 16px;
}

.rootinner {
  position: fixed;
  padding: 110px 0;
  box-sizing: border-box;
  overflow: auto;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  padding: 28px;
  box-sizing: border-box;
  max-width: 720px;
  height: auto;
  border-radius: 10px;
  border: solid 2px #00d77f;
  background-color: #ffffff;
}

.top {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #C4C4C4;

  &.top2 {
    padding: 20px 0;
    border-bottom: 1px solid #C4C4C4;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
  }
}

.px12 {
  font-size: 13px;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.title2 {
  cursor: pointer;
  color: #888;
  font-size: 26px;
  font-weight: 400;
}

.textthing {
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.inputsize {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: 14px;
}

.truemoney {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.starout {
  width: 100%;
  display: flex;
  height: 35px;
  padding-bottom: 16px;
}

.boxtext {
  padding-left: 12px;
}

.star {
  &:global(.el-rate .el-rate__icon) {
    font-size: 35px;
  }
}

.startitle {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 16px;
}

.btn {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
}

.boxsize {
  width: 20px;
  height: 20px;
}

.btnn {
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 999rem;
  background-color: rgba(0, 215, 127, 0.3);
  margin-right: 12px;
  font-size: 14px;
  color: #404040;
}

.inputstyle {
  text-align: left;
  border: none;
  padding: 18px 23px;
  box-sizing: border-box;
  width: 100%;
  height: 160px;
  background-color: #f7f7f7;
  font-size: 14px;
}

.checkstyle {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-bottom: 50px;
}

@media (max-width: 1365px) {
  .rootinner {
    z-index: 5;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 60px 0;
  }

  .bg {
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    max-width: 720px;
    width: 90%;
    height: auto;
    border-radius: 10px;
    border: solid 2px #00d77f;
    background-color: #ffffff;
  }

  .top {
    font-weight: 400;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #727272;

    &.top2 {
      padding: 15px 0;
      border-bottom: 2px solid #ccc;
      font-size: 14px;
      color: #000000;
      font-weight: 400;
    }
  }

  .px12 {
    font-size: 12px;
  }

  .title {
    font-size: 14px;
  }

  .title2 {
    color: #888;
    font-size: 26px;
    font-weight: 400;
  }

  .thingout {
    display: grid;
    align-items: flex-end;
    grid-template-columns: 48px 1fr 0.3fr;
    grid-template-rows: 48px 1fr;
    grid-gap: 10px;
    width: 100%;
    min-height: 100px;
    padding: 10px 0 0 0;
    border-bottom: 1px solid #f2f2f2;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .textthing {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.8fr 1fr;
    font-size: 14px;
  }

  .textinn {
    color: #000;
    line-height: 16px;
    &.format {
      font-size: 13px;
      color: #828282;
    }
  }

  .inputsize {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
  }

  .number {
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    font-size: 13px;
  }

  .truemoney {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .starout {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-around;
  }

  .star {
    &:global(.el-rate .el-rate__icon) {
      font-size: 35px;
    }
  }

  .startitle {
    padding: 10px 0;
    font-size: 14px;
  }

  .btn {
    display: flex;
    flex-wrap: wrap;
  }

  .btnn {
    padding: 5px 10px;
    border-radius: 999rem;
    background-color: rgba(0, 215, 127, 0.3);
    margin: 0 0 10px 10px;
    font-size: 12px;
    color: #404040;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
  }

  .borderbotmm {
    padding-bottom: 10px;
    box-sizing: border-box;
    border-bottom: 2px solid #ccc;
  }

  .checkstyle {
    padding: 10px 0 20px 0;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
  }

  .loginbtn {
    width: 60%;
    height: 35px;
    border-radius: 999rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00d77f;
  }

  .moneyout {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .number {
    justify-content: flex-end;
  }

  .checkstyle {
    width: 120px;
    margin: 10px 0;
    font-size: 14px;
  }

  .loginbtn {
    height: 45px;
    width: 300px;
    margin: 20px auto;
  }

  .texttitle {
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  .thingout {
    grid-template-rows: unset;
  }

  .textthing {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr 1fr;
    grid-template-rows: unset;
    font-size: 14px;
  }

  .loginbtn {
    width: 100%;
    height: 40px;
  }
}
</style>
