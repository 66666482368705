<template>
  <div :class="$style.root">
    <div class="listoutside">
      <p class="listtitle">運費</p>
      <img
        :class="$style.iconsize"
        src="../../assets/img/productpage_icon_shipping.png"
      />
      <div :class="$style.text" @click="open = !open">
        $ 60-100
        <i
          :class="open ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          style="padding-left: 5px;"
        />
        <fare
          v-if="webwatch && open"
          :class="$style.fare"
        />
      </div>
    </div>
    <fare
      v-if="!webwatch && open"
      :class="$style.fare"
    />
  </div>
</template>

<script>
import fare from '@/views/common/fare.vue'
export default {
  components: {
    fare
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
@keyframes cssA {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 1366px) {
  .iconsize {
    width: 25px;
    height: 25px;
    padding-right: 17px;
  }

  .text {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
  }

  .fare {
    position: absolute;
    width: 400px;
    z-index: 1;
    top: 30px;
    animation-name: cssA;
    animation-duration: 0.3s;
  }
}

@media (max-width: 1365px) {
  .iconsize {
    width: 17px;
    height: 17px;
    padding-right: 5px;
  }

  .text {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .fare {
    width: 100%;
    margin-top: 10px;
    animation-name: cssA;
    animation-duration: 0.3s;
  }
}
</style>
