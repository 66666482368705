const coupon = {
  methods: {
    async receiveCoupon (conpondata) {
      try {
        await this.$store.dispatch({
          type: 'userCouponAction',
          action: 'receiveCoupon',
          bodyObj: {
            couponId: conpondata.coupon_id,
            ruleId: conpondata.rule_id,
            code: conpondata.code
          }
        })
        await this.$store.dispatch({
          type: 'userCouponAction',
          action: 'getMyCoupon'
        })
        this.$message({
          type: 'success',
          message: '已領取優惠券！可以在會員中心找到您剛領的優惠券唷！'
        })
        this.$emit('close')
      } catch {
        this.$message({
          type: 'error',
          message: '領取失敗，請重新領取。'
        })
        this.$emit('close')
      }
    }
  }
}

export default coupon
