<template>
  <div :class="$style.root">
    <div :class="$style.rootinner" v-if="webwatch">
      <div :class="$style.bg">
        <p :class="$style.title">{{ msg }}</p>
        <div :class="$style.btn" @click="$emit('close')">確認</div>
      </div>
    </div>
    <div :class="$style.rootinner" v-else>
      <div :class="$style.bg">
        <p>{{ msg }}</p>
        <div :class="$style.btn" @click="$emit('close')">確認</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String
  }
}
</script>

<style lang="scss" module>
.rootinner {
  z-index: 4;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  border: 3px solid #00D77F;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  box-sizing: border-box;
  width: 520px;
  background: #FFFFFF;
}

.title {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #00D77F;
  border-radius: 999rem;
  color: #fff;
  margin-top: 44px;
  font-weight: 500;
  font-size: 16px;
}

@media (max-width: 1365px) {
  .rootinner {
    z-index: 4;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    border: 3px solid #00D77F;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 42px 22px 42px;
    box-sizing: border-box;
    max-width: 520px;
    width: 80%;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #00D77F;
    border-radius: 999rem;
    color: #fff;
    margin-top: 44px;
  }
}
</style>
