import { requestApi } from '@/request/request'
import { classList } from '@/request'

const searchclassJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    searchClassList: null,
    searchChildList: null,
    searchMarqueeList: null
  },
  mutations: {
    searchClassList (state, payload) {
      state.searchClassList = payload.searchClassList
    },
    searchChildList (state, payload) {
      state.searchChildList = payload.searchChildList.map(ele => ele.data)
    },
    searchMarqueeList (state, payload) {
      state.searchMarqueeList = payload.searchMarqueeList
    }
  },
  actions: {
    async searchClassList (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'getRootCategory'
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      await store.commit({ type: 'searchClassList', searchClassList: response.data })
      store.dispatch('searchChildList')
    },
    async searchChildList (store, payload) {
      const url = '/openwebapi.php'
      const response = await Promise.all(
        store.getters.searchClassList.map(ele => {
          return requestApi.post(url, {
            action: 'getChildrenCategory',
            rootCategoryId: ele.value
          }, {
            headers: {
              Authorization: `Bearer ${store.state.adminToken}`
            }
          })
        })
      )
      store.commit({ type: 'searchChildList', searchChildList: response })
    },
    async searchMarqueeList (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'getMarquee'
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      await store.commit({ type: 'searchMarqueeList', searchMarqueeList: response.data })
    }
  },
  getters: {
    searchClassList: (state) => {
      return classList(state.searchClassList)
    },
    searchChildList: (state) => {
      if (state.searchChildList) {
        return state.searchChildList.map(ele => {
          return classList(ele)
        })
      } else {
        return []
      }
    },
    searchMarqueeList: (state) => {
      return state.searchMarqueeList
    }
  }
}

export default {
  searchclassJs
}
