<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.bg" class="animate__animated animate__fadeInDownBig animate__faster">
        <div :class="$style.top">
          <p :class="$style.title">拼團怎麼玩</p>
          <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
        </div>
        <div :class="$style.main">
          <div :class="$style.all">
            <div :class="$style.circle">
              <div :class="$style.top2">1</div>
              <div :class="$style.down">開新團<br>或跟團</div>
            </div>
            <div :class="$style.circle">
              <div :class="$style.top2">2</div>
              <div :class="$style.down">時限內<br>分享邀請</div>
            </div>
            <div :class="$style.circle">
              <div :class="$style.top2">3</div>
              <div :class="$style.down">所有人付款<br>即出貨</div>
            </div>
          </div>
          <div :class="$style.nav">
            <p
              v-for="(item, i) in navtable"
              :key="i"
              :class="[$style.text3, { [$style.textactive]: active === Number(i) }]"
              @click="active = Number(i)"
            >
              {{ item.name }}
            </p>
          </div>
          <div :class="$style.iconinner" v-if="active === 0">
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step1.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 1</div>
                <div :class="$style.title3">發起拼團</div>
                <div :class="$style.text">選定商品後，點選發起拼團！</div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step2.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 2</div>
                <div :class="$style.title3">成功付款</div>
                <div :class="$style.text">成功下單且完成付款</div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step3.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 3</div>
                <div :class="$style.title3">邀請好友</div>
                <div :class="$style.text">分享連結給好友，限時內達滿團人數並付款成功即成團，逾時若未成團則會全額退款</div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step4.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 4</div>
                <div :class="$style.title3">等待收貨</div>
                <div :class="$style.text">每位團員下單時可指定不同收件地址</div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step5.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 5</div>
                <div :class="$style.title3">領取獎金</div>
                <div :class="$style.text">成團後將收到通知信，若該團無退貨可享有推薦獎金！</div>
              </div>
            </div>
          </div>
          <div :class="$style.iconinner" v-if="active === 1 ">
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step1.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 1</div>
                <div :class="$style.title3">加入拼團</div>
                <div :class="$style.text">找到正在拼團的商品，並加入！</div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step2.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 2</div>
                <div :class="$style.title3">成功付款</div>
                <div :class="$style.text">成功下單且完成付款</div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step3.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 3</div>
                <div :class="$style.title3">邀請好友</div>
                <div :class="$style.text">
                  等待其他人一同拼團獲邀請好友一起加入，限時內達滿團人數並付款成功即成團，逾時若未成團則會全額退款。
                </div>
              </div>
            </div>
            <div :class="$style.area">
              <img :class="$style.icon" src="../../assets/img/step4.png">
              <div :class="$style.right">
                <div :class="$style.step">步驟 4</div>
                <div :class="$style.title3">等待收貨</div>
                <div :class="$style.text">成團後系統將通知店主發貨</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      navtable: [{
        name: '自己開團規則'
      }, {
        name: '跟團規則'
      }]
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0 100px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    box-sizing: border-box;
    width: 520px;
    min-height: 854px;
    border-radius: 10px;
    background-color: #ffffff;
  }

  .top {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #F7F8F9;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .main {
    width: 100%;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .title2 {
    right: 15px;
    position: absolute;
    color: #FF4B00;
    font-size: 26px;
    font-weight: 900;
    cursor: pointer;
  }

  .all {
    padding-top: 30px;
    display: flex;
    align-items: center;
  }

  .circle {
    border: 4px solid #FF6100;
    width: 84px;
    height: 84px;
    background: #FF6100;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    text-align: center;
    width: 84px;
    height: 42px;
  }

  .down{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;
    border-radius: 0 0 999px 999px;
    width: 84px;
    height: 42px;
    background: #FFFFFF;
  }

  .all {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .area {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .right {
    width: 174px;
  }

  .step {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.003em;
    text-decoration-line: underline;
    color: #828282;
    margin-bottom: 5px;
  }

  .title3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: #000000;
    margin-bottom: 3px;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.003em;
    color: #000000;
  }

  .icon {
    width: 45px;
    margin-right: 37px;
  }

  .iconinner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
    cursor: pointer;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
  }

  .nav {
    margin: 30px 0;
    height: 40px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    border-top: 2px solid #F7F8F9;
    border-bottom: 2px solid #F7F8F9;
  }
}

@media (max-width: 1365px) {
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 100px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    box-sizing: border-box;
    width: 90%;
    max-width: 520px;
    border-radius: 10px;
    background-color: #ffffff;
  }

  .top {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 2px solid #F7F8F9;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .main {
    width: 100%;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .title2 {
    right: 15px;
    position: absolute;
    color: #FF4B00;
    font-size: 26px;
    font-weight: 900;
  }

  .all {
    padding-top: 30px;
    display: flex;
    align-items: center;
  }

  .circle {
    border: 4px solid #FF6100;
    width: 84px;
    height: 84px;
    background: #FF6100;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    text-align: center;
    width: 84px;
    height: 42px;
  }

  .down{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #000000;
    border-radius: 0 0 999px 999px;
    width: 84px;
    height: 42px;
    background: #FFFFFF;
  }

  .all {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .area {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .right {
    width: 174px;
  }

  .step {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.003em;
    text-decoration-line: underline;
    color: #828282;
    margin-bottom: 5px;
  }

  .title3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.003em;
    color: #000000;
    margin-bottom: 3px;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.003em;
    color: #000000;
  }

  .icon {
    width: 45px;
    margin-right: 37px;
  }

  .iconinner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text3 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
  }

  .nav {
    margin: 30px 0;
    height: 40px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    border-top: 2px solid #F7F8F9;
    border-bottom: 2px solid #F7F8F9;
  }
}
</style>
