<template>
  <div :class="$style.root">
    <div :class="$style.inner"
      @click.self.prevent="$emit('navFnOut', false)"
    >
      <div
        :class="[$style.nav, 'animate__animated', 'animate__fadeInLeft', 'animate__faster']"
        @click="$emit('navFnOut', true)"
      >
        <div :class="$style.out">
          <p :class="$style.title">目錄</p>
          <div :class="$style.hr"></div>
          <router-link
            v-for="(item, i) in directory"
            :key="i"
            :to="{ name: item.link, params: { current: 1 } }"
            :class="$style.text"
            tag="div"
          >
            <p
              @click="$emit('navFnOut', false)"
              :class="$style.long"
            >
              {{ item.name }}
            </p>
          </router-link>
        </div>
        <div :class="$style.out">
          <p :class="$style.title">分類</p>
          <div :class="$style.hr"></div>
          <div
            :class="$style.titlemenu"
            v-for="(item, i) in searchClassList"
            :key="i"
          >
            <p
              @click="showList === i ? showList = null : showList = i"
            >
              {{ item.name }}
              <i :class="showList === i ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"/>
            </p>
            <div
              :class="[$style.titleinner, 'animate__animated', 'animate__fadeIn', 'animate__faster']"
              v-if="showList === i"
            >
              <p @click="searchClassFunction(item.value)">
                {{ item.name }}
              </p>
              <div
                :class="[$style.titleinner, $style.line]"
                v-for="(item2, i2) in searchChildList[i]"
                :key="i2"
              >
                <p @click="searchClassFunction(item2.value)">
                  {{ item2.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.out">
          <p :class="$style.title">帳戶</p>
          <div :class="$style.hr"></div>
          <div
            @click="logingo"
            :class="$style.text"
            v-if="!online"
          >
            <p @click="$emit('navFnOut', false)" :class="$style.long">會員登入</p>
          </div>
          <div
            @click="userFn"
            :class="$style.text"
            v-else
          >
            <p @click="$emit('navFnOut', false)" :class="$style.long">
              會員中心
            </p>
          </div>
          <div
            @click="regiser"
            :class="$style.text"
            v-if="!online"
          >
            <p @click="$emit('navFnOut', false)" :class="$style.long">新用戶註冊</p>
          </div>
          <div
            @click="logingo"
            :class="$style.text"
            v-else
          >
            <p
              @click="loginFn"
              :class="$style.long"
            >
              會員登出
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { searchClass, toLoginPage, logout, userAction } from '@/js'
export default {
  data () {
    return {
      showList: null,
      directory: [{
        name: 'KOL精選',
        link: 'kolList'
      }, {
        name: '拼團專區',
        link: 'groupList'
      }, {
        name: '優惠組合專區',
        link: 'discountList'
      }, {
        name: '精選商品',
        link: 'commonList'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'searchClassList',
      'searchChildList'
    ])
  },
  methods: {
    searchClassFunction (categoryId) {
      this.$emit('navFnOut', false)
      searchClass(categoryId, this.online, 1)
    },
    logingo () { toLoginPage('login', 'login') },
    regiser () { toLoginPage('login', 'regisn') },
    loginFn () {
      this.$emit('navFnOut', false)
      logout()
    },
    userFn () {
      this.nav = false
      this.$emit('navFnOut', this.nav)
      userAction(this.online,
        'userData',
        this.$store.state.loginInfo.userData.id,
        this.$store.state.shopping.trueShopCarId
      )
    }
  },
  created () {
    window.document.body.style.overflow = 'hidden'
  },
  destroyed () {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" module>
.root {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: calc(100vh - 52px);
  overflow: hidden;
}

.inner {
  width: 100%;
  height: 100%;
}

.nav {
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  width: 240px;
  padding: 13px 26px;
  background: #fff;
}

.title {
  padding: 3px 0 3px 15px;
  box-sizing: border-box;
  border-left: 4px solid #00d77f;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.hr {
  padding-top: 10px;
  border-bottom: 1px solid #f7f8f9;
}

.out {
  width: 100%;
  padding-top: 10px;
}

.text {
  width: 80%;
  padding: 15px 0 15px 15px;
  box-sizing: border-box;
  font-size: 16px;
  color: #000000;

  &.text:active {
    background-color: #e57f12 0.7;
  }
}

.titlemenu {
  width: 80%;
  padding: 10px 0 15px 15px;
  font-size: 16px;
  word-break: break-all;
  cursor: pointer;

  .titleinner {
    font-size: 14px;
    color: #9e9e9e;
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    word-break: break-all;

    .line {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}

.long {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
