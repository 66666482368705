<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div
        :class="$style.inputout"
        v-for="(item, i) in list"
        :key="i"
        v-show="navVal === 'common' ? item.type === 'com' || item.type === 'all'
        : navVal === 'employee' ? item.type === 'employeeandkol' || item.type === 'all'
        : item.type === 'kol' || item.type === 'all' || item.type === 'employeeandkol'
        "
      >
        <span :class="$style.titlesize">
          {{ item.babel }}
          <span v-if="item.must" style="color: red;">
            *
          </span>
        </span>
        <input
          :disabled='inputDisabled'
          :type="item.key === 'password' || item.key === 'repassword'
            ? 'password'
            : item.key === 'user_mobile'
              ? 'number'
              : 'input'
          "
          :class="$style.input"
          :placeholder="'請填寫' + item.babel"
          v-model="register[item.key]"
        />
        <select
          v-if="navVal === 'employee' && item.babel === 'email'"
          :class="$style.selsctsize"
          v-model="employeeEmailValue"
        >
          <option
            v-for="(item, i) in employeeEmail"
            :key="i"
            :value="item"
            :bable="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
      <div :class="$style.errorout">
        <p :class="phoneMsg[1] ? $style.success : 'red'">
          <i :class="phoneMsg[1] ? 'el-icon-circle-check' : 'el-icon-circle-close'"/>
          {{ phoneMsg[0] }}
        </p>
        <p v-if="navVal === 'employee' || navVal === 'kol'" :class="emailMsg[1] ? $style.success : 'red'">
          <i :class="emailMsg[1] ? 'el-icon-circle-check' : 'el-icon-circle-close'"/>
          {{ emailMsg[0] }}
        </p>
        <p :class="passwordMsg[1] ? $style.success : 'red'">
          <i :class="passwordMsg[1] ? 'el-icon-circle-check' : 'el-icon-circle-close'"/>
          {{ passwordMsg[0] }}
        </p>
      </div>
      <div :class="$style.check">
        <input
          :disabled='inputDisabled'
          type="checkbox"
          v-model="register.ok"
          :class="$style.check"
        />
        <p>
          <span>我同意</span>
          <span :class="$style.okcss" @click="popup = !popup">
            網站服務條款及隱私政策
          </span>
        </p>
      </div>
      <div
        :class="[$style.loginbtn,
        inputBoo && btnPressed ? '' : $style.unloginbtn]"
        @click="inputBoo && (validateModal === false )? registFn() : ''"
      >
        立即加入
      </div>
      <div v-if="validateModal">
        <form :class="$style.form" @submit.prevent="validateConfirm">
          <div :class="$style.variCode">
            <span :class="$style.titlesize">驗證碼</span>
            <input :class="$style.input" placeholder="請輸入手機驗證碼" type="text" v-model="validationInput"/>
          </div>
          <div :class="$style.variButtons">
            <button>確認送出</button>
            <button :class="counting? $style.disabled : ''" type="button" :disabled="counting" @click="startCountdown">
              <validate-countdown :class="$style.countdown" v-if="counting" :time="60000" @end="onCountdownEnd" v-slot="{ totalSeconds }">
                重發驗證碼<span :class="$style.seconds"> {{ totalSeconds }} </span>
              </validate-countdown>
              <span v-else>重發驗證碼</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <popup
      @close="popup = !popup"
      v-if="popup"
    />
    <success
      v-if="success"
      :email="this.navVal === 'employee' ? this.register.newEmail : this.register.email"
      @close="success = false
        $emit('unResign')"
    />
  </div>
</template>

<script>
import popup from '@/views/login/popup.vue'
import success from '@/views/login/success.vue'

export default {
  components: {
    popup,
    success
  },
  props: {
    navVal: String
  },
  data () {
    return {
      counting: false,
      btnPressed: true,
      inputDisabled: false,
      validateModal: false,
      validationInput: '',
      validationCode: '',
      list: [{
        babel: '姓名',
        key: 'lastname',
        type: 'all',
        must: true
      }, {
        babel: '藝名',
        key: 'user_nickname',
        type: 'kol',
        must: true
      }, {
        babel: '手機號碼',
        key: 'user_mobile',
        type: 'all',
        must: true
      }, {
        babel: 'email',
        key: 'email',
        type: 'employeeandkol',
        must: true
      }, {
        babel: '帳號',
        key: 'user_id',
        type: 'all',
        must: true
      }, {
        babel: '密碼',
        key: 'password',
        type: 'all',
        must: true
      }, {
        babel: '密碼確認',
        key: 'repassword',
        type: 'all',
        must: true
      }, {
        babel: 'email',
        key: 'test_email',
        type: 'com',
        must: false
      }],
      register: {
        lastname: '',
        user_nickname: '',
        user_mobile: '',
        user_id: '',
        password: '',
        repassword: '',
        email: '',
        ok: false,
        test_email: '',
        group_id: null
      },
      employeeEmail: [
        '@cvicloud-srv.net',
        '@cvilux.com',
        '@opro9.com',
        '@cvimall.net'
      ],
      employeeEmailValue: '@cvicloud-srv.net',
      passwordMsg: ['密碼至少8字元包含大小寫英文、數字。', false],
      phoneMsg: ['手機格式為-0900000000。', false],
      emailMsg: ['Email格式為-example@gmail.com 或 不填', false],
      popup: false,
      success: false
    }
  },
  watch: {
    navVal () {
      // 當一般會員、員工、KOL 頁籤跳換的時候，將欄位清空
      this.register.lastname = ''
      this.register.user_nickname = ''
      this.register.user_mobile = ''
      this.register.user_id = ''
      this.register.password = ''
      this.register.repassword = ''
      this.register.email = ''
      this.register.ok = false
      this.register.test_email = ''
      if (this.navVal === 'employee') {
        this.emailMsg[0] = '員工選擇員工信箱註冊，不必再另外輸入。'
        this.emailMsg[1] = false
      } else if (this.navVal === 'kol') {
        this.emailMsg[0] = 'Email格式為-example@gmail.com'
        this.emailMsg[1] = false
      }
    },
    'register.email': {
      handler () {
        this.remmail()
      },
      // 在初始值
      immediate: true
    },
    'register.user_mobile': {
      handler () {
        this.rephone()
      },
      immediate: true
    },
    'register.password': {
      handler () {
        this.repassword()
      },
      immediate: true
    },
    'register.test_email': {
      handler () {
        this.commonRemmail()
      },
      immediate: true
    }
  },
  computed: {
    inputBoo () {
      // 員工跟 kol 的資料長度檢查，有填寫的才算進去
      if (this.navVal === 'employee' || this.navVal === 'kol') {
        const length = Object.values(this.register).filter(value => Boolean(value) === true).length
        if (this.phoneMsg[1] && this.emailMsg[1] && this.passwordMsg[1]) {
          if (this.navVal === 'kol' && length === 8) {
            return true
          } else if (this.navVal === 'employee' && length === 7) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        // 一般會員的資料長度檢查，有填寫的才算進去
        const length = Object.values(this.register).filter(a => Boolean(a) === true).length
        if (this.phoneMsg[1] && this.passwordMsg[1]) {
          if (this.navVal === 'common' && (length === 8 || length === 7) && this.register.ok) {
            return true
          }
        } else {
          return false
        }
      }
      return false
    }
  },
  methods: {
    async startCountdown () {
      this.counting = true
      await this.$store.dispatch({
        type: 'sendSMS',
        mobileNumber: this.register.user_mobile
      })
      this.validationCode = this.$store.state.register.mobileNum
      this.$message.success('已發送驗證碼至您的手機，請查收簡訊。')
    },
    onCountdownEnd () {
      this.counting = false
    },
    rephone () {
      const PhoneRe = /^09\d{8}$/
      if (PhoneRe.test(this.register.user_mobile)) {
        this.phoneMsg[1] = true
      } else {
        this.phoneMsg[1] = false
      }
    },
    repassword () {
      const passwordRe = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      if (passwordRe.test(this.register.password)) {
        this.passwordMsg[1] = true
      } else {
        this.passwordMsg[1] = false
      }
    },
    remmail () {
      const re = [RegExp('@cvicloud*'),
        RegExp('@cvilux*'),
        RegExp('@opro9*'),
        RegExp('@cvimall*'),
        /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-))*$/,
        RegExp('@')]
      if (this.navVal !== 'employee') {
        re.pop()
        const arr = re.map(ele => ele.test(this.register.email)).indexOf(true)
        if (arr === -1 && this.register.email !== '') {
          this.emailMsg[1] = true
          this.emailMsg[0] = 'Email格式為-example@gmail.com'
        } else if (this.register.email === '') {
          this.emailMsg[1] = false
          this.emailMsg[0] = 'Email格式為-example@gmail.com'
        } else {
          this.emailMsg[1] = false
          this.emailMsg[0] = '請勿使用公司信箱'
        }
      } else {
        if (this.register.email === '') {
          this.emailMsg[1] = false
          this.emailMsg[0] = '員工選擇員工信箱註冊，不必再另外輸入。'
        } else if (RegExp('@').test(this.register.email)) {
          this.emailMsg[1] = false
          this.emailMsg[0] = '員工選擇員工信箱註冊，不必再另外輸入。'
        } else {
          this.emailMsg[1] = true
          this.emailMsg[0] = '員工選擇員工信箱註冊，不必再另外輸入。'
        }
      }
    },
    commonRemmail () {
      if (this.register.test_email) {
        this.register.email = this.register.test_email
      } else {
        this.register.email = Math.random().toString(36).substr(2) + '@visitor.com'
      }
    },
    async registFn () {
      this.$emit('loading', true)
      this.inputDisabled = true
      this.btnPressed = false
      try {
        if (this.inputBoo) {
          if (this.register.password === this.register.repassword) {
            await this.$store.dispatch({
              type: 'getEmailByMobile',
              mobileNum: this.register.user_mobile
            })
            if (this.$store.state.register.registMobileIsUsed) {
              this.$message.error('此手機號碼已註冊過，請使用其他號碼，感謝')
              this.inputDisabled = false
              this.btnPressed = true
            } else {
              console.log('hey')
              console.log('NAV', this.navVal)
              switch (this.navVal) {
                case 'common':
                  this.register.group_id = 1
                  break
                case 'kol':
                  this.register.group_id = 6
                  break
                case 'employee':
                  this.register.newEmail = this.register.email + this.employeeEmailValue
                  this.register.group_id = 5
                  break
              }
              console.log(this.register.group_id)
              this.validateModal = true
              await this.$store.dispatch({
                type: 'sendSMS',
                mobileNumber: this.register.user_mobile
              })
              this.validationCode = this.$store.state.register.mobileNum
              console.log('驗證碼', this.validationCode)
              this.$message.success('已發送驗證碼至您的手機！')
              this.counting = true
            }
          } else {
            this.$message.error('兩者密碼輸入錯誤！請重新輸入。')
          }
        }
      } finally {
        this.$emit('loading', false)
      }
    },
    async validateConfirm () {
      if (this.validationCode === this.validationInput) {
        await this.$store.dispatch({
          type: 'registerok',
          data: this.register
        })
        this.$message.success('您已註冊成功！，請使用新帳號密碼登錄唷')
        this.success = true
        this.validateModal = false
      } else {
        this.$message.error('認證碼錯誤')
      }
    }
  }
}
</script>

<style lang="scss" module>

.disabled {
  background: #ccc !important;
}
.countdown {
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
  width: 320px;
  padding-bottom: 2rem;
}

.variCode {
  display: grid;
  grid-template-columns: 80px 1fr;
  padding-bottom: 8px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid #F7F8F9;
}

.variButtons {
  display: flex;
  justify-content: space-between;

  button {
    cursor: pointer;
    width: 150px;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 999rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    background: #ff6100;
    border: none;
  }
}

.rootinner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.red {
  color: red;
  width: 320px;
}

.unloginbtn {
  background: #ccc !important;
  cursor: default !important;
}

.okcss{
  color: #ff6100;
  text-decoration: underline;
  cursor: pointer;
}

.success {
  color: #10a510;
}

.errorout {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 2px solid #ccc;
  display: grid;
  font-size: 14px;
  grid-row-gap: 5px;
}

.check {
  cursor: pointer;
  border-radius: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #000;
  padding-top: 25px;
  padding-bottom: 10px;
  user-select: none;
}

.inputout {
  display: grid;
  grid-template-columns: 80px 1fr auto;
  align-items: center;
  width: 320px;
  font-size: 14px;
  padding-bottom: 8px;
  border-bottom: 2px solid #F7F8F9;
  margin-bottom: 24px;
}

.input {
  color: #828282;
  font-size: 14px;
  padding-right: 20px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  color: #000;
}

.loginbtn {
  cursor: pointer;
  width: 320px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 999rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  background: #ff6100;
}

.titlesize {
  width: 80px;
}

.selsctsize {
  font-size: 13px;
  height: 20px;
  padding-right: 20px;
  appearance: none;
  border: 0px solid;
  border-radius: 0;
  background-image: url(../../assets/img/botton_arrow.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

@media (max-width: 1365px) {
  .red2 {
    width: 83%;
    margin-top: -18px;
    color: #aaa;
  }

  .red {
    color: red;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .inputout {
    width: 100%;
    height: 30px;
    display: flex;
  }

  .loginbtn {
    width: 80%;
  }

  .input {
    width: 60%;
  }

  .selsctsize {
    min-width: unset;
    width: 60px;
    padding-right: 20px;
  }

  .red {
    width: 100%;
  }
}
</style>
