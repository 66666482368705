<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.bg" v-if="message">
        <div :class="$style.title">
          夥伴提示
        </div>
        <div :class="$style.text">
          <p :class="$style.orangetext">
            {{ message }}
          </p>
        </div>
        <div
          @click="rejectRouter"
          :class="[$style.reject, $style.btn, $style.btn2]"
        >
          回首頁
        </div>
      </div>
      <div :class="$style.bg" v-else-if="this.online && mine">
        <div :class="$style.title">
          夥伴提示
        </div>
        <div :class="$style.text">
          <p :class="$style.orangetext">您不能成為自己的下線！</p>
        </div>
        <div
          @click="rejectRouter"
          :class="[$style.reject, $style.btn, $style.btn2]"
        >
          回首頁
        </div>
      </div>
      <div :class="$style.bg" v-else-if="!reject && this.online">
        <div :class="$style.title">
          夥伴提示
        </div>
        <p :class="$style.text">
          {{ this.$route.params.sharePersonalName }}
          邀請您參加他的銷售團隊，共同經營cvimall網路商店，若按下確定即進行角色綁定，
          成為 {{ this.$route.params.sharePersonalName }}
          的銷售夥伴，爾後購買網站精選商品將享有分潤機制
          (不包含KOL精選商品與拼團專區商品)，和夥伴們一起創造共同利潤吧!
        </p>
        <div :class="$style.orangetext">確定成為對方的夥伴嗎？</div>
        <div :class="$style.btnout">
          <div :class="$style.reject" @click="reject = !reject">
            拒絕
          </div>
          <div
            @click="addShare"
            :class="[$style.reject, $style.btn]"
          >
            確認
          </div>
        </div>
        <div :class="[$style.orangetext, $style.ps]">PS .綁定後將無法退出或變更唷！</div>
      </div>
      <div :class="$style.bg" v-else-if="reject && this.online">
        <div :class="$style.title">
          夥伴提示
        </div>
        <div :class="$style.text">
          <p :class="$style.orangetext">您已拒絕！</p>
          <p>
            若想成為
            {{ this.$route.params.sharePersonalName }}
            的夥伴，請再次掃描qrcode!
          </p>
        </div>
        <div
          @click="rejectRouter"
          :class="[$style.reject, $style.btn, $style.btn2]"
        >
          回首頁
        </div>
      </div>
      <div :class="$style.bg" v-else>
        <div :class="$style.title">
          夥伴提示
        </div>
        <div :class="$style.text">
          <p :class="$style.orangetext">您尚未登入唷！</p>
          <p>
            若想成為
            {{ this.$route.params.sharePersonalName }}
            的夥伴，請先 登入/註冊 後再次加入！
          </p>
        </div>
        <div :class="[$style.btnout, $style.btnout2]">
          <div :class="$style.reject" @click="rejectRouter">
            回首頁
          </div>
          <div
            @click="login"
            :class="[$style.reject, $style.btn]"
          >
            登入/註冊
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      reject: false,
      message: null
    }
  },
  computed: {
    ...mapGetters([
      'commonUserInfo'
    ]),
    mine () {
      if (Number(this.$route.params.sharePersonalId) === Number(this.commonUserInfo.id)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async addShare () {
      try {
        await this.$store.dispatch({
          type: 'addShare',
          parent: Number(this.$route.params.sharePersonalId)
        })
        this.message = `加入成功！恭喜你成為 ${this.$route.params.sharePersonalName} 下線！`
      } catch {
        this.message = '加入失敗！可能您已經有上線，請確認過後再次掃qrcode加入下線吧！'
      }
    },
    rejectRouter () {
      localStorage.removeItem('share')
      this.$router.push({ name: 'home' })
    },
    login () {
      localStorage.setItem('share', window.location.href)
      this.$router.push({ name: 'login', query: { state: 'login' } })
    }
  },
  created () {
    window.document.body.style.overflow = 'hidden'
  },
  destroyed () {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" module>
.rootinner {
  z-index: 4;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 42px;
  box-sizing: border-box;
  width: 500px;
  background: #FFFFFF;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  border-bottom: 2px solid #F7F8F9;
  padding: 19px 0;
  margin-bottom: 28px;
}

.orangetext {
  font-weight: 500;
  font-size: 18px;
  color: #FF6100;
  padding: 37px 0;

  &.ps {
    font-size: 16px;
    padding: 30px 0;
  }
}

.reject {
  cursor: pointer;
  width: 47%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  background: #FFFFFF;
  border: 2px solid #FF6100;
  border-radius: 999rem;

  &.btn {
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    margin-left: 12px;
  }

  &.btn2 {
    margin: 30px auto;
  }
}

.btnout {
  display: flex;

  &.btnout2 {
    margin: 30px 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

@media (max-width: 1365px) {
}

@media (max-width: 767px) {
  .bg {
    width: 80%;
  }
}
</style>
