<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <userinfo @loading="loading = $event"/>
      <div :class="$style.topout">
        <div :class="[$style.draw, 'center']">
          <el-progress
            :stroke-width="webwatch ? 20 : 10"
            type="circle"
            :color="level.color"
            :width="webwatch ? 290 : 170"
            stroke-linecap="square"
            ref="circle"
            :percentage="circleValue || 0"
          />
          <div :class="$style.outtext">
            <p :class="$style.acctext">
              {{ fakedata.gradeText }}
            </p>
            <p :class="$style.montext">
              目前累績消費金額
            </p>
            <p :class="$style.moneytext" :style="{ color: `${level.color}` }">
              {{ orderTotal.total }}
            </p>
          </div>
        </div>
        <div :class="$style.levelout">
          <p :class="$style.level" @click="warnWatch = true">
            離
            {{ level.leveltext }}
            還需消費
            {{ level.levelNumber
              ? orderTotal.total > level.levelNumber.price
                ? '0 元，已達到升級條件，請等待升級。'
                : level.levelNumber.price - orderTotal.total
              : 0
            }}
            <i class="el-icon-warning-outline"/>
          </p>
          <div :class="$style.levelbtm">
            <div :class="$style.tagout">
              <div :class="$style.tag">
                資格
              </div>
              <p :class="$style.textadd">一年內消費消費金額
                {{ level.levelNumber ? level.levelNumber.price : 0 }}
              </p>
            </div>
            <div :class="$style.tagout">
              <div :class="$style.tag">
                優惠
              </div>
              <p :class="$style.textadd" v-if="online === 'common'">
                下一階段訂單現金可享
                {{ level.levelNumber ? level.levelNumber.discount * 100 : 0 }} %
                回饋
              </p>
              <p :class="$style.textadd" v-else>
                下一階段員購享
                {{ level.levelNumber ? level.levelNumber.discount * 100 : 0 }}
                折
              </p>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.accountdata">
        <div :class="$style.datatitle">
          <div
            @click="watchimage = true"
            :class="$style.img"
          >
            <img :class="$style.img2" :src="commonUserInfo.userimage"/>
          </div>
          會員資料
        </div>
        <div :class="$style.uploadimg" v-if="fix !== false">
          <label for="updata" :class="$style.uploadingimglabel">
            <img
              :class="$style.uploadicon"
              src="../../assets/img/uploadicon.png"
            />
              上傳大頭照
          </label>
          <input
            ref="updata"
            id="updata"
            type="file"
            accept="image/jpeg,image/png"
            @change="onChange"
          >
        </div>
      </div>
      <div :class="$style.maininner">
        <p :class="$style.nameline">
          姓名
        </p>
        <input
          v-model="fakedata.name"
          :class="[$style.datainput, {[$style.haveline]: fix}]"
          placeholder="請填寫姓名"
          :disabled="!fix"
        />
        <p :class="[$style.nameline, $style.size]">
          帳號
        </p>
        <input
          v-model="fakedata.account"
          :class="[$style.datainput, {[$style.haveline]: fix}]"
          placeholder="請填寫帳號"
          :disabled="!fix"
        />
        <p :class="$style.nameline">
          密碼
        </p>
        <div :class="[$style.datainput, {[$style.haveline]: fix}]">
          <div
            :class="[$style.fixout]"
          >
            ************
          </div>
          <div
            :class="$style.edit2"
            v-if="fix"
            @click="fixpassword = !fixpassword"
          >
            更改
          </div>
        </div>
        <p :class="[$style.nameline, $style.size]">
          Email
        </p>
        <div :class="[$style.datainput, {[$style.haveline]: fix}]">
          <div
            :class="$style.fixout"
            placeholder="請填寫Email"
          >
            {{ fakedata.email }}
          </div>
          <div
            :class="$style.edit2"
            v-if="fix"
            @click="fixemail = !fixemail"
          >
            更改
          </div>
        </div>
        <p :class="$style.nameline">
          手機
        </p>
        <div :class="[$style.datainput, {[$style.haveline]: fix}]">
          <div
            :class="$style.fixout"
          >
            {{ fakedata.phone }}
          </div>
          <div
            :class="$style.edit2"
            v-if="fix"
            @click="fixphone = !fixphone"
          >
            更改
          </div>
        </div>
        <p :class="[$style.nameline, $style.size]">
          性別
        </p>
        <div :class="[$style.datainput, {[$style.haveline]: fix}]">
          <select
            ref="sex"
            :class="[$style.selectstyle, 'selectstyle']"
            :disabled="!fix"
            v-model="fakedata.sex"
          >
            <option :value="Number(1)">男</option>
            <option :value="Number(2)">女</option>
            <option :value="Number(3)">不詳</option>
          </select>
        </div>
        <p :class="$style.nameline">生日</p>
        <div
          :class="[$style.datainput, $style.datainputpos, {[$style.haveline]: fix}]"
          v-if="!info.dob"
        >
          <input
            type="date"
            v-model="fakedata.birthday"
            :disabled="!fix"
            :class="$style.datecolor"
          />
        </div>
        <div v-else :class="$style.birthday">
          {{ fakedata.birthday }}
        </div>
      </div>
      <div :class="$style.inputout" v-if="online !== 'employee'">
        <div :class="[$style.nameline, $style.namelinetop]">
          我的地址
        </div>
        <div :class="$style.arr">
          <div :class="[$style.arr, $style.arr2]"
            v-for="(item, i) in fakedata.addressList"
            :key="i"
          >
            <div :class="$style.line1">
              <div :class="$style.text5">
                {{
                  item.street[2] === 'home'
                    ? '宅配'
                    : item.street[2] + '-' + item.street[1]
                }}
              </div>
              <div :class="$style.name">
                {{ item.firstname }}
              </div>
              <div
                :class="$style.edit2"
                v-if="fix"
                @click="deladdressFn(i)"
              >
                刪除
              </div>
            </div>
            <div :class="[$style.address, {[$style.haveline]: fix }]">
              <p>
                {{
                  item.street[2] === 'home'
                    ? item.city + item.street[0] + item.street[1]
                    : item.city + item.street[0] + '-' + item.street[1]
                }}
              </p>
              <p>{{ item.telephone }}</p>
            </div>
          </div>
          <div
            :class="[$style.inputinput3, $style.datainput, {[$style.haveline]: fix}]"
            @click="fix ? addresspop = true : ''"
          >
            <span v-if="!fix">
              {{ commonUserInfo.addressList.length > 0  ? '' : '尚未設定地址' }}
            </span>
            {{ fix ? '新增地址' : '' }}
            <i class="el-icon-circle-plus-outline" v-if="fix"/>
          </div>
        </div>
      </div>
      <div
        :class="[$style.fix, { [$style.save]: fix }, 'clickCss']"
        @click="saveInfo"
      >
        {{ fix ? '儲存變更' : '更改資料' }}
      </div>
      <poppu
        :message="message2"
        v-if="message2"
        @close="message2 = null"
      />
      <watchimage
        :image="fakedata.userimage"
        v-if="watchimage"
        @close="watchimage = null"
      />
      <warn
        v-if="warnWatch"
        @close="warnWatch = null"
        :allTotalLevel="allTotalLevel"
      />
      <fixpassword
        v-if="fixpassword"
        @close="fixpassword = null"
        :email="fakedata.email"
      />
      <fixphone
        v-if="fixphone"
        @close="fixphone = null"
        :email="fakedata.email"
        :name="fakedata.name"
        :phone="fakedata.phone"
      />
      <fixemail
        v-if="fixemail"
        @close="fixemail = null"
        :email="fakedata.email"
        :name="fakedata.name"
      />
      <addresspop
        @loading="loading = $event"
        v-if="addresspop"
        @close="addresspop = null"
        :info="commonUserInfo"
      />
    </div>
    <loadingCom v-if="loading"/>
  </div>
</template>

<script>
import userinfo from '@/components/user/info.vue'
import poppu from '@/components/outpu/kolpop.vue'
import watchimage from '@/components/outpu/imagebig.vue'
import warn from '@/components/user/warn/informationwarn.vue'
import fixpassword from '@/components/user/fixpassword.vue'
import fixemail from '@/components/user/fixemail.vue'
import addresspop from '@/components/user/address.vue'
import fixphone from '@/components/user/fixphone.vue'
import { mapGetters } from 'vuex'
import loadingCom from '@/components/outpu/loading2.vue'
import '@/css/inputstyle.scss'
export default {
  components: {
    poppu,
    warn,
    fixpassword,
    fixemail,
    addresspop,
    fixphone,
    userinfo,
    watchimage,
    loadingCom
  },
  data () {
    return {
      fakedata: [],
      message2: false,
      fixphone: false,
      addresspop: false,
      fixpassword: false,
      fixemail: false,
      warnWatch: false,
      fix: false,
      watchimage: false
    }
  },
  watch: {
    commonUserInfo () {
      this.fakedata = this.commonUserInfo
    }
  },
  computed: {
    ...mapGetters([
      'commonUserInfo',
      'allTotalLevel',
      'orderTotal'
    ]),
    info () {
      return this.$store.state.loginInfo.userData
    },
    circleValue () {
      if (this.level.levelNumber) {
        return (this.orderTotal.total / this.level.levelNumber.price * 100) > 100
          ? 100
          : this.orderTotal.total / this.level.levelNumber.price * 100
      } else {
        return 0
      }
    },
    level () {
      const url = this.commonUserInfo.gradeNumber
      if ((url === 1 || url === 2 || url === 3) && this.allTotalLevel) {
        let leveltext
        let levelNumber
        let color
        switch (url) {
          case 1:
            levelNumber = this.allTotalLevel[2]
            leveltext = '一般會員'
            color = '#FF6100'
            break
          case 2:
            levelNumber = this.allTotalLevel[3]
            leveltext = 'VIP會員'
            color = '#FFFDF2'
            break
          case 3:
            levelNumber = this.allTotalLevel[3]
            leveltext = '白金VIP會員'
            color = '#92C4FF'
            break
        }
        return {
          leveltext,
          levelNumber,
          color
        }
      } else if ((url === 5 || url === 7 || url === 8 || url === 9) && this.allTotalLevel) {
        let leveltext
        let levelNumber
        let color
        switch (url) {
          case 5:
            levelNumber = this.allTotalLevel[7]
            leveltext = '一般'
            color = '#FF6100'
            break
          case 6:
            levelNumber = this.allTotalLevel[8]
            leveltext = '一級會員'
            color = '#92C4FF'
            break
          case 7:
            levelNumber = this.allTotalLevel[9]
            leveltext = '二級會員'
            color = '#95F8CF'
            break
          case 8:
            levelNumber = this.allTotalLevel[9]
            leveltext = '三級會員'
            color = '#FFFDF2'
            break
        }
        return {
          leveltext,
          levelNumber,
          color
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    // 刪除地址
    async deladdressFn (value) {
      this.loading = true
      const url = this.fakedata.addressList[value]
      if (url.street[2] !== 'home') {
        const cvsNumber = url.street[1].split('-')[1]
        const boo = this.fakedata.addressList.filter(ele => ele.street[2] !== 'home')
          .map(ele => ele.street[1].split('-')[1])
          .filter(ele => ele === cvsNumber)
          .length
        if (boo === 1) {
          try {
            await this.$store.dispatch({
              type: 'csvData',
              action: 'deleteCustomJson',
              key: cvsNumber
            })
          } catch {
          }
        }
      }
      try {
        await this.$store.dispatch({
          type: 'delAddress',
          addressId: this.fakedata.addressList[value].id
        })
        await this.$store.dispatch({
          type: 'getUserData'
        })
        this.$message.success('刪除成功')
      } catch {
        this.$message.error('刪除地址失敗')
      } finally {
        this.loading = false
      }
    },
    // 新增地址
    async addressFn () {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'getAddressList'
        })
        this.addresspop = true
      } finally {
        this.$emit('loading', false)
      }
    },
    // 上傳照片
    async onChange () {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'updataImg',
          img: this.$refs.updata.files
        })
        await this.$store.dispatch({
          type: 'getUserData'
        })
      } catch (err) {
        this.message2 = err.response.data.message
      } finally {
        this.$emit('loading', false)
      }
    },
    // 儲存
    async saveInfo () {
      console.log(this.fix)
      if (this.fix) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'saveUserData',
            dob: this.fakedata.birthday,
            email: this.fakedata.email,
            lastname: this.fakedata.name,
            gender: this.fakedata.sex,
            addresses: this.addressListfake,
            group_id: this.$store.state.loginInfo.userIdentity,
            user_id: this.fakedata.account,
            mobile: this.fakedata.phone,
            user_nickname: this.fakedata.fakename,
            kol_fb: '',
            kol_utube: '',
            kol_ig: '',
            customerId: Number(this.$route.params.userId)
          })
          this.fix = false
          this.$store.dispatch({
            type: 'getUserData'
          })
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        } catch (err) {
          this.message2 = err.response.data.message
          this.$store.dispatch({
            type: 'getUserData'
          })
        } finally {
          this.$emit('loading', false)
        }
      } else {
        this.fix = !this.fix
      }
    }
  },
  async created () {
    console.log('this.commonUserInfo', this.commonUserInfo)
    this.fakedata = this.commonUserInfo
    this.$emit('loading', true)
    try {
      await Promise.all([
        this.$store.dispatch({
          type: 'totalLevel'
        }),
        this.$store.dispatch({
          type: 'orderTotal'
        })
      ])
    } finally {
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" module>
.topout {
  display: flex;
  align-items: center;
  margin: 62px 0 58px 0;
  box-sizing: border-box;
}

.draw {
  position: relative;
}

.outtext  {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.acctext {
  font-weight: 500;
  font-size: 20px;
}

.birthday {
  display: flex;
  align-items: center;
}

.montext {
  font-size: 16px;
}

.moneytext {
  padding-top: 20px;
  font-weight: 500;
  font-size: 36px;
  color: #ff6100;
}

.levelout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 85px;
  width: 100%;
}

.tagout {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000000;
  margin-bottom: 18px;
}

.level {
  cursor: pointer;
  font-size: 14px;
  align-self: flex-start;
  padding-bottom: 15px;
}

.img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  border: 1px solid #ccc;
  border-radius: 999rem;
  cursor: pointer;
  overflow: hidden;
}

.img2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.levelbtm {
  padding-top: 26px;
  border-top: 1px solid #F2F2F2;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.textadd {
  font-size: 14px;
}

.datatitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.dataimg {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 999rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #ccc;
  cursor: pointer;
}

.maininner {
  width: 100%;
  display: grid;
  grid-template-columns: 38px 250px 98px 250px;
  grid-auto-rows: 32px;
  grid-row-gap: 23px;
  grid-column-gap: 38px;
  margin-bottom: 32px;
}

.tag {
  width: 100px;
  height: 28px;
  border-radius: 999rem;
  background-color: #ffc000;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 24px;
}

.accountdata {
  border-top: 2px solid #C4C4C4;
  padding: 35px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploadimg {
  font-size: 16px;
  color: #8b8b8b;
}

.uploadingimglabel {
  cursor: pointer;
}

.datainput {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0);

  &.datainputpos {
    justify-content: space-between;
  }

  &.haveline {
    border-bottom: 2px solid #F2F2F2;
  }
}

.fixout {
  border: none;
  flex: 1;

  &[disabled] {
    color: #828282;
    background: #fff;
  }
}

.edit2 {
  cursor: pointer;
  width: 29px;
  font-size: 14px;
  text-decoration-line: underline;
  color: #828282;
}

.selectstyle {
  cursor: pointer;
  min-width: 60px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 14px;
}

.inputout {
  display: grid;
  grid-template-columns: 75px 1fr;
  width: 100%;
  margin-bottom: 20px;

  &[disabled] {
    background: #fff;
  }
}

.nameline {
  display: flex;
  align-items: center;
  font-size: 14px;

  &.size {
    justify-self: flex-end;
  }

  &.namelinetop {
    align-items: flex-start;
  }
}

.uploadicon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  padding-bottom: 2px;
  font-size: 14px;
}

.fix {
  cursor: pointer;
  border: 1px solid #ff6100;
  color: #ff6100;
  width: 200px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 999rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 30px;

  &.save {
    background-color: #ff6100;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #fff;
  }
}

.line1 {
  width: 100%;
  display: grid;
  grid-template-columns: 224px 1fr 30px;
  grid-column-gap: 10px;
}

.text5 {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.name {
  font-size: 14px;
}

.arr {
  display: flex;
  flex-direction: column;

  &.arr2 {
    margin-bottom: 25px;
  }
}

.address {
  width: 100%;
  word-wrap: break-word;
  padding: 10px 0 10px 0;
  font-size: 14px;
  color: #828282;
  border-bottom: 2px solid rgba(0, 0, 0, 0);

  &.haveline {
  border-bottom: 2px solid #F2F2F2;
  }
}

.inputinput3 {
  cursor: pointer;
  font-size: 14px;
  padding-bottom: 10px;
}

.dateinput {
  background: #fff;
  position: relative;
  border: none;
  font-size: 14px;
  color: #fff;
}

.datecolor {
  cursor: pointer;
  border: none;
  font-size: 14px;
  color: #000;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(../../assets/img/botton_arrow.png);
  background-color: rgba(0, 0, 0, 0);
  background-size: 25px 25px;
  width: 140px;

  &[disabled] {
    background-image: none;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: none;
}

input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

@media (max-width: 1365px) {
  .topout {
    margin: 30px 0 0 0;
    flex-direction: column;
  }

  .draw {
    margin-bottom: 46px;
  }

  .acctext {
    font-size: 13px;
  }

  .montext {
    font-size: 14px;
  }

  .levelout {
    padding-left: 0;
  }

  .dataimg {
    width: 25px;
    height: 25px;
  }

  .uploadicon {
    margin-right: 0px;
    padding-bottom: 0px;
  }

  .datatitle {
    font-size: 14px;
  }

  .moneytext {
    padding-top: 0px;
  }

  .levelbtm {
    padding: 18px 0 24px 0;
    border-bottom: 4px solid #F2F2F2;
  }

  .tag {
    margin-right: 15px;
    font-size: 14px;
    width: 64px;
    height: 22px;
  }

  .maininner {
    padding: 28px 15px 0 15px;
    box-sizing: border-box;
    grid-template-columns: 60px 1fr;
    grid-auto-rows: 25px;
    grid-column-gap: 22px;
    grid-row-gap: 25px;
    margin-bottom: 25px;
  }

  .accountdata {
    border: 0;
    padding: 17px 15px 0 15px;
  }

  .nameline {

    &.size {
      justify-self: flex-start;
    }
  }

  .inputout {
    padding: 0 15px;
    box-sizing: border-box;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 27px;
    min-height: 25px;
    margin-bottom: 20px;
  }

  .selectstyle {
    font-size: 13px;
  }

  .line1 {
    grid-template-columns: 0.9fr 1fr 30px;
  }

  .arr {

    &.arr2 {
      margin-bottom: 15px;
    }
  }

  .address {
    border-bottom: 1px solid #F2F2F2;
    padding: 12px 0;
    font-size: 12px;
    line-height: 20px;
    color: #828282;
  }

  .inputinput3 {
    padding-bottom: 10px;
  }

  .datecolor {
    width: 145px;
  }
}

@media (max-width: 767px) {
  .rootinner {
    width: 100%;
  }
}
</style>
