<template>
  <div :class="$style.root">
    <div :class="$style.bottom">
      <div :class="$style.outside1">
        <div :class="$style.area1">
          <div :class="$style.textout">
            <img src="../../assets/img/cupon_icon_normal.png" :class="$style.icon2"/>
            <p :class="$style.text">優惠券</p>
          </div>
          <div :class="$style.textout">
            <p :class="$style.textmoney">
              - ${{ pickCoupon >= 0
                      ? Math.floor(okPickCouponList[pickCoupon].discount_amount)
                      : 0
                }}
            </p>
            <p
              @click="trueBoxAll.trueboxValue > 0 ? componentname  = 'coupon' : ''"
              :class="[$style.textline, {['red']: trueBoxAll.trueboxValue <= 0 }, 'clickCss']"
            >
              {{ trueBoxAll.trueboxValue > 0 ? '選擇優惠券' : '未選擇商品' }}
            </p>
          </div>
        </div>
        <div :class="$style.area1" v-if="this.$route.name !== 'groupshoplist'">
          <div :class="$style.textout">
            <img src="../../assets/img/productpage_icon_coins.png" :class="$style.icon2"/>
            <p :class="$style.text">現金折抵</p>
          </div>
          <div :class="$style.textout">
            <p :class="$style.textmoney">
              - ${{ inputRedpoint }}
            </p>
            <p
              :class="[$style.textline, 'clickCss']"
              @click="componentname  = 'redpotie'"
            >
              選擇現金數
            </p>
          </div>
        </div>
      </div>
      <div :class="$style.area2">
        <div :class="$style.border" v-if="this.$route.name !== 'groupshoplist'">
          <input
            type="checkbox"
            :class="$style.icon"
            @input="$emit('pickAll', !trueBoxAll.truebox)"
            v-model="trueBoxAll.truebox"
          />
          <div
            :class="[$style.numberstyle, 'clickCss']"
            @click="$emit('pickAll', !trueBoxAll.truebox)"
          >
            全選( {{ trueBoxAll.trueboxValue }} )
          </div>
          <!-- <p
            :class="[$style.underlineborder, 'clickCss']"
            v-if="webwatch"
            @click="$emit('onAllThing')"
          >
            移除無效商品
          </p> -->
        </div>
        <div v-else></div>
        <div :class="$style.border2">
          <div :class="$style.ber2out">
            <div :class="$style.moneyout">
              總金額 ({{ trueBoxAll.trueboxValue }}個商品) :
              <span :class="$style.money">
                ${{ money }}
              </span>
            </div>
            <p :class="$style.red">
              可獲得現金： {{ redpoitValue }}
            </p>
          </div>
          <div
            :class="[$style.btn, 'clickCss']"
            @click="goshopFn"
          >
            去買單
          </div>
        </div>
        <p></p>
        <p :class="[$style.right, 'red']">點選去買單後 即顯示折扣價格</p>
      </div>
    </div>
    <coupon
      v-if="componentname === 'coupon'"
      @close="componentname = null"
      :pickCoupon="pickCoupon"
      @pickCoupon="pickCoupon = $event"
      v-bind="$attrs"
      :okPickCouponList="okPickCouponList"
    />
    <redpotie
      v-if="componentname === 'redpotie'"
      @close="componentname = null"
      :redPoint="redPoint"
      @inputRedpoint="inputRedpoint = $event"
    />
  </div>
</template>

<script>
import coupon from '@/views/shopping/coupon.vue'
import redpotie from '@/views/shopping/redpotie.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    trueBoxAll: Object
  },
  components: {
    coupon,
    redpotie
  },
  data () {
    return {
      componentname: null,
      pickCoupon: -1,
      inputRedpoint: 0
    }
  },
  computed: {
    ...mapGetters([
      'allTotalLevel',
      'commonUserInfo',
      'redPoint',
      'userCouponList',
      'couponBySku'
    ]),
    okPickCouponList () {
      if (this.couponBySku && this.userCouponList) {
        const cmp = (o1, o2) => o1 === o2
        return this.couponBySku.filter(ele => this.userCouponList.some(ele2 => cmp(ele2.coupon_id, ele.coupon_id)))
      } else {
        return []
      }
    },
    money () {
      return this.trueBoxAll.money
    },
    redpoitValue () {
      if (this.allTotalLevel && this.commonUserInfo) {
        const discount = this.allTotalLevel[this.commonUserInfo.gradeNumber].discount
        return Math.floor(this.trueBoxAll.money * discount)
      } else {
        return 0
      }
    }
  },
  methods: {
    async goshopFn () {
      if (this.money > 0) {
        try {
          const coupon = this.okPickCouponList[this.pickCoupon]
          if (coupon && this.money < Math.floor(coupon.fullPrice)) {
            this.$notify({
              message: `${'金額必須滿' + Math.floor(this.okPickCouponList[this.pickCoupon].fullPrice) + '元才能使用優惠券唷！'}`
            })
          } else {
            this.$emit('loading', true)
            await Promise.all([
              this.$store.dispatch({
                type: 'customJsonFn',
                action: 'addCustomJson',
                key: 'pickCoupon',
                value: String(this.pickCoupon)
              }),
              this.$store.dispatch({
                type: 'customJsonFn',
                action: 'addCustomJson',
                key: 'inputRedpoint',
                value: String(this.inputRedpoint)
              })
            ])
            this.$emit('goShop')
          }
        } catch {
          this.$notify({
            message: '因網路延遲，請重新整理再試一次。'
          })
          this.$emit('loading', false)
        }
      } else {
        this.$notify({
          title: '請確定金額',
          message: '金額必須為正數喔！'
        })
      }
    }
  },
  async created () {
    try {
      await Promise.all([
        this.$store.dispatch({
          type: 'customJsonFn',
          action: 'deleteCustomJson',
          key: 'pickCoupon'
        }),
        this.$store.dispatch({
          type: 'customJsonFn',
          action: 'deleteCustomJson',
          key: 'inputRedpoint'
        }),
        this.$store.dispatch({
          type: 'totalLevel'
        })
      ])
    } catch {
      this.$emit.error('購物車載入失敗，請稍後在進行購物。')
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" module>
.bottom {
  display: grid;
  grid-auto-rows: minmax(112px, auto) minmax(80px, auto);
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 2px #ff6100;
  background-color: #ffffff;
}

.icon {
  border-radius: 0;
  width: 18px;
  height: 18px;
  margin-right: 12px;
}

.right {
  text-align: right;
  margin: 0 33px 10px 0;
}

.numberstyle{
  width: 113px;
}

.border{
  cursor: pointer;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #828282;

  .underlineborder {
    text-decoration: underline;
  }
}

.border2 {
  padding: 0 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
}

.outside1 {
  padding: 24px 0;
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #FF6100;
}

.area1 {
  display: flex;
  align-items: center;
}

.area3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.text {
  display: flex;
  font-size: 14px;
  width: 63px;
}

.textmoney {
  padding: 0 24px;
  width: 122px;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.textline {
  cursor: pointer;
  font-size: 14px;
  color: #828282;
  text-decoration: underline;
}

.ber2out {
  justify-self: flex-end;
  align-self: center;
  font-size: 14px;
}

.area2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.btn {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ff6100;
  color: #fff;
  justify-self: center;
  align-self: center;
}

.icon2 {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

.money {
  font-weight: 500;
  font-size: 24px;
  color: #ff6100;
  padding-left: 8px;
}

.red {
  font-size: 13px;
  color: #ffc000;
  text-align: right;
}

.textout {
  display: contents;
}

@media (max-width: 1365px) {
  .outside1 {
    display: contents;
  }

  .textout {
    display: flex;
  }

  .border{
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  .border2 {
    display: contents;
  }

  .area1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px;
    box-sizing: border-box;
    border-bottom: 2px solid #ff6100;
  }

  .area3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .textmoney {
    align-items: center;
    justify-content: flex-end;
    color: #C4C4C4;
  }

  .numberstyle {
    width: unset;
  }

  .text {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    border: solid 3px #ff6100;
  }

  .textline {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 10px;
  }

  .area2 {
    display: grid;
    margin: 0 15px;
    grid-template-columns: 100px 1fr 100px;
    grid-template-rows: 1fr;
  }

  .btn {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 31px;
    border-radius: 30px;
    background-color: #ff6100;
    color: #fff;
  }

  .icon2 {
    width: 20px;
    height: 20px;
    padding-right: 10px;
  }

  .icon {
    width: 20px;
    padding-right: 10px;
    display: flex!important;
    justify-content: center!important;
  }

  .money {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .red {
    text-align: right;
    font-size: 12px;
    color: #ffc000;
    padding: 5px 0;
  }

  .border{
    padding: 0;
    font-size: 14px;
    color: #828282;

    .underlineborder {
      text-decoration: underline;
    }
  }
  .right {
    text-align: right;
    margin: 0 0 10px 0;
  }

  .area1 {
    min-height: 45px;
  }

  .area2 {
    min-height: 45px;
  }

  .border2 {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .area2 {
    display: flex;
    margin: 0 15px;
    flex-direction: column;
  }

  .moneyout {
    text-align: right;
  }

  .ber2out {
    align-self: flex-end;
  }

  .btn {
    margin-bottom: 10px;
    align-self: flex-end;
  }

  .area1 {
    min-height: 45px;
  }

  .area2 {
    min-height: 100px;
  }

  .border {
    margin-top: 10px;
  }

  .red {
    margin-bottom: 5px;
  }
}
</style>
