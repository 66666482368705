import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import reset from './css/reset.css'
import main from './css/main.scss'
import productcss from './css/product.scss'
import productstyle from './css/productstyle.scss'
import list from './css/list.scss'
import mainjs from './js/main.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import message from '@/components/outpu/message.vue'
import VueFb from './js/fb.js'
import animated from 'animate.css'
import VueCountdown from '@chenfengyuan/vue-countdown'

Vue.config.productionTip = false
Vue.use(VueFb)
Vue.use(ElementUI)
Vue.use(animated)
Vue.component('message', message)
Vue.component('validateCountdown', VueCountdown)
Vue.mixin(mainjs)

new Vue({
  router,
  store,
  reset,
  main,
  mainjs,
  productcss,
  productstyle,
  list,
  render: h => h(App)
}).$mount('#app')
