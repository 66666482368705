<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.bg" class="animate__animated animate__fadeInDownBig animate__faster">
        <div :class="$style.top">
          網站服務條款及隱私政策
        </div>
        <p :class="$style.maininner" v-html="typeof onlyHtml === 'string' ? onlyHtml : onlyHtml[0]"/>
        <div :class="$style.bottom" @click="$emit('close')">
          我已閱讀完畢
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'onlyHtml'
    ])
  },
  created () {
    this.$store.dispatch({
      type: 'getFooterOnlyHtml',
      numberArray: [16],
      name: 'onlyHtml'
    })
  }
}
</script>

<style lang="scss" module>
.inner {
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 4;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  margin: auto;
  width: 800px;
  height: auto;
  background: #FFFFFF;
  border-radius: 10px;
}

.top {
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #EEEEEE;
  font-weight: 500;
  font-size: 18px;
}

.maininner {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  word-wrap: break-word;
  min-height: 250px;
}

.bottom {
  cursor: pointer;
  margin-top: 40px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 320px;
  height: 40px;
  background: #FF6100;
  border-radius: 999rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

@media (max-width: 1365px) {
  .bg {
    width: 720px;
    border: 2px solid #FF6100;
  }

  .top {
    height: 55px;
  }

  .bottom {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .bg {
    width: 80%;
  }

  .bottom {
    width: 80%;
  }
}
</style>
