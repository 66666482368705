import store from '../../store'
import Shoppinglistto from '../../views/shopping/shoppinglistto.vue'
import Shoppinginfo from '../../views/shopping/shoppinginfo.vue'
import Success from '../../views/shopping/success.vue'
import Fail from '../../views/shopping/fail.vue'

const isUser = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 1 || id === 2 || id === 3) {
    next()
  } else if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else {
    next({ name: 'home' })
  }
}

const list = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (to.name === 'shoppinginfo' && from.name === 'shoppinglistto' && (id === 1 || id === 2 || id === 3)) {
    next()
  } else if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else {
    next({ name: 'home' })
  }
}

const success = async (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 1 || id === 2 || id === 3) {
    next()
  } else if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else {
    next({ name: 'home' })
  }
}

export default [{
  path: 'shoppinglistto/:userId/:carId',
  name: 'shoppinglistto',
  component: Shoppinglistto,
  beforeEnter: isUser
}, {
  path: 'shoppinginfo/:userId/:carId',
  name: 'shoppinginfo',
  component: Shoppinginfo,
  beforeEnter: list
}, {
  path: 'success',
  name: 'success',
  component: Success,
  beforeEnter: success,
  query: {
    orderId: undefined
  }
}, {
  path: 'groupshoplist/:userId/:carId/:type',
  name: 'groupshoplist',
  component: Shoppinglistto,
  query: { openId: null, groupId: null }
}, {
  path: 'groupshoppinginfo/:userId/:carId/:type',
  name: 'groupshoppinginfo',
  component: Shoppinginfo,
  query: { openId: null, groupId: null }
}, {
  path: 'failOrderId',
  name: 'failOrderId',
  component: Fail
}]
