import store from '@/store'

const mixin = {
  data () {
    return {
      loading: false,
      message: false,
      webwatch: null,
      imgUrl: 'https://shop.cvicloud-srv.net/pub/media/catalog/product/',
      userPhoto: 'https://shop.cvicloud-srv.net/pub/media/customer'
    }
  },
  methods: {
    webWatchFn () {
      if (window.innerWidth > 1365) {
        this.webwatch = true
      } else if (window.innerWidth < 767) {
        this.webwatch = false
      } else {
        this.webwatch = false
      }
    }
  },
  computed: {
    online () {
      const type = store.state.loginInfo.userIdentity
      if (type) {
        switch (type) {
          case 1:
          case 2:
          case 3:
            return 'common'
          case 4:
          case 6:
            return 'kol'
          case 5:
          case 7:
          case 8:
          case 9:
            return 'employee'
        }
      } else {
        return false
      }
    },
    onlinenNmber () {
      const type = store.state.loginInfo.userIdentity
      if (type) {
        return type
      } else {
        return false
      }
    },
    routerName () {
      return this.$route.name
    }
  },
  mounted () {
    this.webWatchFn()
    window.addEventListener('resize', this.webWatchFn)
  },
  beforeDestory () {
    window.removeEventListener('resize', this.webWatchFn)
  }
}

export default mixin
