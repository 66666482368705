import { onlyAction } from '@/request/otherapi'
import { request } from '@/request/request'
import { webApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'

const pointJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    pointNumber: null,
    usePointsHistory: null
  },
  mutations: {
    pointNumber (state, payload) {
      state.pointNumber = payload.pointNumber
    },
    usePointsHistory (state, payload) {
      state.usePointsHistory = payload.usePointsHistory
    }
  },
  actions: {
    async getPointNumber ({ state, commit }, { action }) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await onlyAction('post', action, token)
      commit({ type: 'pointNumber', pointNumber: data })
    },
    async usePointsHistory ({ commit }, payload) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const bodyObj = { pageSize: 8, currentPage: payload.currentPage }
      const data = await webApiFunciton(payload.action, token, bodyObj)
      commit({ type: 'usePointsHistory', usePointsHistory: data })
    },
    async useRedPoints (store, payload) {
      const url = 'carts/mine/mpreward'
      await request.post(url, {
        addressInformation: {},
        ruleId: 'rate',
        points: payload.points
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    }
  },
  getters: {
    redPoint: (state) => {
      if (state.pointNumber) {
        return state.pointNumber.point_balance ? state.pointNumber.point_balance : 0
      } else {
        return 0
      }
    },
    usePointsHistory: (state) => {
      if (state.usePointsHistory) {
        return state.usePointsHistory
      } else {
        return []
      }
    }
  }
}

export default {
  pointJs
}
