<template>
  <div :class="$style.root">
    <div :class="$style.innerroot">
      <div :class="$style.inner">
        <div :class="$style.titletext" v-if="webwatch === false">KOL基本資料</div>
        <div :class="$style.top" v-if="webwatch === false">
          <img
            :src="fakedata.userimage"
            :class="$style.uploadpic"
          />
          <input
            ref="updata"
            type="file"
            accept="image/jpeg,image/png"
            @change="onChange"
          />
          <div :class="$style.size" v-if="fix">
            <p>照片建議解析度：500PX x 500PX</p>
            <p>檔案大小限制：6MB</p>
          </div>
          <label
            v-if="fix"
            :class="$style.upbutton"
            @click="fix ? $refs.updata.click() : ''"
          >
            上傳圖片
          </label>
        </div>
        <div :class="$style.titletext">KOL資料</div>
        <div :class="$style.maininner">
          <div :class="$style.title">帳號</div>
          <input
            v-model="fakedata.account"
            :class="$style.inputstyle"
            placeholder="請輸入帳號"
            :disabled="!fix"
          />
          <div :class="$style.title">姓名</div>
          <input
            v-model="fakedata.name"
            :class="$style.inputstyle"
            placeholder="請輸入名字"
            :disabled="!fix"
          />
          <div :class="$style.title">藝名</div>
          <input
            v-model="fakedata.fakename"
            :class="$style.inputstyle"
            placeholder="請輸入藝名"
            :disabled="!fix"
          />
          <div :class="$style.title">手機</div>
          <div :class="[$style.inputout, {[$style.nolinecss]: !fix }]">
            <div :class="$style.fixinputstyle">
              {{ fakedata.phone }}
            </div>
            <div
              @click="fixphone = !fixphone"
              :class="$style.fix"
              v-if="fix"
            >
              修改
            </div>
          </div>
          <div :class="$style.title">Email</div>
          <div :class="[$style.inputout, {[$style.nolinecss]: !fix }]">
            <div :class="$style.fixinputstyle">
              {{ fakedata.email }}
            </div>
            <div
              @click="fixemail = !fixemail"
              :class="$style.fix"
              v-if="fix"
            >
              修改
            </div>
          </div>
          <div :class="$style.title">密碼</div>
          <div :class="[$style.inputout, {[$style.nolinecss]: !fix }]">
            <div :class="$style.fixinputstyle"> ********* </div>
            <div
              @click="fixpassword = !fixpassword"
              :class="$style.fix"
              v-if="fix"
            >
              修改
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.inner2" v-if="webwatch">
        <div :class="$style.top">
          <img
            :class="$style.uploadpic"
            :src="fakedata.userimage"
          />
          <input
            ref="updata"
            type="file"
            accept="image/jpeg,image/png"
            @change="onChange"
          />
          <div :class="$style.size" v-if="fix">
            <p>照片建議解析度：500PX x 500PX</p>
            <p>檔案大小限制：6MB</p>
          </div>
                    <label
            v-if="fix"
            :class="$style.upbutton"
            @click="fix ? $refs.updata.click() : ''"
          >
            上傳圖片
          </label>
        </div>
      </div>
      <div :class="$style.inner">
        <div :class="$style.titletext">連結網址</div>
        <div :class="$style.urlout">
          <div :class="$style.imgfb"></div>
          <input
            v-model="fakedata.kolfb"
            :class="$style.inputstyle"
            placeholder="尚無資訊"
            :disabled="!fix"
          />
          <div :class="$style.imgig"></div>
          <input
            v-model="fakedata.kolig"
            :class="$style.inputstyle"
            placeholder="尚無資訊"
            :disabled="!fix"
          />
          <div :class="$style.imgu2b"></div>
          <input
            v-model="fakedata.kolutube"
            :class="$style.inputstyle"
            placeholder="尚無資訊"
            :disabled="!fix"
          />
        </div>
      </div>
      <div :class="$style.out">
        <div
          :class="[fix ? $style.fixbtn : $style.save]"
          @click="saveInfo"
        >
          <p :class="$style.btn">
            {{ fix ? '儲存變更' : '更改資料' }}
          </p>
        </div>
      </div>
      <poppu
        :message="message2"
        v-if="message2"
        @close="message2 = null"
      />
      <fixpassword
        v-if="fixpassword"
        @close="fixpassword = null"
        :email="fakedata.email"
      />
      <fixphone
        v-if="fixphone"
        @close="fixphone = null"
        :email="fakedata.email"
        :name="fakedata.name"
        :phone="fakedata.phone"
      />
      <fixemail
        v-if="fixemail"
        :email="fakedata.email"
        :name="fakedata.name"
        @close="fixemail = null"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import fixpassword from '@/components/user/fixpassword.vue'
import fixemail from '@/components/user/fixemail.vue'
import fixphone from '@/components/user/fixphone.vue'
import poppu from '@/components/outpu/kolpop.vue'

export default {
  components: {
    fixpassword,
    fixemail,
    fixphone,
    poppu
  },
  data () {
    return {
      fakedata: [],
      message2: false,
      fixphone: false,
      addresspop: false,
      fixpassword: false,
      fixemail: false,
      warnWatch: false,
      fix: false,
      watchimage: false
    }
  },
  watch: {
    commonUserInfo () {
      this.fakedata = this.commonUserInfo
    },
    fakedata (value) {
      if (!value.kolfb) {
        this.fakedata.kolfb = ''
      }
      if (!value.kolig) {
        this.fakedata.kolig = ''
      }
      if (!value.kolutube) {
        this.fakedata.kolutube = ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'commonUserInfo'
    ])
  },
  methods: {
    // 上傳照片
    async onChange () {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'updataImg',
          img: this.$refs.updata.files
        })
        await this.$store.dispatch({
          type: 'getUserData'
        })
        this.$message.success('上傳成功！')
      } catch (err) {
        this.$message.error('上傳失敗！')
      } finally {
        this.$emit('loading', false)
      }
    },
    // 儲存
    async saveInfo () {
      if (this.fix) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'saveUserData',
            email: this.fakedata.email,
            lastname: this.fakedata.name,
            group_id: this.$store.state.loginInfo.userIdentity,
            user_id: this.fakedata.account,
            mobile: this.fakedata.phone,
            user_nickname: this.fakedata.fakename,
            kol_fb: this.fakedata.kolfb,
            kol_ig: this.fakedata.kolig,
            kol_utube: this.fakedata.kolutube,
            customerId: Number(this.$route.params.userId)
          })
          this.fix = false
          this.$store.dispatch({
            type: 'getUserData'
          })
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        } catch (err) {
          this.message2 = err.response.data.message
          this.$store.dispatch({
            type: 'getUserData'
          })
        } finally {
          this.$emit('loading', false)
        }
      } else {
        this.fix = !this.fix
      }
    }
  },
  async created () {
    this.fakedata = this.commonUserInfo
    console.log(this.fakedata)
    this.$emit('loading', true)
    try {
      await this.$store.dispatch({
        type: 'getUserData'
      })
    } finally {
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .innerroot {
    display: grid;
    grid-template-columns: 1fr 270px;
    grid-template-rows: 1fr;
  }

  .inner {
    width: 320px;
  }

  .top {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
  }

  .titletext {
    display: flex;
    align-items: center;
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    padding-left: 12px;
    box-sizing: border-box;
    border-left: 8px solid #00D77F;
  }

  .input {
    width: 334px;
    height: 46px;
    border: none;
    border-bottom: 2px solid #F2F2F2;
    font-size: 24px;
    letter-spacing: 0.003em;
    color: #000000;
    padding-left: 8px;
    box-sizing: border-box;
  }

  .button {
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    width: 244px;
    height: 50px;
    background: #FF6100;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadpic {
    width: 270px;
    height: 270px;
    font-size: 24px;
    border: 1px solid #7e7e7e;
    object-fit: contain;
  }

  .upload {
    width: 45px;
    height: auto;
    margin-bottom: 13px;
  }

  .size {
    font-size: 13px;
    color: #C4C4C4;
    padding: 12px 0 19px 0;
  }

  .upbutton {
    width: 70%;
    height: 30px;
    border: 2px solid #00D77F;
    color: #00D77F;
    box-sizing: border-box;
    border-radius: 999rem;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .maininner {
    box-sizing: border-box;
    padding-top: 38px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 29px 1fr;
    width: 100%;
    height: auto;
    grid-auto-rows: 25px;
    grid-column-gap: 40px;
    grid-row-gap: 36px;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  }

  .inputstyle {
    border: none;
    border-bottom: 1px solid #C4C4C4;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;

    &[disabled] {
      border-bottom: 1px solid #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .inputout {
    border-bottom: 1px solid #C4C4C4;
    display: grid;
    grid-template-columns: 1fr 35px;
    align-items: center;

    &.nolinecss {
      border-bottom: 1px solid #fff;
    }
  }

  .inputout2 {
    border-bottom: 1px solid #C4C4C4;
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
  }

  .inputpassword {
    border-bottom: 1px solid #C4C4C4;
  }

  .urlout {
    padding-top: 25px;
    display: grid;
    grid-template-columns: 29px 1fr;
    grid-auto-rows: 35px;
    grid-column-gap: 40px;
    grid-row-gap: 16px;
  }

  .fixinputstyle {
    border: none;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px
  }

  .fix {
    cursor: pointer;
    justify-self: flex-end;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
    text-decoration-line: underline;
  }

  .icon {
    cursor: pointer;
    width: 30px;
    height: auto;
    color: #828282;
    font-size: 20px;
  }

  .iconout {
    display: flex;
    justify-content: flex-end;
  }

  .imgfb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-image: url(../../../assets/img/productpage_icon_facebook.png);
  }

  .imgu2b {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-image: url(../../../assets/img/footer_icon_youtube.png);
  }

  .imgig {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-image: url(../../../assets/img/productpage_icon_ig.png);
  }

  .imgblog {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/blog.png);
  }

  .out {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .fix2 {
    width: 200px;
  }

  .fixinputstyle:disabled, .inputstyle:disabled {
    background: none;
  }

  .fixbtn {
    border: 1px solid #ff6100;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    margin-top: 300px;
  }

  .save {
    cursor: pointer;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff6100;
    border: 1px solid rgba(0, 0, 0, 0);
    margin-top: 300px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .innerroot {
    width: 720px;
    margin: 0 auto;
  }

  .inner {
    padding: 15px;
    box-sizing: border-box;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 31px;
    border-bottom: 4px solid #F7F7F7;
  }

  .titletext {
    display: flex;
    align-items: center;
    height: 20.4px;
    font-weight: 400;
    font-size: 16px;
    padding-left: 12px;
    margin-top: 12px;
    border-left: 6px solid #00D77F;
  }

  .input {
    width: 334px;
    height: 46px;
    border: none;
    border-bottom: 2px solid #F2F2F2;
    font-size: 24px;
    letter-spacing: 0.003em;
    color: #000000;
    padding-left: 8px;
    box-sizing: border-box;
  }

  .button {
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    width: 244px;
    height: 50px;
    background: #FF6100;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadpic {
    width: 232px;
    height: 232px;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    border: 1px solid #7e7e7e;
    object-fit: contain;
  }

  .upload {
    width: 45px;
    height: auto;
    margin-bottom: 13px;
  }

  .size {
    font-size: 14px;
    line-height: 20px;
    color: #C4C4C4;;
    padding: 12px 0 19px 0;
  }

  .upbutton {
    width: 250px;
    height: 30px;
    border: 2px solid #00D77F;
    color: #00D77F;
    box-sizing: border-box;
    border-radius: 999rem;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .maininner {
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 28px;
    display: grid;
    grid-template-columns: 60px 1fr;
    width: 100%;
    height: auto;
    grid-auto-rows: 25px;
    grid-column-gap: 22px;
    grid-row-gap: 27px;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  }

  .inputstyle {
    border: none;
    border-bottom: 1px solid #C4C4C4;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;

    &[disabled] {
      border-bottom: 1px solid #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .inputout {
    border-bottom: 1px solid #C4C4C4;
    display: grid;
    grid-template-columns: 1fr 35px;
    align-items: center;

    &.nolinecss {
      border-bottom: 1px solid #fff;
    }
  }

  .inputout2 {
    border-bottom: 1px solid #C4C4C4;
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
  }

  .inputpassword {
    border-bottom: 1px solid #C4C4C4;
  }

  .urlout {
    padding-top: 25px;
    display: grid;
    grid-template-columns: 33px 1fr;
    grid-auto-rows: 35px;
    grid-column-gap: 25px;
    grid-row-gap: 16px;
  }

  .fixinputstyle {
    border: none;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px
  }

  .fix {
    justify-self: flex-end;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
    text-decoration-line: underline;
  }

  .icon {
    width: 30px;
    height: auto;
    color: #828282;
    font-size: 20px;
  }

  .iconout {
    display: flex;
    justify-content: flex-end;
  }

  .imgfb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/productpage_icon_facebook.png);
  }

  .imgu2b {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/footer_icon_youtube.png);
  }

  .imgig {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/productpage_icon_ig.png);
  }

  .imgblog {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/blog.png);
  }

  .out {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .fix2 {
    width: 200px;
  }

  .fixinputstyle:disabled, .inputstyle:disabled {
    background: none;
  }

  .fixbtn {
    border: 1px solid #ff6100;
    color: #ff6100;
    width: 250px;
    height: 40px;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .save {
    width: 250px;
    height: 40px;
    border-radius: 999rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff6100;
    border: 1px solid rgba(0, 0, 0, 0);
  }
}

@media (max-width: 767px) {
  .inner {
    margin-top: 40px;
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 31px;
    border-bottom: 4px solid #F7F7F7;
  }

  .titletext {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 12px;
    margin-top: 12px;
    border-left: 6px solid #00D77F;
  }

  .input {
    width: 334px;
    height: 46px;
    border: none;
    border-bottom: 2px solid #F2F2F2;
    font-size: 24px;
    letter-spacing: 0.003em;
    color: #000000;
    padding-left: 8px;
    box-sizing: border-box;
  }

  .button {
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    width: 244px;
    height: 50px;
    background: #FF6100;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadpic {
    width: 232px;
    height: 232px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    font-size: 24px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    border: 1px solid #7e7e7e;
    object-fit: contain;
  }

  .upload {
    width: 45px;
    height: auto;
    margin-bottom: 13px;
  }

  .size {
    font-size: 14px;
    line-height: 20px;
  color: #C4C4C4;
    padding: 12px 0 19px 0;
  }

  .upbutton {
    width: 70%;
    height: 30px;
    border: 2px solid #00D77F;
    color: #00D77F;
    box-sizing: border-box;
    border-radius: 999rem;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .maininner {
    box-sizing: border-box;
    padding-top: 30px;
    display: grid;
    grid-template-columns: 60px 1fr;
    width: 100%;
    height: auto;
    grid-auto-rows: 25px;
    grid-column-gap: 22px;
    grid-row-gap: 27px;
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  }

  .inputstyle {
    border: none;
    border-bottom: 1px solid #C4C4C4;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;

    &[disabled] {
      border-bottom: 1px solid #fff;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .inputout {
    border-bottom: 1px solid #C4C4C4;
    display: grid;
    grid-template-columns: 1fr 35px;
    align-items: center;

    &.nolinecss {
      border-bottom: 1px solid #fff;
    }
  }

  .inputout2 {
    border-bottom: 1px solid #C4C4C4;
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
  }

  .inputpassword {
    border-bottom: 1px solid #C4C4C4;
  }

  .urlout {
    padding-top: 25px;
    display: grid;
    grid-template-columns: 33px 1fr;
    grid-auto-rows: 35px;
    grid-column-gap: 25px;
    grid-row-gap: 16px;
  }

  .fixinputstyle {
    border: none;
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px
  }

  .fix {
    justify-self: flex-end;
    font-size: 14px;
    line-height: 20px;
    color: #828282;
    text-decoration-line: underline;
  }

  .icon {
    width: 30px;
    height: auto;
    color: #828282;
    font-size: 20px;
  }

  .iconout {
    display: flex;
    justify-content: flex-end;
  }

  .imgfb {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/productpage_icon_facebook.png);
  }

  .imgu2b {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/footer_icon_youtube.png);
  }

  .imgig {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/productpage_icon_ig.png);
  }

  .imgblog {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url(../../../assets/img/blog.png);
  }

  .out {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 38px;
    padding-bottom: 38px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .fix2 {
    width: 200px;
  }

  .fixinputstyle:disabled, .inputstyle:disabled {
    background: none;
  }

  .fixbtn {
    border: 1px solid #ff6100;
    color: #ff6100;
    width: 250px;
    height: 40px;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .save {
    width: 60%;
    height: 40px;
    border-radius: 999rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff6100;
    border: 1px solid rgba(0, 0, 0, 0);
  }
}
</style>
