import { requestApi } from './request'

const method = 'post'

export async function groupApiFunciton (action, token, bodyObj) {
  const { data } = await requestApi[method]('/groupbuyApi.php', {
    action: action,
    ...bodyObj && { ...bodyObj }
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

export async function getGroupListBySKU (token, bodyObj) {
  const data = await groupApiFunciton('getGroupbuyAvailableBySKU', token, bodyObj)
  return data
}

export async function createGroupbuy (token, bodyObj) {
  await groupApiFunciton('createGroupbuy', token, bodyObj)
}

export async function joinGroupbuy (token, bodyObj) {
  await groupApiFunciton('joinGroupbuy', token, bodyObj)
}
