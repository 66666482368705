<template>
  <div :class="$style.root">
    <userinfo @loading="$emit('loading', $event)"/>
    <div :class="$style.rootinner" v-show="Number(ordersList.total) > 0">
      <listtag :class="$style.listtag" v-if="webwatch"/>
      <div :class="$style.listout">
        <listbtn
          :class="$style.listbtn"
          @openFn="open"
          :orderlistData="ordersList.list"
          @loading="$emit('loading', $event)"
        />
      </div>
      <returnpage
        :listData="data"
        v-if="openCom === 'returnpage'"
        @close="openCom = null"
        @loading="loading = $event"
      />
      <assess
        :listData="data"
        v-if="openCom === 'assess'"
        @success="msg = '評價成功!'"
        @close="openCom = null"
        @loading="loading = $event"
      />
      <cancel
        :data="data"
        v-if="openCom === 'cancel'"
        @close="openCom = null"
        @loading="loading = $event"
        @success="msg = '已取消訂單！'"
      />
      <progresspage
        :data="data"
        v-if="openCom === 'progresspage'"
        @close="openCom = null"
      />
      <userpop
        v-if="msg"
        @close="msg = false"
        :msg="msg"
      />
      <loadingCom v-if="loading"/>
    </div>
    <div
      :class="['noany', 'noanythingout']"
      v-show="ordersList.total <= 0"
    >
      <img
        src="../../assets/img/orderlist.svg"
        :class="'noanyimg'"
      />
      目前沒有任何訂單唷！
    </div>
    <page
      v-show="ordersList.total > 0"
      :pageNumber="ordersList.total"
      :pageSize="8"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import listbtn from '@/components/user/listbtn.vue'
import listtag from '@/components/user/listtag.vue'
import userinfo from '@/components/user/info.vue'
import returnpage from '@/components/user/listpage/return.vue'
import assess from '@/components/user/listpage/assess.vue'
import cancel from '@/components/user/listpage/cancel.vue'
import progresspage from '@/components/user/listpage/progress.vue'
import userpop from '@/components/outpu/userpop.vue'
import page from '@/components/page.vue'
import loadingCom from '@/components/outpu/loading2.vue'
import { mapGetters } from 'vuex'
import { pageFunction, overflowFunction } from '@/js'
import '@/css/noany.scss'
export default {
  components: {
    returnpage,
    assess,
    cancel,
    progresspage,
    userpop,
    page,
    userinfo,
    listtag,
    listbtn,
    loadingCom
  },
  data () {
    return {
      data: null,
      watch: false,
      msg: false,
      openCom: null
    }
  },
  watch: {
    openCom () {
      overflowFunction(this.openCom)
    }
  },
  computed: {
    ...mapGetters([
      'ordersList'
    ])
  },
  methods: {
    async pageFn (current) {
      this.$emit('loading', true)
      try {
        await pageFunction('getOrdersList', undefined, current, this.$route.params.userId)
      } catch {
        this.$message('載入失敗，請重新載入！')
        this.$emit('loading', false)
      }
    },
    open (value, data) {
      this.openCom = value
      this.data = data
    }
  },
  destroyed () {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .page {
    margin-top: 40px;
  }

  .listout {
    min-height: 560px;
  }

  .listtag {
    width: 100%;
    height: 64px;
    border-top: 2px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
    margin-top: 45px;
  }

  .listbtn {
    width: 100%;
  }
}

@media (max-width: 1365px) {
  .listout {
    min-height: calc(100vh - 510px);
  }

  .listtag {
    width: 100%;
    height: 64px;
    border-top: 2px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
  }
}

@media (max-width: 767px) {
  .listout {
    min-height: calc(100vh - 280px);
  }

  .img {
    width: 60%;
    height: auto;
  }
}
</style>
