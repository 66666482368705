<template>
  <div :class="$style.root">
    <div :class="[$style.all, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']">
      <div :class="$style.top">
        <p :class="$style.texttop">邀請好友一起拼團</p>
        <i
          :class="['el-icon-close', $style.close]"
          @click="$emit('close')"
        />
      </div>
      <div :class="$style.area1">
        <div
          :class="$style.pic"
          :style="{ backgroundImage: `url(${
            groupListLink.product_image
              ? imgUrl + groupListLink.product_image
              : require('../../assets/img/cvimall_comingsoon_small.png')
          })` }"
        />
        <div :class="$style.right">
          <div :class="$style.name">{{ groupListLink.product_name }}</div>
          <div :class="$style.price">
            團購價：＄ {{ Math.floor(groupListLink.product_new_price) }}
          </div>
        </div>
      </div>
      <div :class="$style.area2" v-if="groupListLink.status === 1">
        <div :class="$style.text">
          於
          <span :class="$style.textcolor">
            {{ time ? time.day + '日' : '' }}
            {{ time ? time.hours + '時' : '' }}
            {{ time ? time.minutes + '分' : '' }}
            {{ time ? time.seconds + '秒' : '' }}
            {{ time ? '' : '已結束' }}
          </span>
          內，再邀請
          <span :class="$style.textcolor">
            {{ groupSuccessNumber - groupListLink.buy_count }}
          </span>
          人加入您的拼團，即可成團!
        </div>
        <div :class="$style.text2">分享連結：</div>
        <div :class="$style.link">
          <div :class="$style.address">
            {{ linkCopy }}
          </div>
          <img
            ref="copyButton"
            :data-clipboard-text="linkCopy"
            :class="[$style.copy, 'clickCss']"
            src="../../assets/img/linkcopy.png"
          >
        </div>
        <div :class="$style.shareto">
          <div :class="$style.text2">點擊分享給好友：</div>
          <img
            @click="share"
            :class="[$style.iconsize, 'clickCss']"
            src="../../assets/img/fblink.png"
          >
          <img
            @click="sharemessage"
            :class="[$style.iconsize, 'clickCss']"
            src="../../assets/img/messagelink.png"
          >
          <img
            @click="sharemessage"
            :class="$style.iconsize"
            src="../../assets/img/sharelink.png"
            v-if="!webwatch"
          >
        </div>
      </div>
      <div v-else :class="$style.area2">
        <div :class="$style.text">
          <p>您尚未付款喔～請盡快付款讓大家知道您的開團喔！</p>
          若24小時內尚未付款，訂單會自動取消，若您不小心下錯單，可至訂單列表內取消訂單唷！
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reciprocal from '@/js/reciprocal.js'
import Clipboard from 'clipboard'
export default {
  mixins: [
    reciprocal
  ],
  props: {
    groupListLink: Object,
    groupSuccessNumber: Number
  },
  computed: {
    linkCopy () {
      return `${window.location.href + '?openId=' + this.groupListLink.entity_id}`
    }
  },
  methods: {
    initClipboard () {
      const clipboard = new Clipboard(this.$refs.copyButton)
      clipboard.on('success', (e) => {
        this.$message.success('複製成功！')
      })
    },
    share () {
      window.FB.ui({
        method: 'share',
        href: this.linkCopy
      }, function (response) {})
    },
    sharemessage () {
      window.FB.ui({
        method: 'send',
        link: this.linkCopy
      })
    },
    phoneshare () {
      if (navigator.share) {
        navigator.share({
          title: `${'快來和朋友一起拼團吧！' + '-cvimall-' + this.groupListLink.owner_name}`,
          text: `${'最獨特的新型態電商平台，我們以「策略合作」為核心價值，創造多贏」為經營目標，帶給消費者「全新的購物體驗」-cvimall-' +
            this.groupListLink.owner_name}`,
          url: this.linkCopy
        })
      } else {
        this.$message.error('目前裝置不支援唷！請使用其他分享方式分享給你的好友吧！')
      }
    }
  },
  created () {
    this.fakeTime = this.groupListLink.end_dt
  },
  mounted () {
    this.initClipboard()
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 4;
  display: flex;
  overflow-y: auto;
}

.close {
  font-size: 25px;
  font-weight: 900;
  color: #FF6100;
  cursor: pointer;
}

.all {
  margin: auto;
  background: #fff;
  width: 80%;
  max-width: 800px;
  padding: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.top {
  display: grid;
  grid-template-columns: 1fr 30px;
  font-size: 18px;
  height: 50px;
  border-bottom: 2px solid #ececec;
  align-items: center;
  padding-bottom: 10px;
}

.texttop {
  padding-left: 30px;
  box-sizing: border-box;
  justify-self: center;
}

.area1 {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 32px 0;
  border-bottom: 1px solid #000000;
  margin-bottom: 20px;
}

.pic {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom: 1px solid #ececec;
}

.right {
  flex: 1;
}

.name {
  font-size: 16px;
}

.price {
  font-weight: 500;
  font-size: 14px;
  color: #FF6100;
  margin-top: 10px;
}

.area2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
}

.textcolor {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #FF6100;
}

.text2 {
  font-size: 16px;
  text-align: center;
}

.link {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding-bottom: 10px;
  word-wrap: break-word;
  word-break: break-all;
}

.address {
  font-size: 16px;
  color: #828282;
  padding-bottom: 2px;
  border-bottom: 1px solid #C4C4C4;
}

.copy {
  width: 31px;
  height: 31px;
  margin-left: 10px;
  cursor: pointer;
}

.shareto {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.icon {
  width: 35px;
  height: 35px;
  border-radius: 999px;
  border: 1px solid #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}

.iconsize {
  width: 40px;
  height: 40px;
  margin: 0 10px;
}

.cancel {
  width: 100%;
  height: 35px;
  border: 1px solid #FF6100;
  border-radius: 35px;
  font-weight: 500;
  font-size: 14px;
  color: #FF6100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;

  &.yes {
    color: #FFFFFF;
    background: #FF6100;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .top {
    font-size: 15px;
    height: 30px;
  }

  .area1 {
    padding: 20px 0;
  }

  .pic {
    width: 56px;
    height: 56px;
  }

  .name {
    font-size: 14px;
  }

  .text {
    font-size: 14px;
  }

  .link {
    margin-top: 0;

  }

  .text2 {
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }

  .shareto {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}
</style>
