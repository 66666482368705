import { request } from './request'

export async function getProduct (url, adminToken, typeValue, pageSize, currentPage) {
  const { data } = await request.get(url, {
    headers: {
      Authorization: `Bearer ${adminToken}`
    },
    params: {
      'searchCriteria[filterGroups][0][filters][0][field]': 'type_id',
      'searchCriteria[filterGroups][0][filters][0][value]': 'configurable',
      'searchCriteria[filterGroups][1][filters][0][field]': 'sell_type',
      'searchCriteria[filterGroups][1][filters][0][value]': typeValue,
      'searchCriteria[filterGroups][2][filters][0][field]': 'status',
      'searchCriteria[filterGroups][2][filters][0][value]': 1,
      'searchCriteria[pageSize]': pageSize,
      'searchCriteria[currentPage]': currentPage || 1
    }
  })
  return data
}

export function productListData (productName) {
  if (productName) {
    const total = productName.total_count
    const list = productName.items.filter(ele => ele.status === 1)
      .map(ele => {
        const newName = ele.name
        const image = ele.media_gallery_entries[0]
          ? ele.media_gallery_entries[0].file
          : 'NaN'
        const oldPrize = ele.custom_attributes.find(ele2 => ele2.attribute_code === 'originalprice').value
        const newPrize = ele.custom_attributes.find(ele => ele.attribute_code === 'newprice')
          ? ele.custom_attributes.find(ele => ele.attribute_code === 'newprice').value
          : oldPrize
        const sku = ele.sku
        const id = ele.id
        const oldPrizeNum = Math.floor(Number(oldPrize))
        const newPrizeNum = Math.floor(Number(newPrize))
        return {
          newName,
          image,
          oldPrizeNum,
          newPrizeNum,
          sku,
          id
        }
      })
    return {
      total,
      list
    }
  } else {
    return { total: 0, list: [] }
  }
}
