<template>
  <div :class="$style.root">
    <div class="listoutside">
      <p class="listtitle">數量</p>
      <div :class="$style.size">
        <div :class="$style.btnsize" @click="lessFn">
          <i class="el-icon-minus"/>
        </div>
        <div :class="$style.numbersize">
          {{ number }}
        </div>
        <div :class="$style.btnsize" @click="addFn">
          <i class="el-icon-plus"/>
        </div>
      </div>
      <p :class="$style.buy">
        剩餘 {{ !surplus && surplus !== 0
          ? 'NaN'
          : surplus - number >= 0
            ? surplus - number
            : 0
          }} 件
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    surplus: null
  },
  data () {
    return {
      number: 1
    }
  },
  watch: {
    number () {
      this.$emit('number', this.number)
    }
  },
  methods: {
    lessFn () {
      if (this.number !== 1) {
        this.number -= 1
      }
    },
    addFn () {
      if (this.surplus && this.surplus - this.number !== 0) {
        this.number += 1
      }
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .size {
    width: 117px;
    height: 33px;
    display: grid;
    grid-template-columns: 31px 51px 31px;
  }

  .btnsize {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
    cursor: pointer;
  }

  .numbersize {
    font-weight: normal;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .buy {
    padding-left: 40px;
    font-size: 14px;
    color: #828282;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .size {
    width: 117px;
    height: 33px;
    display: grid;
    grid-template-columns: 31px 51px 31px;
  }

  .btnsize {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
    cursor: pointer;
  }

  .numbersize {
    font-weight: normal;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .buy {
    padding-left: 40px;
    font-size: 14px;
    color: #828282;
  }
}

@media (max-width: 767px) {
  .size {
    flex: 1;
    height: 25px;
    display: grid;
    grid-template-columns: 25px 40px 25px;
  }

  .btnsize {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
  }

  .numbersize {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .buy {
    font-size: 13px;
    color: #828282;
  }
}
</style>
