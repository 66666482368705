<template>
  <div :class="$style.root">
    <div :class="$style.innerroot">
      <div
        :class="[$style.bg,
          'animate__animated',
          'animate__faster',
          'animate__fadeInDownBig']"
      >
        <div :class="$style.top">
          <div :class="$style.out">
            <p :class="$style.title">選擇現金優惠</p>
          </div>
          <div :class="$style.toptext">請勾選及填入欲使用的現金</div>
        </div>
        <div :class="$style.main">
          <div :class="$style.inner">
            <div :class="$style.innertitle">您的現金點數：</div>
            <div :class="$style.point">
              {{ redPoint }}
            </div>
            <div :class="$style.innertitle">點</div>
          </div>
        </div>
        <div :class="$style.main2" v-if="redPoint">
          <div :class="$style.raido" @click="check = 0">
            <input
              type="radio"
              v-model="check"
              value="0"
              :class="$style.radio"
            />
            <p :class="$style.text">
              使用目前全部現金點數
            </p>
          </div>
          <div :class="$style.raido" @click="check = 1">
            <input
              type="radio"
              v-model="check"
              value="1"
              :class="$style.radio"
            />
            <p :class="$style.text">
              使用部分現金點數
            </p>
          </div>
          <div :class="$style.pointinput" v-if="Number(check) === 1">
            <p>選擇現金：</p>
            <input
              v-model="inputRedPoint"
              :class="$style.innerstyle"
              placeholder="請輸入欲使用點數"
              type="number"
              :max="redPoint"
            />
            <p>點</p>
          </div>
          <div :class="$style.raido" @click="check = 2">
            <input
              type="radio"
              v-model="check"
              value="2"
              :class="$style.radio"
            />
            <p :class="$style.text">
              本次不使用現金
            </p>
          </div>
        </div>
        <div
          v-else
          :class="['noanything', 'noanythingplug']"
        >
          <img
            :class="'noanythingimg'"
            src="../../assets/img/redpoint.svg"
          />
          目前沒有現金點數唷！
        </div>
        <div :class="$style.out">
        <div
          :class="[$style.no, 'clickCss']"
          @click="$emit('close')"
        >
          取消
        </div>
        <div
          :class="[$style.no, $style.yes, 'clickCss']"
          @click="inputRedpoint"
        >
          確定
          <i class="el-icon-loading" v-if="loading"/>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    redPoint: Number
  },
  data () {
    return {
      check: null,
      inputRedPoint: 0
    }
  },
  watch: {
    check () {
      if (Number(this.check) === 0) {
        this.inputRedPoint = this.redPoint
      } else if (Number(this.check) === 1) {
        this.inputRedPoint = 0
      } else {
        this.inputRedPoint = 0
      }
    },
    inputRedPoint () {
      if (this.inputRedPoint > this.redPoint) this.inputRedPoint = this.redPoint
    }
  },
  computed: {
    ...mapGetters([
      'redpointApi'
    ])
  },
  methods: {
    inputRedpoint () {
      this.$emit('inputRedpoint', this.inputRedPoint)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" module>
.main {
  border: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.radio {
  width: 20px;
  height: 20px;
}

@media (min-width: 1366px) {
  .innerroot {
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .bg {
    margin: auto;
    padding: 17px 17px 13px 19px;
    box-sizing: border-box;
    width: 500px;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    padding-top: 3px;
    font-size: 14px;
    color: #828282;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 14px;
    font-weight: 400;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    cursor: pointer;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .main {
    border-bottom: 2px solid #C4C4C4;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .inner {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .point {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #ff6100;
  }

  .caption {
    font-size: 12px;
    color: #828282;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 17px;
    cursor: pointer;
  }

  .pointinput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-bottom: 17px;
    width: 300px;
    font-size: 14px;
  }

  .main2 {
    padding: 12px 0;
    box-sizing: border-box;
    min-height: 280px;
  }

  .text {
    font-size: 14px;
    padding-left: 12px;
  }

  .innerstyle {
    border: 1px solid #C4C4C4;
    height: 35px;
    padding: 0 10px;
    border-radius: 0;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .innerroot {
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .bg {
    margin: auto;
    padding: 17px 17px 13px 19px;
    box-sizing: border-box;
    width: 500px;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    padding-top: 3px;
    font-size: 12px;
    color: #828282;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 13px;
    font-weight: 400;
    width: 47%;
    height: 31px;
    border-radius: 999rem;
    border: solid 1px #ff6100;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .main {
    border-bottom: 2px solid #C4C4C4;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .inner {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .point {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #ff6100;
  }

  .caption {
    font-size: 12px;
    color: #828282;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 17px;
  }

  .pointinput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-bottom: 17px;
    width: 270px;
  }

  .main2 {
    padding: 12px 0;
    box-sizing: border-box;
    min-height: 280px;
  }

  .text {
    font-size: 13px;
    padding-left: 12px;
  }

  .innerstyle {
    border: 1px solid #C4C4C4;
    height: 20px;
    padding: 0 10px;
  }
}

@media (max-width: 767px) {
  .innerroot {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .bg {
    margin: auto;
    padding: 17px 17px 13px 19px;
    box-sizing: border-box;
    width: 90%;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    padding-top: 3px;
    font-size: 12px;
    color: #828282;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 13px;
    font-weight: 400;
    width: 47%;
    height: 31px;
    border-radius: 999rem;
    border: solid 1px #ff6100;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .main {
    border-bottom: 2px solid #C4C4C4;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .inner {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .point {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #ff6100;
  }

  .caption {
    font-size: 12px;
    color: #828282;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 17px;
  }

  .pointinput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-bottom: 17px;
  }

  .main2 {
    padding: 12px 0;
    box-sizing: border-box;
    min-height: 280px;
  }

  .text {
    font-size: 13px;
    padding-left: 12px;
  }

  .innerstyle {
    border: 1px solid #C4C4C4;
    height: 20px;
    padding: 0 10px;
  }
}
</style>
