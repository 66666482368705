import { customJsonFunction } from '@/request'
import loginInfoJs from '../../api/login.js'

const userJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    pickCoupon: null,
    inputRedpoint: null,
    csvData: null,
    LastTimeData: null,
    LastTimebilling: null
  },
  mutations: {
    pickCoupon (state, payload) {
      state.pickCoupon = payload.pickCoupon
    },
    inputRedpoint (state, payload) {
      state.inputRedpoint = payload.inputRedpoint
    },
    csvData (state, payload) {
      state.csvData = payload.csvData
    },
    LastTimeData (state, payload) {
      state.LastTimeData = payload.LastTimeData
    },
    LastTimebilling (state, payload) {
      state.LastTimebilling = payload.LastTimebilling
    }
  },
  actions: {
    // getCustomJson addCustomJson updateCustomJson deleteCustomJson
    // key => pickCoupon inputRedpoint
    async customJsonFn ({ commit }, { action, key, value }) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await customJsonFunction(action, key, value, token)
      const typeObj = {
        pickCoupon: 'pickCoupon',
        inputRedpoint: 'inputRedpoint',
        LastTimeData: 'LastTimeData',
        LastTimebilling: 'LastTimebilling'
      }[key]
      if (typeObj && action === 'getCustomJson') {
        commit({ type: typeObj, [typeObj]: data })
      } else if (action === 'getCustomJson') {
        commit({ type: 'csvData', csvData: data })
      }
    }
  },
  getters: {
    pickCoupon: (state) => {
      return state.pickCoupon
        ? Number(JSON.parse(state.pickCoupon.json_text))
        : -1
    },
    inputRedpoint: (state) => {
      if (state.inputRedpoint) {
        return state.inputRedpoint.json_text
          ? Number(JSON.parse(state.inputRedpoint.json_text))
          : false
      } else {
        return 0
      }
    },
    csvData: (state) => {
      if (state.csvData) {
        return state.csvData.json_text
          ? JSON.parse(state.csvData.json_text)
          : false
      } else {
        return false
      }
    },
    LastTimeData: (state) => {
      if (state.LastTimeData) {
        return state.LastTimeData.json_text
          ? JSON.parse(state.LastTimeData.json_text)
          : false
      } else {
        return false
      }
    },
    LastTimebilling: (state) => {
      if (state.LastTimebilling) {
        return state.LastTimebilling.json_text
          ? JSON.parse(state.LastTimebilling.json_text)
          : false
      } else {
        return false
      }
    }
  }
}

export default {
  userJs
}
