<template>
  <div :class="$style.root">
    <userinfo/>
    <div :class="$style.rootinner">
      <sharing/>
      <div :class="$style.top">
        <div :class="$style.titleout">
          <p :class="$style.title">我的銷售夥伴（人)</p>
          <div :class="$style.people">
            {{ shareList.total }} 人
          </div>
        </div>
        <div :class="$style.smalltext">
          最後更新時間：{{ shareList.update_time ? shareList.update_time : '尚未更新' }}
        </div>
        <div :class="$style.smalltext" v-if="shareList.myparent">
          我的上線：{{ shareList.myparent.lastname }}
        </div>
        </div>
      <div :class="$style.listtitle" v-if="webwatch">
        <div>帳號</div>
        <div :class="$style.text">團隊人數</div>
        <div :class="$style.text">貢獻金額</div>
      </div>
      <div :class="$style.list">
        <div
          :class="$style.listinner"
          v-for="(item, i) in shareList.members"
          :key="i"
        >
          <div :class="$style.imgout">
            <img
              :src="item.customer_image ? userPhoto + item.customer_image : require('../../assets/img/noimg.png')"
              :class="$style.img"
            />
            <div :class="$style.line1" v-if="webwatch">
              {{ item.lastname }}
            </div>
          </div>
          <div :class="$style.right">
            <div :class="$style.line1" v-if="!webwatch">
              {{ item.lastname }}
            </div>
            <div :class="$style.text">
              {{ webwatch ? item.member_total : '團隊人數：' + item.member_total }}
            </div>
            <div :class="$style.text">
              {{ webwatch ? item.reward_total : '貢獻金額：' + item.reward_total }}
            </div>
          </div>
        </div>
        <div
          :class="$style.nolist"
          v-if="shareList.total <= 0"
        >
          <img
            src="../../assets/img/deal.svg"
            :class="$style.img2"
          />
          目前沒有任何銷售夥伴唷！
        </div>
      </div>
    </div>
    <page
      :pageNumber="Number(shareList.total)"
      :pageSize="6"
      @Number="pageFn($event)"
      v-show="Number(shareList.total) > 0"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import userinfo from '@/components/user/info.vue'
import sharing from '@/components/user/sharing.vue'
import { mapGetters } from 'vuex'
import { pageFunction } from '@/js'
export default {
  components: {
    userinfo,
    sharing,
    page
  },
  computed: {
    ...mapGetters([
      'shareList'
    ])
  },
  methods: {
    async pageFn (current) {
      this.$emit('loading', true)
      try {
        await pageFunction('getShareList', 'getProfitChild', current, this.$route.params.userId)
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" module>
.nolist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 20px;
  min-height: 200px;
  color: #ccc;
}

.img2 {
  opacity: .7;
  width: 130px;
  height: 150px;
}

.no {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #828282;
  border-bottom: 1px solid #C4C4C4;
  width: 100%;
  height: 100%;
}

.top {
  padding-top: 40px;
  padding-bottom: 60px;
}

.titleout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding: 9px 36px;
  border-radius: 999rem;
  background-color: #ffc000;
  font-size: 16px;
  color: #ffffff;
}

.people {
  font-weight: 500;
  font-size: 32px;
}

.right {
  display: contents;
}

.smalltext {
  font-size: 13px;
  color: #828282;
  text-align: right;
}

.listtitle {
  font-size: 14px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 421px 1fr 1fr;
  grid-template-rows: 64px;
  border-top: 2px solid #C4C4C4;
  border-bottom: 2px solid #C4C4C4;
  align-items: center;
}

.list {
  height: 200px;
  min-height: 200px;
}

.listinner {
  border-bottom: 1px solid #C4C4C4;
  font-size: 14px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 421px 1fr 1fr;
  grid-template-rows: 64px;
  align-items: center;
}

.imgout {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.text {
  text-align: center;
  font-size: 14px;
  color: #000000;
}

.img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
  background-color: #c4c4c4;
  border-radius: 999rem;
  margin-right: 16px;
}

@media (max-width: 1365px) {
  .right {
    display: block;
  }

  .titleout, .imgout {
    display: contents;
  }

  .top {
    padding: 0;
    margin-top: 13px;
  }

  .people {
    text-align: right;
    font-size: 22px;
    font-weight: 400;
    color: #000000;
  }

  .smalltext {
    text-align: right;
    align-self: center;
    font-size: 12px;
  }

  .outout {
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 24px;
    font-size: 14px;
  }

  .text {
    text-align: left;
  }

  .line1 {
    display: flex;
    align-items: center;
  }

  .line1 {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    align-items: center;
  }

  .list {
    margin-top: 20px;
    border-top: 1px solid #ccc;
  }

  .listinner {
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
  }

  .img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 35px;
    height: 35px;
    background-color: #c4c4c4;
  }

  .right {
    align-items: center;
    padding-left: 10px;
    padding: 8px 0;
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    font-size: 14px;
    color: #000;
  }

  .nolist {
    font-size: 14px;
  }

  .img2 {
    opacity: .7;
    width: 100px;
    height: 100px;
  }
}
</style>
