<template>
  <div :class="$style.root">
    <user :class="$style.user"/>
    <router-view :class="$style.main" @loading="$emit('loading', $event)"/>
  </div>
</template>

<script>
import user from '@/components/kol/user.vue'
export default {
  components: {
    user
  }
}
</script>

<style lang="scss" module>
.root {
  margin-top: 78px;
  padding-bottom: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}

.user {
  width: 202px;
  height: auto;
  margin-right: 78px;
}

.main {
  width: 780px;
}

@media (max-width: 1365px) {
  .root {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    border-bottom: 0;
    padding-top: 91px;
  }

  .user {
    z-index: 1;
    position: fixed;
    top: 80px;
    width: 100%;
    height: 39px;
  }

  .main {
    width: 100%;
    min-height: 100%;
    padding: 15px;
    margin-top: 50px;
    box-sizing: border-box;
  }
}
</style>
