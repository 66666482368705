<template>
  <div :class="$style.root">
    <swiper :class="$style.outside" :options="swiperOption" ref="mySwiper">
      <swiper-slide
        v-for="(item, i) in productImageList"
        :key="i" :class="$style.imgout"
      >
        <div
          :class="$style.img2"
          :style="{ backgroundImage: `url(${ imgUrl + item.img})` }"
        >
          <div :class="$style.text" v-if="!offShelf">
            已下架
          </div>
        </div>
      </swiper-slide>
      <div :class="['swiper-pagination', $style.pos]" slot="pagination"></div>
      <div
        :class="['swiper-button-prev', $style.bga]"
        slot="button-prev"
      />
      <div
        :class="['swiper-button-next', $style.bga]"
        slot="button-next"
      />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    productImageList: Array,
    isStock: Number,
    offShelf: Boolean
  },
  data () {
    return {
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          type: 'fraction',
          el: '.swiper-pagination'
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
.outside {
  width: 100%;
  height: 100%;
  z-index: unset !important;

  :global(.swiper-wrapper) {
    z-index: -1 !important;
  }
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  font-size: 20px;
  padding: 10px 20px;
  background: #828282;
  border-radius: 999rem;
  color: #fff;
}

.imgout {
  width: 100%;
  height: 100%;
  z-index: unset !important;
}

.img2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sell {
  font-size: 18px;
  background: #828282;
  color: #fff;
  border-radius: 999rem;
  width: 80px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.pos {
  &:global(.swiper-pagination-fraction) {
    padding: 4px 7px;
    color: #fff;
    bottom: 20px;
    background: #828282;
    border-radius: 999rem;
    left: unset;
    width: unset;
    right: 20px;
  }
}

.bga {
  &:global(.swiper-button-next) {
    color: #898989;
    z-index: 1 !important;
  }

  &:global(.swiper-button-prev) {
    color: #898989;
    z-index: 1 !important;
  }
}
</style>
