import { requestApi } from '@/request/request'
import loginInfoJs from '../../api/login.js'

const shareJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    shareList: null,
    addShare: null
  },
  mutations: {
    shareList (state, payload) {
      state.shareList = payload.shareList
    },
    addShare (state, payload) {
      state.addShare = payload.addShare
    }
  },
  actions: {
    async getShareList (store, payload) {
      const url = '/profitApi.php'
      const response = await requestApi.post(url, {
        action: payload.action,
        pageSize: 6,
        currentPage: payload.currentPage
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'shareList', shareList: response.data })
    },
    async addShare (store, payload) {
      const url = '/profitApi.php'
      const response = await requestApi.post(url, {
        action: 'addProfitChild',
        parent: payload.parent // customer id
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'addShare', addShare: response.data })
    }
  },
  getters: {
    shareList: (state) => {
      if (state.shareList) {
        return state.shareList
      } else {
        return { members: [], myparent: undefined, total: 0, update_time: '尚未開始' }
      }
    }
  }
}

export default {
  shareJs
}
