import { request, requestApi } from '@/request/request'
import { webApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'

const ordersJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    ordersList: null,
    orderData: null,
    returnMethods: null,
    returnData: null
  },
  mutations: {
    ordersList (state, payload) {
      state.ordersList = payload.ordersList
    },
    orderData (state, payload) {
      state.orderData = payload.orderData.map(ele => ele.data)
    },
    returnMethods (state, payload) {
      state.returnMethods = payload.returnMethods
    },
    returnData (state, payload) {
      state.returnData = payload.returnData
    }
  },
  actions: {
    async getOrdersList (store, payload) {
      const url = '/orders'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'customer_id',
          'searchCriteria[filterGroups][0][filters][0][value]': payload.userId,
          'searchCriteria[sortOrders][0][field]': 'entity_id',
          'searchCriteria[sortOrders][0][direction]': 'desc',
          'searchCriteria[pageSize]': 8,
          'searchCriteria[currentPage]': payload.currentPage
        }
      })
      store.commit({ type: 'ordersList', ordersList: response.data })
    },
    async getOrderData (store, payload) {
      const data = await Promise.all(
        payload.orderId.map(ele => {
          const promise = requestApi.post('/webapi.php', {
            action: 'getOrderData',
            orderId: ele
          }, {
            headers: {
              Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
            }
          })
          return promise
        })
      )
      store.commit({ type: 'orderData', orderData: data })
    },
    async returnMethods (store, payload) {
      const url = '/webapi.php'
      const response = await requestApi.post(url, {
        action: 'addRma',
        orderId: payload.orderId,
        message: payload.message,
        orderItem: [{
          orderItemId: '261',
          qty: '1',
          resolutionId: 2,
          resolution: '退款a',
          conditionId: 2,
          condition: '未開封a',
          reasonId: 1,
          reason: '尺寸錯誤a'
        }]
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'returnMethods', returnMethods: response.data })
    },
    async returnData (store, payload) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await webApiFunciton('getRmaRecord', token, payload.bodyObj)
      store.commit({ type: 'returnData', returnData: data })
    }
  },
  getters: {
    ordersList: (state) => {
      if (state.ordersList) {
        const total = state.ordersList.total_count
        const list = state.ordersList.items.map(ele => {
          const orderId = ele.entity_id
          return { orderId }
        })
        return {
          total,
          list
        }
      } else {
        return []
      }
    },
    orderData: (state) => {
      if (state.orderData) {
        const array = state.orderData.map(ele => {
          console.log(ele)
          const note = ele.customer_note
          const products = ele.products
          const time = ele.created_at
          const group = ele.groupbuy_id || undefined
          const paymentInfo = ele.payment_info
            ? JSON.parse(ele.payment_info)
            : undefined
          const oldMoney = Math.floor(ele.subtotal)
          const sendMoney = Math.floor(ele.shipping_amount)
          const orderId = ele.entity_id
          const discount = Math.floor(ele.discount_amount)
          const redPoint = ele.mp_reward_spent
            ? Math.floor(ele.mp_reward_spent)
            : 0 // 這次花多少紅利
          const getredPoint = ele.mp_reward_earn
            ? Math.floor(ele.mp_reward_earn)
            : 0 // 這次得到多少紅利
          const total = Math.floor(ele.grand_total)
          const shipmentInfo = ele.shipment_info
            ? JSON.parse(ele.shipment_info)
            : undefined
          var sendMethods = ''
          console.log(paymentInfo)
          if (ele.shipment_type === 'home') {
            sendMethods = '宅配'
          } else {
            // 超商取貨
            switch (ele.shipment_sub_type) {
              case ('UNIMART'):
                sendMethods = '7-11取貨不付款'
                break
              case ('HILIFE'):
                sendMethods = '萊爾富取貨不付款'
                break
              default:
                sendMethods = '全家取貨不付款'
                break
            }
          }
          if (ele.payment_type === 'cod') {
            switch (ele.shipment_sub_type) {
              case ('UNIMART'):
                sendMethods = '7-11取貨付款'
                break
              case ('HILIFE'):
                sendMethods = '萊爾富取貨付款'
                break
              default:
                sendMethods = '全家取貨付款'
                break
            }
            paymentInfo.PaymentType = '取貨付款'
            if (ele.payment_stage === 'paid') {
              paymentInfo.RtnMsg = '已付款'
              paymentInfo.RtnCode = 1
            } else {
              paymentInfo.RtnMsg = '未付款'
            }
          }
          const sendAddress = ele.shipping_street
          const sendName = ele.shipping_firstname
          const sendPhone = ele.shipping_telephone
          const statusValue = ele.status
          const rtndata = ele.ram_status_log
          let statetext
          switch (ele.status) {
            case 'processing':
              statetext = '處理中'
              break
            case 'pending_payment':
              Number(paymentInfo.RtnCode) === 10100073 || Number(paymentInfo.RtnCode) === 2
                ? statetext = '取號成功'
                : statetext = '等待付款'
              break
            case 'payment_review':
              Number(paymentInfo.RtnCode) === 1
                ? statetext = '確認付款'
                : statetext = '付款失敗'
              break
            case 'pending':
              statetext = '訂單成立'
              break
            case 'holded':
              statetext = 'On Hold'
              break
            case 'STATE_OPEN':
              statetext = 'Open'
              break
            case 'complete':
              statetext = '訂單完成'
              break
            case 'Closed':
              statetext = '訂單結束'
              break
            case 'canceled':
              statetext = '取消訂單'
              break
            case 'returning':
              statetext = '退貨中'
              break
            case 'refunded':
              statetext = '退貨完成'
              break
          }
          return {
            time,
            paymentInfo,
            products,
            oldMoney,
            sendMoney,
            orderId,
            discount,
            redPoint,
            getredPoint,
            total,
            statetext,
            shipmentInfo,
            sendMethods,
            statusValue,
            sendAddress,
            sendName,
            sendPhone,
            note,
            group,
            rtndata
          }
        })
        return array
      } else {
        return undefined
      }
    },
    returnMethods: (state) => {
      if (state.returnMethods) {
        return state.returnMethods
      } else {
        return []
      }
    },
    returnData: (state) => {
      return state.returnData
        ? state.returnData.rmaMessage[1].text.split('/')
        : ['未有資訊', '未有資訊', '未有資訊', '未有資訊', '未有資訊']
    }
  }
}

export default {
  ordersJs
}
