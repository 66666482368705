<template>
  <div :class="$style.root">
    <div :class="$style.topout">
      <top :class="$style.top" @navFnOut="navwatchFn" :tagNav="tagNav"/>
      <searchlist
        @searchListOpen="searchListOpenFn($event)"
        :class="$style.search"
        v-if="!webwatch && $route.meta.search"
        ref="searchlist"
      />
    </div>
    <searchView
      @closeKeyboard="refFunction('closeKeyboard')"
      @searchText="refFunction('searchText', $event)"
      v-if="!webwatch && searchListOpen"
      @close="refFunction('close')"
      :class="!webwatch ? $style.main : ''"
    />
    <router-view
      v-show="webwatch || !searchListOpen"
      :class="$style.main"
      @loading="loadingFn($event)"
      :style="{ paddingTop: webwatch ? '130px' : !webwatch && $route.meta.search ? '94px' : '52px' }"
    />
    <img
      v-if="online && online === 'common' && imgPluin"
      src="../assets/img/qrcode.png"
      :class="$style.qrcode"
      @click="pluin = !pluin"
    />
    <qrcode
      :class="$style.qrcodebox"
      v-if="online && online === 'common' && pluin === true"
      @close="pluin = false"
      @rule="rule"
    />
    <bottom :class="$style.bottom"/>
    <menulist :class="$style.menusize" @navFnOut="navwatchFn" v-if="tagNav" ref="menu"/>
    <loadingPage v-if="loading"/>
  </div>
</template>

<script>
import searchlist from '@/components/search/searchlist.vue'
import top from '@/components/top.vue'
import menulist from '@/components/menu.vue'
import bottom from '@/components/bottom.vue'
import qrcode from '@/components/outpu/qrcode.vue'
import loadingPage from '@/components/outpu/loading.vue'
import searchView from '@/components/search/searchview.vue'
export default {
  components: {
    top,
    menulist,
    bottom,
    searchlist,
    loadingPage,
    qrcode,
    searchView
  },
  data () {
    return {
      tagNav: false,
      pluin: false,
      imgPluin: true,
      searchListOpen: false
    }
  },
  watch: {
    async $route (to, from, next) {
      this.searchListOpen = false
      if (this.pluin) {
        this.pluin = false
      }
      if (to.meta.pluin && !this.imgPluin) {
        this.imgPluin = true
        window.FB.CustomerChat.show(false)
      } else if (!to.meta.pluin && this.imgPluin) {
        this.imgPluin = false
        window.FB.CustomerChat.hide()
      }
    }
  },
  methods: {
    searchListOpenFn (value) {
      this.searchListOpen = value
    },
    rule () {
      this.$router.push({
        name: 'split',
        params: { current: 1, userId: this.$store.state.loginInfo.userData.id }
      })
    },
    refFunction (value, eventText) {
      if (value === 'closeKeyboard') {
        this.$refs.searchlist.closeKeyboardFn()
      } else if (value === 'searchText') {
        this.$refs.searchlist.searchText(eventText)
      } else if (value === 'close') {
        this.$refs.searchlist.closeFn()
      }
    },
    navwatchFn (value) {
      this.tagNav = value
    },
    loadingFn (value) {
      this.loading = value
    }
  },
  mounted () {
    if (this.$route.meta.pluin && !this.imgPluin) {
      this.imgPluin = true
      window.FB.CustomerChat.show(false)
    } else if (!this.$route.meta.pluin && this.imgPluin) {
      this.imgPluin = false
      window.FB.CustomerChat.hide()
    }
  }
}
</script>

<style lang="scss" module>
.root {
  display: flex;
  flex-direction: column;
}

.topout {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 1;
}

.top {
  box-sizing: border-box;
  width: 100%;
  height: 150px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
}

.main {
  padding-top: 130px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.bottom {
  border-top: 14px solid #f7f8f9;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 320px;
}

.qrcode {
  width: 64px;
  position: fixed;
  bottom: 95px;
  right: 23px;
  -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.35));
  cursor: pointer;
}

.qrcodebox {
  z-index: 3;
  position: fixed;
  bottom: 177px;
  right: 35px;
}

@media (max-width: 1365px) {
  .top {
    display: flex;
    flex-direction: column;
    height: 80px;
    box-shadow: unset;
  }

  .search {
    height: 42px;
  }

  .main {
    padding-top: 94px;
    width: 100%;
  }

  .bottom {
    border-top: 7px solid #f7f8f9;
    height: 200px;
  }

  .menusize {
    z-index: 1000;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);
  }

  .qrcode {
    width: 66px;
    position: fixed;
    bottom: 96px;
    right: 22px;
    -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.35));
  }

  .qrcodebox {
    z-index: 3;
    position: fixed;
    bottom: 182px;
    right: 35px;
  }
}

@media (max-width: 767px) {
  .top {
    height: 100px;
  }

  .menusize {
    height: calc(100% - 100px);
  }

  .qrcode {
    width: 66px;
    position: fixed;
    bottom: 93px;
    right: 22px;
    -webkit-filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.35));
  }

  .qrcodebox {
    z-index: 3;
    position: fixed;
    bottom: 172px;
    right: 25px;
  }

  .bottom {
    height: 100%;
  }
}

@media (max-width: 320px){
  .top {
    height: 105px;
  }

  .menusize {
    height: calc(100% - 105px);
  }

  .qrcodebox {
    z-index: 3;
    position: fixed;
    margin: 0;
    right: unset;
    bottom: unset;
  }
}
</style>
