import router from '@/router'
import ElementUI from 'element-ui'

export function toUserDataPage (userLink, userId) {
  router.push({
    name: userLink,
    params: { userId: userId }
  })
}

export function toUserShippingPage (userLink, userId, carId) {
  router.push({
    name: userLink,
    params: { userId: userId, carId: carId }
  })
}

export function toUserGropeShipPage (userLink, userId, carId, type, openId, groupId) {
  router.push({
    name: userLink,
    params: { userId: userId, carId: carId, type: type },
    query: { openId: openId, groupId: groupId }
  })
}

export function changeUserDataPage (userLink, userId) {
  router.push({
    name: userLink,
    params: { userId: userId, current: 1 }
  })
}

export function userAction (online, action, userId, carId) {
  switch (online) {
    case 'employee':
      action === 'userData'
        ? toUserDataPage('employeeuserlayout', userId)
        : toUserShippingPage('employeeshoplist', userId, carId)
      break
    case 'kol':
      toUserDataPage('koluserlayout', userId)
      break
    case 'common':
      action === 'userData'
        ? toUserDataPage('user', userId)
        : toUserShippingPage('shoppinglistto', userId, carId)
      break
    case false:
      ElementUI.Notification.warning({
        title: '尚未登入',
        message: '尚未登入，請進行登入！'
      })
      break
  }
}
