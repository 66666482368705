<template>
  <div :class="$style.root">
    <div :class="$style.innerout">
      <div :class="$style.line" v-for="(item, i) in orderData" :key="i">
        <div :class="$style.list">
          <div :class="$style.outout" v-if="webwatch">
            <div :class="$style.webtitle">
              <p :class="$style.text">
                {{ item.orderId }}
              </p>
              <p :class="$style.text">{{ item.time }}</p>
            </div>
            <div :class="[$style.text, $style.tatol]">{{ item.total }}</div>
            <p :class="[$style.text, $style.status]">{{ item.statetext }}</p>
          </div>
          <div :class="[$style.text, $style.btnout]">
            <div :class="$style.btn">
              <div
                v-if="method[i].method === 'callphone'"
              >
                請通知客服！
              </div>
              <div
                v-if="method[i].method === 'nomethod'"
              >
                -
              </div>
              <div
                @click="openFn('cancel', i)"
                :class="$style.btntext"
                v-if="method[i].method === 'cancel'"
              >
                取消訂單
              </div>
              <div
                @click="openFn('returnpage', i)"
                :class="$style.btntext"
                v-if="method[i].method === 'complete' && online !== 'employee'"
              >
                退貨
              </div>
              <div
                @click="openFn('assess', i)"
                :class="$style.btntext"
                v-if="method[i].method === 'complete' && online !== 'employee'"
              >
                評價
              </div>
              <div
                @click="openFn('progresspage', i)"
                :class="$style.btntext"
                v-if="method[i].method === 'return' && online !== 'employee'"
              >
                退貨進度
              </div>
            </div>
            <div
              v-if="webwatch"
              @click="listwatch(i)"
              :class="active === i && watch
                ? [$style.btnwatch,$style.btnwatchactive]
                : $style.btnwatch"
            >
              <span>查看</span>
            </div>
          </div>
          <div :class="$style.line" v-if="!webwatch">
            <p :class="[$style.text, $style.topline]">{{ item.orderId }}</p>
            <p :class="$style.text">{{ item.time }}</p>
            <p :class="$style.text">{{ item.total }}</p>
            <p :class="[$style.text, $style.btmline]">{{ item.statetext }}</p>
          </div>
          <div
            v-if="!webwatch"
            @click="listwatch(i)"
            :class="[$style.btnwatch,
              {[$style.btnwatchactive]: active === i && watch },
              {[$style.btnend]: i === orderData.length - 1 }
            ]"
          >
            <span>查看</span>
          </div>
        </div>
        <listlist
          :commonUserInfo="commonUserInfo"
          :orderData="orderData[Number(i)]"
          :class="[$style.listlist, 'animate__animated', 'animate__faster', 'animate__fadeIn']"
          v-if="active === i && watch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import listlist from '@/components/user/listlist.vue'
export default {
  props: {
    orderlistData: Array
  },
  components: {
    listlist
  },
  data () {
    return {
      active: null,
      watch: false
    }
  },
  watch: {
    async orderlistData () {
      this.active = null
      try {
        const array = this.orderlistData.map(ele => ele.orderId)
        await this.$store.dispatch({
          type: 'getOrderData',
          orderId: array
        })
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
  },
  computed: {
    ...mapGetters([
      'orderData',
      'commonUserInfo'
    ]),
    method () {
      return this.orderData && this.orderData.map(ele => {
        const state = ele.statusValue || undefined
        let method
        switch (state) {
          case 'complete':
            method = 'complete'
            break
          case 'payment_review':
          case 'canceled':
            method = 'nomethod'
            break
          case 'returning':
          case 'refunded':
            method = 'return'
            break
          case 'pending':
            method = 'cancel'
            break
        }
        return { method }
      })
    }
  },
  methods: {
    openFn (value, number) {
      this.$emit('openFn', value, this.orderData[number])
    },
    async listwatch (value) {
      this.$emit('loading', true)
      try {
        if (this.active === null) {
          this.active = value
          this.watch = true
        } else if (this.active !== value && this.active !== null) {
          this.active = value
        } else if (this.active === value) {
          this.active = null
          this.watch = false
        }
      } finally {
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" module>
.outout {
  height: 100%;
  display: flex;
}

.btntext {
  padding-right: 8px;
  font-size: 14px;
  text-decoration: underline;
  color: #828282;
  cursor: pointer;
}

@media (min-width: 1366px) {
  .innerout {
    width: 100%;
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #C4C4C4;
    min-height: 90px;
  }

  .list {
    padding: 23px 21px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .webtitle {
    width: 280px;
  }

  .btn {
    display: flex;
  }

  .text {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;

    &.status {
      width: 121px;
    }

    &.tatol {
      width: 126px;
    }

    &.btnout {
      width: 218px;
      justify-content: space-between;
    }
  }

  .btnwatch {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 80px;
    height: 28px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    cursor: pointer;

    &.btnwatchactive {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .listlist {
    width: 100%;
  }
}

@media (max-width: 1365px) {
  .btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  .return {
    padding-left: 10px;
    font-size: 13px;
    text-decoration: underline;
    color: #828282;
  }

  .line {
    width: 100%;
  }

  .text {
    padding: 0 6px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 29px;
    font-size: 14px;
    border-bottom: 1px solid #E5E5E5;

    &.topline {
      border-top: 1px solid #E5E5E5;
    }

    &.btmline {
      border-bottom: 2px solid #E5E5E5;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .btnwatch {
    margin: 11px 0 50px 0;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    width: 72px;
    height: 30px;
    border-radius: 999rem;
    border: solid 2px #ff6100;

    &.btnwatchactive {
      color: #fff;
      width: 72px;
      height: 30px;
      border-radius: 25px;
      border: solid 2px #ff6100;
      background-color: #ff6100;
      margin-bottom: 8px;
    }
  }

  .listbtn {
    border-bottom: 0;
  }

  .listlist {
    width: 100%;
  }
}

.btnend {
  margin-bottom: 10px;
}
</style>
