import { mapGetters } from 'vuex'
import { productPageFn, routerHavePage } from '@/js'

const kolJs = {
  data () {
    return {
      time: [false, false, false]
    }
  },
  watch: {
    timeData () {
      this.reciprocal()
    }
  },
  computed: {
    ...mapGetters([
      'kolProdutsList',
      'kolInfopro'
    ]),
    kolList () {
      if (this.kolProdutsList && this.kolInfopro) {
        const url = this.kolProdutsList.list
          .map((ele, i) => {
            const kolInfo = this.kolInfopro[i]
            return {
              kolInfo,
              ...ele
            }
          })
        return url
      } else {
        return []
      }
    },
    timeData () {
      return (this.kolProdutsList.list
        ? this.kolProdutsList.list
        : [])
        .map(ele => new Date(ele.time).getTime())
    }
  },
  methods: {
    reciprocal () {
      setInterval(this.myTimer, 1000)
    },
    clearReciprocal () {
      clearInterval(this.myTimer)
    },
    myTimer () {
      this.time = this.timeData.map(t => {
        const milliseconds = Math.max(t - Date.now(), 0)
        if (milliseconds <= 0) {
          return { day: false, hours: false, minutes: false, seconds: false }
        } else {
          const day = Math.floor(milliseconds / (24 * 60 * 60 * 1000)) || 0
          const hours = Math.floor(milliseconds / (60 * 60 * 1000)) - day * 24 || 0
          const minutes = Math.floor(milliseconds / (60 * 1000)) - (hours * 60) - (day * 24 * 60) || 0
          const seconds = Math.floor(milliseconds / 1000) - (minutes * 60) - (hours * 60 * 60) - (day * 24 * 60 * 60) || 0
          return { day, hours, minutes, seconds }
        }
      })
    },
    kolproduts (data) {
      const product = { tag: 5642, momSku: data.sku, parentId: data.id }
      productPageFn(product)
    },
    kolInfoGo (value) {
      this.$router.push({
        name: 'personal',
        params: { kolId: value, current: 1 }
      })
    },
    goList () {
      routerHavePage('kolList')
    }
  },
  destroyed () {
    this.clearReciprocal()
  }
}

export default kolJs
