<template>
  <div :class="$style.root">
    <div :class="$style.top">
      <div :class="[$style.icon, $style.step1]">1</div>
      <div :class="$style.line"/>
      <div :class="[$style.icon, $style.step2]">2</div>
      <div
        :class="step === 3
        ? $style.line
        : [$style.line, $style.unline]"
      />
      <div
        :class="step === 3
        ? [$style.icon, $style.step3]
        : [$style.icon, $style.step3, $style.unicon]"
      >
        3
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: null
  }
}
</script>

<style lang="scss" module>
.top {
  padding: 50px 0 0 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 40px 187px 40px 187px 40px;
  grid-template-rows: 40px;
}

.icon {
    color: #fff;
    display: flex;
    height: 100%;
    font-weight: 500;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    background-color: #ff6100;

    &.step1 {
      position: relative;

      &::after {
        font-weight: 400;
        color: #000;
        font-size: 16px;
        position: absolute;
        content: '購物結帳';
        top: 60px;
        width: 120px;
        text-align: center;
      }
    }

    &.step2 {
      position: relative;

      &::after {
        font-weight: 400;
        color: #000;
        font-size: 16px;
        position: absolute;
        content: '填寫資料';
        top: 60px;
        width: 120px;
        text-align: center;
      }
    }

    &.step3 {
      position: relative;

      &::after {
        font-weight: 400;
        color: #000;
        font-size: 16px;
        position: absolute;
        content: '訂單成立';
        top: 60px;
        width: 120px;
        text-align: center;
      }
    }

    &.unicon {
      background-color: rgba(130, 130, 130, 0.3);
    }
  }

.line {
    align-items: center;
    display: flex;
    width: 100%;
    height: 5px;
    background: #ff6100;

    &.unline {
      background-color: rgba(130, 130, 130, 0.3);
    }
  }

@media (max-width: 1365px) {
  .top {
    grid-template-columns: 35px 1fr 35px 1fr 35px;
    grid-template-rows: 35px;
    padding: 50px 30px 50px 30px;
    box-sizing: border-box;
  }

  .icon {
    font-size: 14px;

    &.step1 {

      &::after {
        top: 45px;
        font-size: 14px;
        width: 35px;
        left: 0;
      }
    }

    &.step2 {

      &::after {
        top: 45px;
        font-size: 14px;
        width: 35px;
        left: 0;
      }
    }

    &.step3 {

      &::after {
        top: 45px;
        font-size: 14px;
        width: 35px;
        left: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .top {
    padding: 0 20px 50px 20px;
    grid-template-columns: 30px 1fr 30px 1fr 30px;
    grid-template-rows: 30px;
  }
}
</style>
