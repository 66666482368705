import store from '@/store'
import Koluserlayout from '../../views/kol/user/koluserlayout.vue'
import Kolinformation from '../../views/kol/user/kolinformation.vue'
import Kolgroup from '../../views/kol/user/kolgroup.vue'

const iskol = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 4 || id === 6) {
    next()
  } else if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else {
    next({ name: 'home' })
  }
}

export default [{
  path: 'koluser/:userId',
  name: 'koluserlayout',
  component: Koluserlayout,
  redirect: { name: 'kolinformation' },
  beforeEnter: iskol,
  children: [{
    path: 'kolinformation',
    name: 'kolinformation',
    component: Kolinformation
  }, {
    path: 'kolgroup/:current',
    name: 'kolgroup',
    component: Kolgroup
  }]
}]
