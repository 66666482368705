import { request, requestApi } from '@/request/request'
import { loginFunction, getUserToken } from '@/servers'
import path from '../path'

const loginInfoJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    userData: null,
    userToken: null,
    userIdentity: 0,
    ...path
  },
  mutations: {
    userData (state, payload) {
      state.userData = payload.userData
    },
    userToken (state, payload) {
      if (payload.userToken === null) {
        localStorage.removeItem('userToken')
        state.userToken = null
      } else {
        state.userToken = payload.userToken
        localStorage.setItem('userToken', payload.userToken)
      }
    },
    userIdentity (state, payload) {
      state.userIdentity = payload.userIdentity
    }
  },
  actions: {
    async getUserToken (store, payload) {
      const data = await getUserToken(payload)
      store.commit({ type: 'userToken', userToken: data })
    },
    async getUserData (store, payload) {
      console.log('getUserData')
      const data = await loginFunction(store.state.userToken)
      store.commit({ type: 'userData', userData: data })
      store.commit({ type: 'userIdentity', userIdentity: data.gradeNumber })
    },
    async saveUserData (store, payload) {
      const url = `/customers/${payload.customerId}`
      const response = await request.put(url, {
        customer: {
          id: payload.customerId,
          dob: payload.dob,
          email: payload.email, // 必填
          firstname: payload.lastname, // 姓名 // 必填
          lastname: payload.lastname, // 姓名 // 必填
          website_id: 1, // 必填
          gender: payload.gender, // 性別
          group_id: payload.group_id, // 必填
          custom_attributes: [{
            attribute_code: 'user_id', // 帳號
            value: payload.user_id
          }, {
            attribute_code: 'user_mobile', // 電話
            value: payload.mobile
          }, {
            attribute_code: 'user_nickname', // kol必填
            value: payload.user_nickname
          }, {
            attribute_code: 'kol_fb', // kol必填
            value: payload.kol_fb
          }, {
            attribute_code: 'kol_ig', // kol必填
            value: payload.kol_ig
          }, {
            attribute_code: 'kol_utube', // kol必填
            value: payload.kol_utube
          }]
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'userData', userData: response.data })
      store.commit({ type: 'userIdentity', userIdentity: response.data.group_id })
    },
    async updataImg (store, payload) {
      // action = uploadCustomerImage uploadKolImage
      const url = '/webapi.php'
      const data = new FormData()
      data.append('action', 'uploadCustomerImage')
      data.append('customerImage', payload.img[0])
      await requestApi.post(url, data, {
        headers: {
          Authorization: `Bearer ${store.state.userToken}`
        }
      })
    }
  },
  getters: {
    commonUserInfo: (state) => {
      if (state.userData) {
        return state.userData
      } else {
        return {
          gradeNumber: 0,
          birthday: undefined,
          email: undefined,
          account: '未有帳號',
          name: '尚未填寫名字',
          phone: '尚未設定手機',
          sex: 0,
          userimage: require('@/assets/img/noimg.png'),
          addressList: '尚未設定地址',
          // fakename(暱稱) / kolfb / kolig / kolutube (KOl才有)
          fakename: '未填寫暱稱',
          kolfb: '',
          kolig: '',
          kolutube: '',
          id: undefined
        }
      }
    }
  }
}

export default {
  loginInfoJs
}
