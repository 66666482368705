<template>
  <div :class="$style.root">
    <div :class="[$style.list, { [$style.emstyle]: online === 'employee' }]">
      <div
        :class="$style.listinner"
        v-for="(item, i) in listData"
        :key="i"
      >
        <input
          v-if="item.stock[item.info.sku] > 0 &&
            routerName !== 'groupshoplist'
          "
          type="checkbox"
          :class="$style.checkbox"
          v-model="item.checkbox"
        />
        <div v-else></div>
        <div :class="$style.border">
          <div
            :class="$style.img"
            :style="{ backgroundImage: `url(${ imgUrl + item.img})` }"
            @click="productRouter(i)"
          >
            <p
              v-if="item.stock[item.info.sku] <= 0"
              :class="$style.nothgin"
            >
              已售完
            </p>
          </div>
          <div
            :class="[$style.overflowout, 'textlang']"
            @click="productRouter(i)"
          >
            {{ item.description }}
          </div>
          <div :class="$style.tag" v-if="online !== 'employee'">
            {{ item.tagText }}
          </div>
          <div v-else></div>
          <div :class="$style.selectout2">
            <p
              :class="$style.selectitle"
              @click="pick !== i ? pick = i : pick = null"
            >
              規格：
              <i
                class="el-icon-arrow-down"
                :class="$style.down"
              />
            </p>
            <el-tooltip
              class="item"
              effect="dark"
              :content="Boolean(item.info.name.split(item.description + '-')[1])
                ? item.info.name.split(item.description + '-')[1]
                : item.info.name
              "
              placement="bottom-start"
            >
              <p :class="$style.valuestyle">
                {{ Boolean(item.info.name.split(item.description + '-')[1])
                    ? item.info.name.split(item.description + '-')[1]
                    : item.info.name
                }}
              </p>
            </el-tooltip>
            <pick
              @loading="$emit('loading', $event)"
              v-if="Number(i) === pick"
              :childlist="item"
              @close="pick = false"
            />
          </div>
        </div>
        <div :class="$style.textcenter">
          {{ item.info.newPrice }}
          <p style="text-decoration: line-through;">
            {{ item.info.oldPrice }}
          </p>
        </div>
        <div :class="[$style.bottom, $style.textcenter]">
          <div :class="$style.btnn" @click="$emit('lessFn', i)">
            <i class="el-icon-minus"/>
          </div>
          <div :class="$style.numberbtn">
            {{ item.info.number }}
          </div>
          <div :class="$style.btnn" @click="$emit('addFn', i)">
            <i class="el-icon-plus"/>
          </div>
        </div>
        <div :class="$style.textcenter">
          {{ item.info.number * item.info.newPrice }}
        </div>
        <div
          :class="[$style.dele, $style.textcenter]"
          @click="$emit('deleFn', i)"
        >
          刪除
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pick from '@/components/shopping/pick.vue'
import { productPageFn } from '@/js'
export default {
  components: {
    pick
  },
  props: {
    listData: Array
  },
  data () {
    return {
      pick: null
    }
  },
  methods: {
    productRouter (listNumber) {
      const data = this.listData[listNumber]
      const product = { tag: data.tag, momSku: data.momSku, parentId: data.parentId }
      productPageFn(product)
    }
  }
}
</script>

<style lang="scss" module>
.down {
  font-weight: 900;
  cursor: pointer;
}

.selectout2 {
  position: relative;
}

.delloading {
  font-size: 18px;
  font-weight: 900;
  color: #ff6100;
}

.nothgin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}

.list {
  min-height: 573px;
}

.border {
  font-size: 14px;
  font-weight: normal;
  display: grid;
  grid-template-columns: 60px 1fr 50px 100px;
  grid-template-rows: 60px;
  grid-column-gap: 28px;
  align-items: center;
}

.textcenter {
  justify-self: center;
}

.valuestyle {
  color: #828282;
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

.listinner {
  padding: 0 28px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px 570px 1fr 1.5fr 1fr 1fr;
  grid-template-rows: 100px;
  border-bottom: 1px solid #C4C4C4;
  align-items: center;
  font-size: 14px;

  &.listinner:hover {
    background: rgba(255, 192, 0, 0.08);
  }
}

.img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.overflowout {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #aaa;
    transition: 0.3s;
  }
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 24px;
  border-radius: 5px;
  background-color: #ffc000;
  font-size: 13px;
}

.dele {
  cursor: pointer;
  text-decoration: underline;
  color: #000000;
}

.checkbox {
  margin-left: 7px;
  border-radius: 0;
  width: 18px;
  height: 18px;
}

.selectout2 {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  align-items: center;
}

.selectitle {
  width: 100%;
  display: flex;
  align-items: center;
}

.bottom {
  width: 90px;
  height: 25px;
  display: grid;
  grid-template-columns: 25px 40px 25px;
}

.btnn {
  cursor: pointer;
  background: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EEEEEE;
}

.numberbtn {
  background: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.emstyle {
  min-height: unset;
}
</style>
