<template>
  <div class="content">
    <div class="contentinner contentinner__super">
      <h3 class="supertitle">最超值組合價</h3>
      <ul class="superlist">
        <li class="list" @click="selectComboSet(childSkuDataForCombo[0].comboQty)">
          <div class="number">・<span>{{ childSkuDataForCombo[0].comboQty }}入組</span></div>
          <div>每入單價 <span class="perone">${{ childSkuDataForCombo[0].perNewPrice }}</span>（總價<span>${{ childSkuDataForCombo[0].newPrizeNum }}</span>）<span class="original">原${{ childSkuDataForCombo[0].oldPrizeNum }}</span></div>
        </li>
      </ul>
    </div>
    <div class="contentinner">
      <h3>其他組合</h3>
      <ul v-for="(item, i) in forOther"
        :key="i"
        :value="i">
        <li class="list" @click="selectComboSet(item.comboQty)">
          <div class="number">・<span>{{ item.comboQty }}入組</span></div>
          <div>每入單價 <span class="perone">${{ item.perNewPrice }}</span>（總價<span>${{ item.newPrizeNum }}</span>）<span class="original">原${{ item.oldPrizeNum }}</span></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  emits: ['update-childSkuNumber'],
  computed: {
    ...mapGetters([
      // 母sku產品
      'momProductList',
      // skuCombo規格,
      'childSkuDataForCombo'
    ]),
    forOther () {
      const withoutSuper = this.childSkuDataForCombo.filter((_, index) => index !== 0)
      return withoutSuper
    }
  },
  methods: {
    selectComboSet (selectedSet) {
      this.$emit('update-childSkuNumber', selectedSet)
    }
  }
}
</script>

<style lang="scss" scope>

  .content {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    width: 100%;
  }

  .contentinner {
    display: flex;
    flex-direction: column;

    &__super {
      border: 1px solid rgb(191, 191, 191);
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    h3 {
      padding: 0.5rem 1.2rem;
    }
  }

  .supertitle {
    background-color: rgb(205, 205, 205);
  }

  .superlist {
    background-color: #F7F8F9;
  }

  .list {
    display: grid;
    grid-template-columns: 10rem 1fr;
    padding: 0.7rem 0;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  .number {
    margin-left: 2rem;
    margin-right: 4rem;
  }

  .perone {
    color: #ff6100;
    font-weight: bold;
  }

  .original {
    font-size: 10px;
    color: rgb(159, 159, 159);
    text-decoration: line-through;
  }
</style>
