import { request, requestApi } from '@/request/request'
import loginInfoJs from '../../api/login.js'

const infoJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    // 金額等級
    totalLevel: null,
    // 目前消費金額
    orderTotal: null,
    password: null
  },
  mutations: {
    totalLevel (state, payload) {
      state.totalLevel = payload.totalLevel
    },
    orderTotal (state, payload) {
      state.orderTotal = payload.orderTotal
    },
    password (state, payload) {
      state.password = payload.password
    },
    fixEamil (state, payload) {
      state.fixEamil = payload.fixEamil
    }
  },
  actions: {
    async totalLevel (store, payload) {
      const url = '/payment/upgradeLevel.php'
      const response = await requestApi.get(url)
      store.commit({ type: 'totalLevel', totalLevel: response.data })
    },
    async orderTotal (store, payload) {
      const url = '/webapi.php'
      const response = await requestApi.get(url, {
        params: {
          action: 'orderTotal'
        },
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'orderTotal', orderTotal: response.data })
    },
    async checkShipmentCol (store, payload) {
      const url = '/webapi.php'
      await requestApi.post(url, {
        action: 'checkShipmentCol',
        lastname: payload.name,
        telephone: payload.phone,
        email: payload.email,
        postcode: payload.postcode,
        street: payload.street
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async fixPassword (store, payload) {
      const url = '/customers/me/password'
      await request.put(url, {
        currentPassword: payload.oldpassword, // 舊密碼
        newPassword: payload.newpassword // 新密碼
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async fixEamil (store, payload) {
      const url = `/customers/${payload.customerId}`
      const response = await request.put(url, {
        customer: {
          id: payload.customerId,
          email: payload.newemail, // 新 email
          firstname: payload.name,
          lastname: payload.name,
          website_id: 1, // 都是1
          group_id: loginInfoJs.loginInfoJs.state.userIdentity // 會員 ID 代碼
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'fixEamil', fixEamil: response.data })
    },
    async fixphone (store, payload) {
      const url = `/customers/${payload.customerId}`
      await request.put(url, {
        customer: {
          id: payload.customerId,
          email: payload.email,
          firstname: payload.name,
          lastname: payload.name,
          website_id: 1, // 都是1
          group_id: loginInfoJs.loginInfoJs.state.userIdentity, // 會員 ID 代碼
          custom_attributes: [{
            attribute_code: 'user_mobile', // 手機
            value: payload.user_mobile
          }]
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
    },
    async addAddress (store, payload) {
      const url = `/customers/${payload.customerId}`
      await request.put(url, {
        customer: {
          id: payload.customerId,
          addresses: payload.addresses,
          email: payload.email,
          firstname: payload.name,
          lastname: payload.name,
          website_id: 1, // 都是1
          group_id: loginInfoJs.loginInfoJs.state.userIdentity // 會員 ID 代碼
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
    },
    async delAddress (store, payload) {
      const url = `/addresses/${payload.addressId}`
      await request.delete(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
    }
  },
  getters: {
    totalLevel: (state) => {
      if (state.totalLevel) {
        const userIdentity = loginInfoJs.loginInfoJs.state.userIdentity
          ? loginInfoJs.loginInfoJs.state.userIdentity
          : 1
        return state.totalLevel[userIdentity]
      } else {
        return 1
      }
    },
    allTotalLevel: (state) => {
      return state.totalLevel ? state.totalLevel : undefined
    },
    orderTotal: (state) => {
      return state.orderTotal ? state.orderTotal : {}
    }
  }
}

export default {
  infoJs
}
