<template>
  <div :class="$style.root">
    <userinfo/>
    <div :class="$style.rootinner" v-if="messageUser.total > 0">
      <div :class="$style.listout">
        <div :class="$style.message"
          v-for="(item, i) in messageUser.data"
          :key="i"
        >
          <div :class="$style.title">{{ item.subject }}</div>
          <div>{{ item.message }}</div>
          <div :class="$style.time">{{ item.createTime }}</div>
        </div>
      </div>
    </div>
    <div
      :class="['noany', 'noanythingout']"
      v-if="messageUser.total <= 0"
    >
      <img
        src="../../assets/img/mailbox.svg"
        :class="['noanyimg', 'imgmargin']"
      />
      目前沒有任何訊息唷！
    </div>
    <page
      v-show="messageUser.total > 0"
      :pageNumber="messageUser.total"
      :pageSize="8"
      @Number="pageFn($event)"
    />
  </div>
</template>

<script>
import userinfo from '@/components/user/info.vue'
import page from '@/components/page.vue'
import { mapGetters } from 'vuex'
import { pageFunction } from '@/js'
export default {
  components: {
    userinfo,
    page
  },
  computed: {
    ...mapGetters([
      'messageUser'
    ])
  },
  methods: {
    async pageFn (current) {
      this.$emit('loading', true)
      try {
        await pageFunction('getMessage', undefined, current, this.$route.params.userId)
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" module>
.nolist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 20px;
  min-height: 560px;
  color: #ccc;
}

.img {
  opacity: .7;
  width: 130px;
  height: 150px;
}

.rootinner {
  width: 100%;
  min-height: 500px;
}

.listout {
  border-top: 2px solid #C4C4C4;
  min-height: 560px;
  padding-top: 32px;
}

.listout2 {
  font-size: 20px;
  color: #ccc;
  text-align: center;
}

.time {
  color: #aaa;
  font-size: 14px;
}

.message {
  width: 100%;
  min-height: 100px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background: #fff6f0;
  margin-bottom: 32px;
  border-radius: 5px;
  font-size: 16px;
}

.title {
  color: #989898;
  border-bottom: 2px solid #ff6100;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1365px) {
  .rootinner {
    min-height: calc(100vh - 504px);
  }

  .listout {
    min-height: unset;
  }

  .listtag {
    width: 100%;
    height: 64px;
    border-top: 2px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
  }
}

@media (max-width: 767px) {
  .rootinner {
    min-height: calc(100vh - 284px);
  }
}
</style>
