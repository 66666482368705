<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div
        :class="[$style.bg,
          'animate__animated',
          'animate__faster',
          'animate__fadeInDownBig']"
        v-if="fix"
      >
        <div :class="$style.top">
          <p :class="$style.title">更改Email</p>
          <i
            :class="['el-icon-close', $style.title2]"
            @click="$emit('close')"
          />
        </div>
        <div :class="$style.main">
          <div :class="$style.texttitle">
            現在的email
            <span :class="$style.mail">{{ email }}</span>
          </div>
          <input
            type="email"
            v-model="newemail"
            :class="$style.inputstyle"
            placeholder="請輸入更更改email"
          />
          <p :class="$style.errortext" v-if="message">{{ message }}</p>
          <div :class="$style.btn" @click="fixEamil">確定</div>
        </div>
      </div>
      <div :class="$style.bg" v-else>
        <div :class="$style.top">
          <p :class="$style.title">更改Email</p>
          <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
        </div>
        <div :class="$style.main">
          <img src="../../assets/img/fixok.png" :class="$style.imgsize1">
          <div :class="$style.text2">
            已修改成功並傳送確認信至
            <p>{{ newemail }}</p>
          </div>
          <div :class="$style.btn" @click="$emit('close')">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: null,
    name: null
  },
  data () {
    return {
      newemail: '',
      fix: true,
      message: null
    }
  },
  methods: {
    async fixEamil () {
      const reEmail = RegExp('@')
      const isEmail = reEmail.test(this.newemail)
      if (this.newemail && isEmail) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'fixEamil',
            newemail: this.newemail,
            customerId: this.$route.params.userId,
            name: this.name
          })
          await this.$store.dispatch({
            type: 'getUserData'
          })
          this.fix = false
        } catch (err) {
          this.message = 'email已重複，請輸入其他可用Eamil。'
        } finally {
          this.$emit('loading', false)
        }
      } else if (!isEmail) {
        this.message = '請輸入正確的email格式喔！'
      }
    }
  }
}
</script>

<style lang="scss" module>
.errortext {
  color: red;
  margin-bottom: 10px;
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  box-sizing: border-box;
  max-width: 520px;
  width: 80%;
  border-radius: 12px;
  background-color: #ffffff;
}

.top {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #F7F8F9;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.title2 {
  cursor: pointer;
  right: 22px;
  position: absolute;
  color: #FF4B00;
  font-size: 28px;
  font-weight: 900;
}

.right {
  width: 174px;
}

.text {
  padding-top: 4px;
  padding-bottom: 22px;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.texttitle {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 320px;
  font-size: 14px;
  color: #000;
}

.inputstyle {
  font-size: 14px;
  padding: 0 13px;
  box-sizing: border-box;
  height: 40px;
  width: 320px;
  border: 1px solid #CCCCCC;
  border-radius: 1px;
  margin: 16px 0 80px 0;
}

.mail {
  font-size: 14px;
  color: #828282;
}

.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  width: 320px;
  height: 40px;
  background: #FF6100;
  color: #fff;
  border-radius: 999rem;
}

.imgsize1 {
  width: 140px;
  height: auto;
}

.text2 {
  padding: 34px 0 36px 0;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #828282;
}

@media (max-width: 767px) {
  .bg {
    max-width: 300px;
    width: 80%;
  }

  .inputstyle {
    width: 100%;
  }

  .texttitle {
    width: 100%;
    flex-wrap: wrap;
  }

  .btn {
    width: 100%;
  }

  .title {
    font-size: 14px;
  }

  .title2 {
    font-size: 23px;
  }

  .mail {
    margin-top: 10px;
    word-wrap: break-word;
    word-break: break-all;
  }

  .main {
    padding: 30px 50px;
  }
}
</style>
