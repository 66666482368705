import { request } from '@/request/request'

export async function getTrueShopCarId (token) {
  const url = '/carts/mine'
  return (await request.post(url, {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })).data
}
