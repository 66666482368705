<template>
  <div :class="$style.root">
    <div :class="$style.list" v-if="openGroupList.total">
      <div :class="$style.top3">
        {{ openGroupList && openGroupList.total }} 個群組正在等著您
      </div>
      <div
        :class="$style.area"
        v-for="(item, i) in openGroupList && openGroupList.data"
        :key="i"
      >
        <img
          :class="$style.pic"
          :src="item.owner_image
            ? userPhoto + item.owner_image
            : require('../../assets/img/cvimall_comingsoon_small.png')
          "
        />
        <div :class="$style.textarea" v-if="item.status === 1">
          <div :class="$style.title5">{{ item.owner_name }} 正等著您</div>
          <div :class="$style.people">
            差
            <span :class="$style.number">
              {{ Number(groupSuccessNumber) - item.buy_count }}
            </span>
            人成團
          </div>
          <div :class="$style.time">
            剩餘
            {{ time[i] ? time[i].day + '日' : '' }}
            {{ time[i] ? time[i].hours + '時' : '' }}
            {{ time[i] ? time[i].minutes + '分' : '' }}
            {{ time[i] ? time[i].seconds + '秒' : '' }}
            {{ time[i] ? '' : '已結束' }}
          </div>
        </div>
        <div :class="$style.textarea" v-else>
          <div :class="$style.title5">{{ item.owner_name }} 正等著您</div>
          <div :class="$style.people">您尚未付款</div>
          <div>請盡快付款，讓大家看到您的開團唷！</div>
        </div>
        <div
          :class="[$style.join, {[$style.joinbyme]: item.is_groupbuy_owner === 1}]"
          @click="item.is_groupbuy_owner === 0
            ? groupByShopFn('add', i)
            : linkCom = !linkCom"
        >
          {{ item.is_groupbuy_owner === 1
              ? '邀請好友加入'
              : '加入此拼團'
          }}
        </div>
      </div>
    </div>
    <div
      :class="[$style.list, $style.nolist]"
      v-else
    >
      <img
        src="../../assets/img/puzzle.svg"
        :class="$style.img2"
      />
      {{ online
          ? '目前無任何團購可加入，趕快來開團吧！'
          : '登入後可看到哪些拼團可加入唷！'
      }}
    </div>
    <sharelink
      @close="linkCom = !linkCom"
      v-if="linkCom"
      :groupListLink="groupListLink"
      :groupSuccessNumber="Number(groupSuccessNumber)"
    />
    <page
      v-show="Number(openGroupList.total) > 0"
      :pageNumber="Number(openGroupList.total)"
      :pageSize="3"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toUserGropeShipPage, overflowFunction } from '@/js'
import sharelink from '@/components/group/sharelink.vue'
import reciprocal from '@/js/reciprocalArray.js'
import page from '@/components/page.vue'
export default {
  components: {
    sharelink,
    page
  },
  mixins: [
    reciprocal
  ],
  props: {
    groupSuccessNumber: String
  },
  data () {
    return {
      linkCom: false
    }
  },
  watch: {
    linkCom () {
      overflowFunction(this.linkCom)
    },
    async openGroupList () {
      this.$emit('haveOpen', this.haveOpen)
      if (this.online && this.openGroupList.total > 0 && this.openGroupList.data[0].is_groupbuy_owner === 1) {
        await this.$store.dispatch({
          type: 'getGroupFunction',
          action: 'getGroupbuyAvailableById',
          bodyObj: {
            id: this.openGroupList.data[0].entity_id
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'openGroupList',
      'groupListLink'
    ]),
    haveOpen () {
      if (this.openGroupList.total > 0 && this.openGroupList.data[0].is_groupbuy_owner === 1) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async pageFn (value) {
      if (this.online) {
        this.$router.push({
          params: { groupcurrent: value || 1 },
          query: { ...this.$route.query }
        })
        const bodyObj = {
          parent_sku: this.$route.params.skuId,
          pageSize: 3,
          currentPage: value
        }
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'openGroupList',
            bodyObj
          })
        } catch {
          this.$message('載入失敗，請重新載入！')
        } finally {
          this.$emit('loading', false)
        }
      }
    },
    async groupByShopFn (type, i) {
      if (this.online !== 'kol' && this.online) {
        const userId = this.$store.state.loginInfo.userData.id
        const cartId = this.$store.state.shopping.trueShopCarId
        const openId = this.openGroupList.data[i].entity_id
        const groupId = this.openGroupList.data[i].owner_customer_id
        await this.$emit('addShopFn')
        localStorage.setItem('groupLink', this.$route.path)
        toUserGropeShipPage('groupshoplist', userId, cartId, type, openId, groupId)
      } else if (this.online === 'kol' && this.online) {
        this.$notify({
          message: '請登入一般會員或是員工會員進行購買喔！',
          type: 'warning'
        })
      } else {
        this.$notify({
          title: '尚未登入',
          message: '請先登入才能將您的寶貝加入購物車喔',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style lang="scss" module>
.nolist {
  font-size: 18px;
  text-align: center;
  color: #575757;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img2 {
  opacity: .7;
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}

@media (min-width: 1366px) {
  .textarea {
    display: contents;
  }

  .page {
    margin-bottom: 30px;
  }

  .list {
    width: 1100px;
    padding: 40px 0;
  }

  .top3 {
    font-weight: 500;
    font-size: 16px;
    color: #FF6100;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 97, 0, 0.3);
  }

  .area {
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr 1fr;
    grid-column-gap: 12px;
    padding: 20px 0;
    align-items: center;
    border-bottom: 1px solid rgba(255, 97, 0, 0.3);
  }

  .pic {
    width: 50px;
    height: 50px;
    border-radius: 999rem;
    border: 1px solid #e0e0e0;
    object-fit: contain;
  }

  .title5 {
    font-size: 16px;
  }

  .title6 {
    display: flex;
    align-items: center;
  }

  .people {
    justify-self: center;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .number {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #FF6100;
  }

  .time {
    justify-self: center;
    font-size: 14px;
    color: #828282;
  }

  .join {
    cursor: pointer;
    width: 200px;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;

    &.joinbyme {
      background: #fff;
      border: 1px solid #ff6100;
      color: #ff6100;
    }
  }

  .right {
    display: flex;
    align-items: center;

    &.selectout {
      display: grid;
      grid-template-columns: 45% 45%;
      grid-auto-rows: 28px;
      justify-content: space-between;
      grid-row-gap: 8px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000000;
  }

  .area4 {
    width: 1100px;
    padding: 80px 0 40px 0;
  }

  .topbtn {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #FBFBFB;
  }

  .one {
    width: 160px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #828282;
    border-bottom: 2px solid #FBFBFB;
    padding-bottom: 16px;
    cursor: pointer;

    &.two {
      color: #000000;
      border-bottom: 2px solid #00D77F;
    }
  }

  .btnn {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
    cursor: pointer;
  }

  .numberbtn {
    font-weight: normal;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .bottombtn {
    width: 117px;
    height: 33px;
    display: grid;
    grid-template-columns: 31px 51px 31px;
  }

  .commodity {
    font-size: 14px;
  }

  .outout {
    padding-top: 32px;
    display: flex;
  }

  .btn {
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    width: 180px;
    height: 48px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
  }

  .btn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    width: 180px;
    height: 48px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
    }

  .selectstyle {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    height: 100%;
    font-size: 13px;
    appearance: none;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  .iconsizelike {
    width: 30px;
    height: auto;
    margin-right: 12px;
  }

  .comm {
    position: absolute;
    width: 300px;
    top: 35px;
  }

  .comm2 {
    position: absolute;
    margin-top: 10px;
    width: 475px;
    z-index: 1;
  }

  .recommendstyle {
    width: 100%;
    height: 624px;
  }
}

@media (max-width: 1365px) {
  .root {
    margin: 0 auto;
    width: 90%;
    padding: 20px 0 0 0;
    box-sizing: border-box;
    margin-bottom: 50px;
  }

  .area2 {
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .title {
    line-height: 23px;
    font-size: 18px;
    color: #000000;
  }

  .money {
    display: flex;
    justify-content: space-between;
  }

  .moneyinner {
    display: flex;
    font-weight: bold;
    align-items: flex-end;
    padding-top: 10px;
  }

  .money1 {
    color: #ff6100;
    font-size: 23px;
    padding-right: 10px;
  }

  .icon {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .money2 {
    font-size: 17.5px;
    color: #727272;
    text-decoration: line-through;
  }

  .buy {
    text-decoration-line: line-through;
    font-size: 15px;
    align-self: flex-end;
    color: #828282;
  }

  .heart {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #828282;
  }

  .iconinner {
    display: flex;
    flex-direction: row;
  }

  .iconstyle {
    width: 45px;
    height: 45px;
    padding-right: 5px;
  }

  .area3 {
    padding: 0 15px 15px 15px;
    box-sizing: border-box;
  }

  .text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000000;
  }

  .yellow {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border-radius: 999rem;
    height: 20px;
    background-color: #ffc000;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    margin-right: 7px;
  }

  .iconsize {
    width: 17px;
    height: 17px;
    padding-right: 5px;
  }

  .selectstyle {
    padding: 0 10px;
    box-sizing: border-box;
    height: 100%;
    font-size: 13px;
    appearance: none;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  .iconsizelike {
    width: 20px;
    margin-right: 10px;
  }

  .comm {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .comm2 {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .fare {
    padding: 14px 18px 4px 18px;
    box-sizing: border-box;
    width: calc(100% - 45px - 37px);
    border-radius: 0 10px 10px 10px;
    border: solid 2px #ffc000;
    background-color: #ffffff;
  }

  .titlecircle {
    width: 22px;
    height: 22px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .innercircle {
    font-weight: bold;
    font-size: 14px;
    color: #FF6100;
  }

  .righttop {
    display: flex;
    align-items: center;
  }

  .info {
    display: flex;
    align-items: center;
    color: #727272;
    font-size: 14px;
  }

  .right3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .iconstyleinfo {
    margin-left: 5px;
    color: #FF6100;
    font-size: 18px;
  }

  .list {
    width: 100%;
    padding: 0 15px 15px 15px;
    box-sizing: border-box;
  }

  .top3 {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    font-weight: bolder;
    color: #FF6100;
    padding-bottom: 11px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 97, 0, 0.3);
  }

  .area {
    display: grid;
    grid-template-columns: 45px 1fr 130px;
    grid-column-gap: 10px;
    padding-bottom: 8px;
    width: 100%;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 97, 0, 0.3);
  }

  .pic {
    align-self: center;
    width: 40px;
    height: 40px;
    border-radius: 999rem;
    background: #C4C4C4;
    object-fit: contain;
  }

  .line1 {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .title5 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .title6 {
    display: flex;
    align-items: center;
  }

  .people {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .number {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #FF6100;
  }

  .time {
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #828282;
  }

  .join {
    width: 100%;
    height: 40px;
    background: #FF6100;
    border-radius: 36px;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    &.joinbyme {
      background: #fff;
      border: 1px solid #ff6100;
      color: #ff6100;
    }
  }

  .recommendstyle {
    width: 100%;
    height: 260px;
  }

  .img2 {
    width: 100px;
    height: 100px;
  }

  .nolist {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .area {
    display: flex;
    flex-wrap: wrap;
  }

  .join {
    margin-top: 10px;

    &.joinbyme {
      background: #fff;
      border: 1px solid #ff6100;
      color: #ff6100;
    }
  }

  .img2 {
    width: 100px;
    height: 100px;
  }
}
</style>
