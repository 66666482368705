<template>
  <div :class="$style.root">
    <messageSell/>
    <loading v-if="loading"/>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import messageSell from '@/components/outpu/sharesell.vue'
export default {
  components: {
    messageSell
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
.root {
  min-height: calc(100vh - 100px);
}
</style>
