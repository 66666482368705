<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.left"/>
      <div :class="$style.nav">
        <div
          v-for="(item, i) in navtable"
          :key="i"
          @click="navFun(item.link)"
          :class="$style.mar"
        >
          <p
            @click="active = Number(i)"
            :class="[$style.text, { [$style.textactive]: active === Number(i) }]"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div :class="$style.right"/>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      navtable: [{
        name: '個人資料',
        link: 'employeeinformation'
      }, {
        name: '訂單列表',
        link: 'employeeuserlist'
      }]
    }
  },
  methods: {
    navFun (value) {
      if (value !== 'employeeinformation') {
        this.$router.push({
          name: value,
          params: { current: 1 }
        })
      } else {
        this.$router.push({ name: value })
      }
    }
  },
  created () {
    switch (this.$route.name) {
      case 'employeeinformation':
        this.active = 0
        break
      case 'employeeuserlist':
        this.active = 1
        break
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .rootinner {
    position: relative;
    display: flex;
    width: 100%;
  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 75px 0 30px 0;
    box-sizing: border-box;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #B5B5B5;
    height: 34px;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    box-sizing: border-box;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .mar {
    margin-bottom: 41px;
  }

  .textactive {
    border-left: 10px solid #00D77F;
    border-right: 10px solid #00D77F;
    color: #000;
  }

  .left, .right  {
    display: none;
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .left, .right {
    display: none;
  }

  .rootinner {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  .nav {
    -ms-overflow-style: none;
    display: flex;
    justify-content: center;
    border: 0;
    padding: 0;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .text {
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    font-size: 15px;
    color: #000;
    height: 100%;
    position: relative;
    box-sizing: inherit;
    border: 0;
    margin: 0;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
    left: 35px
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .nav::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {
  .rootinner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  .nav {
    -ms-overflow-style: none;
    display: flex;
    border: 0;
    padding: 0;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    justify-content: center;
  }

  .text {
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    font-size: 14px;
    color: #000;
    height: 100%;
    position: relative;
    box-sizing: inherit;
    border: 0;
    margin: 0;
  }

  .left {
    position: absolute;
  }

  .right {
    right: 0;
    position: absolute;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
    left: 38%
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .nav::-webkit-scrollbar {
    display: none;
  }
}
</style>
