<template>
  <div :class="$style.root">
    <div :class="$style.call2">
      <div :class="$style.title" v-if="webwatch">
        {{ title }}
      </div>
      <div :class="$style.navnav">
        <div
          v-for="(item, i) in listTitle"
          :key="i"
          :class="[listTitle.length - 1 !== Number(i) ? $style.text222 : $style.text3,
           { [$style.textactive2]: active2 === Number(i) }]"
          @click="active2 = Number(i)"
        >
          {{ item }}
        </div>
      </div>
      <div
        :class="$style.call"
        v-html="listHtml[active2]"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    title: String
  },
  data () {
    return {
      active2: 0,
      nav2: [{
        name: 'KOL加入'
      }, {
        name: '供應商加入'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'listHtml'
    ]),
    listTitle () {
      const type = this.$route.meta.list
      if (type === 'contentUser') {
        return ['KOL加入', '供應商加入']
      } else if (type === 'aboutMe') {
        return ['現金規則', '付款', '運費', '退換貨']
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .title {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid #C4C4C4;
  }

  .navnav {
    display: flex;
    align-items: center;
    height: 74px;
  }

  .text222 {
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #B5B5B5;
    border-right: 1px solid #B5B5B5;
    height: 17px;
    display: flex;
    align-items: center;
  }

  .text3 {
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #B5B5B5;
    height: 17px;
  }

  .textactive2 {
    color: #00D77F;
  }

  .call {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    min-height: calc(100vh - 193px - 101px);
  }

  .callstyle {
    width: 100%;
    height: 500px;
    background-color: #c4c4c4;
  }

  .navnav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 11px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 44px;
  }

  .text3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #ccc;
    box-sizing: border-box;
    padding: 0 15px;
  }

  .text222 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #ccc;
    box-sizing: border-box;
    padding: 0 15px;
    border-right: 1px solid #000;
  }

  .textactive2 {
    color: #000;
  }

  .call {
    width: 720px;
    padding: 15px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .root {
    min-height: calc(100vh - 52px - 42px);
  }

  .callstyle {
    width: 100%;
    height: 500px;
    background-color: #c4c4c4;
  }

  .navnav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 11px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 44px;
  }

  .text3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #ccc;
    box-sizing: border-box;
    padding: 0 15px;
  }

  .text222 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    font-size: 14px;
    color: #ccc;
    box-sizing: border-box;
    padding: 0 15px;
    border-right: 1px solid #000;
  }

  .textactive2 {
    color: #000;
  }

  .call {
    padding: 15px;
    box-sizing: border-box;
    margin-left: 15px;
  }
}
</style>
