<template>
  <div :class="$style.root" v-if="groupListLink && groupPeopleList">
    <div :class="$style.out">
      <img
        :class="[$style.image, 'imgcss']"
        :src="groupListLink.owner_image
          ? userPhoto + groupListLink.owner_image
          : require('../../assets/img/cvimall_comingsoon_small.png')
        "
      />
      <div :class="$style.text">團主</div>
    </div>
    <div :class="[$style.out, $style.out2, 'textlangone']">
      <div :class="[$style.orange, $style.state]">
        {{ stausData }}
      </div>
      <div
        :class="[$style.orange, $style.time]"
        v-if="this.groupListLink.status === 1"
      >
        {{ time ? time.day + '日' : '' }}
        {{ time ? time.hours + '時' : '' }}
        {{ time ? time.minutes + '分' : '' }}
        {{ time ? time.seconds + '秒' : '' }}
        {{ time ? '' : '已結束' }}
      </div>
      <div v-else-if="this.groupListLink.status === -1">
        尚未付款
      </div>
      <div v-else>倒數已結束</div>
      <div :class="['textlangone', $style.shareout]">
        <div :class="[$style.orange, $style.share, $style.gary, 'textlangone']">
          {{ groupListLink.status === 1 ? url + groupListLink.link : '已結束' }}
        </div>
        <img
          v-if="groupListLink.status === 1"
          ref="copyButton"
          :data-clipboard-text="urlLink"
          :class="$style.linkcopy"
          src="../../assets/img/linkcopy.png"
        />
      </div>
    </div>
    <div :class="$style.people">
      <div :class="[$style.text, $style.text2]">團員</div>
      <div :class="$style.outpeople">
        <div :class="['swiper-button-prev', $style.bg]"/>
        <swiper
          :class="['swiper', $style.outweb]"
          :options="swiperPeople"
          ref="mySwiper"
        >
          <swiper-slide
            v-for="(item, i) in groupPeopleList.data"
            :key="i"
            :class="[$style.outside, 'textlangone']"
          >
            <img
              :class="[$style.image, 'imgcss']"
              :src="item.child_image
                ? userPhoto + item.child_image
                : require('../../assets/img/cvimall_comingsoon_small.png')
              "
            />
            <div :class="[$style.text, $style.text3, 'textlangone']">
              {{ item.child_name }}
            </div>
          </swiper-slide>
        </swiper>
        <div :class="['swiper-button-next', $style.bg]"/>
        <div
          :class="[$style.gary, $style.area]"
          v-if="groupPeopleList.total <= 0"
        >
          尚無團員
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import reciprocalJs from '@/js/reciprocal.js'
import Clipboard from 'clipboard'
import '@/css/image.scss'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  mixins: [
    reciprocalJs
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperPeople: {
        slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  watch: {
    groupListLink () {
      this.fakeTime = this.groupListLink.end_dt
    }
  },
  computed: {
    ...mapGetters([
      'groupListLink',
      'groupPeopleList'
    ]),
    stausData () {
      let text
      switch (this.groupListLink.status) {
        case -1:
          text = '審核中'
          break
        case 0:
          text = '未開團'
          break
        case 1:
          text = '開團中'
          break
        case 2:
          text = '開團失敗'
          break
        case 3:
          text = '開團成功'
          break
      }
      return text
    },
    url () {
      return window.location.origin
    },
    urlLink () {
      return `${window.location.origin +
        this.groupListLink.link +
        '?openId=' +
        String(this.groupListLink.entity_id)}`
    }
  },
  methods: {
    initClipboard () {
      const clipboard = new Clipboard(this.$refs.copyButton)
      clipboard.on('success', (e) => {
        this.$message.success('複製成功！')
      })
    }
  },
  mounted () {
    this.initClipboard()
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  grid-template-rows: 130px;
  grid-column-gap: 30px;
  padding: 30px 0;
  box-sizing: border-box;
}

.outpeople {
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 85px !important;
}

.bg {
  &:global(.swiper-button-next) {
    position: unset;
    padding-top: 20px;
    padding-left: 10px;
    color: #898989;
    z-index: 0;

    &::after {
      font-size: 23px !important;
    }
  }

  &:global(.swiper-button-prev) {
    position: unset;
    padding-top: 20px;
    padding-right: 10px;
    color: #898989;
    z-index: 0;
    &::after {
      font-size: 23px !important;
    }
  }
}

.outweb {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &:global(.swiper-wrapper) {
    justify-content: center;
  }
}

.aa {
  width: 100px;
}

.people {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.innerpeople {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.out {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.out2 {
    align-items: flex-start;
    justify-content: space-between;
  }
}

.shareout {
  width: 100%;
  display: flex;
  align-items: center;
}

.image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 999rem;
}

.text {
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;

  &.text2 {
    margin-top: 0;
  }

  &.text3 {
    width: 80%;
  }
}

.orange {
  color: #FF6100;
  font-size: 14px;

  &.orange::before {
    color: #000;
    margin-right: 12px;
  }

  &.state::before {
    content: '狀態'
  }

  &.time::before {
    content: '剩餘'
  }

  &.share::before {
    content: '分享'
  }
}

.gary {
  color: #828282;
  font-size: 14px;
  width: 100%;

  &.area {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.linkcopy {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .root {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    grid-column-gap: unset;
  }

  .out {
    width: 50px;
    height: 95px;
    margin-right: 20px;
  }

  .out2 {
    width: calc(100% - 90px);
  }

  .people {
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    width: 100%;
  }

  .text2 {
    padding-left: 8px;
  }

  .outpeople {
    width: 100%;
  }
}
</style>
