<template>
  <div :class="$style.root">
    <div :class="$style.totletext" v-if="webwatch">
      <div></div>
      <div></div>
      <div></div>
      <p :class="$style.titlecenter">小計</p>
      <p :class="$style.titlecenter">$ {{ total }}</p>
      <div></div>
      <div></div>
    </div>
    <div :class="$style.inner" v-if="webwatch">
      <div :class="$style.dis">
        <div>活動</div>
        <div>折扣</div>
      </div>
      <div :class="$style.dislist">
        <div>-</div>
        <div>折扣： - ＄ 0</div>
      </div>
      <div :class="$style.dislist2">
        <div></div>
        <div :class="[$style.totle]">總計：$ {{ total }}</div>
      </div>
      <div :class="$style.bottom2">
        <div :class="$style.ex">
          員購每週三23:59截單，週四12:30前至財會繳款，週五取貨。
        </div>
        <div
          @click="$emit('goShopemployee')"
          :class="[$style.btn, 'clickCss']"
        >
          送出訂單
        </div>
      </div>
    </div>
    <div :class="$style.inner" v-else>
      <div :class="$style.totletext">小計：$ {{ total }}</div>
      <div :class="$style.dis">
        <div :class="$style.distext">-</div>
        <div :class="$style.distotle">折扣：$ -</div>
      </div>
      <div :class="$style.totle">總計：$ {{ total }}</div>
      <div :class="$style.bottom2">
        <div
          @click="$emit('goShopemployee')"
          :class="[$style.btn, 'clickCss']"
        >
          送出訂單
        </div>
        <div :class="$style.ex">員購每週三23:59截單，
          <p>週四12:30前至財會繳款，週五取貨</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: Array
  },
  data () {
    return {
    }
  },
  computed: {
    total () {
      if (this.listData) {
        const url = this.listData.filter(ele => ele.checkbox === true)
          .map(ele => ele.info.newPrice * ele.info.number)
          .reduce((start, end) => start + end, 0)
        return url
      } else {
        return 0
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {

  .inner {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .titlelist {
    padding: 0 28px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 60px 570px 1fr 1.5fr 1fr 1fr;
    grid-template-rows: 100px;
    border-bottom: 1px solid #C4C4C4;
    align-items: center;
    font-size: 14px;
  }

  .titlecenter {
    justify-self: center;
  }

  .imgout {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 28px;
    grid-template-rows: 60px;
    align-items: center;
    padding-left: 3px;
  }

  .list {
    width: 1104px;
  }

  .listinner {
    padding-left: 24px;
    display: grid;
    grid-template-columns: 553px 1fr 1fr 1fr 1fr;
    grid-template-rows: 100px;
    font-size: 14px;
    align-items: center;
    box-sizing: border-box;
  }

  .img {
    width: 100%;
    height: 100%;
    background: #C4C4C4;
  }

  .delect {
    color: #828282;
    text-decoration-line: underline;
  }

  .innertext {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text {
    display: grid;
    grid-template-columns: 1fr 35px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    font-size: 14px;
    color: #000000;
  }

  .bottom2 {
    width: 1104px;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }

  .producttext {
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
  }

  .bottom {
    width: 90px;
    height: 25px;
    display: grid;
    grid-template-columns: 25px 40px 25px;
  }

  .btnn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
  }

  .numberbtn {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .money {
    font-size: 14px;
    color: #FF6100;
  }

  .bottominner {
    display: flex;
    justify-content: space-between;
  }

  .totletext {
    padding: 0 28px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 60px 570px 1fr 1.5fr 1fr 1fr;
    grid-template-rows: 100px;
    align-items: center;
    font-size: 14px;
  }

  .dis {
    width: 1104px;
    padding-left: 24px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 867px 1fr;
    grid-template-rows: 54px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    border-bottom: 2px solid #C4C4C4;
    margin-top: 56px;
  }

  .dislist {
    width: 1104px;
    padding-left: 24px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 867px 1fr;
    grid-template-rows: 62px;
    align-items: center;
    font-size: 14px;
    border-bottom: 2px solid #C4C4C4;
  }

  .dislist2 {
    width: 1104px;
    padding-left: 24px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 867px 1fr;
    grid-template-rows: 62px;
    align-items: center;
    font-size: 14px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    font-size: 16px;
  }

  .ex {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #FF6100;
  }

  .totle {
    font-weight: 500;
    font-size: 18px;
    color: #FF6100;
    border-bottom: 0;
  }
}

@media (max-width: 1365px) {
  .inner {
    padding: 15px;
    box-sizing: border-box;
  }

  .list {
    display: grid;
    grid-auto-rows: 90px;
    align-items: center;
  }

  .listinner {
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-template-rows: 56px;
    padding: 17px 0;
    border-bottom: 1px solid #F2F2F2;
    grid-column-gap: 21px;
  }

  .img {
    background: #C4C4C4;
  }

  .delect {
    color: #828282;
    font-size: 13px;
    text-decoration-line: underline;
    text-align: right;
  }

  .innertext {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text {
    display: grid;
    grid-template-columns: 1fr 35px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
    font-size: 14px;
    color: #000000;
  }

  .producttext {
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
  }

  .bottom {
    width: 90px;
    height: 25px;
    display: grid;
    grid-template-columns: 25px 40px 25px;
  }

  .btnn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
  }

  .numberbtn {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .money {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .bottominner {
    display: flex;
    justify-content: space-between;
  }

  .totletext {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    padding: 15px 0;
    border-bottom: 1px solid #EEEEEE;
  }

  .dis {
    padding: 14px 0;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 40px;
    border-bottom: 1px solid #EEEEEE;
  }

  .distotle {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .totle {
    text-align: right;
    font-size: 16px;
    color: #FF6100;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .distext {
    font-size: 14px;
    line-height: 20px
  }

  .bottom2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 31px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
  }

  .ex {
    padding-top: 15px;
    padding-bottom: 25px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #FF6100;
  }
}
</style>
