<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.left"/>
      <div :class="$style.nav">
        <div
          v-for="(item, i) in navtable"
          :key="i"
          @click="navFun(item.link)"
          :class="$style.mar"
        >
          <p
            @click="active = Number(i)"
            :class="[$style.text, { [$style.textactive]: active === Number(i) }]"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div :class="$style.right"/>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      navtable: [{
        name: '個人資料',
        link: 'information'
      }, {
        name: '訂單列表',
        link: 'list'
      }, {
        name: '現金點數',
        link: 'redpoint'
      }, {
        name: '優惠券',
        link: 'ticket'
      }, {
        name: '我的最愛',
        link: 'favorite'
      }, {
        name: '訊息中心',
        link: 'message'
      }]
    }
  },
  methods: {
    navFun (value) {
      if (value !== 'information') {
        this.$router.push({
          name: value,
          params: { current: 1 }
        })
      } else {
        this.$router.push({ name: value })
      }
    }
  },
  created () {
    console.log('user.vue')
    switch (this.$route.name) {
      case 'information':
        this.active = 0
        break
      case 'list':
        this.active = 1
        break
      case 'redpoint':
        this.active = 2
        break
      case 'ticket':
        this.active = 3
        break
      case 'favorite':
        this.active = 4
        break
      case 'split':
        this.active = 5
        break
      case 'message':
        this.active = 6
        break
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .rootinner {
    position: relative;
    display: flex;
    width: 100%;
  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 75px 0 30px 0;
    box-sizing: border-box;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #B5B5B5;
    height: 34px;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    box-sizing: border-box;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .mar {
    margin-bottom: 41px;
  }

  .textactive {
    box-sizing: border-box;
    border-left: 10px solid #00D77F;
    border-right: 10px solid #00D77F;
    color: #000;
  }

  .left, .right  {
    display: none;
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .left, .right {
    display: none;
  }

  .rootinner {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  .nav {
    -ms-overflow-style: none;
    display: flex;
    justify-content: center;
    border: 0;
    padding: 0;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    height: 100%;
    padding: 0 15px;
  }

  .text {
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    font-size: 15px;
    color: #000;
    height: 100%;
    position: relative;
    box-sizing: inherit;
    border: 0;
    margin: 0;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
    left: 35px
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .nav::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {
  .left, .right {
    display: block;
  }

  .rootinner {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 0 25px;
    box-sizing: border-box;
  }

  .nav {
    -ms-overflow-style: none;
    display: flex;
    border: 0;
    padding: 0;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    height: 100%;
  }

  .text {
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    font-size: 14px;
    color: #000;
    height: 100%;
    position: relative;
    box-sizing: inherit;
    border: 0;
    margin: 0;
  }

  .left {
    left: 0;
    position: absolute;
  }

  .right {
    right: 0;
    position: absolute;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
    left: 38%
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }

  .nav::-webkit-scrollbar {
    display: none;
  }

  .left {
    z-index: 1;
    width: 40px;
    height: 100%;
    background: -webkit-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .right {
    z-index: 1;
    width: 40px;
    height: 100%;
    background: -webkit-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: -moz-linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(270deg, #FFFFFF 45.31%, rgba(255, 255, 255, 0) 100%);
    transform: (-1, 0, 0, 1, 0, 0);
  }
}
</style>
