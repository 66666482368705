<template>
  <div :class="$style.root">
    <div :class="$style.innerlist">
      <div :class="$style.imgout">
        <div :class="$style.imgoutbefore" ref="imgoutbefore"/>
        <carousel
          :imgList="imgListinner"
          :class="$style.carousel"
          :style="{ height: `${ carouselHeight }px` }"
          @imageIndex="imageIndex = $event"
        />
        <div :class="$style.imgoutafter" ref="imgoutafter"/>
      </div>
      <div class="hr" v-if="webwatch"/>
      <div class="outside">
        <div class="top">
          <div class="toptitle">
            熱門商品
          </div>
        </div>
        <div class="productmain list-innerlist">
          <div
            v-for="(item, i) in hotList.list"
            :key="i"
            class="productinner"
            @click="getProductFn(item.sku)"
            :class="$style.imgout"
          >
            <img src="../../assets/img/tag.png" :class="$style.img"/>
            <div
              class="productimg"
              :style="{ backgroundImage: `url(${ imgUrl + item.image })` }"
            />
            <div class="producttextout">
              <p class="textlang">{{ item.newName }}</p>
            </div>
            <div class="productoutside">
              <p class="productextdel">$ {{ item.oldPrizeNum }}</p>
              <p class="productextmain">$ {{ item.newPrizeNum }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page
      :class="$style.page"
      v-show="hotList.total > 0"
      :pageNumber="hotList.total"
      :pageSize="8"
      @Number="pageFn($event)"
    />
    <loading v-if="loading"/>
  </div>
</template>

<script>
import carousel from '@/components/carousel/carousel.vue'
import { mapGetters } from 'vuex'
import listimg from '@/js/listimg.js'
import { pageFunction } from '@/js'
import page from '@/components/page.vue'
export default {
  mixins: [
    listimg
  ],
  components: {
    carousel,
    page
  },
  data () {
    return {
      watch: false,
      active: 0,
      imageIndex: 0
    }
  },
  computed: {
    ...mapGetters([
      'imgListinner',
      'hotList'
    ])
  },
  watch: {
    imageIndex (newVal) {
      var beforeVal = newVal - 1
      if (newVal === 0) {
        beforeVal = this.imgListinner.length - 1
      }
      var afterVal = newVal + 1
      if (this.imgListinner.length - 1 === newVal) {
        afterVal = 0
      }
      this.$refs.imgoutbefore.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[beforeVal].img})`
      this.$refs.imgoutafter.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[afterVal].img})`
    }
  },
  methods: {
    async pageFn (current) {
      this.$emit('loading', true)
      try {
        await pageFunction('getHotList', undefined, current, undefined)
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    },
    getProductFn (value) {
      this.$router.push({
        name: 'commonProduct',
        params: { skuId: value, current: 1 }
      })
    }
  },
  async created () {
    await this.$store.dispatch({
      type: 'getBanner',
      banner: 'common'
    })
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .innerlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
  }

  .page {
    margin: 0 auto;
    width: 1110px;
    padding-bottom: 40px;
  }

  .carousel {
    z-index: 0;
    width: 950px;
    height: 450px;
    margin: 0 auto;
  }

  .imgout {
    width: 100%;
    height: 450px;
    position: relative;

    .imgoutbefore {
      position: absolute;
      top: 0;
      left: -475px;
      width: 50%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
    }

    .imgoutafter {
      position: absolute;
      top: 0;
      right: 0px;
      width: 25%;
      height: 100%;
      z-index: -3;
      opacity: 0.2;
    }
  }

  .img {
    position: absolute;
    top: -2px;
    left: 20px;
    width: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .innerlist {
    width: 768px;
    margin: 0 auto;
    margin-bottom: 70px;
  }

  .carousel {
    margin: 28px 0;
    width: 768px;
    height: 384px;
  }

  .page {
    margin: 0 auto;
    width: 768px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .carousel {
    z-index: 0;
    width: 100%;
  }

  .page {
    width: 100%;
    padding: 30px 0;
  }
}
</style>
