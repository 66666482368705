const shopping = {
  methods: {
    // 得到所有子sku
    async getChildSku () {
      await this.$store.dispatch({
        type: 'getChildArr',
        sku: this.stateMomSku.map(ele => ele.momSku)
      })
    },
    // 清除真購物車、拿優惠券、點數
    async apiCatch () {
      this.$emit('loading', true)
      try {
        await this.getChildSku()
        await Promise.all([
          this.$store.dispatch({
            type: 'delTrueShopCarList'
          }),
          this.$store.dispatch({
            type: 'userCouponAction',
            action: 'getMyCoupon'
          }),
          this.$store.dispatch({
            type: 'getPointNumber',
            action: 'getMyPoint'
          }),
          // 抓庫存和能用的優惠券
          this.$store.dispatch({
            type: 'childArrStockAll',
            array: this.stateMomSku.map(ele => ele.parentId)
          }),
          // 能用的優惠券
          this.$store.dispatch({
            type: 'couponBySku',
            action: 'getCouponBySku',
            bodyObj: {
              product: this.stateMomSku.map(ele => ele.momSku)
            }
          })
        ])
      } catch {
        this.$emit.error('購物車載入失敗，請稍後在進行購物。')
        this.$router.push({ name: 'home' })
      } finally {
        this.$emit('loading', false)
      }
    },
    async shopToCart (list, routerLink) {
      if (list.length <= 0) {
        this.$notify({
          title: '未選擇商品至結帳區',
          message: '請選擇您愛的寶貝，才能至結帳區結帳唷！'
        })
        this.loading = false
      } else {
        // 商品呈現樣子 (不用在特別處理api資料)
        sessionStorage.setItem('buyList', JSON.stringify(list))
        try {
          for (let i = 0; i < list.length; i++) {
            await this.$store.dispatch({
              type: 'addTrueShopCar',
              qty: list[i].info.number,
              quote_id: this.$route.params.carId,
              momSku: list[i].momSku,
              option_id: list[i].info.optionId,
              option_value: list[i].info.optionValue
            })
          }
          await this.$store.dispatch({
            type: 'shopPayMoney'
          })
          if (routerLink !== 'groupshoppinginfo') {
            this.$router.push({
              name: routerLink,
              params: {
                userId: this.$route.params.userId,
                carId: this.$store.state.shopping.trueShopCarId
              }
            })
          } else {
            this.$router.push({
              name: routerLink,
              params: {
                userId: this.$route.params.userId,
                carId: this.$store.state.shopping.trueShopCarId
              },
              query: { openId: this.$route.query.openId, groupId: this.$route.query.groupId }
            })
          }
        } catch (err) {
          this.$message.error('購物車載入失敗，請稍後再試。')
        } finally {
          this.loading = false
        }
      }
    },
    async lessFn (value) {
      if (this.listData[value].info.number > 1) {
        this.listData[value].info.number -= 1
        await this.$store.dispatch({
          type: 'itemAction',
          action: 'updateItem',
          catchList: false,
          bodyObj: {
            idx: this.listData[value].info.idxNumber,
            qty: this.listData[value].info.number
          }
        })
      }
    },
    async addFn (value) {
      if (this.listData[value].info.number !==
          this.childArrStockAll[this.listData[value].parentId][this.listData[value].info.sku]) {
        this.listData[value].info.number += 1
        await this.$store.dispatch({
          type: 'itemAction',
          action: 'updateItem',
          catchList: false,
          bodyObj: {
            idx: this.listData[value].info.idxNumber,
            qty: this.listData[value].info.number
          }
        })
      }
    }
  }
}

export default shopping
