const shopping = {
  methods: {
    // 一般購買 -> 優惠券 -> 送貨地址 -> 寄送地址
    // 團購購買 -> 優惠券 -> 開團 -> 送貨地址 -> 寄送地址
    async userCouponFunction (buyType) {
      try {
        if (this.pickCoupon) {
          await this.$store.dispatch({
            type: 'useCouponPoint',
            couponCode: this.pickCoupon.code
          })
          await this.$store.dispatch({
            type: 'userCouponAction',
            action: 'deleteMyCoupon',
            bodyObj: {
              couponId: this.pickCoupon.coupon_id
            }
          })
        }
        await this.sendMoneyFunction(buyType)
      } catch (err) {
        if (err.response.config.url === `/carts/mine/coupons/${this.pickCoupon.code}`) {
          this.loading = false
          err.response.status === 404
            ? this.$message.error('優惠券使用失敗，請使用其他張優惠券！')
            : this.$message.error('伺服器發生錯誤，請確認網路是否正常')
        }
      }
    },
    async sendMoneyFunction (buyType) {
      try {
        await this.$store.dispatch({
          type: 'settingMethod',
          data: this.address,
          method: this.methodMoney === 0
            ? 'freeshipping'
            : this.methodMoney === 180
              ? 'outlyingshipping'
              : this.methodMoney === 100
                ? 'homeshipping'
                : 'flatrate'
        })
        await this.$store.dispatch({
          type: 'useRedPoints',
          points: this.inputRedpoint
        })
        await this.$store.dispatch({
          type: 'settingShopPay',
          data: this.billingData
        })
        buyType === 'common'
          ? this.billingAndMoney()
          : this.groupFunction()
      } catch (err) {
        this.loading = false
      }
    },
    async groupFunction () {
      const JSONdata = JSON.parse(sessionStorage.getItem('buyList'))[0]
      const type = this.$route.params.type
      try {
        await this.$store.dispatch({
          type: type === 'open' ? 'createGroupBuy' : 'joinGroupBuy',
          bodyObj: type === 'open'
            ? {
              parent_sku: JSONdata.momSku,
              owner_reward_point: JSONdata.groupBonus.value,
              current_qty: JSONdata.info.number,
              link: localStorage.getItem('groupLink'),
              grouping_amount: JSONdata.groupAmount.value,
              grouping_hours: JSONdata.groupHours.value
            }
            : {
              parent_sku: JSONdata.momSku,
              owner_customer_id: this.$route.query.groupId,
              sku: JSONdata.info.sku,
              groupbuy_id: this.$route.query.openId
            }
        })
        await this.billingAndMoney()
      } catch (err) {
        this.loading = false
        await this.$store.dispatch({
          type: 'getTrueShopCarId'
        })
        this.$message.error('訂單下單失敗！')
        console.log('groupFunction exception')
        this.$router.push({
          name: 'failOrderId'
        })
      }
    },
    async billingAndMoney () {
      try {
        await this.$store.dispatch({
          type: 'PostRemarks',
          comment: this.tradeDesctext
        })
        await this.$store.dispatch({
          type: 'newSendMethods',
          shipType: this.address.street[2] === 'home'
            ? 'home'
            : 'cvs',
          subType: this.address.street[2] === 'home'
            ? 'ECAN'
            : this.address.street[2] === '7eleven'
              ? 'UNIMART'
              : this.address.street[2] === 'hilife'
                ? 'HILIFE'
                : 'FAMI',
          extraData: this.csvData ? this.csvData : {}
        })
        if (this.payment.value === 'cod') {
          await this.$store.dispatch({
            type: 'createCvsCodOrder'
          })
        } else {
          await this.$store.dispatch({
            type: 'payMoneyGo',
            staging: this.payment.time,
            payMethods: this.payment.value
          })
        }
        console.log('billingAndMoney', this.$store.state.shopping.billingResult)
        console.log('billingAndMoney order id', this.$store.state.shopping.settingShopPayId)
        if (this.$store.state.shopping.billingResult === true) {
          this.$router.push({
            name: 'success',
            query: { orderId: this.$store.state.shopping.settingShopPayId }
          })
        } else {
          console.log('store api shopping shopping.js')
          this.$router.push({
            name: 'failOrderId'
          })
        }
      } catch (err) {
        await this.$store.dispatch({
          type: 'getTrueShopCarId'
        })
        console.log(err)
        console.log('billingAndMoney')
        this.$message.error('訂單下單失敗！')
        this.$router.push({
          name: 'failOrderId'
        })
      }
    }
  }
}

export default shopping
