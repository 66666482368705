<template>
  <div :class="$style.root">
    <div class="outside">
      <div class="top">
        <div class="toptitle">
          {{ list.title }}
        </div>
        <div
          v-if="list.list.total"
          @click="go"
          class="morewatch"
        >
          查看全部
          <i class="el-icon-arrow-right"/>
        </div>
      </div>
      <div class="mainout" v-if="list.list.total">
        <div class="productmain">
          <div
            v-for="(item, i) in list.list.list"
            :key="i"
            class="productinner"
            @click="getProductFn(item.sku, item.id)"
          >
            <div
              class="productimg"
              :style="{ backgroundImage: `url(${ imgUrl + item.image })` }"
            />
            <div class="producttextout">
              <p class="textlang">{{ item.newName }}</p>
            </div>
            <div class="productoutside">
              <p class="productextdel">$ {{ item.oldPrizeNum }}</p>
              <p class="productextmain">$ {{ item.newPrizeNum }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="['noanything', 'noanyhome']"
        v-if="list.list.total <= 0"
      >
        <img
          src="../../assets/img/noting.png"
          :class="['noanythingimg', 'noanythingimgwidth']"
        />
        目前沒有商品喔！
      </div>
    </div>
  </div>
</template>

<script>
import { componentRouter } from '@/js'
export default {
  props: {
    list: Object
  },
  methods: {
    getProductFn (sku, id) {
      componentRouter(this.list.productName, sku, id)
    },
    go () {
      this.$router.push({
        name: this.list.name,
        params: { current: 1 }
      })
    }
  }
}
</script>

<style lang="scss" module>
.nolist {
  font-size: 25px;
  text-align: center;
  color: #ccc;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img2 {
  opacity: .7;
  width: auto;
  height: 180px;
  margin-bottom: 20px;
}

@media (min-width: 1366px) {
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
}
</style>
