<template>
  <div :class="$style.root" v-if="orderData.paymentInfo.PaymentNo || orderData.paymentInfo.Barcode3 || orderData.paymentInfo.vAccount">
    <div :class="$style.title">
      {{ orderData.paymentInfo.PaymentNo
          ? '代碼繳費'
          : orderData.paymentInfo.Barcode3
            ? '條碼繳費'
            : 'ATM轉帳'
      }}
    </div>
    <div v-if="orderData.paymentInfo.PaymentNo">
      <p>超商代碼：{{ orderData.paymentInfo.PaymentNo }}</p>
      <p>各超商代碼繳費流程：https://www-stage.ecpay.com.tw/Service/pay_way_cvpay</p>
    </div>
    <div v-else-if="orderData.paymentInfo.Barcode3">
      <VueBarcode
        :height="50"
        :fontSize="12"
        :width="1"
        :value="orderData.paymentInfo.Barcode1"
      />
      <VueBarcode
        :height="50"
        :fontSize="12"
        :width="1"
        :value="orderData.paymentInfo.Barcode2"
      />
      <VueBarcode
        :height="50"
        :fontSize="12"
        :width="1"
        :value="orderData.paymentInfo.Barcode3"
      />
    </div>
    <div v-else>
      <p>銀行代號：{{ orderData.paymentInfo.BankCode }} - {{ backCode.find(ele => ele.code === orderData.paymentInfo.BankCode).name }}</p>
      <p>轉帳帳號：{{ orderData.paymentInfo.vAccount }}</p>
    </div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import backCode from '@/address/bankcode.json'
export default {
  components: {
    VueBarcode
  },
  props: {
    orderData: Object
  },
  data () {
    return {
      backCode
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border: solid 2px #ff6100;
  border-radius: 10px;
}

.title {
  font-size: 14px;
  border-bottom: 1px solid #ff6100;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
</style>
