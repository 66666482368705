<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.title" v-if="webwatch">
        {{ title }}
      </div>
      <div
        v-if="!privacy"
        :class="$style.callstyle"
        v-html="onlyHtml[0]"
      />
      <div
        v-else
        :class="$style.callstyle"
        v-html="privacy"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    title: String
  },
  computed: {
    ...mapGetters([
      'onlyHtml'
    ]),
    privacy () {
      const type = this.$route.meta.list
      if (type === 'information') {
        if (this.$route.name === 'policy') {
          return this.onlyHtml[0]
        } else {
          return this.onlyHtml[1]
        }
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .title {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid #C4C4C4;
  }

  .callstyle {
    width: 100%;
    margin-top: 40px;
  }

  .navnav {
    display: flex;
    justify-content: center;
    padding: 0 11px;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 44px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    padding: 15px;
    min-height: calc(100vh - 193px - 101px);
  }

  .callstyle {
    width: 720px;
    margin: 0 auto;
  }

  .navnav {
    display: flex;
    justify-content: center;
    padding: 0 11px;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 44px;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 15px;
    min-height: calc(100vh - 52px - 42px);
  }

  .rootinner {
    padding: 15px;
    box-sizing: border-box;
    margin-left: 15px;
  }

  .callstyle {
    width: 100%;
  }

  .navnav {
    display: flex;
    justify-content: center;
    padding: 0 11px;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 44px;
  }
}
</style>
