import { request, requestApi } from '@/request/request'

const registerJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    registMobileIsUsed: false,
    emailBoolen: null,
    hasRegisterok: null,
    mobileNum: null,
    newPassword: null
  },
  mutations: {
    getNewPassword (state, payload) {
      state.newPassword = payload.newPassword
    },
    checkRegistMobileIsUsed (state, payload) {
      state.registMobileIsUsed = payload.isUsed
    },
    mobileBoo (state, payload) {
      state.mobileNum = payload.mo.valid_number
    },
    emailBoolen (state, payload) {
      state.emailBoolen = payload.emailBoolen
    },
    hasRegisterok (state, payload) {
      state.hasRegisterok = payload.hasRegisterok
    }
  },
  actions: {
    async resetPasswordSMS (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.put(url, {
        action: 'resetPasswordSMS',
        mobileNumber: payload.mobileNumber
      })
      store.commit({ type: 'getNewPassword', newPassword: response.data.data.valid_number })
    },
    async getEmailByMobile (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'getEmailByMobile',
        mobileNumber: payload.mobileNum
      })
      if (response.data.length > 0) {
        store.commit({ type: 'checkRegistMobileIsUsed', isUsed: true })
      } else {
        store.commit({ type: 'checkRegistMobileIsUsed', isUsed: false })
      }
    },
    async sendSMS (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'sendSMS',
        mobileNumber: payload.mobileNumber
      })
      store.commit({ type: 'mobileBoo', mo: response.data.data })
    },
    async emailAvailable (store, payload) {
      const url = '/customers/isEmailAvailable'
      const response = await request.post(url, {
        customerEmail: payload.customerEmail
      })
      store.commit({ type: 'emailBoolen', emailBoolen: response.data })
    },
    async registerok (store, payload) {
      const url = '/customers'
      const response = await request.post(url, {
        customer: {
          group_id: payload.data.group_id,
          email: payload.data.group_id === 5 ? payload.data.newEmail : payload.data.email,
          firstname: payload.data.lastname, // 姓名
          lastname: payload.data.lastname, // 姓名
          gender: 3, // 1男 2女 3不詳
          website_id: 1, // hardcode
          custom_attributes: [{
            attribute_code: 'user_id', // 使用者帳號(必填）
            value: payload.data.user_id
          }, {
            attribute_code: 'user_nickname', // 使用者暱稱 （KOL必填）
            value: payload.data.user_nickname
          }, {
            attribute_code: 'user_mobile', // 使用者手機 （KOL必填）
            value: payload.data.user_mobile
          }]
        },
        password: payload.data.password
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'hasRegisterok', hasRegisterok: response.data })
    },
    async resetForEamil (store, payload) {
      const url = '/customers/password'
      // 重寄認證信
      await request.put(url, {
        email: payload.email,
        template: 'email_reset', // 固定值
        websiteId: 1 // 固定值
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
    }
  }
}

export default {
  registerJs
}
