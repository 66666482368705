<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.imgout">
        <div :class="$style.imgoutbefore" ref="imgoutbefore"/>
        <carousel
          :imgList="imgListinner"
          :class="$style.carousel"
          :style="{ height: `${ carouselHeight }px` }"
          @imageIndex="imageIndex = $event"
        />
        <div :class="$style.imgoutafter" ref="imgoutafter"/>
      </div>
      <voucher
        :class="$style.voucher"
        v-if="online && online !== 'kol'"
      />
      <kol
        :class="$style.kol"
      />
      <!--
      <product
        :list="{
          title: '拼團專區',
          list: groupList,
          name: 'groupList',
          productName: 'groupProduct'
        }"
        :class="[$style.product, $style.productsmall]"
      />
      -->
      <product
        :list="{
          title: '優惠組合商品',
          list: discountList,
          name: 'discountList',
          productName: 'discountProduct'
        }"
        :class="[$style.product, $style.productsmall]"
      />
      <product
        :list="{
          title: '精選商品',
          list: commonList,
          name: 'commonList',
          productName: 'commonProduct'
        }"
        :class="[$style.product, $style.productsmall, $style.noline]"
      />
    </div>
    <loading v-if="loading"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import carousel from '@/components/carousel/carousel.vue'
import voucher from '@/components/voucher/voucher.vue'
import kol from '@/components/product/kolhome.vue'
import product from '@/components/product/producthome.vue'
import listimg from '@/js/listimg.js'
export default {
  mixins: [
    listimg
  ],
  components: {
    carousel,
    voucher,
    kol,
    product
  },
  data () {
    return {
      watch: false,
      active: 0,
      imageIndex: 0
    }
  },
  watch: {
    imageIndex (newVal) {
      var beforeVal = newVal - 1
      if (newVal === 0) {
        beforeVal = this.imgListinner.length - 1
      }
      var afterVal = newVal + 1
      if (this.imgListinner.length - 1 === newVal) {
        afterVal = 0
      }
      this.$refs.imgoutbefore.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[beforeVal].img})`
      this.$refs.imgoutafter.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[afterVal].img})`
    }
  },
  computed: {
    ...mapGetters([
      'imgListinner',
      'commonList',
      'groupList',
      'discountList'
    ])
  },
  methods: {
    watchFn (value) {
      if (this.watch === true && this.active === value) {
        this.watch = false
      } else {
        this.active = value
        this.watch = true
      }
    },
    close () {
      this.watch = false
    }
  },
  async created () {
    this.$emit('loading', true)
    try {
      await Promise.all([
        this.$store.dispatch({
          type: 'getListProducts',
          pageSize: 4,
          typeValue: 5643
        }),
        this.$store.dispatch({
          type: 'getListProducts',
          pageSize: 4,
          typeValue: 5644
        }),
        this.$store.dispatch({
          type: 'getListProducts',
          pageSize: 4,
          typeValue: 5646
        }),
        this.$store.dispatch({
          type: 'kolProdutsList',
          pageSize: 3,
          field: 'kol_promote',
          fieldValue: 1
        }),
        this.$store.dispatch({
          type: 'getBanner',
          banner: this.$route.meta.type
        })
      ])
    } finally {
      this.$emit('loading', false)
    }
  },
  beforeDestroy () {
    this.$store.commit({ type: 'kolProdutsList', kolProdutsList: null })
    this.$store.commit({ type: 'kolInfopro', kolInfopro: null })
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .carousel {
    z-index: 0;
    width: 950px;
    height: 450px;
    margin: 0 auto;
  }

  .imgout {
    width: 100%;
    height: 450px;
    position: relative;

    .imgoutbefore {
      position: absolute;
      top: 0;
      left: -475px;
      width: 50%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
    }

    .imgoutafter {
      position: absolute;
      top: 0;
      right: 0px;
      width: 25%;
      height: 100%;
      z-index: -3;
      opacity: 0.2;
    }
  }

  .voucher {
    width: 100%;
    height: 240px;
    display: flex;
    background: #f7f8f9;
    justify-content: center;
  }

  .kol {
    border-bottom: 14px solid #f7f8f9;
    height: 550px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .product {
    border-bottom: 14px solid #f7f8f9;
    height: 550px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sizeimg {
    width: 60px;
    height: 60px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .carousel {
    z-index: 0;
    margin: 28px 0;
    width: 768px;
    height: 384px;
  }

  .kol {
    border-bottom: 7px solid #f7f8f9;
    height: 400px;
    width: 100%;
  }

  .productsmall {
    border-bottom: 7px solid #f7f8f9;
    height: 406px;
    width: 100%;
  }

  .sizeimg {
    width: 60px;
    height: 60px;
  }

  .voucher {
    border-top: 7px solid #f7f8f9;
    border-bottom: 7px solid #f7f8f9;
    width: 100%;
    height: 157px;
  }
}

@media (max-width: 767px){
  .carousel {
    z-index: 0;
    width: 100%;
  }

  .kol {
    border-bottom: 4px solid #f7f8f9;
    height: 310px;
    width: 100%;
  }

  .productsmall {
    border-bottom: 4px solid #f7f8f9;
    height: 315px;
    width: 100%;
  }

  .voucher {
    border-top: 5px solid #f7f8f9;
    border-bottom: 5px solid #f7f8f9;
    width: 100%;
    height: 125px;
  }

  .sizeimg {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 320px){
  .qrcodebox {
    z-index: 3;
    position: fixed;
    margin: 0;
    right: unset;
    bottom: unset;
  }
}

.noline {
  border: 0;
}
</style>
