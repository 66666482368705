import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Layout from '../layout/layout.vue'
import LoginRouter from './login.js'
import Home from './allview/home.js'
import Allproducts from './product/allproducts.js'
// 一般使用者購物車
import Usershopping from './shopping/user.js'
// 一般會員
import Commonuser from './user/common.js'
// kol使用者資料
import Koluser from './user/kol.js'
// 員工購物車、個人頁、商品頁
import Employeeuser from './user/employee.js'
// 404
import Error404 from './404.js'
import Footer from './allview/footer.js'
import NoSupportBrowser from '@/views/noSupportBrowser.vue'
import Bowser from 'bowser'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'layout',
  component: Layout,
  redirect: { name: 'home' },
  children: [
    ...LoginRouter,
    ...Home,
    ...Allproducts,
    // 一般使用者購物車
    ...Usershopping,
    // 一般使用者資料
    ...Commonuser,
    // kol使用者資料
    ...Koluser,
    // 員工購物車、個人頁、商品頁
    ...Employeeuser,
    // 404
    ...Error404,
    ...Footer
  ]
}, {
  path: '/noSupportBrowser',
  name: 'noSupportBrowser',
  component: NoSupportBrowser
}, {
  path: '*',
  redirect: { name: 'error404' }
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 跳頁後畫面會從最上面開始
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

// 判斷是否登入過 (localStorage是否有token和資料)
router.beforeEach(async (to, from, next) => {
  const browser = Bowser.getParser(window.navigator.userAgent)
  if (to.name !== 'noSupportBrowser' && browser.getBrowserName() === 'Internet Explorer') {
    next({ name: 'noSupportBrowser' })
  } else {
    try {
      document.getElementsByTagName('routersize')[0].scrollTop = 0
    } catch {
    }
    const hasEmail = localStorage.getItem('email')
    // 第三方登入
    if (!hasEmail) {
      console.log('hasEmail')
      const hasStoreToken = store.state.loginInfo.userToken
      if (!hasStoreToken) {
        const hasToken = localStorage.getItem('userToken')
        if (hasToken) {
          try {
            await store.commit({
              type: 'userToken',
              userToken: hasToken
            })
            await Promise.all([store.dispatch({ type: 'getUserData' })],
              [store.dispatch({ type: 'itemAction', action: 'getItem', catchList: true })],
              [store.dispatch({ type: 'getTrueShopCarId' })])
            Vue.prototype.$notify({
              title: '已登入',
              message: '歡迎回來cvimall。'
            })
            next()
          } catch (err) {
            store.commit({ type: 'userToken', userToken: null })
            store.commit({ type: 'userData', userData: null })
            store.commit({ type: 'userIdentity', userIdentity: null })
            Vue.prototype.$notify({
              title: '已登出',
              message: '帳號逾時，請重新登入。'
            })
            next({ name: 'common', query: { state: 'login' } })
          }
        } else {
          next()
        }
      } else {
        next()
      }
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
