<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.bg">
        <div :class="$style.toptitle">
          新增帳單地址、統編、公司抬頭
        </div>
        <div :class="$style.listout">
          <div :class="$style.top">
            <div :class="$style.toptext">
              <span class="red">*</span>
              帳單地址
            </div>
          </div>
          <div :class="$style.selectout">
            <select
              v-model="selectValueCity"
              :class="[$style.out, $style.selectstyle]"
              :disabled="same"
            >
              <option
                v-for="(item, i) in taiwanDistrict"
                :value="i"
                :key="i"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              v-model="selectValue"
              :class="[$style.out, $style.selectstyle]"
              :disabled="same"
            >
              <option
                v-for="(item, i) in taiwanDistrict[selectValueCity].districts"
                :value="i"
                :key="i"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <input
            v-model="address"
            :class="$style.out"
            placeholder="詳細地址"
            :disabled="same"
          />
          <div :class="$style.selectout">
            <input
              :disabled="same"
              v-model="sendname"
              :class="[$style.out, $style.selectstyle, $style.input]"
              placeholder="姓名"
            />
            <input
              :disabled="same"
              v-model="sendphone"
              :class="[$style.out, $style.selectstyle, $style.input]"
              placeholder="手機號碼"
            />
          </div>
          <div :class="home ? [$style.checkboxout] : [$style.checkboxoutno]">
            <input
              :class="$style.checkbox"
              type="checkbox"
              v-model="same"
              :disabled="!home"
            />
            <span
              @click="home ? same = !same : ''"
            >
              與寄送地址相同(只可用在宅配)
            </span>
          </div>
          <div :class="$style.top">
            <div :class="$style.toptext">統編、公司抬頭(可不填寫)</div>
          </div>
          <input
            v-model="editor"
            :class="$style.out"
            placeholder="統編"
          />
          <input
            v-model="letterhead"
            :class="$style.out"
            placeholder="公司抬頭"
          />
        </div>
        <p :class="$style.errortext" v-if="message">{{ message }}</p>
        <div :class="$style.btnout">
          <div :class="$style.cancel" @click="$emit('close')">取消</div>
          <div
            :class="[ok ? $style.send : $style.sendno]"
            @click="ok ? addAddress() : ''"
          >
            完成
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import taiwanDistrict from '@/address/taiwan_districts.json'
export default {
  props: {
    info: Object,
    pickAddressId: Number,
    LastTimebilling: null
  },
  data () {
    return {
      selectValueCity: 0,
      selectValue: 0,
      same: false,
      address: '',
      sendname: '',
      sendphone: '',
      editor: '',
      letterhead: '',
      message: null,
      taiwanDistrict
    }
  },
  watch: {
    same () {
      if (this.same && this.home) {
        const arrPos = this.info.addressList.find(ele => ele.id === this.pickAddressId)
        const cityNumber = this.taiwanDistrict.map(ele => ele.name).indexOf(arrPos.city)
        const areaNumber = this.taiwanDistrict[cityNumber].districts
          .map(ele => ele.name)
          .indexOf(arrPos.street[0])
        this.selectValueCity = cityNumber
        this.selectValue = areaNumber
        this.address = arrPos.street[1]
        this.sendname = arrPos.lastname
        this.sendphone = arrPos.telephone
      }
    }
  },
  computed: {
    ok () {
      if (this.address && this.sendname && this.sendphone) {
        return true
      } else {
        return false
      }
    },
    home () {
      const arrPos = this.info.addressList.find(ele => ele.id === this.pickAddressId)
      if (arrPos && this.pickAddressId >= 0) {
        if (arrPos.street[2] === 'home') {
          return true
        } else {
          return false
        }
      } return false
    },
    addaddressFN () {
      const newaddress = {
        street: [
          this.taiwanDistrict[this.selectValueCity].districts[this.selectValue].name,
          this.address
        ],
        region_id: 0, // 固定
        country_id: 'TW', // 固定
        telephone: this.sendphone,
        postcode: 251,
        city: this.taiwanDistrict[this.selectValueCity].name,
        firstname: '抬頭：' + (this.letterhead ? this.letterhead : '無') + ', ' +
          '統編：' + (this.editor ? this.editor : '無'),
        lastname: this.sendname
      }
      return newaddress
    }
  },
  methods: {
    async addAddress () {
      if (this.address && this.sendname && this.sendphone) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'customJsonFn',
            action: this.LastTimebilling ? 'updateCustomJson' : 'addCustomJson',
            key: 'LastTimebilling',
            value: this.addaddressFN
          })
          await this.$store.dispatch({
            type: 'customJsonFn',
            action: 'getCustomJson',
            key: 'LastTimebilling'
          })
          this.$emit('billing', this.addaddressFN)
          this.$emit('close')
        } catch {
          this.$message.error('請確認填妥寄送資料')
        } finally {
          this.$emit('loading', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
.checkboxout {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: -10px 0 20px 0;
  cursor: pointer;
}

.checkboxoutno {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: -10px 0 20px 0;
  cursor: not-allowed;
  color: #ccc;
}

.checkbox {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.errortext {
  text-align: center;
  color: red;
  margin-top: 10px;
}

.cancel {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF6100;
  font-weight: 500;
  font-size: 16px;
  width: 47%;
  height: 40px;
  border: 1px solid #FF6100;
  box-sizing: border-box;
  border-radius: 999rem;
  margin-top: 20px;
}

.send {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 47%;
  height: 40px;
  background: #FF6100;
  border: 1px solid #FF6100;
  box-sizing: border-box;
  border-radius: 999rem;
  margin-top: 20px;
}

@media (min-width: 1366px) {
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #FF6100;
    margin: auto;
    width: 480px;
    border-radius: 10px;
    background-color: #ffffff;
    min-height: 470px;
    padding: 20px 32px;
    box-sizing: border-box;
  }

  .top {
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  .listout {
    min-height: 490px;
  }

  .toptitle {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    padding-bottom: 8px;
  }

  .toptext {
    font-size: 14px;
    color: #828282;
  }

  .out {
    font-size: 14px;
    -webkit-appearance: none;
    box-shadow: none;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    border: 1px solid #C4C4C4;

    &.outactive {
      border: 1px solid #FF6100;
    }
  }

  .imgsize {
    width: 27px;
    height: auto;
  }

  .outtext {
    padding-left: 20px;
    font-size: 14px;
  }

  .btnout {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .listtitle {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 12px;
  }

  .selectout {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .selectstyle {
    cursor: pointer;
    width: 48%;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;

    &.input {
      background-image: none;
    }
  }

  .nono {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    width: 180px;
    height: 40px;
    background: #aaa;
    border: 1px solid #aaa;
    box-sizing: border-box;
    border-radius: 999rem;
  }

  .check {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .checktext {
    margin-left: 10px;
  }
}

@media (max-width: 1365px) {
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 100px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #FF6100;
    margin: auto;
    width: 90%;
    max-width: 480px;
    border-radius: 10px;
    background-color: #ffffff;
    min-height: 470px;
    padding: 18px 20px;
    box-sizing: border-box;
  }

  .top {
    border-bottom: 2px solid #C4C4C4;
    padding-bottom: 10px;
    margin-bottom: 13px;
  }

  .listout {
    min-height: 340px;
  }

  .toptitle {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }

  .toptext {
    font-size: 12px;
    line-height: 14px;
    color: #828282;
  }

  .out {
    -webkit-appearance: none;
    box-shadow: none;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #C4C4C4;
  }

  .outactive {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #FF6100;
  }

  .imgsize {
    width: 25px;
    height: auto;
  }

  .outtext {
    padding-left: 15px;
    font-size: 13px;
  }

  .btnout {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .listtitle {
    font-size: 13px;
    padding: 12px 0;
  }

  .selectout {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .selectstyle {
    cursor: pointer;
    width: 48%;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;

    &.input {
      cursor: auto;
      background-image: none;
    }
  }

  .nono {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    width: 47%;
    height: 31px;
    background: #aaa;
    border: 1px solid #aaa;
    box-sizing: border-box;
    border-radius: 999rem;
  }

  .check {
    display: flex;
  }

  .checktext {
    margin-left: 10px;
  }

  .checkboxout {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0 0 20px 0;
    cursor: pointer;
  }

  .checkboxoutno {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0 0 20px 0;
    cursor: not-allowed;
    color: #ccc;
  }
}

.sendno {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  width: 47%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 999rem;
  margin-top: 20px;
  background: #ccc;
  color: #fff;
  border: 1px solid #ccc;
  cursor: not-allowed;
}
</style>
