import store from '@/store'
import Employeeuserlayout from '../../views/employee/user/employeeuserlayout.vue'
import Employeeinformation from '../../views/user/information.vue'
import Employeeuserlist from '../../views/user/list.vue'
import List from '../../views/product/list.vue'
import Product from '../../views/product/product.vue'
import Employeesuccess from '../../views/employee/employeesuccess.vue'
import Shoppinglistto from '../../views/shopping/shoppinglistto.vue'

const isEmployee = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 5 || id === 7 || id === 8 || id === 9) {
    next()
  } else {
    next({ name: 'home' })
  }
}

export default [{
  path: 'employeeuser/:userId',
  name: 'employeeuserlayout',
  component: Employeeuserlayout,
  redirect: { name: 'employeeinformation' },
  beforeEnter: isEmployee,
  children: [{
    path: 'employeeinformation',
    name: 'employeeinformation',
    component: Employeeinformation
  }, {
    path: 'employeeuserlist/:current',
    name: 'employeeuserlist',
    component: Employeeuserlist
  }]
}, {
  path: 'employeelist/:current',
  name: 'employeelist',
  component: List,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'employee',
    number: 5645
  }
}, {
  path: 'employeelist/employeeProduct/:skuId',
  name: 'employeeProduct',
  component: Product,
  beforeEnter: isEmployee,
  meta: {
    search: true,
    type: 'employee'
  }
}, {
  path: 'employeeshoplist/:userId/:carId',
  name: 'employeeshoplist',
  component: Shoppinglistto,
  beforeEnter: isEmployee,
  meta: { search: true }
}, {
  path: 'employeesuccess/:userId/:orderId',
  name: 'employeesuccess',
  component: Employeesuccess,
  beforeEnter: isEmployee
}]
