<template>
  <div :class="$style.root">
    <div :class="$style.listtitle">
      <div>訂單商品</div>
      <div :class="$style.centertext">單價</div>
      <div :class="$style.centertext">數量</div>
      <div :class="$style.centertext">總價</div>
    </div>
    <div :class="$style.title" v-if="!webwatch">訂單商品</div>
    <div :class="$style.list" v-if="webwatch">
      <div :class="$style.listinner" v-for="(item, i) in listData" :key="i">
        <div :class="$style.border">
          <div :class="$style.borderinnder">
            <div
              :class="$style.img"
              :style="{ backgroundImage: `url(${ imgUrl + item.img})` }"
            />
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.description"
              placement="top-start"
            >
              <div class="textlangone">
                {{ item.description }}
              </div>
            </el-tooltip>
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.info.name"
            placement="bottom-start"
          >
            <p class="textlangone">規格：
              {{ item.info.name }}
            </p>
          </el-tooltip>
          <div :class="$style.tag">
            {{ item.tagText }}
          </div>
        </div>
        <p :class="$style.centertext">
          {{ item.info.newPrice }}
        </p>
        <p :class="$style.centertext">{{ item.info.number }}</p>
        <p :class="$style.centertext">
          {{ item.info.newPrice * item.info.number }}
        </p>
      </div>
    </div>
    <div :class="$style.list" v-else>
      <div :class="$style.listinner" v-for="(item, i) in listData" :key="i">
        <div :class="$style.border">
          <div
            :class="$style.img"
            :style="{ backgroundImage: `url(${ imgUrl + item.img})` }"
          />
        </div>
        <div :class="[$style.inner ,'textlangone']">
          <p :class="[$style.overflowout, 'textlangone']">
            {{ item.description }}
          </p>
          <p style="font-size: 13px; color: #828282; white-space: normal;">
            {{ item.info.name }}
          </p>
          <p style="font-size: 13px;">$ {{ item.info.newPrice }}</p>
        </div>
        <div :class="$style.innerbtmm">
          <div :class="$style.tag">
            {{ item.tagText }}
          </div>
          <div>數量：{{ item.info.number }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    listData () {
      return JSON.parse(sessionStorage.getItem('buyList'))
    }
  },
  async beforeDestroy () {
    if (this.listData[0].tag === '5644' && this.$route.name !== 'groupshoplist') {
      await this.$store.dispatch({
        type: 'itemAction',
        action: 'delItem',
        catchList: false,
        bodyObj: {
          idx: this.listData[0].info.idxNumber
        }
      })
      await this.$store.dispatch({
        type: 'itemAction',
        action: 'getItem',
        catchList: true
      })
    }
  }
}
</script>

<style lang="scss" module>
.listtitle {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  display: grid;
  grid-template-columns: 690px 1fr 1fr 1fr;
  grid-template-rows: 66px;
  align-items: center;
  padding-top: 65px;
  border-bottom: 2px solid #C4C4C4;
  margin-bottom: 20px;
}

.centertext {
  justify-self: center;
}

.list {
  width: 100%;
}

.listinner {
  box-sizing: border-box;
  padding-left: 26px;
  width: 100%;
  display: grid;
  grid-template-columns: 664px 1fr 1fr 1fr;
  grid-template-rows: 50px;
  padding-bottom: 12px;
  align-items: center;
}

.border {
  display: grid;
  grid-template-columns: 347px 1fr 1fr;
  grid-template-rows: 50px;
  grid-column-gap: 74px;
  font-size: 14px;
  align-items: center;
}

.borderinnder {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 50px;
  grid-column-gap: 28px;
  align-items: center;
}

.img {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: contain;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 50px;
  height: 28px;
  border-radius: 5px;
  background-color: #ffc000;
}

@media (max-width: 1365px) {
  .listtitle {
    display: none;
  }

  .title {
    font-size: 14px;
    padding: 20px 0;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(56px, auto);
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-template-rows: 100%;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .border {
    display: flex;
  }

  .borderinnder {
    display: flex;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .inner {
    width: 100%;
  }

  .overflowout {
    flex: 1;
  }
}

@media (max-width: 767px) {
}
</style>
