<template>
  <div :class="$style.root">
    <img src="../assets/img/chat.svg" :class="$style.img"/>
    <p :class="$style.title">非常抱歉！請勿使用Internet Explorer開啟！</p>
    <p :class="$style.text">
      您可以選擇使用Google Chrome瀏覽器、Microsoft Edge瀏覽器進行購物！
    </p>
    <div :class="$style.out">
      <div :class="$style.outside">
        <a href="https://www.google.com/intl/zh-TW/chrome/" target="_blank">
          <img src="../assets/img/chrome.png" :class="$style.img2"/>
        </a>
        Google Chrome下載
      </div>
      <div :class="$style.outside">
        <a href="https://www.microsoft.com/zh-tw/edge" target="_blank">
          <img src="../assets/img/edge.png" :class="$style.img2"/>
        </a>
        Microsoft Edge下載
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" module>
.img {
  width: 150px;
}

.root {
  width: 80%;
  height: 100vh;
  max-width: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.title {
  font-size: 22px;
  margin-top: 20px;
  color: #ff5722;
}

.text {
  font-size: 18px;
  margin-top: 20px;
  color: #696969;
}

.outside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #696969;
  margin-right: 15px;
  margin-left: 15px;
}

.out {
  display: flex;
  margin-top: 50px;
}

.img2 {
  width: 80px;
  margin-bottom: 10px;
}
</style>
