const productimg = {
  data () {
    return {
      carouselWidthtImg: null,
      carouselHeightImg: null
    }
  },
  methods: {
    carouselImg () {
      if (document.body.offsetWidth < 768) {
        this.carouselWidthtImg = document.body.offsetWidth
        this.carouselHeightImg = this.carouselWidthtImg
      } else {
        this.carouselWidthtImg = 450
        this.carouselHeightImg = 450
      }
    }
  },
  mounted () {
    this.carouselImg()
    window.addEventListener('resize', this.carouselImg)
  },
  beforeDestory () {
    window.removeEventListener('resize', this.carouselImg)
  }
}

export default productimg
