<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div
        :class="[$style.bg,
        'animate__animated',
        'animate__faster',
        'animate__fadeInDownBig']"
      >
        <div :class="$style.top">
          <div :class="$style.out">
            <p :class="$style.title">選擇優惠券</p>
            <p
              @click="check = -1"
              :class="[$style.title3, 'clickCss']"
            >
              不使用優惠券
            </p>
          </div>
          <div :class="$style.toptext">
            請勾選欲選用優惠券
          </div>
        </div>
        <div :class="$style.main" v-if="okPickCouponListBoolean.length > 0">
          <div
            :class="$style.ticout"
            v-for="(item, i) in okPickCouponListBoolean"
            :key="i"
          >
            <div :class="$style.tic">
              <div :class="$style.imgsize">
                {{ Math.floor(Number(item.discount_amount)) }}
              </div>
              <div :class="[$style.text, {[$style.nopick]: !item.haveBoolean }]">
                <div
                  :class="[$style.textinner, 'textlang']"
                  @click="check = i"
                >
                  {{ item.name }}
                </div>
                <div :class="$style.textright">
                  <input
                    v-if="item.haveBoolean"
                    type="radio"
                    :class="$style.checkbox"
                    :value="i"
                    v-model="check"
                  />
                  <div v-else/>
                  <p
                    :class="$style.rule"
                    @click="active === i ? active = null : active = i"
                  >
                    規則
                  </p>
                </div>
              </div>
            </div>
            <div
              :class="$style.list"
              v-if="active === i"
            >
              <i
                :class="[$style.icon, 'el-icon-close']"
                @click="active = null"
              />
              <p :class="$style.listtext">
                {{ item.description }}
              </p>
              <p :class="$style.time">
                使用期限：
                {{ item.to_date ? item.to_date : '無期限' }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-else
          :class="['noanything', 'noanythingplug']"
        >
          <img
            :class="'noanythingimg'"
            src="../../assets/img/coupon.svg"
          />
          目前沒有優惠券唷！
        </div>
        <div :class="$style.out">
          <div
            :class="[$style.no, 'clickCss']"
            @click="$emit('close')"
          >
            取消
          </div>
          <div
            :class="[$style.no, $style.yes, 'clickCss']"
            @click="pickCouponFn"
          >
            確定
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    listData: Array,
    okPickCouponList: Array,
    pickCoupon: Number
  },
  data () {
    return {
      active: null,
      check: null
    }
  },
  watch: {
    check () {
      this.active = -1
    },
    ListBoolean () {
      if (!this.ListBoolean.includes(true)) {
        this.$emit('pickCoupon', -1)
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCouponList',
      'couponBySku'
    ]),
    okPickCouponListBoolean () {
      const pickSkuArray = this.listData.filter(ele => ele.checkbox).map(ele => ele.momSku)
      return this.okPickCouponList.map(ele => {
        let haveBoolean
        haveBoolean = ele.allow.some(ele2 => ele2 === 'all' || pickSkuArray.includes(ele2))
        return {
          ...ele,
          haveBoolean
        }
      })
    },
    ListBoolean () {
      return this.okPickCouponListBoolean
        ? this.okPickCouponListBoolean.map(ele => ele.haveBoolean)
        : []
    }
  },
  methods: {
    pickCouponFn () {
      this.$emit('pickCoupon', this.check)
      this.$emit('close')
    }
  },
  created () {
    this.check = this.pickCoupon
  }
}
</script>

<style lang="scss" module>
.rootinner {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.main {
  border: 0;
}

.title3 {
  cursor: pointer;
  text-decoration: underline;
  color: #aaa;
  font-size: 14px;
}

.bg {
  margin: auto;
  padding: 15px;
  box-sizing: border-box;
  width: 500px;
  height: auto;
  border-radius: 5px;
  border: solid 1px #ff6100;
  background-color: #ffffff;
}

.top {
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
}

.out {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
}

.title2 {
  font-size: 14px;
  color: #ff6100;
}

.toptext {
  font-size: 14px;
  color: #828282;
  padding-top: 3px;
}

.no {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff6100;
  font-size: 16px;
  font-weight: 400;
  width: 47%;
  height: 40px;
  border-radius: 999rem;
  border: solid 1px #ff6100;
  cursor: pointer;

  &.yes {
    color: #fff;
    background-color: #ff6100;
  }
}

.main {
  padding: 20px 3px 7px 3px;
  box-sizing: border-box;
  min-height: 400px;
}

.tic {
  display: grid;
  grid-template-columns: 94px 1fr;
  height: 65px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
}

.checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.textright {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.imgsize {
  width: 94px;
  height: 100%;
  z-index: 1;
  margin-left: 3px;
  background: url(../../assets/img/smallcoupon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 500;
  font-size: 40px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.text {
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}

.textinner {
  font-size: 13px;
  width: 100%;
  height: 35px;
  padding: 0 5px;
  box-sizing: border-box;
  overflow: hidden;
}

.rule {
  right: 7px;
  bottom: 4px;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: #828282;
  text-decoration: underline;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #f4fff0;
}

.icon {
  align-self: flex-end;
  font-size: 20px;
  color: #828282;
}

.listtext {
  padding: 7px 0;
  border-bottom: 2px solid #ccc;
}

.ticout {
  position: relative;
  margin-bottom: 13px;
}

.time {
  align-self: flex-end;
  padding-top: 7px;
}

.nopick {
  background: rgba(0, 0, 0, 0.1) !important;
}

@media (max-width: 1365px) {
  .rootinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .bg {
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    max-width: 520px;
    width: 80%;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    font-size: 12px;
    color: #828282;
    padding-top: 3px;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 13px;
    font-weight: 400;
    width: 47%;
    height: 31px;
    border-radius: 999rem;
    border: solid 1px #ff6100;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .main {
    padding: 20px 3px 7px 3px;
    box-sizing: border-box;
    min-height: 400px;
  }

  .tic {
    display: grid;
    grid-template-columns: 94px 1fr;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
  }

  .checkbox {
    width: 18px;
    height: 18px;
  }

  .textright {
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .imgsize {
    width: 94px;
    height: 100%;
    z-index: 1;
    margin-left: 3px;
    background: url(../../assets/img/smallcoupon.svg);
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 500;
    font-size: 40px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .text {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    width: 100%;
    background: #fff;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .textinner {
    font-size: 13px;
  }

  .rule {
    right: 7px;
    bottom: 4px;
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #828282;
    text-decoration: underline;
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #f4fff0;
  }

  .icon {
    align-self: flex-end;
    font-size: 20px;
    color: #828282;
  }

  .listtext {
    padding: 7px 0;
    border-bottom: 2px solid #ccc;
  }

  .ticout {
    margin-bottom: 13px;
  }

  .time {
    align-self: flex-end;
    padding-top: 7px;
  }
}
</style>
