<template>
  <div :class="$style.root">
    <div :class="$style.inputout">
      <input
        v-model="search"
        :class="$style.searchrwd"
        :placeholder="online === 'employee'
          ? '搜尋員工商品 請輸入關鍵字'
          : '搜尋商品 請輸入關鍵字'
        "
        @click="searchFunction"
        @keypress.enter="searchFu"
        ref="inputCom"
      />
      <img
        v-if="webwatch"
        @click="searchFu"
        src="../../assets/img/header_button_search_default.png"
        :class="['el-icon-close', $style.searchimg]"
      />
      <i
        @click="closeFn"
        v-if="!webwatch && close"
        :class="['el-icon-close', $style.iconx]"
      />
    </div>
    <searchView
      @closeKeyboard="closeKeyboardFn"
      @searchText="searchText($event)"
      v-if="webwatch && close"
      @close="closeFn"
    />
    <loading v-if="loading"/>
  </div>
</template>

<script>
import searchView from '@/components/search/searchview.vue'
export default {
  components: {
    searchView
  },
  data () {
    return {
      search: '',
      close: false
    }
  },
  computed: {
  },
  methods: {
    closeKeyboardFn () {
      this.$refs.inputCom.blur()
    },
    async searchFu () {
      if (this.search !== '') {
        this.webwatch ? this.close = false : this.$emit('searchListOpen', false)
        const historySearch = JSON.parse(localStorage.getItem('historySearch'))
        if (historySearch) {
          historySearch.push(this.search)
          localStorage.setItem('historySearch', JSON.stringify(historySearch))
        } else {
          localStorage.setItem('historySearch', JSON.stringify([this.search]))
        }
        await this.searchText(this.search)
      }
    },
    async searchText (value) {
      this.webwatch ? this.close = false : this.$emit('searchListOpen', false)
      this.$router.push({
        name: 'searchList',
        params: { typeWord: this.online === 'employee' ? 'employee' : 'common', current: 1 },
        query: { text: value }
      })
      this.search = ''
    },
    searchFunction () {
      this.close = true
      if (!this.webwatch) {
        this.$emit('searchListOpen', true)
      }
    },
    closeFn () {
      if (!this.webwatch) {
        this.$emit('searchListOpen', false)
      }
      this.close = false
      this.search = ''
    }
  }
}
</script>

<style lang="scss" module>
.inputout {
  width: 100%;
  height: 100%;
  position: relative;
}

.searchrwd {
  background-repeat: no-repeat;
  background-size: 26px 26px;
  background-position: 455px 45%;
  padding: 0 50px 0 18px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 999rem;
  background-color: #fff;
  font-size: 14px;
  border: 2px solid #F2F2F2;
  font-weight: 300;
  color: #828282;
  transition: .3s;

  &:focus {
    border: 2px solid #ff6100;
    transition: .3s;
  }
}

.searchimg {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 18%;
  right: 3%;
  cursor: pointer;
}

.iconx {
  position: absolute;
  top: 17%;
  right: 8%;
  font-size: 20px;
  color: #828282;
}

@media (max-width: 1365px){
  .inputout {
    position: sticky;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0 10px 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .searchrwd {
    background-image: url(../../assets/img/header_button_search_default.png);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: 20px 45%;
    border: none;
    padding: 0 35px 0 55px;
    box-sizing: border-box;
    width: 93%;
    height: 33px;
    border-radius: 999rem;
    background-color: #f7f7f7;
    font-size: 14px;
    color: #828282;
    border: 2px solid #f7f7f7;
  }

  .iconx {
    position: absolute;
    top: 17%;
    right: 5%;
    font-weight: 900 !important;
    font-size: 20px;
    color: #828282;
  }

  @media (max-width: 767px) {
    .iconx {
      position: absolute;
      top: 17%;
      right: 8%;
      font-weight: 900 !important;
      font-size: 20px;
      color: #828282;
    }
  }
}
</style>
