<template>
  <div :class="$style.root">
    <div :class="$style.send">
      <div :class="$style.sendtop">
        <p :class="$style.titit">付款方式</p>
        <p
          :class="[$style.titit2, { ['red']: !payment }]"
        >
          {{ payment ? payment.ex : '尚未設定付款方式' }}
          <span :class="$style.lineee" @click="comName = 'paymethods'">
            變更
          </span>
        </p>
      </div>
      <div :class="$style.sendtop">
        <p :class="$style.titit">優惠券</p>
        <p :class="$style.titit2">
          - ＄ {{ pickCoupon >= 0
              ? Math.floor(okPickCouponList[pickCoupon].discount_amount)
              : 0
            }}
          <span
            :class="$style.lineee"
            @click="comName = 'coupon'"
          >
            變更
          </span>
        </p>
      </div>
      <div
        :class="$style.sendtop"
        v-if="routerName !== 'groupshoppinginfo'"
      >
        <p :class="$style.titit">現金</p>
        <p :class="$style.titit2">
          - ＄ {{ inputRedpoint }}
          <span
            :class="$style.lineee"
            @click="comName = 'redpotie'"
          >
            變更
          </span>
        </p>
      </div>
      <div :class="$style.send2">
        <div :class="$style.sendtop22">
          <p :class="$style.titit">商品總金額</p>
          <p :class="$style.text">
            ＄ {{ trueTotal.total }}
          </p>
        </div>
        <div :class="$style.sendtop22">
          <p :class="$style.titit">運費總金額</p>
          <p :class="$style.text">
            ＄ {{ methodMoneyHtml }}
          </p>
        </div>
        <div :class="[$style.sendtop22, $style.sendno]">
          <p :class="$style.titit">優惠總金額</p>
          <p :class="$style.text">
            - ＄ {{ inputRedpoint +
                (pickCoupon >= 0 ? Math.floor(okPickCouponList[pickCoupon].discount_amount) : 0) }}
          </p>
        </div>
        <div :class="$style.send2">
          <div :class="[$style.sendtop22, $style.sendno]">
            <p :class="$style.orange">總付款金額</p>
            <div :class="$style.orange2">
              ＄ {{ total }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <coupon
      :listData="listData"
      :okPickCouponList="okPickCouponList"
      :pickCoupon="pickCoupon"
      v-if="comName === 'coupon'"
      @pickCoupon="inputFn('pickCoupon', $event)"
      @close="comName = null"
    />
    <paymethods
      v-if="comName === 'paymethods'"
      v-on="$listeners"
      @payment="payment = $event"
      @close="comName = null"
    />
    <redpotie
      :redPoint="redPoint"
      @inputRedpoint="inputFn('inputRedpoint', $event)"
      v-if="comName === 'redpotie'"
      @close="comName = null"
    />
  </div>
</template>

<script>
import coupon from '@/views/shopping/coupon.vue'
import paymethods from '@/views/shopping/paymethods.vue'
import redpotie from '@/views/shopping/redpotie.vue'
import { mapGetters } from 'vuex'
export default {
  props: {
    address: null
  },
  components: {
    coupon,
    paymethods,
    redpotie
  },
  data () {
    return {
      comName: null,
      payment: null
    }
  },
  watch: {
    pickCoupon () {
      this.$emit('pickCoupon', this.okPickCouponList[this.pickCoupon])
    },
    methodMoneyHtml () {
      this.$emit('methodMoney', this.methodMoneyHtml)
    }
  },
  computed: {
    ...mapGetters([
      'inputRedpoint',
      'redPoint',
      'pickCoupon',
      'couponBySku',
      'userCouponList',
      'trueTotal'
    ]),
    listData () {
      return JSON.parse(sessionStorage.getItem('buyList'))
    },
    okPickCouponList () {
      if (this.couponBySku && this.userCouponList) {
        const cmp = (o1, o2) => o1 === o2
        return this.couponBySku.filter(ele => this.userCouponList.some(ele2 => cmp(ele2.coupon_id, ele.coupon_id)))
      } else {
        return []
      }
    },
    methodMoneyHtml () {
      if (this.address) {
        if (this.trueTotal.total >= 990) {
          return 0
        } else if (this.address.street[2] === 'home') {
          switch (this.address.city) {
            case '連江縣':
            case '釣魚台':
            case '南海島':
            case '澎湖縣':
            case '金門縣':
              return 180
            default:
              return 100
          }
        } else {
          return 65
        }
      } else {
        return '未選寄送地址'
      }
    },
    total () {
      if (this.address) {
        return this.trueTotal.total + this.methodMoneyHtml - this.inputRedpoint -
        (this.pickCoupon >= 0 ? Math.floor(this.okPickCouponList[this.pickCoupon].discount_amount) : 0)
      } else {
        return this.trueTotal.total - this.inputRedpoint -
        (this.pickCoupon >= 0 ? Math.floor(this.okPickCouponList[this.pickCoupon].discount_amount) : 0)
      }
    }
  },
  methods: {
    async inputFn (key, value) {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'customJsonFn',
          action: 'updateCustomJson',
          key: key,
          value: String(value)
        })
        await this.$store.dispatch({
          type: 'customJsonFn',
          action: 'getCustomJson',
          key: key
        })
      } catch {
        this.$message.error('請確認填妥寄送資料')
      } finally {
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" module>
.hidden {
  display: none;
}

.rootinner {
  width: 1104px;
  margin: 0 auto;
}

.btmout {
  width: 100%;
}

.bordertitle {
  position: relative;
  padding-bottom: 15px;
  &::after {
    content: '';
    width: 25px;
    height: 5px;
    background: #ff5722;
    position: absolute;
    top: 25px;
    left: 0;
  }
}

.listtitle {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  display: grid;
  grid-template-columns: 690px 1fr 1fr 1fr;
  grid-template-rows: 66px;
  align-items: center;
  padding-top: 65px;
  border-bottom: 2px solid #C4C4C4;
  margin-bottom: 20px;
}

.border {
  display: grid;
  grid-template-columns: 347px 1fr 1fr;
  grid-template-rows: 50px;
  grid-column-gap: 74px;
  font-size: 14px;
  align-items: center;
}

.inputout {
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 52px;
  background: #fff;
  z-index: 5;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.listinner {
  box-sizing: border-box;
  padding-left: 26px;
  width: 100%;
  display: grid;
  grid-template-columns: 664px 1fr 1fr 1fr;
  grid-template-rows: 50px;
  padding-bottom: 12px;
  align-items: center;
}

.check {
  display: flex;
  justify-content: center;
  align-items: center;
}

.titit2 {
  color: #000;
}

.img {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: contain;
}

.inner {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  font-size: 14px;
  color: #000000;
}

.overflowout {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 50px;
  height: 28px;
  border-radius: 5px;
  background-color: #ffc000;
}

.innerbtmm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.pluin {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 5;
}

.title2 {
  font-size: 14px;
  color: #000;
  padding: 20px 0;
}

.inputstyle {
  font-size: 14px;
  text-align: left;
  border: none;
  padding: 9px 16px;
  box-sizing: border-box;
  height: 40px;
  flex: 1;
  background-color: #f7f7f7;
  resize: none;
}

.send {
  border-bottom: 1px solid #e2e2e2;
  padding: 0px 26px 0 0;
  box-sizing: border-box;
}

.send2 {
  padding: 20px 0 8px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.out {
  margin-top: 8px;
  border-top: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  display: flex;
  align-items: center;
  height: 96px;
  font-size: 14px;
  padding: 26px;
  box-sizing: border-box;
}

.sendtop {
  color: #989898;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 15px 0;
  border-bottom: 1px solid #e2e2e2;
  font-size: 15px;

  &.sendno {
    padding-bottom: 0;
  }
}

.sendtop22 {
  min-width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-bottom: 20px;

  &.sendno {
    padding-bottom: 0;
  }
}

.text {
  font-size: 14px;
}

.shop {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-rows: auto;
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.textout {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  line-height: 20px;
}

.text2 {
  color: #828282;
}

.lineee {
  cursor: pointer;
  color: #828282;
  text-decoration: underline;
  padding-left: 40px;
}

.orange {
  font-weight: 500;
  font-size: 18px;
  color: #ff6100;
}

.orange2 {
  padding-left: 10px;
  font-weight: 500;
  font-size: 40px;
  color: #ff6100;
}

.sendtop2 {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #000000;
}

.orangebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff6100;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  border: solid 1px #ff6100;
  margin-right: 48px;
  cursor: pointer;
}

.orangebtn2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ff6100;
  border: solid 1px #ff6100;
}

.orangebtn2no {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ccc;
  border: solid 1px #ccc;
}

.sendtop3 {
  padding: 37px 0 80px 0;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 16px;
}

.number {
  font-size: 14px;
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

.centertext {
  justify-self: center;
}

@media (min-width: 768px) and (max-width: 1365px) {
  .rootinner {
    width: 720px;
    height: 100%;
    margin: 0 auto;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .top {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 20px 20px 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 35px 1fr 35px 1fr 35px;
    grid-template-rows: 35px;
    width: 80%;
  }

  .icon2 {
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(130, 130, 130, 0.1);
  }

  .lineout {
    align-items: center;
    display: flex;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sendtop22 {
    padding: 5px 20px 5px 20px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 56px;
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    background: #ccc;
    width: 100%;
    height: 56px;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    box-sizing: border-box;
  }

  .out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    border-bottom: 1px solid #C4C4C4;
  }

  .title {
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;
  }

  .sendtop {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .shop {
    font-size: 13px;
    display: flex;
    margin-bottom: 20px;
  }

  .textout {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    min-height: 40px;
  }

  .text2 {
    font-size: 12px;
    color: #828282;
  }

  .titit {
    font-size: 13px;
    color: #000000;
  }

  .lineee {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 20px;
  }

  .orange {
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
  }

  .orange2 {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 18px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 50px;
  }

  .orangebtn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
  }

  .sendtop3 {
    padding: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: #000000;
  }

  .number {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .rootinner {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .lineout {
    align-items: center;
    display: flex;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 56px;
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    background: #ccc;
    width: 100%;
    height: 56px;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    box-sizing: border-box;
  }

  .out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    border-bottom: 1px solid #C4C4C4;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .title {
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;
  }

  .sendtop {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .shop {
    font-size: 13px;
    display: flex;
    margin-bottom: 20px;
  }

  .textout {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    min-height: 40px;
  }

  .text2 {
    font-size: 12px;
    color: #828282;
  }

  .titit {
    font-size: 13px;
    color: #000000;
  }

  .lineee {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 20px;
  }

  .orange {
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
  }

  .orange2 {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 18px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .sendtop3 {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: #000000;
  }

  .sendtop22 {
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    &.sendno {
      padding-bottom: 0;
    }
  }

  .sendtop22 {
    width: 100%;
    min-width: unset;
  }

  .send2 {
    width: 100%;
  }

  .number {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
  }
}
</style>
