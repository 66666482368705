<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="[$style.bg, 'animate__animated', 'animate__fadeInDownBig', 'animate__faster']">
      <div :class="$style.top">
        <p :class="$style.title">取消訂單</p>
        <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
      </div>
      <div :class="$style.one">
        <div :class="$style.green">1</div>
        <div :class="$style.title3">退貨商品</div>
        <div :class="$style.px12">訂單編號：{{ data.orderId }}</div>
      </div>
      <productlist :listData="data.products"/>
      <div :class="$style.one">
        <div :class="$style.green">2</div>
        <div :class="$style.title3">取消原因</div>
      </div>
      <div :class="$style.btn">
        <div :class="$style.btnn" @click="textFn">修改訂單內容</div>
        <div :class="$style.btnn" @click="textFn2">對商品有疑慮</div>
      </div>
      <textarea
        :class="$style.inputstyle"
        v-model="canceltext"
        placeholder="請填寫訂單取消原因"
      />
      <div :class="$style.one">
        <div :class="$style.green">3</div>
        <div :class="$style.title3">現金退還</div>
      </div>
      <p :class="$style.textpoint">點數：
        {{ data.getredPoint - data.redPoint }} </p>
      <div
        :class="[$style.loginbtn, { [$style.no]: !Boolean(canceltext) }]"
        @click="canceltext ? cancelFn() : ''"
      >
        送出
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import productlist from '@/components/user/listpage/productlist.vue'
export default {
  components: {
    productlist
  },
  props: {
    data: Object
  },
  data () {
    return {
      canceltext: ''
    }
  },
  methods: {
    async cancelFn () {
      if (this.canceltext && this.canceltext !== '') {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'cancelOrder',
            orderId: this.data.orderId,
            reason: this.canceltext
          })
          await this.$store.dispatch({
            type: 'getOrdersList',
            userId: this.$route.params.userId,
            currentPage: this.$route.params.current
          })
          this.$emit('success')
          this.$emit('close')
        } catch {
          this.$message.error('取消失敗，請從重新動作或是通知客服！')
        } finally {
          this.$emit('loading', false)
        }
      } else {
        this.$message.error('尚未填寫原因')
      }
    },
    textFn () {
      this.canceltext = '不好意思，忙不小心下錯訂單，我將會重新下單。'
    },
    textFn2 () {
      this.canceltext = '商品與介紹不相同。'
    }
  }
}
</script>

<style lang="scss" module>
.rootinner {
  z-index: 4;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px 0 50px 0;
  overflow: auto;
}

.bg {
  margin: auto 0;
  padding: 28px;
  box-sizing: border-box;
  width: 740px;
  height: auto;
  border-radius: 10px;
  border: solid 2px #00d77f;
  background-color: #ffffff;
}

.img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.title2 {
  color: #888;
  font-size: 26px;
  font-weight: 900;
  cursor: pointer;
}

.innernumber {
  align-self: center;
  justify-self: center;
}

.top {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.thingout {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 400px 1fr 1fr 1fr;
  grid-template-rows: 60px;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 55px;
}

.imm {
  display: grid;
  grid-template-columns: 60px 324px;
  grid-template-rows: 60px;
  grid-column-gap: 16px;
}

.textthing {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  font-size: 14px;
}

.textinn {
  font-size: 14px;
  align-self: center;
}

.moneyout {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.number {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.truemoney {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.one {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 24px 66px 1fr;
  grid-column-gap: 16px;
  grid-template-rows: 24px;
}

.green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: #00d77f;
}

.title3 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.px12 {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
}

.inputstyle {
  font-size: 14px;
  text-align: left;
  border: none;
  padding: 28px;
  box-sizing: border-box;
  width: 593px;
  height: 160px;
  margin: 20px 44px 0 44px;
  background-color: #f7f7f7;
  color: #828282;
  resize: none;
}

.btn {
  display: flex;
  flex-wrap: wrap;
  padding: 0 39px;
}

.btnn {
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 999rem;
  background-color: rgba(0, 215, 127, 0.3);
  margin-right: 12px;
  font-size: 14px;
  color: #404040;
}

.textpoint {
  font-size: 14px;
  padding-left: 40px;
}

.loginbtn {
  cursor: pointer;
  width: 240px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 999rem;
  color: #fff;
  display: flex;
  margin: 35px auto 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #00d77f;

  &.no {
    cursor: not-allowed;
    background-color: #aaa;
  }
}

.titleinner {
  padding-bottom: 12px;
  font-size: 14px;
  border-bottom: 1px solid #C4C4C4;
  display: grid;
  grid-template-columns: 400px 1fr 1fr 1fr;
  padding: 10px 55px;
}

@media (max-width: 1365px) {
  .bg {
    padding: 15px;
    max-width: 720px;
    width: 80%;
  }

  .one {
    padding: 10px 0;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .title2 {
    color: #888;
    font-size: 26px;
    font-weight: 400;
  }

  .thingout {
    grid-template-columns: 48px 1fr 0.3fr;
    grid-auto-rows: minmax(20px, auto);
    grid-gap: 10px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
  }

  .textthing {
    width: 100%;
    height: 100%;
    grid-template-rows: 1.8fr 1fr;
  }

  .textinn {

    &.format {
      font-size: 13px;
      color: #828282;
    }
  }

  .green {
    font-size: 12px;
    font-weight: 400;
  }

  .title3 {
    font-size: 14px;
  }

  .px12 {
    align-items: center;
    font-size: 12px;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    border: none;
    padding: 10px;
    width: 100%;
    height: 100px;
    margin: 0;
  }

  .btn {
    overflow-x: auto;
    display: flex;
    padding: 0
  }

  .btnn {
    padding: 5px 10px;
    margin: 0 0 10px 10px;
    font-size: 12px;
  }

  .textpoint {
    font-size: 13px;
  }

  .loginbtn {
    margin: 40px auto 10px auto;
    max-width: 250px;
    width: 80%;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
  }

  .imm {
    display: contents;
  }
}
</style>
