import { requestApi } from './request'

export async function onlyAction (method, action, token) {
  const { data } = await requestApi[method]('/webapi.php', {
    action: action
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

export async function productReviews (method, action, productId, pageSize, currentPage, token) {
  const { data } = await requestApi[method]('/webapi.php', {
    action: action,
    productId: productId,
    pageSize: pageSize,
    currentPage: currentPage
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

export async function customJsonApi (action, key, value, token) {
  const { data } = await requestApi.post('/webapi.php', {
    action: action,
    key: key,
    value: value
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}
