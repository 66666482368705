<template>
  <div :class="$style.root">
    <div class="topline"/>
    <div :class="$style.rootinner">
      <progressshopping :step="2"/>
      <listshopping/>
      <div :class="$style.out">
        <p :class="$style.title">留言：</p>
        <textarea
          :class="$style.inputstyle"
          placeholder="請留言"
          v-model="tradeDesctext"
        />
      </div>
      <sendaddress
        @loading="loading = $event"
        @billing="billingData = $event"
        @address="address = $event"
      />
      <payandother
        :address="address"
        @payment="payment = $event"
        @loading="loading = $event"
        @pickCoupon="pickCoupon = $event"
        @methodMoney="methodMoney = $event"
      />
      <div :class="$style.sendtop3">
        <div
          :class="$style.orangebtn"
          @click="backCarFn"
        >
          返回購物車
        </div>
        <div
          :class="billingData && payment && address
          ? $style.orangebtn2
          : $style.orangebtn2no"
          @click="buyListFn(routerName === 'groupshoppinginfo' ? 'group' : 'common')">
          結帳
        </div>
      </div>
    </div>
    <loadingCom v-if="loading"/>
  </div>
</template>

<script>
import listshopping from '@/components/shopping/listshopping.vue'
import progressshopping from '@/components/shopping/progress.vue'
import sendaddress from '@/components/shopping/sendaddress.vue'
import loadingCom from '@/components/outpu/loading2.vue'
import payandother from '@/components/shopping/payandother.vue'
import shopping from '@/components/shopping/js/shopping'
import { mapGetters } from 'vuex'
import { toUserShippingPage, toUserGropeShipPage } from '@/js'
export default {
  mixins: [
    shopping
  ],
  components: {
    progressshopping,
    listshopping,
    sendaddress,
    loadingCom,
    payandother
  },
  data () {
    return {
      billingData: null,
      payment: null,
      tradeDesctext: '',
      pickCoupon: null,
      methodMoney: null,
      apistate: null
    }
  },
  watch: {
    async payMoneyGo (value) {
      this.$message('即將轉跳付款畫面，請勿關閉視窗！')
      try {
        this.clearBuyList()
        document.body = value
        document.getElementById('__ecpayForm').submit()
      } catch {
        document.body = value
        document.getElementById('__ecpayForm').submit()
      }
    }
  },
  computed: {
    ...mapGetters([
      'csvData',
      'LastTimeData',
      'commonUserInfo',
      'inputRedpoint'
    ]),
    address () {
      if (this.LastTimeData) {
        return this.commonUserInfo.addressList.find(ele => ele.id === this.LastTimeData)
      } else {
        return false
      }
    },
    payMoneyGo () {
      const parser = new DOMParser()
      const url = this.$store.state.shopping.payMoneyGo
      if (url) {
        return parser.parseFromString(url, 'text/html').body
      } else {
        return undefined
      }
    }
  },
  methods: {
    async clearBuyList () {
      const listdata = JSON.parse(sessionStorage.getItem('buyList'))
      for (let i = 0; i < listdata.length; i++) {
        await this.$store.dispatch({
          type: 'itemAction',
          action: 'delItem',
          catchList: false,
          bodyObj: {
            idx: listdata[i].info.idxNumber
          }
        })
      }
    },
    backCarFn () {
      const url = this.$route.params
      const urlQuery = this.$route.query
      if (this.routerName === 'groupshoppinginfo') {
        toUserGropeShipPage('groupshoplist', url.userId, url.carId, url.type, urlQuery.openId, urlQuery.groupId)
      } else {
        toUserShippingPage('shoppinglistto', url.userId, url.carId)
      }
    },
    async buyListFn (value) {
      if (this.billingData && this.payment && this.address) {
        this.loading = true
        await this.userCouponFunction(value)
        await this.clearBuyList()
      } else {
        this.$notify.error('請確認資料是否正確。')
      }
    }
  },
  async created () {
    try {
      await Promise.all([
        this.$store.dispatch({
          type: 'customJsonFn',
          action: 'getCustomJson',
          key: 'pickCoupon'
        }),
        this.$store.dispatch({
          type: 'customJsonFn',
          action: 'getCustomJson',
          key: 'inputRedpoint'
        }),
        this.$store.dispatch({
          type: 'userCouponAction',
          action: 'getMyCoupon'
        }),
        this.$store.dispatch({
          type: 'getPointNumber',
          action: 'getMyPoint'
        })
      ])
    } catch {
      this.$emit.error('購物車載入失敗，請稍後在進行購物。')
      this.$router.push({ name: 'home' })
    } finally {
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" module>
.hidden {
  display: none;
}

@media (min-width: 1366px) {
  .rootinner {
    width: 1104px;
    margin: 0 auto;
  }

  .btmout {
    width: 100%;
  }

  .listtitle {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    display: grid;
    grid-template-columns: 690px 1fr 1fr 1fr;
    grid-template-rows: 66px;
    align-items: center;
    padding-top: 65px;
    border-bottom: 2px solid #C4C4C4;
    margin-bottom: 20px;
  }

  .border {
    display: grid;
    grid-template-columns: 347px 1fr 1fr;
    grid-template-rows: 50px;
    grid-column-gap: 74px;
    font-size: 14px;
    align-items: center;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .listinner {
    box-sizing: border-box;
    padding-left: 26px;
    width: 100%;
    display: grid;
    grid-template-columns: 664px 1fr 1fr 1fr;
    grid-template-rows: 50px;
    padding-bottom: 12px;
    align-items: center;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: contain;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 50px;
    height: 28px;
    border-radius: 5px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .title2 {
    font-size: 14px;
    color: #000;
    padding: 20px 0;
  }

  .inputstyle {
    font-size: 14px;
    text-align: left;
    border: none;
    padding: 9px 16px;
    box-sizing: border-box;
    height: 40px;
    flex: 1;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 28px 26px;
    box-sizing: border-box;
  }

  .send2 {
    border-bottom: 1px solid #ccc;
    padding: 28px 26px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .out {
    margin-top: 8px;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    align-items: center;
    height: 96px;
    font-size: 14px;
    padding: 26px;
    box-sizing: border-box;
  }

  .sendtop {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-bottom: 20px;

    &.sendno {
      padding-bottom: 0;
    }
  }

  .sendtop22 {
    min-width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-bottom: 20px;

    &.sendno {
      padding-bottom: 0;
    }
  }

  .shop {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }

  .textout {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    line-height: 20px;
  }

  .text2 {
    color: #828282;
  }

  .lineee {
    cursor: pointer;
    color: #828282;
    text-decoration: underline;
    padding-left: 40px;
  }

  .orange {
    font-weight: 500;
    font-size: 18px;
    color: #ff6100;
  }

  .orange2 {
    padding-left: 10px;
    font-weight: 500;
    font-size: 40px;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .sendtop3 {
    padding: 37px 0 80px 0;
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    font-size: 16px;
  }

  .number {
    font-size: 14px;
    overflow : hidden;
    text-overflow : ellipsis;
    white-space : nowrap;
  }

  .centertext {
    justify-self: center;
  }
}
@media (min-width: 768px) and (max-width: 1365px) {
  .rootinner {
    width: 720px;
    height: 100%;
    margin: 0 auto;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .top {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 40px 20px 20px 20px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 35px 1fr 35px 1fr 35px;
    grid-template-rows: 35px;
    width: 80%;
  }

  .icon2 {
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(130, 130, 130, 0.1);
  }

  .lineout {
    align-items: center;
    display: flex;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sendtop22 {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 56px;
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    background: #ccc;
    width: 100%;
    height: 56px;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    box-sizing: border-box;
  }

  .out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    border-bottom: 1px solid #C4C4C4;
  }

  .title {
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;
  }

  .sendtop {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .shop {
    font-size: 13px;
    display: flex;
    margin-bottom: 20px;
  }

  .textout {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    min-height: 40px;
  }

  .text2 {
    font-size: 12px;
    color: #828282;
  }

  .titit {
    font-size: 13px;
    color: #000000;
  }

  .lineee {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 20px;
  }

  .orange {
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
  }

  .orange2 {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 18px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 50px;
  }

  .orangebtn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    width: 200px;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
  }

  .sendtop3 {
    padding: 30px 0 20px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: #000000;
  }

  .number {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .rootinner {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  .lineout {
    align-items: center;
    display: flex;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputout {
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    background: #fff;
    z-index: 5;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 56px;
    grid-gap: 17px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
  }

  .listinner {
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr 60px;
    grid-auto-rows: 100%;
    grid-gap: 2%;
  }

  .check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img {
    background: #ccc;
    width: 100%;
    height: 56px;
  }

  .inner {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    font-size: 14px;
    color: #000000;
  }

  .overflowout {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
  }

  .innerbtmm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    text-align: left;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    resize: none;
  }

  .send {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    box-sizing: border-box;
  }

  .out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 160px;
    border-bottom: 1px solid #C4C4C4;
  }

  .orangebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6100;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    margin-right: 48px;
    cursor: pointer;
  }

  .orangebtn2 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .orangebtn2no {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    background-color: #ccc;
    border: solid 1px #ccc;
  }

  .title {
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;
  }

  .sendtop {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .shop {
    font-size: 13px;
    display: flex;
    margin-bottom: 20px;
  }

  .textout {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    min-height: 40px;
  }

  .text2 {
    font-size: 12px;
    color: #828282;
  }

  .titit {
    font-size: 13px;
    color: #000000;
  }

  .lineee {
    font-size: 13px;
    color: #828282;
    text-decoration: underline;
    padding-left: 20px;
  }

  .orange {
    font-size: 14px;
    font-weight: 400;
    color: #ff6100;
  }

  .orange2 {
    font-size: 20px;
    font-weight: 400;
    color: #ff6100;
  }

  .sendtop2 {
    padding: 18px 8px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #000000;
  }

  .sendtop3 {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    font-size: 13px;
    color: #000000;
  }

  .sendtop22 {
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;

    &.sendno {
      padding-bottom: 0;
    }
  }

  .number {
    display: flex;
    align-items: flex-end;
    font-size: 13px;
  }
}
</style>
