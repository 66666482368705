import { request } from '@/request/request'

const bannerJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    banner: null
  },
  mutations: {
    banner (state, payload) {
      state.banner = payload.banner
    }
  },
  actions: {
    //  BANNER001 = 首頁
    //  BANNER002 = 精選商品
    //  BANNER003 = KOL
    //  BANNER004 = 拼團
    //  BANNER005 = 員購
    //  BANNER006 = 優惠精選商品
    async getBanner (store, payload) {
      let bannerValue
      switch (payload.banner) {
        case (undefined):
          bannerValue = 'BANNER001'
          break
        case ('common'):
          bannerValue = 'BANNER002'
          break
        case ('kol'):
          bannerValue = 'BANNER003'
          break
        case ('group'):
          bannerValue = 'BANNER004'
          break
        case ('employee'):
          bannerValue = 'BANNER005'
          break
        case ('discount'):
          bannerValue = 'BANNER006'
          break
      }
      const url = `/products/${bannerValue}`
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'banner', banner: response.data })
    }
  },
  getters: {
    imgListinner: (state) => {
      const url = (state.banner
        ? state.banner
        : undefined)
      const urlLink = (url
        ? url.media_gallery_entries.map((ele, i) => {
          const img = ele.file
          const imgUrl = url.custom_attributes.find(ele => ele.attribute_code === ('url' + `${i}`))
            ? url.custom_attributes.find(ele => ele.attribute_code === ('url' + `${i}`)).value
            : 'NaN'
          return {
            img,
            imgUrl
          }
        })
        : [])
      return urlLink
    }
  }
}

export default {
  bannerJs
}
