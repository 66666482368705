<template>
  <div :class="$style.root">
    <userinfo/>
    <div :class="$style.inner" v-if="favoriteList.total > 0">
      <div :class="$style.out">
        <div :class="$style.toptitle" v-if="webwatch">
          <div>商品品項</div>
          <div>價格</div>
          <div>操作</div>
        </div>
        <div
          :class="$style.all"
          v-for="(item, i) in favoriteList.list"
          :key="i"
        >
          <div
            :class="$style.imgout"
          >
            <div
              :class="$style.pic"
              :style="{ backgroundImage: `url(${ item.image })` }"
              @click="productRouter(i)"
            >
              <div
                :class="$style.stock"
                v-if="item.stock === false || item.status === 2"
              >
                {{ item.stock === false ? '已售完' : '已下架' }}
              </div>
            </div>
            <div
              :class="[$style.text, item.stock === true ? $style.text : $style.textno]"
              @click="productRouter(i)"
            >
              {{ item.skuname }}
            </div>
          </div>
          <div :class="$style.price">
            <div :class="[$style.price1, item.stock === true ? $style.price1 : $style.textno]">
              {{ item.newPriceNum }}
            </div>
            <div :class="[$style.price2, item.stock === true ? $style.price2 : $style.textno]">
              {{ item.oldPriceNum }}
            </div>
          </div>
          <div
            @click="delFn(i)"
            :class="$style.cancle"
          >
            取消
          </div>
        </div>
      </div>
    </div>
    <div
      :class="['noany', 'noanythingout']"
      v-if="favoriteList.total <= 0"
    >
      <img
        src="../../assets/img/like.svg"
        :class="['noanyimg', 'imgmargin']"
      />
      目前沒有任何最愛唷！
    </div>
    <page
      v-show="favoriteList.total > 0"
      :pageNumber="favoriteList.total"
      :pageSize="8"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import userinfo from '@/components/user/info.vue'
import { mapGetters } from 'vuex'
import { productPageFn, pageFunction } from '@/js'
import '@/css/image.scss'
import '@/css/noany.scss'
export default {
  components: {
    page,
    userinfo
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'favoriteList'
    ])
  },
  methods: {
    productRouter (listNumber) {
      const data = this.favoriteList.list[listNumber]
      const product = { tag: data.tag, momSku: data.momsku, parentId: data.momskuid }
      productPageFn(product)
    },
    async pageFn (current) {
      this.$emit('loading', true)
      try {
        await pageFunction('favoriteAction', 'getWishlist', current, this.$route.params.userId)
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    },
    delFn (i) {
      this.$store.dispatch({
        type: 'favoriteAction',
        action: 'delWishlist',
        noPage: true,
        bodyObj: {
          sku: this.favoriteList.list[i].sku,
          idx: this.favoriteList.list[i].idxNumber
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.nolist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 20px;
  min-height: 560px;
  color: #ccc;
}

.img {
  opacity: .7;
  width: 150px;
  height: 200px;
}

@media (min-width: 1366px) {
  .out {
    min-height: 560px;
  }

  .toptitle {
    padding: 0 21px;
    box-sizing: border-box;
    font-size: 14px;
    display: grid;
    align-items: center;
    grid-template-columns: 578px 1fr 1fr;
    grid-template-rows: 64px;
    border-top: 2px solid #C4C4C4;
    border-bottom: 2px solid #C4C4C4;
  }

  .stock {
    background: #828282;
    border-radius: 999rem;
    padding: 2px 5px;
    color: #fff;
    font-size: 12px;
  }

  .all {
    display: grid;
    grid-template-columns: 564px 1fr 1fr;
    grid-template-rows: 84px;
    padding: 0 21px 0 34px;
    box-sizing: border-box;
    border-bottom: 1px solid #C4C4C4;
    align-items: center;
  }

  .imgout {
    cursor: pointer;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-template-rows: 60px;
    grid-column-gap: 24px;
    align-items: center;
  }

  .pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .text {
    padding-right: 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
  }

  .price1 {
    font-size: 14px;
    color: #000000;
  }

  .price2 {
    font-size: 14px;
    color: #000000;
    text-decoration: line-through;
  }

  .cancle {
    font-size: 14px;
    text-decoration-line: underline;
    color: #828282;
    cursor: pointer;
  }

  .textno {
    color: #E7E7E7;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .inner {
    min-height: calc(100vh - 509px);
  }

  .stock {
    background: #828282;
    border-radius: 999rem;
    padding: 2px 5px;
    color: #fff;
    font-size: 12px;
  }

  .out {
    margin: 15px 15px 0 15px;
    box-sizing: border-box;
    border-top: 1px solid #E5E5E5;
  }

  .all {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
  }

  .pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 16px;
  }

  .text {
    flex: 1;
    min-height: 40px;
    font-size: 14px;
    color: #000000;
    margin-right: 15px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price1 {
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .price2 {
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #000000;
    text-decoration: line-through;
    margin-right: 22px;
  }

  .price {
    width: 75px;
  }

  .cancle {
    font-size: 13px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #828282;
    cursor: pointer;
  }
}

@media (max-width: 1365px) {
  .inner {
    width: 100%;
  }

  .out {
    margin: 15px 15px 0 15px;
    box-sizing: border-box;
    border-top: 1px solid #E5E5E5;
  }

  .all {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
  }

  .pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 47px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 16px;
  }

  .text {
    flex: 1;
    min-height: 40px;
    font-size: 14px;
    color: #000000;
    margin-right: 15px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price1 {
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .price2 {
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #000000;
    text-decoration: line-through;
    margin-right: 22px;
  }

  .price {
    width: 50px;
  }

  .cancle {
    font-size: 13px;
    letter-spacing: 0.3px;
    text-decoration-line: underline;
    color: #828282;
    cursor: pointer;
  }

  .stock {
    background: #828282;
    border-radius: 999rem;
    padding: 2px 5px;
    color: #fff;
    font-size: 12px;
  }

  .imgout {
    display: contents;
  }
}

@media (max-width: 767px) {
  .inner {
    min-height: calc(100vh - 289px);
  }
}
</style>
