<template>
  <div :class="$style.root">
    <user :class="$style.user"/>
    <router-view :class="$style.main" @loading="$emit('loading', $event)"/>
  </div>
</template>

<script>
import user from '@/components/user/user.vue'
export default {
  components: {
    user
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .root {
    margin: 72px 0 80px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .user {
    margin-top: 88px;
    width: 202px;
    height: auto;
    margin-right: 78px;
  }

  .main {
    width: 780px;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-bottom: 0;
  }

  .user {
    margin-top: 0;
    z-index: 1;
    position: fixed;
    top: 80px;
    width: 100%;
    height: 50px;
  }

  .main {
    box-sizing: border-box;
    padding: 65px 15px 15px 15px;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 720px;
    min-height: calc(100vh - 258px);
  }
}

@media (max-width: 767px) {
  .root {
    padding: 0;
    border-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .user {
    margin-top: 0;
    z-index: 1;
    position: fixed;
    top: 80px;
    width: 100%;
    height: 39px;
  }

  .main {
    box-sizing: border-box;
    padding: 50px 15px 15px 15px;
    width: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    min-height: calc(100vh - 58px);
  }
}
</style>
