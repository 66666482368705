<template>
  <div :class="$style.root">
    <div class="listoutside">
      <p class="listtitle">優惠券</p>
      <div
        v-for="(item, i) in productCouponList" :key="i"
        @click.self.prevent="openFn(i)"
        :class="[$style.yellow, {[$style.expired]: item.isExpired }, {[$style.get]: item.isGet }]"
      >
        {{ item.name }}
        <i
          @click.self.prevent="openFn(i)"
          :class="open === i ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          style="padding-left: 5px;"
        />
        <ticket
          @close="open = false"
          :data="productCouponList[open]"
          v-if="webwatch && open === i"
          :class="$style.ticket"
        />
      </div>
      <div
        :class="$style.nocoupon"
        v-if="productCouponList.length <= 0"
      >
        目前沒有用於此商品的優惠券唷！
      </div>
    </div>
    <ticket
      @close="open = false"
      :data="productCouponList[open]"
      v-if="!webwatch && (open || open === 0)"
      :class="$style.ticket"
    />
  </div>
</template>

<script>
import ticket from '@/views/common/ticket.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    ticket
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    ...mapGetters([
      'productCouponList'
    ])
  },
  methods: {
    openFn (i) {
      if (this.open === i) {
        this.open = false
      } else {
        this.open = i
      }
    }
  },
  async created () {
    if (this.online && this.online !== 'kol') {
      await Promise.all([
        this.$store.dispatch({
          type: 'getCouponList',
          action: 'getCouponBySku',
          bodyObj: {
            product: [this.$route.params.skuId]
          },
          typeList: 'forProduct'
        }),
        this.$store.dispatch({
          type: 'userCouponAction',
          action: 'getMyCoupon'
        })
      ])
    }
  }
}
</script>

<style lang="scss" module>
.nocoupon {
  color: #AAA;
  font-size: 14px;
}

@keyframes cssA {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 1366px) {
  .yellow {
    position: relative;
    padding: 2px 10px;
    border-radius: 999rem;
    background: #ffc000;
    font-size: 14px;
    color: #ffffff;
    margin-right: 24px;
    cursor: pointer;
    margin-top: 10px;

    &.expired {
      background: #ff6100;
    }

    &.get {
      background: #ccc;
    }
  }

  .ticket {
    position: absolute;
    margin-top: 10px;
    width: 400px;
    z-index: 1;
    animation-name: cssA;
    animation-duration: 0.3s;
  }
}

@media (max-width: 1365px) {
  .yellow {
    padding: 2px 10px;
    border-radius: 999rem;
    background-color: #ffc000;
    font-size: 12px;
    color: #ffffff;
    margin-right: 7px;
    margin-top: 10px;

    &.expired {
      background: #ff6100;
    }

    &.get {
      background: #ccc;
    }
  }

  .ticket {
    width: 100%;
    margin-top: 10px;
    animation-name: cssA;
    animation-duration: 0.3s;
  }
}
</style>
