import { webApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'

const favoriteJs = {
  state: {
    favoriteData: null,
    favoriteBoolean: undefined
  },
  mutations: {
    // 我的最愛
    favoriteData (state, payload) {
      state.favoriteData = payload.favoriteData
    },
    favoriteBoolean (state, payload) {
      state.favoriteBoolean = payload.favoriteBoolean
    }
  },
  actions: {
    async favoriteAction ({ commit }, payload) {
      // addWishlist updateWishlist delWishlist getWishlist getWishlistBySku
      const token = loginInfoJs.loginInfoJs.state.userToken
      let bodyObj
      if (payload.noPage) {
        if (payload.bodyObj) {
          bodyObj = payload.bodyObj
        }
      } else {
        bodyObj = { pageSize: 8, currentPage: payload.currentPage }
      }
      const data = await webApiFunciton(payload.action, token, bodyObj)
      payload.action === 'getWishlistBySku'
        ? commit({ type: 'favoriteBoolean', favoriteBoolean: data })
        : commit({ type: 'favoriteData', favoriteData: data })
    }
  },
  getters: {
    favoriteList: (state) => {
      if (state.favoriteData && Number(state.favoriteData.total) > 0) {
        const total = Number(state.favoriteData.total)
        const list = state.favoriteData.items.map(ele => {
          const sku = ele.sku
          const oldPrice = ele.price
          const newPrice = (ele.custom_attributes.find(ele => ele.attribute_code === 'special_price')
            ? ele.custom_attributes.find(ele => ele.attribute_code === 'special_price').value
            : oldPrice)
          const tag = ele.parent_sell_type
          const momsku = ele.parent_sku
          const momskuid = ele.parent_id
          const oldPriceNum = Math.floor(Number(oldPrice))
          const newPriceNum = Math.floor(Number(newPrice))
          const idxNumber = ele.idx
          const skuname = ele.name
          const image = ele.image
            ? ('https://shop.cvicloud-srv.net/pub/media/catalog/product/' + ele.image)
            : require('@/assets/img/cvimall_comingsoon_small.png')
          const stock = ele.extension_attributes.stock_item.is_in_stock
          return {
            sku,
            oldPriceNum,
            newPriceNum,
            idxNumber,
            image,
            stock,
            tag,
            momsku,
            momskuid,
            skuname
          }
        })
        return { total, list }
      } else {
        return { total: 0, list: [] }
      }
    },
    favoriteBoolean: (state) => {
      return state.favoriteBoolean
    }
  }
}

export default {
  favoriteJs
}
