<template>
  <div :class="$style.root">
    <infow v-if="infoww" @close="infoww = false"/>
    <div :class="$style.out">
      <p :class="$style.text">團主獎金</p>
      <div :class="$style.right3">
        <div :class="$style.righttop">
          <div :class="$style.titlecircle">賺</div>
          <div :class="$style.innercircle">$ {{ groupData.bonus }}</div>
        </div>
        <div :class="$style.righttop">
          <div :class="$style.titlecircle">團</div>
          <div :class="$style.innercircle">{{ groupData.amount }} 人成團</div>
        </div>
        <div :class="$style.info" @click="infoww = !infoww">
          拼團怎麼玩
          <i :class="['el-icon-warning-outline', $style.iconstyleinfo]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infow from '@/components/group/information.vue'
import { overflowFunction } from '@/js'
export default {
  props: {
    groupData: Object
  },
  components: {
    infow
  },
  data () {
    return {
      infoww: false
    }
  },
  watch: {
    infoww () {
      overflowFunction(this.infoww)
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .out {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-auto-rows: 28px;
    grid-column-gap: 23px;
    width: 100%;
    align-items: center;
  }

  .titlecircle {
    width: 24px;
    height: 24px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
  }

  .innercircle {
    font-weight: bold;
    font-size: 14px;
    color: #FF6100;
    margin-right: 24px;
  }

  .righttop {
    display: flex;
    align-items: center;
  }

  .info {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #727272;
    font-size: 14px;
  }

  .right3 {
    display: flex;

  }

  .iconstyleinfo {
    margin-left: 5px;
    color: #FF6100;
    font-size: 18px;
  }
}

@media (max-width: 1365px) {
  .titlecircle {
    width: 22px;
    height: 22px;
    background: #FF6100;
    border-radius: 999rem;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .innercircle {
    font-weight: bold;
    font-size: 14px;
    color: #FF6100;
    margin-right: 30px;
  }

  .righttop {
    display: flex;
    align-items: center;
  }

  .info {
    display: flex;
    align-items: center;
    color: #727272;
    font-size: 14px;
  }

  .right3 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100px;
  }

  .iconstyleinfo {
    margin-left: 5px;
    color: #FF6100;
    font-size: 18px;
  }

  .out {
    display: grid;
    grid-template-columns: 58px 1fr;
    grid-auto-rows: minmax(28px, auto);
    grid-column-gap: 32px;
    width: 100%;
    padding-top: 27px;
    align-items: center;

    &.out1 {
      position: relative;
    }
  }
}

@media (max-width: 437px) {
  .info {
    margin-top: 10px;
  }
}
</style>
