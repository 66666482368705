<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.titletitle" v-if="webwatch">
        {{ title }}
      </div>
      <div :class="$style.selectoutside">
        <div :class="$style.selectout" v-for="(item ,i) in listProblem" :key="i">
          <div :class="$style.title"
            @click="activeFn(i)"
          >
            {{ item.title }}
            <i class="el-icon-arrow-up"
              v-if="watch === true && active === i"
            />
            <i class="el-icon-arrow-down" v-else/>
          </div>
          <div
            :class="$style.text"
            v-if="watch === true && active === i"
          >
            <p v-html="item.content"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    title: String
  },
  data () {
    return {
      active: 0,
      watch: false
    }
  },
  computed: {
    ...mapGetters([
      'listProblem'
    ])
  },
  methods: {
    activeFn (value) {
      if (this.watch === true && this.active !== value) {
        this.active = value
      } else if (this.watch === true && this.active === value) {
        this.watch = false
      } else if (this.watch === false) {
        this.active = value
        this.watch = true
      }
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .titletitle {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid #C4C4C4;
  }

  .selectoutside {
    padding-top: 24px;
    min-height: 335px;
  }

  .selectout {
    width: 100%;
    padding: 0 0 16px 0;
  }

  .title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 24px;
    font-size: 14px;
    width: 100%;
    height: 40px;
    background: #FFF5EF;
  }

  .text {
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 24px;
    font-size: 14px;
    width: 100%;
    min-height: 40px;
    background: #FFF5EF;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    min-height: calc(100vh - 193px - 101px);
  }

  .rootinner {
    width: 720px;
    margin: 0 auto;
    padding: 15px;
  }

  .selectout {
    width: 100%;
    padding: 10px 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    border: solid 1px #ff6100;

    &.title2 {
      box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .text {
    border: solid 1px #ff6100;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
}

@media (max-width: 767px) {
  .root {
    min-height: calc(100vh - 52px - 42px);
  }

  .rootinner {
    padding: 15px;
  }

  .selectout {
    width: 100%;
    padding: 10px 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    font-size: 14px;
    width: 100%;
    border: solid 1px #ff6100;

    &.title2 {
      box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .text {
    border: solid 1px #ff6100;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }
}
</style>
