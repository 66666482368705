import router from '@/router'
import store from '@/store'

export function searchClass (categoryId, online, current) {
  router.push({
    name: 'searchList',
    params: { typeWord: online === 'employee' ? 'employee' : 'common', current: current },
    query: { category_id: categoryId }
  })
}

export function productPageFn (product) {
  let productType
  switch (Number(product.tag)) {
    case 5642:
      productType = 'kolProduct'
      break
    case 5643:
      productType = 'commonProduct'
      break
    case 5644:
      productType = 'groupProduct'
      break
    case 5645:
      productType = 'employeeProduct'
      break
    case 5646:
      productType = 'discountProduct'
      break
  }
  componentRouter(productType, product.momSku, product.parentId)
}

export function componentRouter (productType, momSku, parentId) {
  router.push({
    name: productType,
    params: {
      skuId: momSku,
      Id: parentId,
      current: 1,
      groupcurrent: productType === 'groupProduct' ? 1 : undefined
    }
  })
}

export function componentRouterText (productType, momSku, parentId) {
  return {
    name: productType,
    params: {
      skuId: momSku,
      Id: parentId,
      current: 1,
      groupcurrent: productType === 'groupProduct' ? 1 : undefined
    }
  }
}

export async function pageFunction (typeName, typeAction, current, userId) {
  router.push({
    params: { current: current }
  })
  await store.dispatch({
    type: typeName,
    action: typeAction,
    userId: userId,
    currentPage: current
  })
}

export async function pageReviewFunction (typeName, typeAction, current, productId) {
  router.push({
    params: { current: current || 1 }
  })
  await store.dispatch({
    type: typeName,
    action: typeAction,
    productId: productId,
    currentPage: current
  })
}

export function routerHavePage (routerLink) {
  router.push({
    name: routerLink,
    params: { current: 1 }
  })
}
