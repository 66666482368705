var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{class:_vm.$style.inner},[_c('div',{class:_vm.$style.topout},[_c('div',{class:_vm.$style.top},[_c('div',{class:[_vm.$style.pic, 'imgcss'],style:({
            backgroundImage: ("url(" + (_vm.kolInfoSelfList.userImage) + ")")
          })}),_c('div',{class:_vm.$style.text},[_vm._v(" "+_vm._s(_vm.kolInfoSelfList.kolName)+" ")]),_c('div',{class:_vm.$style.click},[(_vm.kolInfoSelfList.fbUrl)?_c('img',{class:_vm.$style.icon,attrs:{"src":require("../../assets/img/footer_icon_facebook.png")},on:{"click":function($event){return _vm.open(_vm.kolInfoSelfList.fbUrl)}}}):_vm._e(),(_vm.kolInfoSelfList.igUrl)?_c('img',{class:_vm.$style.icon,attrs:{"src":require("../../assets/img/productpage_icon_ig.png")},on:{"click":function($event){return _vm.open(_vm.kolInfoSelfList.igUrl)}}}):_vm._e(),(_vm.kolInfoSelfList.utbUrl)?_c('img',{class:_vm.$style.icon,attrs:{"src":require("../../assets/img/footer_icon_youtube.png")},on:{"click":function($event){return _vm.open(_vm.kolInfoSelfList.utbUrl)}}}):_vm._e()])]),_c('div',{class:[_vm.$style.top, _vm.$style.toptwo]},[_c('div',{class:_vm.$style.picall,on:{"click":function($event){return _vm.kolprodutsGo(_vm.mainProduct.sku, _vm.mainProduct.id)}}},[_c('div',{class:[_vm.$style.pic1, 'imgcss'],style:({
              backgroundImage: ("url(" + (_vm.mainProduct.image[0]) + ")")
            })}),_c('div',{class:_vm.$style.picright},[_c('div',{class:[_vm.$style.pic2, 'imgcss'],style:({
                backgroundImage: ("url(" + (_vm.mainProduct.image[1]) + ")")
              })}),_c('div',{class:[_vm.$style.pic2, 'imgcss'],style:({
                backgroundImage: ("url(" + (_vm.mainProduct.image[2]) + ")")
              })})])]),_c('div',{class:_vm.$style.middle},[_c('div',{class:_vm.$style.titleline},[_c('div',{class:_vm.$style.title,on:{"click":function($event){return _vm.kolprodutsGo(_vm.mainProduct.sku, _vm.mainProduct.id)}}},[_c('p',{staticClass:"textlangone"},[_vm._v(" "+_vm._s(_vm.mainProduct ? _vm.mainProduct.name : 'NaN')+" ")])]),_c('div',{class:_vm.$style.recommend},[_vm._v("推薦產品")])]),_c('div',{class:_vm.$style.text2},[_c('p',{staticClass:"textlang"},[_vm._v(" "+_vm._s(_vm.mainProduct ? _vm.mainProduct.description : 'NaN')+" ")])]),_c('div',{class:_vm.$style.time,on:{"click":function($event){return _vm.kolprodutsGo(_vm.mainProduct.sku, _vm.mainProduct.id)}}},[_c('i',{staticClass:"el-icon-time",staticStyle:{"margin-right":"5px"}}),_c('div',{class:_vm.$style.timetext},[_vm._v(" 此團倒數 "+_vm._s(_vm.time ? _vm.time.day + '日' : '')+" "+_vm._s(_vm.time ? _vm.time.hours + '時' : '')+" "+_vm._s(_vm.time ? _vm.time.minutes + '分' : '')+" "+_vm._s(_vm.time ? _vm.time.seconds + '秒' : '')+" "+_vm._s(_vm.time ? '' : '已結束')+" ")])])])]),_c('div',{class:[_vm.$style.top, _vm.$style.topthree]},[_c('div',{class:_vm.$style.border},[_c('p',{class:_vm.$style.bordertitle},[_vm._v("KOL推薦好文")]),_c('div',{class:_vm.$style.bordertext},[(_vm.postUrl)?_c('iframe',{staticStyle:{"border":"none","overflow":"hidden"},attrs:{"src":_vm.postUrl,"width":"100%","height":"100%","scrolling":"no","frameborder":"0","allowTransparency":"true","allow":"encrypted-media"}}):_c('div',[_vm._v(" 目前沒有文章喔！ ")])])])])]),_c('div',{class:_vm.$style.hr}),_c('div',{class:_vm.$style.kol},[_c('div',{class:_vm.$style.top2},[_vm._v(" KOL推薦 ")]),_c('div',{class:_vm.$style.main},_vm._l((_vm.kolProdutsList.list),function(item,i){return _c('div',{key:i,class:_vm.$style.all},[_c('div',{class:_vm.$style.pic3,style:({
              backgroundImage: ("url(" + (item.image ? item.image : _vm.noImage) + ")")
            }),on:{"click":function($event){return _vm.kolprodutsGo(_vm.mainProduct.sku, _vm.mainProduct.id)}}}),_c('div',{class:_vm.$style.inner3},[_c('div',{class:_vm.$style.title3,on:{"click":function($event){return _vm.kolprodutsGo(_vm.mainProduct.sku, _vm.mainProduct.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:_vm.$style.text3},[_c('p',{staticClass:"textlang"},[_vm._v(" "+_vm._s(item.description)+" ")])])]),_c('div',{class:_vm.$style.pricearea},[_c('div',{class:_vm.$style.discount},[_vm._v("$"+_vm._s(item.newPrizeNum))]),_c('div',{class:_vm.$style.price},[_vm._v("$"+_vm._s(item.oldPrizeNum))]),_c('div',{class:_vm.$style.add,on:{"click":function($event){return _vm.kolprodutsGo(_vm.mainProduct.sku, _vm.mainProduct.id)}}},[_vm._v(" 進入商品 ")])])])}),0),(_vm.kolProdutsList.total <= 0)?_c('div',{class:_vm.$style.nolist},[_c('img',{class:_vm.$style.img,attrs:{"src":require("../../assets/img/noting.png")}}),_vm._v(" 目前沒有商品喔！ ")]):_vm._e()])]),_c('page',{directives:[{name:"show",rawName:"v-show",value:(_vm.kolProdutsList.total),expression:"kolProdutsList.total"}],class:_vm.$style.page,attrs:{"pageSize":16,"pageNumber":_vm.kolProdutsList.total},on:{"Number":function($event){return _vm.numberFn($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }