<template>
  <div :class="$style.root">
    <div :class="$style.innerroot">
      <div :class="$style.bg">
      <div :class="$style.top">
        <div :class="$style.out">
          <p :class="$style.title">選擇付款方式</p>
        </div>
      </div>
      <div :class="$style.main">
        <div
          v-for="(item, i) in list"
          :key="i"
          :class="$style.raido"
        >
          <input
            type="radio"
            v-model="listactive"
            :value="i"
            :class="$style.radiostyle"
          />
          <div
            :class="$style.text"
            @click="listactive = i"
          >
            {{ item.ex }}
          </div>
        </div>
      </div>
      <div :class="$style.out">
        <div :class="$style.no" @click="$emit('close')">取消</div>
        <div
          @click="payment"
          :class="[$style.yes, (listactive !== null) ? $style.no : $style.noactive]"
        >
          確定
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      listactive: null,
      list: [{
        ex: '超商代碼繳費',
        value: 'barcode'
      }, {
        ex: '超商條碼繳費',
        value: 'cvs'
      }, {
        ex: '超商取貨付款',
        value: 'cod'
      }, {
        ex: 'ATM 銀行 轉帳',
        value: 'atm'
      }, {
        ex: '信用卡一次付清',
        value: 'credit',
        time: 0
      }, {
        ex: '信用卡三次付清',
        value: 'credit',
        time: 3
      }, {
        ex: '信用卡六次付清',
        value: 'credit',
        time: 6
      }, {
        ex: '信用卡十二次付清',
        value: 'credit',
        time: 12
      }]
    }
  },
  methods: {
    payment () {
      if (this.listactive !== null) {
        this.$emit('payment', { ...this.list[this.listactive] })
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .innerroot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    padding: 30px;
    box-sizing: border-box;
    width: 550px;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 15px;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    font-size: 12px;
    color: #828282;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 15px;
    font-weight: 600;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    cursor: pointer;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .noactive {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 15px;
    font-weight: 600;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ccc;
    cursor: pointer;
  }

  .main {
    padding: 10px;
    box-sizing: border-box;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 3px;
    box-sizing: border-box;
  }

  .radiostyle {
    width: 17px;
    height: 17px;
  }

  .text {
    font-size: 14px;
    padding-left: 12px;
    cursor: pointer;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .innerroot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    width: 550px;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 15px;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    font-size: 12px;
    color: #828282;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 15px;
    font-weight: 600;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
    cursor: pointer;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .noactive {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 15px;
    font-weight: 600;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ccc;
    cursor: pointer;
  }

  .main {
    padding: 10px;
    box-sizing: border-box;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 3px;
    box-sizing: border-box;
  }

  .radiostyle {
    width: 17px;
    height: 17px;
  }

  .text {
    font-size: 14px;
    padding-left: 12px;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .innerroot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0 60px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    margin: auto;
    padding: 15px;
    box-sizing: border-box;
    width: 300px;
    height: auto;
    border-radius: 5px;
    border: solid 1px #ff6100;
    background-color: #ffffff;
  }

  .top {
    padding-bottom: 10px;
    border-bottom: 2px solid #ccc;
  }

  .out {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 14px;
    letter-spacing: 0.3px;
    color: #000000;
  }

  .title2 {
    font-size: 14px;
    color: #ff6100;
  }

  .toptext {
    font-size: 12px;
    color: #828282;
  }

  .no {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff6100;
    font-size: 13px;
    font-weight: 400;
    width: 47%;
    height: 31px;
    border-radius: 999rem;
    border: solid 1px #ff6100;

    &.yes {
      color: #fff;
      background-color: #ff6100;
    }
  }

  .noactive {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;
    font-size: 15px;
    font-weight: 600;
    width: 47%;
    height: 40px;
    border-radius: 999rem;
    border: solid 1px #ccc;
    cursor: pointer;
  }

  .main {
    padding: 10px;
    box-sizing: border-box;
  }

  .raido {
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
    padding: 0 3px;
    box-sizing: border-box;
  }

  .radiostyle {
    width: 17px;
    height: 17px;
  }

  .text {
    font-size: 13px;
    padding-left: 12px;
    cursor: pointer;
  }
}
</style>
