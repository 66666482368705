<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.topout">
        <div :class="$style.top">
          <div
            :class="[$style.pic, 'imgcss']"
            :style="{
              backgroundImage: `url(${ kolInfoSelfList.userImage })`
            }"
          />
          <div :class="$style.text">
            {{ kolInfoSelfList.kolName }}
          </div>
          <div :class="$style.click">
            <img
              v-if="kolInfoSelfList.fbUrl"
              src="../../assets/img/footer_icon_facebook.png"
              :class="$style.icon"
              @click="open(kolInfoSelfList.fbUrl)"
            />
            <img
              v-if="kolInfoSelfList.igUrl"
              src="../../assets/img/productpage_icon_ig.png"
              :class="$style.icon"
              @click="open(kolInfoSelfList.igUrl)"
            />
            <img
              v-if="kolInfoSelfList.utbUrl"
              src="../../assets/img/footer_icon_youtube.png"
              :class="$style.icon"
              @click="open(kolInfoSelfList.utbUrl)"
            />
          </div>
        </div>
        <div :class="[$style.top, $style.toptwo]">
          <div
            :class="$style.picall"
            @click="kolprodutsGo(mainProduct.sku, mainProduct.id)"
          >
            <div
              :class="[$style.pic1, 'imgcss']"
              :style="{
                backgroundImage: `url(${ mainProduct.image[0] })`
              }"
            />
            <div :class="$style.picright">
              <div
                :class="[$style.pic2, 'imgcss']"
                :style="{
                  backgroundImage: `url(${ mainProduct.image[1] })`
                }"
              />
              <div
                :class="[$style.pic2, 'imgcss']"
                :style="{
                  backgroundImage: `url(${ mainProduct.image[2] })`
                }"
              />
            </div>
          </div>
          <div :class="$style.middle">
            <div :class="$style.titleline">
              <div
                @click="kolprodutsGo(mainProduct.sku, mainProduct.id)"
                :class="$style.title"
              >
                <p class="textlangone">
                  {{ mainProduct ? mainProduct.name : 'NaN' }}
                </p>
              </div>
              <div :class="$style.recommend">推薦產品</div>
            </div>
            <div :class="$style.text2">
              <p class="textlang">
                {{ mainProduct ? mainProduct.description : 'NaN' }}
              </p>
            </div>
            <div
              :class="$style.time"
              @click="kolprodutsGo(mainProduct.sku, mainProduct.id)"
            >
              <i class="el-icon-time" style="margin-right: 5px;"/>
              <div :class="$style.timetext">
                此團倒數
                {{ time ? time.day + '日' : '' }}
                {{ time ? time.hours + '時' : '' }}
                {{ time ? time.minutes + '分' : '' }}
                {{ time ? time.seconds + '秒' : '' }}
                {{ time ? '' : '已結束' }}
              </div>
            </div>
          </div>
        </div>
        <div :class="[$style.top, $style.topthree]">
        <div :class="$style.border">
          <p :class="$style.bordertitle">KOL推薦好文</p>
          <div :class="$style.bordertext">
            <iframe
              v-if="postUrl"
              :src="postUrl"
              width="100%"
              height="100%"
              style="border:none;overflow:hidden"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allow="encrypted-media">
            </iframe>
            <div v-else>
              目前沒有文章喔！
            </div>
          </div>
        </div>
      </div>
      </div>
      <div :class="$style.hr"/>
      <div :class="$style.kol">
        <div :class="$style.top2">
          KOL推薦
        </div>
        <div :class="$style.main">
          <div
            :class="$style.all"
            v-for="(item, i) in kolProdutsList.list"
            :key="i"
          >
            <div
              :class="$style.pic3"
              :style="{
                backgroundImage: `url(${
                  item.image ? item.image : noImage
                })`
              }"
               @click="kolprodutsGo(mainProduct.sku, mainProduct.id)"
            />
            <div :class="$style.inner3">
              <div
                @click="kolprodutsGo(mainProduct.sku, mainProduct.id)"
                :class="$style.title3"
              >
                {{ item.name }}
              </div>
              <div :class="$style.text3">
                <p class="textlang">
                  {{ item.description }}
                </p>
              </div>
            </div>
            <div :class="$style.pricearea">
              <div :class="$style.discount">${{ item.newPrizeNum }}</div>
              <div :class="$style.price">${{ item.oldPrizeNum }}</div>
              <div
                :class="$style.add"
                @click="kolprodutsGo(mainProduct.sku, mainProduct.id)"
              >
                進入商品
              </div>
            </div>
          </div>
        </div>
        <div
          :class="$style.nolist"
          v-if="kolProdutsList.total <= 0"
        >
          <img
            src="../../assets/img/noting.png"
            :class="$style.img"
          />
          目前沒有商品喔！
        </div>
      </div>
    </div>
    <page
      v-show="kolProdutsList.total"
      :class="$style.page"
      @Number="numberFn($event)"
      :pageSize="16"
      :pageNumber="kolProdutsList.total"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import reciprocalJs from '@/js/reciprocal.js'
import { mapGetters } from 'vuex'
import { productPageFn } from '@/js'
import '@/css/image.scss'
export default {
  mixins: [
    reciprocalJs
  ],
  components: {
    page
  },
  data () {
    return {
      noImage: require('../../assets/img/cvimall_comingsoon_small.png')
    }
  },
  watch: {
    mainProduct () {
      this.fakeTime = this.mainProduct.time
    }
  },
  computed: {
    ...mapGetters([
      'kolInfoSelfList',
      'kolProdutsList',
      'mainProduct'
    ]),
    postUrl () {
      if (this.kolInfoSelfList.postId) {
        return 'https://www.facebook.com/plugins/post.php?href=' +
        encodeURIComponent('https://www.facebook.com/Cvimall168/posts/' +
          this.kolInfoSelfList.postId +
          '&width=500&&show_text=true&appId=1005505009930679'
        )
      } else {
        return undefined
      }
    }
  },
  methods: {
    kolprodutsGo (sku, id) {
      const product = { tag: 5642, momSku: sku, parentId: id }
      productPageFn(product)
    },
    open (value) {
      window.open(value)
    },
    async numberFn (value) {
      this.$emit('loading', true)
      try {
        // 個人頁非主打商品
        await this.$store.dispatch({
          type: 'mainKolProducts',
          kol_id: this.$route.params.kolId,
          kol_promote: 0,
          pageSize: 16,
          currentPage: value
        })
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
  },
  async created () {
    this.$emit('loading', true)
    try {
      await Promise.all([
        this.$store.dispatch({
          type: 'getKolInfoSelf',
          kolId: this.$route.params.kolId
        }),
        // 主打商品
        this.$store.dispatch({
          type: 'mainKolProducts',
          kol_id: this.$route.params.kolId,
          kol_promote: 1
        })
      ])
    } catch {
      this.$message.error('載入失敗，請重新載入。')
    } finally {
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" module>
.nolist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 20px;
  min-height: 320px;
  color: #ccc;
}

.img {
  opacity: .7;
  width: auto;
  height: 150px;
  margin-bottom: 15px;
}

.pic {
  border: 1px solid #e2e2e2;
}

.bordertitle {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

@media (min-width: 1366px) {
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .topout {
    display: flex;
    justify-content: center;
    width: 1044px;
    padding: 80px 0;
  }

  .hr {
    width: 100%;
    height: 14px;
    background: #F7F8F9;
  }

  .top {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 2px solid #F2F2F2;
    padding-right: 50px;
    min-height: 353px;

    &.toptwo {
      width: 300px;
      padding: 0 50px;
      border-right: 2px solid #F2F2F2;
      cursor: pointer;
    }

    &.topthree {
      width: 302px;
      border-right: none;
      padding-left: 50px;
    }
  }

  .borderimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
  }

  .btnadd {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FF7520;
    border-radius: 999rem;
    color: #FF7520;
    font-weight: 500;
    font-size: 16px;
    width: 280px;
    height: 40px;
  }

  .borderimginn {
    width: 319px;
    height: 129px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pic {
    width: 234px;
    height: 234px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    padding: 15px 0;
    box-sizing: border-box;
    width: 234px;
  }

  .click {
    width: 234px;
    display: flex;
  }

  .icon {
    width: 50px;
    height: 50px;
    margin: 7px 15px 0 0;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 10px);
    }
  }

  .picall {
    display: flex;
  }

  .pic1 {
    width: 181px;
    height: 181px;
    margin-right: 9px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .middle {
    width: 278px;
  }

  .picright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pic2 {
    width: 88px;
    height: 88px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .titleline {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 16px 0;
  }

  .title {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #000000;

    &:hover {
      color: #FF6100;
    }
  }

  .recommend {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #FF6100;
    border: 1px solid #FF6100;
    width: 71px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .text2 {
    cursor: text;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    height: 40px;
    overflow: hidden;
  }

  .time {
    width: 282px;
    height: 33px;
    background: #FF6100;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
    transition: 0.3s;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px;
      transition: 0.3s;
    }
  }

  .iconfont {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    margin-right: 30px;
  }

  .border {
    overflow: hidden;
    padding: 12px;
    width: 282px;
    height: 433px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .kol {
    width: 1042px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 71px 0 38px 0;
  }

  .top2 {
    position: relative;
    display: flex;
    width: 1042px;
    height: 95px;
    font-weight: 500;
    font-size: 18px;

    &::after {
      position: absolute;
      content: '';
      width: 50px;
      height: 8px;
      top: 38px;
      background: #00D77F;
    }
  }

  .title2 {
    display: flex;
    font-size: 16px;
    color: #000;
  }

  .all {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 320px 90px 124px;
    transition: 0.3s;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px;
      transition: 0.3s;
      border-radius: 5px 5px 30px 5px;
    }
  }

  .pic3 {
    width: 100%;
    height: 100%;
    border: 2px solid #F2F2F2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    cursor: pointer;
  }

  .inner3 {
    width: 100%;
    height: 100%;
    border-left: 2px solid #F2F2F2;
    border-right: 2px solid #F2F2F2;
    box-sizing: border-box;
    padding: 16px 22px 0 20px;
  }

  .title3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-bottom: 7px;

    &:hover {
      color: #FF6100;
      cursor: pointer;
    }
  }

  .text3 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }

  .discount {
    font-weight: 500;
    font-size: 20px;
    color: #FF6100;
  }

  .price {
    font-size: 20px;
    text-decoration-line: line-through;
    color: #727272;
    padding-left: 20px;
  }

  .pricearea {
    border-left: 2px solid #F2F2F2;
    border-right: 2px solid #F2F2F2;
    border-bottom: 2px solid #F2F2F2;
    border-radius: 0 0 30px 0;
    padding: 18px 24px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .main {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 323px);
    grid-auto-rows: 533px;
    justify-content: space-between;
    grid-row-gap: 36px;
  }

  .add {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #FF6100;
    width: 241px;
    height: 32px;
    border: 2px solid #FF7520;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #fff;
      background: #FF7520;
      transition: 0.3s;
    }
  }

  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #8C8C8C;
    padding-top: 32px;
  }

  .imgcolor {
    margin-right: 10px;
    border-radius: 999rem;
    width: 36.35px;
    height: 36.35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .divout {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .bordertext {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    overflow: hidden;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  .topout {
    display: flex;
    padding: 80px 0 35px 0;
    width: 720px;
    flex-wrap: wrap;
  }

  .hr {
    width: 100%;
    height: 8px;
    background: #F7F8F9;
  }

  .top {
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.toptwo {
      border-left: 1px solid #EEEEEE;
      cursor: pointer;
    }

    &.topthree {
      border-top: 1px solid #EEEEEE;
      width: 100%;
      border-right: none;
      margin-top: 35px;
      padding-top: 35px;
    }
  }

  .borderimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
  }

  .btnadd {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FF7520;
    border-radius: 999rem;
    color: #FF7520;
    font-weight: 500;
    font-size: 16px;
    width: 280px;
    height: 40px;
  }

  .borderimginn {
    width: 220px;
    height: 129px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .pic {
    width: 234px;
    height: 234px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 11px;
    width: 234px;
  }

  .click {
    width: 234px;
    display: flex;
  }

  .icon {
    width: 35px;
    height: 35px;
    margin: 7px 6px 0 0;
    cursor: pointer;
  }

  .picall {
    display: flex;
  }

  .pic1 {
    width: 181px;
    height: 181px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 9px;
  }

  .middle {
    width: 278px;
  }

  .picright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pic2 {
    width: 88px;
    height: 88px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .titleline {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 16px 0;
  }

  .title {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #000000;
  }

  .recommend {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #FF6100;
    border: 1px solid #FF6100;
    width: 71px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .text2 {
    cursor: text;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    height: 40px;
    overflow: hidden;
  }

  .time {
    width: 282px;
    height: 33px;
    background: #FF6100;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
  }

  .iconfont {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    margin-right: 30px;
  }

  .border {
    overflow: hidden;
    padding: 20px;
    width: 100%;
    height: 553px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .kol {
    width: 720px;
    display: flex;
    flex-direction: column;
    padding-top: 71px;
  }

  .top2 {
    position: relative;
    display: flex;
    height: 95px;
    font-weight: 500;
    font-size: 18px;

    &::after {
      position: absolute;
      content: '';
      width: 50px;
      height: 8px;
      top: 38px;
      background: #00D77F;
    }
  }

  .title2 {
    display: flex;
    font-size: 16px;
    color: #000;
  }

  .all {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 320px 90px 124px;
  }

  .pic3 {
    width: 100%;
    height: 100%;
    border: 2px solid #F2F2F2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
  }

  .inner3 {
    width: 100%;
    height: 100%;
    border-left: 2px solid #F2F2F2;
    border-right: 2px solid #F2F2F2;
    box-sizing: border-box;
    padding: 16px 22px 0 20px;
  }

  .title3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-bottom: 7px;
  }

  .text3 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }

  .discount {
    font-weight: 500;
    font-size: 20px;
    color: #FF6100;
  }

  .price {
    font-size: 20px;
    text-decoration-line: line-through;
    color: #727272;
    padding-left: 20px;
  }

  .pricearea {
    border-left: 2px solid #F2F2F2;
    border-right: 2px solid #F2F2F2;
    border-bottom: 2px solid #F2F2F2;
    border-radius: 0 0 30px 0;
    padding: 18px 24px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .main {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 323px);
    grid-auto-rows: 533px;
    justify-content: space-around;
    grid-row-gap: 36px;
  }

  .add {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #FF6100;
    width: 241px;
    height: 32px;
    border: 2px solid #FF7520;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #8C8C8C;
    padding-top: 32px;
  }

  .imgcolor {
    margin-right: 10px;
    border-radius: 999rem;
    width: 36.35px;
    height: 36.35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .divout {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .bordertext {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }

  .top {
    width: 300px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;

    &.two {
      border-bottom: none;
    }
  }

  .borderimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 16px 0;
  }

  .borderimginn {
    width: 220px;
    height: 129px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .pic {
    width: 234px;
    height: 234px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 11px;
    width: 234px;
  }

  .click {
    width: 234px;
    display: flex;
  }

  .icon {
    width: 35px;
    height: 35px;
    margin: 7px 6px 0 0;
    cursor: pointer;
  }

  .picall {
    display: flex;
  }

  .pic1 {
    width: 181px;
    height: 181px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 9px;
  }

  .middle {
    width: 278px;
  }

  .picright {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pic2 {
    width: 88px;
    height: 88px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .titleline {
    display: flex;
    justify-content: space-between;
    margin: 14px 0 16px 0;
  }

  .title {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #000000;
  }

  .recommend {
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.3px;
    color: #FF6100;
    border: 1px solid #FF6100;
    width: 71px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  .text2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    height: 40px;
    overflow: hidden;
  }

  .time {
    width: 282px;
    height: 33px;
    background: #FF6100;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #FFFFFF;
  }

  .iconfont {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    margin-right: 30px;
  }

  .pricearea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 16px;
  }

  .border {
    padding: 12px;
    width: 282px;
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 5px;
    height: 500px;
  }

  .kol {
    width: 100%;
    padding: 25px 21px 0 21px;
    box-sizing: border-box;
  }

  .top2 {
    position: relative;
    display: flex;
    height: 45px;
    font-weight: 500;
    font-size: 16px;

    &::after {
      position: absolute;
      content: '';
      width: 25px;
      height: 6px;
      top: 27px;
      background: #00D77F;
    }
  }

  .title2 {
    display: flex;
    font-size: 16px;
    color: #000;
  }

  .all {
    margin-top: 25px;
    padding-bottom: 15px;
    width: 282px;
    border: 2px solid #F2F2F2;
    border-radius: 0 0 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pic3 {
    width: 282px;
    height: 282px;
    border: 2px solid #F2F2F2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
  }

  .inner3 {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 16px;
  }

  .title3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 9px;
  }

  .text3 {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }

  .discount {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #FF6100;
    margin-right: 31px;
  }

  .price {
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;
    text-decoration-line: line-through;
    color: #727272;
  }

  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #FF6100;
    width: 241px;
    height: 32px;
    border: 2px solid #FF7520;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 10px 0;
  }

  .more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #8C8C8C;
    padding-top: 32px;
  }

  .imgcolor {
    margin-right: 10px;
    border-radius: 999rem;
    width: 36.35px;
    height: 36.35px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .divout {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .bordertext {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
    color: #000000;
    overflow: hidden;
  }

  .hr {
    margin: 30px 0;
    width: 100%;
    height: 8px;
    background: #F7F8F9;
  }
}
</style>
