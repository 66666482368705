<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div
        :class="[$style.bg,
          'animate__animated',
          'animate__faster',
          'animate__fadeInDownBig']"
          v-if="next === 0"
      >
        <div :class="$style.top">
          <div :class="$style.toptitle">新增地址</div>
          <div :class="$style.toptext">請確認收件資料及地址</div>
        </div>
        <div :class="$style.listout">
          <div
            :class="[ {[$style.outactive]: activestyle === 1}, $style.out]"
            @click="activestyle = 1"
          >
            <img src="../../assets/img/home.png" :class="$style.imgsize"/>
            <div :class="$style.outtext">宅配到貨</div>
          </div>
          <div
            :class="[ {[$style.outactive]: activestyle === 2}, $style.out]"
            @click="activestyle = 2"
          >
            <img src="../../assets/img/shop.png" :class="$style.imgsize"/>
            <div :class="$style.outtext">超商取貨</div>
          </div>
        </div>
        <div :class="$style.btnout">
          <div
            :class="[$style.cancel, 'clickCss']"
            @click="$emit('close')"
          >
            取消
          </div>
          <div
            :class="[activestyle === null ? $style.sendno : $style.send]"
            @click="nextFn()"
          >
            下一步
          </div>
        </div>
      </div>
      <div
        :class="[$style.bg,
          'animate__animated',
          'animate__faster',
          'animate__fadeIn']"
        v-if="next === 1"
      >
        <div :class="$style.top">
          <div :class="$style.toptitle">新增宅配地址</div>
          <div :class="$style.toptext">請確認收件資料及地址</div>
        </div>
        <div :class="$style.listout">
          <input
            v-model="sendname"
            :class="[$style.out, 'inputCss']"
            placeholder="收件人姓名"
          />
          <input
            v-model="sendphone"
            :class="[$style.out, 'inputCss']"
            type="number"
            placeholder="收件人手機號碼"
          />
          <p :class="$style.errortext"
            v-if="message"
          >
            {{ message }}
          </p>
          <p :class="$style.listtitle">填寫地址</p>
          <div :class="$style.selectout">
            <select
              v-model="selectValueCity"
              :class="[$style.out, $style.selectstyle, 'inputCss']"
            >
              <option
                v-for="(item, i) in taiwanDistrict"
                :value="i"
                :key="i"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              v-model="selectValue"
              :class="[$style.out, $style.selectstyle, 'inputCss']"
            >
              <option
                v-for="(item, i) in taiwanDistrict[selectValueCity].districts"
                :value="i"
                :key="i"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <input
            v-model="address"
            :class="[$style.out, 'inputCss']"
            placeholder="詳細地址"
          />
        </div>
        <div :class="$style.btnout">
          <div
            :class="[$style.cancel, 'clickCss']"
            @click="$emit('close')"
          >
            取消
          </div>
          <div
            :class="[[address && sendname && sendphone && !message ? $style.send : $style.sendno], 'clickCss']"
            @click="address && sendname && sendphone && !message ? addAddress() : ''"
          >
            確定
          </div>
        </div>
      </div>
      <div
        :class="[$style.bg,
          'animate__animated',
          'animate__faster',
          'animate__fadeIn']"
        v-if="next === 2"
      >
        <div :class="$style.top">
          <div :class="$style.toptitle">
            新增超商地址
          </div>
          <div :class="$style.toptext">
            請確認收件資料與領取者身分證姓名相同 以便取貨時供超商店員核對
          </div>
        </div>
        <div :class="$style.listout">
          <input
            v-model="sendname"
            :class="[$style.out, 'inputCss']"
            placeholder="收件人姓名"
          />
          <input
            v-model="sendphone"
            :class="[$style.out, 'inputCss']"
            type="number"
            placeholder="收件人手機號碼"
          />
          <p :class="$style.errortext"
            v-if="message"
          >
            {{ message }}
          </p>
          <p :class="$style.listtitle">選擇門市</p>
          <div
            v-if="!addNumber || addNumber === 1"
            :class="[ { [$style.outactive]: activeshop === 0 }, $style.out]"
            @click="activeshop = 0"
          >
            <img src="../../assets/img/711shop.png" :class="$style.imgsize"/>
            <div :class="$style.outtext"> 7-Eleven</div>
          </div>
          <div
            v-if="!addNumber || addNumber === 3"
            :class="[ { [$style.outactive]: activeshop === 2 }, $style.out]"
            @click="activeshop = 2"
          >
            <img src="../../assets/img/familymartshop.png" :class="$style.imgsize"/>
            <div :class="$style.outtext">全家 FamilyMart</div>
          </div>
          <div
            v-if="!addNumber || addNumber === 2"
            :class="[ { [$style.outactive]: activeshop === 1 }, $style.out]"
            @click="activeshop = 1"
          >
            <img src="../../assets/img/hilifeshop.png" :class="$style.imgsize"/>
            <div :class="$style.outtext"> 萊爾富 Hi-Life </div>
          </div>
          <div :class="$style.clickbtn">
            <div :class="$style.text" v-if="csvData">
              {{ activeshop === 0
                ? '7-Eleven商店'
                : activeshop === 1
                  ? 'Hi-Life 萊爾富商店'
                  : 'FamilyMart商店'
              }}
              <p>{{ csvData.CVSAddress }}</p>
              <p>{{ csvData.CVSStoreName }}</p>
            </div>
            <div v-else/>
            <div>
              <div class="red">
                選擇門市時，請勿此關閉視窗。
              </div>
              <div
                v-if="activeshop || activeshop === 0"
                :class="[$style.selectshop, 'clickCss']"
                @click="selectshopFn"
              >
                {{ addNumber >= 0 ? '重新選擇' : csvData ? '重新選擇' : '選擇超商' }}
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.btnout">
          <div
            :class="[$style.cancel, 'clickCss']"
            @click="$emit('close')"
          >
            取消
          </div>
          <div
            :class="['clickCss', csvData && sendname && sendphone && !message ? $style.send : $style.sendno]"
            @click="csvData && sendname && sendphone && !message ? addAddress() : ''"
          >
            確定
          </div>
        </div>
        <form
          v-show="no"
          action="https://shop.cvicloud-srv.net/shipment/cvsMap.php"
          method="post"
          target="_blank"
        >
          <input
            v-model="subType[activeshop]"
            id="subType"
            name="subType"
          >
          <button ref="btn">
            選擇商店
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import taiwanDistrict from '@/address/taiwan_districts.json'
export default {
  props: {
    info: Object,
    addNumber: Number,
    pickAddressId: Number
  },
  data () {
    return {
      next: 0,
      no: false,
      csvData: null,
      subType: ['UNIMART', 'HILIFE', 'FAMI'],
      activestyle: null,
      activeshop: null,
      selectValueCity: 0,
      selectValue: 0,
      address: '',
      sendname: '',
      sendphone: '',
      message: null,
      taiwanDistrict
    }
  },
  watch: {
    activeshop () {
      if (this.addNumber >= 0 && this.activeshop >= 0) {
        this.$refs.btn.click()
      }
      this.csvData = null
    },
    sendname () {
      this.reFunction()
    },
    sendphone () {
      this.reFunction()
    }
  },
  computed: {
    addaddressFN () {
      // next 1 => 宅配
      // next 2 => 門市
      // type / 宅配 = home / 7eleven = 711 / hilife = 全家 / familymart = 萊爾富
      let type
      if (this.next === 1) {
        type = 'home'
        const newaddress = {
          street: [
            this.taiwanDistrict[this.selectValueCity].districts[this.selectValue].name,
            this.address,
            type
          ],
          region_id: 0, // 固定
          country_id: 'TW', // 固定
          telephone: this.sendphone,
          postcode: 251,
          city: this.taiwanDistrict[this.selectValueCity].name,
          firstname: this.sendname,
          lastname: this.sendname
        }
        return newaddress
      } else {
        if (this.activeshop === 0) {
          type = '7eleven'
        } else if (this.activeshop === 1) {
          type = 'hilife'
        } else {
          type = 'familymart'
        }
        const newaddress = {
          street: [
            this.csvData.CVSAddress.slice(3),
            this.csvData.CVSStoreName + '-' + this.csvData.CVSStoreID,
            type
          ],
          region_id: 0, // 固定
          country_id: 'TW', // 固定
          telephone: this.sendphone,
          postcode: 251,
          city: this.csvData.CVSAddress.slice(0, 3),
          firstname: this.sendname,
          lastname: this.sendname
        }
        return newaddress
      }
    }
  },
  methods: {
    reFunction () {
      const nameRe = /^[\u4e00-\u9fa5]+$|^[a-zA-Z\s]+$/
      const PhoneRe = /^09\d{8}$/
      if (!PhoneRe.test(this.sendphone) && !nameRe.test(this.sendname)) {
        this.message = '請輸入正確的手機與姓名。'
      } else if (!PhoneRe.test(this.sendphone) && this.sendphone !== '') {
        this.message = '請輸入正確的手機格式。'
      } else if (!nameRe.test(this.sendname) && this.sendname !== '') {
        this.message = '請輸入姓名格式。'
      } else {
        this.message = null
      }
    },
    async getStoreId (value) {
      this.csvData = value
    },
    selectshopFn () {
      this.$refs.btn.click()
    },
    nextFn () {
      if (this.activestyle !== null) {
        this.next = this.activestyle
      }
    },
    async addressFn () {
      try {
        await this.$store.dispatch({
          type: 'checkShipmentCol',
          name: this.addaddressFN.lastname,
          phone: this.addaddressFN.telephone,
          email: this.info.email,
          postcode: 251,
          street: this.addaddressFN.city + this.addaddressFN.street[0] + this.addaddressFN.street[1]
        })
        await this.$store.dispatch({
          type: 'addAddress',
          customerId: this.$route.params.userId,
          name: this.info.name,
          email: this.info.email,
          addresses: this.info.addressList
        })
        await this.$store.dispatch({
          type: 'getUserData'
        })
        if (this.routerName === 'groupshoppinginfo' || this.routerName === 'shoppinginfo') {
          let id
          for (let i = 0; i < this.info.addressList.length; i++) {
            let max = 0
            id = Math.max(this.info.addressList[i].id, max)
          }
          await this.$store.dispatch({
            type: 'customJsonFn',
            action: (this.pickAddressId >= 0) ? 'updateCustomJson' : 'addCustomJson',
            key: 'LastTimeData',
            value: id
          })
          await this.$store.dispatch({
            type: 'customJsonFn',
            action: 'getCustomJson',
            key: 'LastTimeData'
          })
        }
        this.$emit('close')
        this.$message({
          message: '地址新增成功！',
          type: 'success'
        })
      } catch {
        await this.$store.dispatch({
          type: 'getUserData'
        })
        this.message = '請輸入有效的姓名/電話。'
      } finally {
        this.$emit('loading', false)
      }
    },
    async addAddress () {
      if (this.sendname && this.sendphone && !this.message) {
        const arr = this.info.addressList
        arr.push(this.addaddressFN)
        this.$emit('loading', true)
        if (this.next === 2) {
          try {
            await this.$store.dispatch({
              type: 'customJsonFn',
              action: 'addCustomJson',
              key: this.csvData.CVSStoreID,
              value: this.csvData
            })
          } catch {
          }
        }
        this.addressFn()
      } else {
        this.message = '此列輸入全為必填。'
      }
    }
  },
  async created () {
    window.getStoreId = this.getStoreId
    if (this.addNumber >= 0) {
      switch (this.addNumber) {
        case 0:
          this.next = 1
          break
        case 1:
          this.next = 2
          this.activeshop = 0
          break
        case 2:
          this.next = 2
          this.activeshop = 1
          break
        case 3:
          this.next = 2
          this.activeshop = 2
          break
      }
    }
  }
}
</script>

<style lang="scss" module>
.text2 {
  color: #ccc;
  margin-top: -15px;
  margin-bottom: 5px;
}

.loading {
  z-index: 999;
}

.clickbtn {
  display: flex;
  justify-content: space-between;
  min-height: 170px;
}

.errortext {
  color: red;
  margin-bottom: 10px;
}

.text {
  font-size: 14px;
  color: #828282;
}

.selectshop {
  width: 100px;
  height: 30px;
  color: #fff;
  font-size: 14px;
  border: 1px solid #FF6100;
  background: #FF6100;
  border-radius: 999rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  margin: 10px auto;
}

.cancel {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF6100;
  font-weight: 500;
  font-size: 16px;
  width: 47%;
  height: 40px;
  border: 1px solid #FF6100;
  box-sizing: border-box;
  border-radius: 999rem;
}

.send {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 47%;
  height: 40px;
  background: #FF6100;
  border: 1px solid #FF6100;
  box-sizing: border-box;
  border-radius: 999rem;
}

.sendno {
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 47%;
  height: 40px;
  background: #ccc;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 999rem;
}

@media (min-width: 1366px) {
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #FF6100;
    margin: auto;
    width: 480px;
    border-radius: 10px;
    background-color: #ffffff;
    min-height: 470px;
    padding: 25px 32px;
    box-sizing: border-box;
  }

  .top {
    border-bottom: 1px solid #C4C4C4;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  .listout {
    min-height: 490px;
  }

  .toptitle {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    padding-bottom: 8px;
  }

  .toptext {
    font-size: 14px;
    color: #828282;
  }

  .out {
    cursor: pointer;
    font-size: 14px;
    -webkit-appearance: none;
    box-shadow: none;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    border: 1px solid #C4C4C4;

    &.outactive {
      border: 1px solid #FF6100;
    }
  }

  .imgsize {
    width: 27px;
    height: auto;
  }

  .outtext {
    padding-left: 20px;
    font-size: 14px;
  }

  .btnout {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .listtitle {
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 12px;
  }

  .selectout {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .selectstyle {
    cursor: pointer;
    width: 48%;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;
  }

  .nono {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    width: 47%;
    height: 40px;
    background: #aaa;
    border: 1px solid #aaa;
    box-sizing: border-box;
    border-radius: 999rem;
  }

  .check {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .checktext {
    margin-left: 10px;
  }
}

@media (max-width: 1365px) {
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 100px 0;
    box-sizing: border-box;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid #FF6100;
    margin: auto;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    background-color: #ffffff;
    min-height: 470px;
    padding: 18px 20px;
    box-sizing: border-box;
  }

  .top {
    border-bottom: 2px solid #C4C4C4;
    padding-bottom: 10px;
    margin-bottom: 13px;
  }

  .listout {
    min-height: 415px;
  }

  .toptitle {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }

  .toptext {
    font-size: 12px;
    line-height: 14px;
    color: #828282;
  }

  .out {
    -webkit-appearance: none;
    box-shadow: none;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #C4C4C4;
  }

  .outactive {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid #FF6100;
  }

  .imgsize {
    width: 25px;
    height: auto;
  }

  .outtext {
    padding-left: 15px;
    font-size: 13px;
  }

  .btnout {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .listtitle {
    font-size: 13px;
    padding: 12px 0;
  }

  .selectout {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .selectstyle {
    width: 48%;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;
  }

  .check {
    display: flex;
  }

  .checktext {
    margin-left: 10px;
  }
}
</style>
