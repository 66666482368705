<template>
  <div :class="$style.root">
    <div :class="$style.innerlist">
      <div :class="$style.imgout">
        <div :class="$style.imgoutbefore" ref="imgoutbefore"/>
        <carousel
          :imgList="imgListinner"
          :class="$style.carousel"
          :style="{ height: `${ carouselHeight }px` }"
          @imageIndex="imageIndex = $event"
        />
        <div :class="$style.imgoutafter" ref="imgoutafter"/>
      </div>
      <div class="outside" v-if="kolProdutsList.total">
        <div class="top">
          <div class="toptitle">
            KOL精選
          </div>
        </div>
        <div class="list-innerlist">
          <div :class="$style.main">
            <div
              v-for="(item, i) in kolList" :key="i"
              :class="['bottominner', $style.bottominner]"
            >
              <div
                @click="kolInfoGo(item.kolId)"
                class="imgout"
                :style="{
                  backgroundImage: `url(${ item.kolInfo.image })`
                }"
              />
              <div class="name">
                <p
                  @click="kolInfoGo(item.kolId)"
                  class="nametitle"
                >
                  {{ item.kolInfo ? item.kolInfo.name : 'NaN' }}
                </p>
                <div class="time">
                  <i class="el-icon-time" style="margin-right: 5px;"/>
                    <p v-if="webwatch">時間:</p>
                    <div v-if="time[i]">
                      {{ time[i].day ? time[i].day + '日' : '' }}
                      {{ time[i].hours ? time[i].hours + '時' : '' }}
                      {{ time[i].minutes ? time[i].minutes + '分' : '' }}
                      {{ time[i].seconds ? time[i].seconds + '秒' : '' }}
                      {{ time[i].seconds ? '' : '已結束' }}
                    </div>
                    <div v-else>已結束</div>
                </div>
              </div>
              <div
                :class="['btm', $style.btm]"
                @click="kolproduts(item)"
              >
                <div
                  class="groupimg"
                  :style="{ backgroundImage: `url(${ item.image })` }"
                />
                <div class="btmtext">
                  <p class="btmtitle">{{ item.name }}</p>
                  <div class="btmtitle2">
                    <p :class="webwatch ? 'textlangthree' : 'textlang'">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!kolList.length" :class="['noproduct', 'outside']">
        目前沒有商品喔！
      </div>
    </div>
    <page
      v-show="kolProdutsList.total > 0"
      :class="$style.page"
      @Number="numberFn($event)"
      :pageSize="16"
      :pageNumber="kolProdutsList.total"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import carousel from '@/components/carousel/carousel.vue'
import page from '@/components/page.vue'
import listimg from '@/js/listimg.js'
import koljs from '@/js/kollistjs.js'
import '@/css/kollist.scss'
export default {
  mixins: [
    listimg,
    koljs
  ],
  components: {
    carousel,
    page
  },
  data () {
    return {
      watch: false,
      active: 0,
      imageIndex: 0
    }
  },
  computed: {
    ...mapGetters([
      'imgListinner'
    ])
  },
  watch: {
    kolList () {
      if (this.kolList.length) {
        this.$emit('loading', false)
      }
    },
    imageIndex (newVal) {
      var beforeVal = newVal - 1
      if (newVal === 0) {
        beforeVal = this.imgListinner.length - 1
      }
      var afterVal = newVal + 1
      if (this.imgListinner.length - 1 === newVal) {
        afterVal = 0
      }
      this.$refs.imgoutbefore.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[beforeVal].img})`
      this.$refs.imgoutafter.style.backgroundImage = `url(${this.imgUrl + this.imgListinner[afterVal].img})`
    }
  },
  methods: {
    async numberFn (value, type) {
      this.$emit('loading', true)
      await this.$store.dispatch({
        type: 'kolProdutsList',
        pageSize: 16,
        field: 'kol_promote',
        fieldValue: 1,
        currentPage: value
      })
    }
  },
  async created () {
    await Promise.all([
      this.$store.dispatch({
        type: 'getBanner',
        banner: 'kol'
      })
    ])
  }
}
</script>

<style lang="scss" module>
.innerlist {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (min-width: 1366px) {
  .carousel {
    z-index: 0;
    width: 950px;
    height: 450px;
    margin: 0 auto;
  }

  .imgout {
    width: 100%;
    height: 450px;
    position: relative;

    .imgoutbefore {
      position: absolute;
      top: 0;
      left: -475px;
      width: 50%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.2;
    }

    .imgoutafter {
      position: absolute;
      top: 0;
      right: 0px;
      width: 25%;
      height: 100%;
      z-index: -3;
      opacity: 0.2;
    }
  }

  .inner {
    width: 1110px;
    padding-top: 85px;
    box-sizing: border-box;
  }

  .main {
    width: 100%;
    display: grid;
    grid-template-columns: 349px 349px 349px;
    grid-auto-rows: 392px;
    justify-content: space-between;
    grid-row-gap: 30px;
  }

  .btmtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: flex-start;
    padding-bottom: 14px;
    font-weight: 400;
    font-size: 20px;

    &:hover {
      color: #ff6100;
    }
  }

  .page {
    padding-bottom: 40px;
    width: 1110px;
    margin: 0 auto;
  }
}

@media (max-width: 1365px) {
  .carousel {
    margin: 28px 0;
    width: 768px;
    height: 384px;
  }

  .inner {
    width: 768px;
    padding: 0 8px;
    box-sizing: border-box;
  }

  .page {
    padding: 40px 0;
  }

  .main {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 240px);
    grid-auto-rows: 270px;
    grid-row-gap: 35px;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .inner {
    font-weight: 500;
    padding: 24px 0 25px 0;
    box-sizing: border-box;
    width: 100%;
  }

  .main {
    justify-content: center;
    grid-column-gap: 20px;
    grid-template-columns: repeat(2, 240px);
    grid-auto-rows: 225px;
  }

  .bottominner {
  }

  .carousel {
    z-index: 0;
    width: 100%;
  }

  .page {
    padding-bottom: 30px;
  }

  .btm {
    align-self: center;
  }
}

@media (max-width: 530px) {
  .main {
    grid-template-columns: repeat(1, 240px);
  }
}
</style>
