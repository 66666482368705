import { requestApi } from './request'

export async function webApiFunciton (action, token, bodyObj) {
  const { data } = await requestApi.post('/webapi.php', {
    action: action,
    ...bodyObj && { ...bodyObj }
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

export async function openWebApiFunciton (action, token, bodyObj) {
  const { data } = await requestApi.post('/openwebapi.php', {
    action: action,
    ...bodyObj && { ...bodyObj }
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}

// getCustomJson addCustomJson updateCustomJson deleteCustomJson
export async function customJsonFunction (action, key, value, token) {
  const { data } = await requestApi.post('/webapi.php', {
    action: action,
    key: key,
    value: value
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return data
}
