<template>
  <popup @close="$emit('close')">
    <template #title>
      <div :class="$style.title" v-if="loginBoolean">
        會員登入
      </div>
      <login v-else @navVal="navVal = $event"/>
    </template>
    <template #main v-if="loginBoolean">
      <div :class="$style.iconout">
        <div :class="[$style.iconlogin, 'clickCss']" @click="fbLogin">
          <img :class="$style.icon" src="../../assets/img/login_icon_facebook.png"/>
          <p :class="$style.size">使用 Facebook 登入</p>
        </div>
        <div :class="[$style.iconlogin, 'clickCss']" @click="googleLogin">
          <img :class="$style.icon" src="../../assets/img/login_icon_google.png"/>
          <p :class="$style.size">使用 Google 登入</p>
        </div>
      </div>
      <div :class="$style.inputout">
        <span :class="$style.titlesize">
          手機/信箱
        </span>
        <input
          :class="$style.input"
          placeholder="請填寫手機/信箱"
          v-model="login.username"
        />
      </div>
      <div :class="[$style.inputout, $style.nomargin]">
        <span :class="$style.titlesize">
          密碼
        </span>
        <input
          :class="$style.input"
          placeholder="請填寫密碼"
          type="password"
          v-model="login.password"
        />
      </div>
      <p v-if="messagetext" :class="$style.red">
        {{ messagetext }}
      </p>
      <div
        :class="[$style.forget, 'clickCss']"
        @click="routerFun">
        <span>忘記密碼</span>
      </div>
    </template>
    <template #main v-else>
      <resign :navVal="navVal" @unResign="loginBoolean = true"/>
    </template>
    <template #btn>
      <div
        :class="[$style.loginbtn, 'clickCss']"
        @click="loginFn"
        v-if="loginBoolean"
      >
        登入
      </div>
      <div :class="$style.sign">
        {{ loginBoolean ? '還沒有帳號嗎？' : '' }}
        <span
          :class="[$style.bottomline, 'clickCss']"
          @click="loginBoolean = !loginBoolean"
        >
          {{ loginBoolean ? '點擊這裡註冊' : '已有帳號' }}
        </span>
      </div>
      <div
        :class="[$style.loginbtn, 'clickCss']"
        @click="visitorRegist"
        v-if="loginBoolean"
      >
        訪客登入
      </div>
      <div :class="$style.sign" v-if="loginBoolean">
        ＊免註冊，直接以訪客身份登入
      </div>
    </template>
  </popup>
</template>

<script>
import popup from '@/components/popup/popup.vue'
import resign from '@/views/login/resign.vue'
import login from '@/views/login/login.vue'
import { requestApi } from '@/request/request'
export default {
  components: {
    popup,
    resign,
    login
  },
  data () {
    return {
      messagetext: null,
      loginBoolean: true,
      navVal: 'common',
      login: {
        username: '',
        password: '',
        userNameEmail: ''
      },
      visitorRegister: {
        lastname: 'wang',
        user_nickname: 'yiwei',
        user_mobile: '0918777654',
        user_id: '',
        password: 'Abc12345678',
        repassword: 'Abc12345678',
        email: Math.random().toString(36).substr(2) + '@visitor.com',
        ok: true,
        group_id: 1
      }
    }
  },
  watch: {
    login: {
      handler () {
        this.messagetext = null
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    routerFun () {
      this.$emit('close')
      this.$router.push({ name: 'resetpassword' })
    },
    fallLogin () {
      this.$store.commit({ type: 'userToken', userToken: null })
      this.$store.commit({ type: 'userData', userData: null })
      this.$store.commit({ type: 'userIdentity', userIdentity: null })
    },
    validateEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.login.userNameEmail).toLowerCase())
    },
    async loginApi () {
      await Promise.all([
        this.$store.dispatch({
          type: 'getTrueShopCarId'
        }),
        this.$store.dispatch({
          type: 'itemAction',
          action: 'getItem',
          catchList: true
        })
      ])
    },
    async loginFn () {
      this.$emit('loading', true)
      try {
        if (this.login.username && this.login.password) {
          if (this.login.username.match(/^09[0-9]{8}/)) {
            const url = '/openwebapi.php'
            const response = await requestApi.post(url, {
              action: 'getEmailByMobile',
              mobileNumber: this.login.username
            })
            if (response.data.length < 1) {
              this.$message.error('帳號密碼輸入錯誤，請確認後再進行輸入。')
              return
            }
            this.login.userNameEmail = response.data[0].email
          }
          if (!this.validateEmail()) {
            this.$message.error('帳號格式錯誤')
            return
          }
          await this.$store.dispatch({
            type: 'getUserToken',
            username: this.login.userNameEmail,
            password: this.login.password
          })
          await this.$store.dispatch({
            type: 'getUserData'
          })
          this.$emit('close')
          const ele = this.$store.state.loginInfo.userIdentity
          switch (ele) {
            case 1:
            case 2:
            case 3:
              if (localStorage.getItem('share')) {
                window.location.href = localStorage.getItem('share')
              } else {
                await this.loginApi()
                this.$router.push({ name: 'home' })
              }
              break
            case 4:
            case 6:
              await this.loginApi()
              this.$router.push({
                name: 'kolinformation',
                params: {
                  userId: this.$store.state.loginInfo.userData.id
                }
              })
              break
            case 5:
            case 7:
            case 8:
            case 9:
              await this.loginApi()
              this.$router.push({
                name: 'employeelist',
                params: {
                  typeValue: '5645',
                  current: 1
                }
              })
              break
          }
        } else {
          this.messagetext = '信箱/手機和密碼不可為空的'
        }
      } finally {
        this.$emit('loading', false)
      }
    },
    async visitorRegist () {
      await this.$store.dispatch({
        type: 'emailAvailable',
        customerEmail: this.visitorRegister.email
      })
      await this.$store.dispatch({
        type: 'registerok',
        data: this.visitorRegister
      })
      await this.$store.dispatch({
        type: 'getUserToken',
        username: this.visitorRegister.email,
        password: this.visitorRegister.password
      })
      await this.$store.dispatch({
        type: 'getUserData'
      })
      this.$emit('close')
    },
    async saveToken (value) {
      this.$emit('loading', true)
      if (value.token) {
        try {
          await localStorage.setItem('userToken', value.token)
          await this.$store.commit({ type: 'userToken', userToken: value.token })
          if (localStorage.getItem('share')) {
            window.location.href = localStorage.getItem('share')
          } else {
            await Promise.all([
              this.$store.dispatch({ type: 'getUserData' }),
              this.$store.dispatch({ type: 'itemAction', action: 'getItem', catchList: true }),
              this.$store.dispatch({ type: 'getTrueShopCarId' })
            ])
            this.$message('已登入！')
            this.$router.push({ name: 'home' })
          }
        } finally {
          this.$emit('close')
          this.$emit('loading', false)
        }
      } else {
        try {
          await this.$store.dispatch({
            type: 'registerok',
            data: {
              lastname: value.name,
              user_nickname: '未設定',
              user_mobile: '0900000000',
              user_id: value.email,
              password: 'sdkasjdkaskjdkassadasddjaQ!',
              email: value.email
            }
          })
          await this.$store.dispatch({
            type: 'getUserToken',
            username: value.email,
            password: 'sdkasjdkaskjdkassadasddjaQ!'
          })
          await this.$store.dispatch({
            type: 'getUserData',
            username: value.email,
            password: 'sdkasjdkaskjdkassadasddjaQ!'
          })
          this.$notify.success({
            title: '註冊成功',
            message: '恭喜註冊成功！'
          })
          this.$router.push({ name: 'home' })
        } catch {
          this.$notify.error('登入失敗，請重新登入！')
        } finally {
          this.$emit('loading', false)
        }
      }
    },
    googleLogin () {
      // 第三方登陸
      window.open('https://shop.cvicloud-srv.net/social/google.php')
    },
    fbLogin () {
      // 第三方登陸
      window.open('https://shop.cvicloud-srv.net/social/fb.php')
    }
  },
  created () {
    window.saveToken = this.saveToken
  }
}
</script>

<style lang="scss" module>
.inputout {
  display: grid;
  grid-template-columns: 85px 1fr auto;
  width: 350px;
  font-size: 14px;
  padding-bottom: 8px;
  border-bottom: 2px solid #F7F8F9;
  margin: 0 auto 24px auto;
  align-items: center;

  .input {
    color: #828282;
    font-size: 14px;
    border: 0;
  }

  &.nomargin {
    margin: 0 auto 10px auto;
  }
}

.iconout {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.iconlogin {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 40px;
  border-radius: 999rem;
  border: 2px solid #eaebec;
  font-size: 14px;
  cursor: pointer;
}

.title {
  border-bottom: 3px solid #ff6100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100px;
}

.selsctsize {
  font-size: 13px;
  height: 20px;
  min-width: 130px;
  appearance: none;
  border: 0px solid;
  border-radius: 0;
  background-image: url(../../assets/img/botton_arrow.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

.red {
  width: 100%;
  display: flex;
  justify-content: center;
  color: red;
}

.forget {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline !important;
  color: #FF6100;
  display: flex;
  justify-content: flex-end;
  width: 320px;
  margin: 0 auto;
}

.loginbtn {
  cursor: pointer;
  width: 320px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 999rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px auto;
  background-color: #ff6100;
}

.sign {
  width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomline {
  cursor: pointer;
  font-size: 14px;
  color: #ff6100;
  text-decoration: underline;
}

.icon {
  width: 23px;
  height: auto;
  margin-right: 5px;
}

.size {
  width: 140px;
  text-align: center;
}

.welcome {
  width: 200px;
  height: auto;
  margin: auto;
  margin-bottom: 30px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  color: #B5B5B5;
  width: 100%;
  padding: 23px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1365px) {
  .inputout {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
  }

  .titlesize {
    text-align: center;
    width: 100%;
  }

  .input {
    color: #828282;
    padding: 0 14px;
  }

  .forget {
    padding: 0;
  }

  .red {
    width: 100%;
  }

  .iconout {
    width: 100%;
    padding: 0;
  }

  .iconlogin {
    width: 222px;
    height: 35px;
    border: solid 1px #F7F8F9;
    margin-bottom: 11px;
  }

  .size {
    width: 155px;
  }
}

@media (max-width: 767px) {
  .inputout {
    display: flex;
  }

  .input {
    width: calc(100% - 120px);
  }

  .selsctsize {
    min-width: unset;
    width: 60px;
    padding-right: 20px;
  }

  .forget {
    width: 80px;
  }

  .loginbtn {
    max-width: 100%;
    height: 40px;
  }
}
</style>
