<template>
  <div :class="$style.root">
    <div :class="$style.text">
      <div
        @click="watchimage = true"
        :class="$style.img"
      >
        <img :class="$style.img2" :src="commonUserInfo.userimage"/>
      </div>
      <p :class="[$style.textover, 'textlangone']">
        {{ commonUserInfo.account }}
      </p>
      <div :class="$style.bgcolor" :style="{ background: `${level}` }">
        {{ commonUserInfo.gradeText }}
      </div>
    </div>
    <watchimage
      :image="commonUserInfo.userimage"
      v-if="watchimage"
      @close="watchimage = null"
    />
  </div>
</template>

<script>
import watchimage from '@/components/outpu/imagebig.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    watchimage
  },
  computed: {
    ...mapGetters([
      'commonUserInfo'
    ]),
    level () {
      const url = this.commonUserInfo.gradeNumber
      let color
      switch (url) {
        case 1:
        case 5:
          color = '#FF6100'
          break
        case 2:
        case 8:
          color = 'linear-gradient(180deg, #FFE143 0%, #FFFDF2 51.56%, #FFE143 100%)'
          break
        case 3:
        case 6:
          color = 'linear-gradient(180deg, #0075FF 0%, #92C4FF 53.12%, #0075FF 100%)'
          break
        case 7:
          color = 'linear-gradient(180deg, #00D77F 0%, #95F8CF 51.56%, #00D77F 100%)'
          break
      }
      return color
    }
  },
  data () {
    return {
      watchimage: false
    }
  }
}
</script>

<style lang="scss" module>
.root {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.textover {
  flex: 1;
  padding-right: 20px;
  box-sizing: border-box;
}

.img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  border: 1px solid #ccc;
  border-radius: 999rem;
  cursor: pointer;
  overflow: hidden;
}

.img2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
}

.bgcolor {
  margin-left: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 5px 17px;
  box-sizing: border-box;
  border-radius: 999rem;
  background-color: #ff6100;
}

@media (max-width: 1365px) {
  .root {
    width: 100%;
    justify-content: space-between;
  }

  .text {
    font-size: 14px;
  }

  .bgcolor {
    margin-left: 40px;
    font-size: 16px;
    padding: 5px 17px;
    box-sizing: border-box;
  }

  .img {
    width: 40px;
    height: 40px;
  }
}
</style>
