<template>
  <div :class="$style.root">
    <div :class="$style.fare2">
      <div :class="$style.fare2name">
        {{ data.name }}
      </div>
      <div :class="$style.fare2out">
        <div :class="$style.fare2inner">
          <div :class="[$style.svg, {[$style.svg3]: data.isGet }]">
            {{ Math.floor(data.discount_amount) }}
          </div>
          <p
            :class="[$style.fare2check, {[$style.get]: data.isGet }]"
            @click="!data.isGet ? receiveCoupon(data) : ''"
          >
            {{ data.isGet ? '已領取' : '點擊優惠券領取' }}
          </p>
        </div>
        <div :class="$style.fare2text">
          {{ data.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coupon from '@/js/coupon.js'
export default {
  mixins: [
    coupon
  ],
  props: {
    data: null
  }
}
</script>

<style lang="scss" module>
.fare2 {
  padding: 13px 16px;
  box-sizing: border-box;
  border-radius: 0 10px 10px 10px;
  border: solid 2px #ffc000;
  background-color: #ffffff;
}

.fare {
  padding: 14px 18px 4px 18px;
  box-sizing: border-box;
  width: calc(100% - 45px - 37px);
  border-radius: 0 10px 10px 10px;
  border: solid 2px #ffc000;
  background-color: #ffffff;
}

.liststyle {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000000;
}

.liststyle2 {
  font-size: 12px;
  color: #828282;
  padding-bottom: 10px;
}

.outout2 {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #828282;
}

.name2 {
  display: flex;
  align-items: center;
  padding-left: 7px;
  height: 13px;
  border-left: 2px solid #000;
}

.fare2name {
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 13px;
  border-bottom: 1px solid #000;
}

.fare2out {
  padding-top: 18px;
  display: flex;
}

.svg {
  width: 94px;
  height: 65px;
  background: url(../../assets/img/smallcoupon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  font-weight: 500;
  font-size: 40px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  &.svg3 {
    background: url(../../assets/img/smallgetcoupon.svg);
  }
}

.fare2inner {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fare2check {
  padding-top: 10px;
  text-align: center;
  width: 110px;
  color: #00D77F;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;

  &.get {
    color: #ccc;
    cursor: not-allowed;
  }
}

.fare2text {
  line-height: 20px;
  color: #828282;
  font-size: 14px;
}
</style>
