import store from '../../store'
import Userlayout from '../../layout/userlayout.vue'
import Information from '../../views/user/information.vue'
import List from '../../views/user/list.vue'
import Redpoint from '../../views/user/redpoint.vue'
import Ticket from '../../views/user/ticket.vue'
import Favorite from '../../views/user/favorite.vue'
import Split from '../../views/user/split.vue'
import Message from '../../views/user/message.vue'

const isUser = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 1 || id === 2 || id === 3) {
    next()
  } else if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else {
    next({ name: 'home' })
  }
}

export default [{
  path: 'user/:userId',
  name: 'user',
  component: Userlayout,
  beforeEnter: isUser,
  redirect: { name: 'information' },
  children: [{
    path: 'information',
    name: 'information',
    component: Information
  }, {
    path: 'list/:current',
    name: 'list',
    component: List
  }, {
    path: 'redpoint/:current',
    name: 'redpoint',
    component: Redpoint
  }, {
    path: 'ticket/:current',
    name: 'ticket',
    component: Ticket
  }, {
    path: 'split/:current',
    name: 'split',
    component: Split
  }, {
    path: 'favorite/:current',
    name: 'favorite',
    component: Favorite
  }, {
    path: 'message/:current',
    name: 'message',
    component: Message
  }]
}]
