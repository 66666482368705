<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.title">訂單編號 / 下單時間</div>
      <div :class="[$style.title, $style.total]">總金額</div>
      <div :class="[$style.title, $style.status]">訂單狀態</div>
      <div :class="[$style.title, $style.operating]">操作</div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" module>
.inner {
  box-sizing: border-box;
  padding: 0 21px;
  display: flex;
  height: 100%;
  width: 100%;
}

.title {
  font-size: 14px;
  width: 280px;
  display: flex;
  align-items: center;

  &.total {
    width: 126px;
  }

  &.status {
    width: 121px;
  }

  &.operating {
    width: 218px;
  }
}
</style>
