<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <p :class="$style.titlestyle">為您推薦</p>
      <div class="productmain">
        <div
          class="productinner"
          v-for="(item, i) in commonList.list"
          :key="i"
          @click="getProductFn(i)"
        >
          <div
            class="productimg"
            :style="{ backgroundImage: `url(${ imgUrl + item.image })` }"
          />
          <div class="producttextout">
            <p class="textlang">{{ item.newName }}</p>
          </div>
          <div class="productoutside">
            <p class="productextdel">$ {{ item.oldPrizeNum }}</p>
            <p class="productextmain">$ {{ item.newPrizeNum }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { componentRouter } from '@/js'
export default {
  computed: {
    ...mapGetters([
      'commonList'
    ])
  },
  methods: {
    getProductFn (value) {
      componentRouter('commonProduct', this.commonList.list[value].sku, this.commonList.list[value].id)
    }
  },
  async created () {
    if (this.commonList.total <= 0) {
      this.$store.dispatch({
        type: 'getListProducts',
        typeValue: 5643,
        pageSize: 4
      })
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .root {
    background: #F7F8F9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    width: 1110px;
    box-sizing: border-box;
  }

  .titlestyle {
    font-size: 18px;
    padding-bottom: 57px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    background: #F7F8F9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner {
    width: 750px;
    box-sizing: border-box;
  }

  .titlestyle {
    font-size: 16px;
    padding-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .inner {
    width: 100%;
    height: 100%;
    background: #F7F8F9;
    padding: 15px;
    box-sizing: border-box;
  }

  .titlestyle {
    font-size: 16px;
    padding-bottom: 15px;
  }
}
</style>
