<template>
  <div :class="$style.root">
    <div :class="$style.nav">
      <div :class="$style.navinner">
        <router-link
          tag="div"
          :to="{ name: item.link }"
          v-for="(item, i) in listRouter.listRouter"
          :key="i"
          :active-class="$style.textactive"
          :class="$style.text"
        >
          {{ item.name }}
        </router-link>
      </div>
    </div>
    <router-view
      :title="listRouter.title"
      :class="$style.routerstyle"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
    }
  },
  watch: {
    async listProblemNumber () {
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'getFooterOnlyHtml',
          numberArray: this.listProblemNumber,
          name: 'listProblem'
        })
      } finally {
        this.$emit('loading', false)
      }
    },
    async $route (to, from) {
      this.$emit('loading', true)
      const toType = to.meta.list
      const fromType = from.meta.list
      try {
        if (toType !== fromType) {
          if (toType === 'contentUser') {
            await Promise.all([
              this.$store.dispatch({
                type: 'getFooterOnlyHtml',
                numberArray: [9],
                name: 'onlyHtml'
              }),
              this.$store.dispatch({
                type: 'getFooterOnlyHtml',
                numberArray: [7, 18],
                name: 'listHtml'
              })
            ])
          } else if (toType === 'aboutMe') {
            await Promise.all([
              this.$store.dispatch({
                type: 'getFooterOnlyHtml',
                numberArray: [5],
                name: 'onlyHtml'
              }),
              this.$store.dispatch({
                type: 'getFooterOnlyHtml',
                numberArray: [11, 19, 20, 21],
                name: 'listHtml'
              }),
              this.$store.dispatch({
                type: 'getFooterOnlyHtml',
                numberArray: [26],
                name: 'listProblemNumber'
              })
            ])
          } else if (toType === 'information') {
            await Promise.all([
              this.$store.dispatch({
                type: 'getFooterOnlyHtml',
                numberArray: [4, 14],
                name: 'onlyHtml'
              })
            ])
          }
        }
      } finally {
        this.$emit('loading', false)
      }
    }
  },
  computed: {
    ...mapGetters([
      'listProblemNumber'
    ]),
    listRouter () {
      const type = this.$route.meta.list
      if (type === 'contentUser') {
        return {
          listRouter: [
            { name: '聯絡CviMall', link: 'contentme' },
            { name: '加入CviMall', link: 'addme' }
          ],
          title: '聯絡我們'
        }
      } else if (type === 'aboutMe') {
        return {
          listRouter: [
            { name: '關於我們', link: 'info' },
            { name: '購物說明', link: 'shoppingguide' },
            { name: '常見問題', link: 'problem' }
          ],
          title: '關於 CviMall'
        }
      } else if (type === 'information') {
        return {
          listRouter: [
            { name: '隱私權政策', link: 'policy' },
            { name: '會員權益聲明', link: 'declaration' }
          ],
          title: '隱私權政策'
        }
      } else {
        return []
      }
    }
  },
  async created () {
    const type = this.$route.meta.list
    this.$emit('loading', true)
    try {
      if (type === 'contentUser') {
        await Promise.all([
          this.$store.dispatch({
            type: 'getFooterOnlyHtml',
            numberArray: [9],
            name: 'onlyHtml'
          })
        ], [
          this.$store.dispatch({
            type: 'getFooterOnlyHtml',
            numberArray: [7, 18],
            name: 'listHtml'
          })
        ])
      } else if (type === 'aboutMe') {
        await Promise.all([
          this.$store.dispatch({
            type: 'getFooterOnlyHtml',
            numberArray: [5],
            name: 'onlyHtml'
          }),
          this.$store.dispatch({
            type: 'getFooterOnlyHtml',
            numberArray: [11, 19, 20, 21],
            name: 'listHtml'
          }),
          this.$store.dispatch({
            type: 'getFooterOnlyHtml',
            numberArray: [26],
            name: 'listProblemNumber'
          })
        ])
      } else if (type === 'information') {
        await Promise.all([
          this.$store.dispatch({
            type: 'getFooterOnlyHtml',
            numberArray: [4, 14],
            name: 'onlyHtml'
          })
        ])
      }
    } finally {
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 50px 0;
    box-sizing: border-box;
    margin-top: 60px;
    min-height: calc(100vh - 130px);
  }

  .nav {
    padding-top: 38px;
    margin-right: 78px;
  }

  .navinner {
    padding: 80px 0 36px 0;
    box-sizing: border-box;
    width: 202px;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }

  .text {
    height: 34px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    font-weight: 500;
    font-size: 18px;
    color: #B5B5B5;
    box-sizing: border-box;
    margin-bottom: 44px;
  }

  .textactive {
    border-left: 10px solid  #00D77F;
    border-right: 10px solid  #00D77F;
    color: #000;
    box-sizing: border-box;
  }

  .routerstyle {
    width: 766px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    flex-direction: column;
  }

  .nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navinner {
    width: 720px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 11px;
    margin: 0 auto;
    box-sizing: border-box;
    height: 50px;
  }

  .text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
  }

  .textactive:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 24px;
    background: #00D77F;
    bottom: 0;
  }

}

@media (max-width: 767px) {
  .navinner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 11px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 44px;
  }

  .text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
  }

  .textactive:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 24px;
    background: #00D77F;
    bottom: 0;
  }

  .routerstyle {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
  }
}
</style>
