<template>
  <div :class="$style.root">
    <div
      :class="[$style.inner,
      'animate__animated',
      'animate__faster',
      {['animate__fadeInUpBig']: !webwatch}]
    ">
      <div :class="$style.top">
        <div :class="$style.close" @click="$emit('close')">
          <i :class="[$style.x, 'el-icon-close']"/>
        </div>
      </div>
      <div
        :style="{ backgroundImage: `url(${ qrcodeData })` }"
        :class="$style.qrcode"
      />
      <div :class="[$style.text, $style.textpos]">
        您的QRcode
      </div>
      <div :class="$style.text">
        <p>想賺取更多分潤嗎？ </p>
        <p>分享您的二維碼讓對方加入你的團隊吧！</p>
      </div>
      <div :class="$style.buttomout">
        <div :class="$style.buttom" @click="downloadImg">
          <i :class="[$style.icon, 'el-icon-download']"/>
          <div :class="$style.title">保存</div>
        </div>
        <div
          :class="$style.buttom"
          ref="copyButton"
          :data-clipboard-text="copy"
          @click="share"
        >
          <i :class="[$style.icon, 'el-icon-share']"/>
          <div :class="$style.title">分享</div>
        </div>
        <div :class="$style.buttom" @click="$emit('rule', !rule)">
          <i :class="[$style.icon, 'el-icon-question']"/>
          <div :class="$style.title">玩法</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode'
import Clipboard from 'clipboard'
export default {
  data () {
    return {
      qrcodeData: null,
      copy: window.location.origin +
        '/shareturn/' +
        this.$store.state.loginInfo.userData.id +
        '/' +
        this.$store.state.loginInfo.userData.firstname
    }
  },
  methods: {
    downloadImg () {
      const a = document.createElement('a')
      a.setAttribute('href', this.qrcodeData)
      a.setAttribute('download', `${this.$store.state.loginInfo.userData.firstname + '的QRcode -cvimall-'}`)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    share () {
      if (navigator.share) {
        navigator.share({
          title: `${this.$store.state.loginInfo.userData.firstname + '的QRcode -cvimall-'}`,
          text: `${this.$store.state.loginInfo.userData.firstname + '的QRcode -cvimall-'}`,
          url: `${window.location.origin +
            '/shareturn/' +
            this.$store.state.loginInfo.userData.id}` +
            '/' +
            this.$store.state.loginInfo.userData.firstname
        })
      } else {
        this.initClipboard()
      }
    },
    initClipboard () {
      const clipboard = new Clipboard(this.$refs.copyButton)
      clipboard.on('success', (e) => {
        this.$message.success('複製成功！')
      })
    }
  },
  async created () {
    if (this.online && this.online === 'common') {
      this.qrcodeData = await QRCode.toDataURL(
        `${window.location.origin +
        '/shareturn/' +
        this.$store.state.loginInfo.userData.id}` +
        '/' +
        this.$store.state.loginInfo.userData.firstname
      )
    }
  },
  mounted () {
    this.initClipboard()
  }
}
</script>

<style lang="scss" module>
.inner {
  padding: 16px 16px 24px 16px;
  box-sizing: border-box;
  width: 360px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.top {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.textpos {
  margin: 8px 0 12px 0;
  border-bottom: 2px solid #F2F2F2;
}

.close {
  width: 30px;
  height: 30px;
  background: #F2F2F2;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.x {
  font-size: 18px;
}

.qrcode {
  background: #F2F2F2;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 180px;
  height: 180px;
  border-radius: 10px;
  color: #828282;
  padding-bottom: 12px;
  box-sizing: border-box;
  border: 2px solid #F2F2F2;
}

.text {
  width: 100%;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #828282;
  padding-bottom: 12px;
}

.buttom {
  cursor: pointer;
  padding: 18px 15px;
  box-sizing: border-box;
  height: 30px;
  background: #FF6100;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}

.icon {
  color: #fff;
  font-size: 20px;
  width: 30px;
}

.buttomout {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 767px) {
  .root {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 4;
    display: flex;
    align-items: flex-end;
  }

  .inner {
    width: 100%;
  }

  .buttom {
    width: 30%;
  }

  .title {
    font-size: 12px;
  }
}
</style>
