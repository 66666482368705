<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <meta charset="utf-8"/>
      <meta name="robots" content="INDEX,FOLLOW"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="format-detection" content="telephone=no"/>
      <meta name="title" :content="momProductList.metaTitle"/>
      <meta name="description" :content="momProductList.metaDescription"/>
      <meta name="keywords" :content="momProductList.metaKeyword"/>
      <div :class="$style.innertop">
        <div :class="$style.innerinner">
          <imgcarousel
            :class="$style.carousel"
            :style="{ height: `${ carouselHeightImg }px` }"
            :productImageList="momProductList.productImage"
            :offShelf="offShelf"
          />
          <favoirtefn
            v-if="isMulti && webwatch && routerName !== 'employeeProduct'"
            :class="$style.iconout"
            :chikdSku="selectedItem || undefined"
            @loading="loading = $event"
          />
          <favoirtefn
            v-if="!isMulti && webwatch && routerName !== 'employeeProduct'"
            :class="$style.iconout"
            :chikdSku="childSkuData[childSkuNumberNotMulti] || undefined"
            @loading="loading = $event"
          />
        </div>
        <div :class="$style.area2">
          <!-- 多數量  -->
          <div :class="$style.productitle" @click="showChildSkuNumber">
            {{ momProductList.productname }}
            <p v-if="isMulti" class="productbuy">
              已售 {{ sellNumber }} 件
            </p>
            <p v-if="!isMulti" class="productbuy">
              已售 {{ childSkuData[childSkuNumberNotMulti].sellNumber }} 件
            </p>
          </div>
          <div :class="$style.moneyout">
            <p v-if="isMulti" :class="$style.oldprice">
              原價 $ {{ oldPrice }}
            </p>
            <p v-if="!isMulti" :class="$style.oldprice">
              原價 $ {{ childSkuData[childSkuNumberNotMulti].oldPrizeNum }}
            </p>
            <div v-if="isMulti" :class="$style.combobox">
              <p :class="$style.combonum">{{ childSkuNumber }} 入組合價</p>
            </div>
            <div v-if="!isMulti" :class="$style.combobox">
              <p :class="$style.combonum">最新優惠價</p>
            </div>
            <div :class="$style.newpricebox">
              <span v-if="routerName === 'kolProduct'">
                KOL推薦價：
              </span>
              <div v-if="isMulti" :class="$style.newprice"><p>$ {{ newPrice }}</p><p :class="$style.perunit">/入</p></div>
              <div v-if="!isMulti" :class="$style.newprice">$ {{ childSkuData[childSkuNumberNotMulti].newPrizeNum }}</div>
            </div>
          </div>
          <!-- 多數量  -->
          <favoirtefn
            :class="$style.iconout"
            v-if="isMulti && !webwatch && routerName !== 'employeeProduct'"
            :chikdSku="selectedItem || undefined"
            @loading="loading = $event"
          />
          <favoirtefn
            :class="$style.iconout"
            v-if="!isMulti && !webwatch && routerName !== 'employeeProduct'"
            :chikdSku="childSkuData[childSkuNumberNotMulti] || undefined"
            @loading="loading = $event"
          />
          <div class="listmargin" v-if="routerName === 'discountProduct'">
            <div class="listoutside">
              <p class="listtitle">優惠活動</p>
              <div :class="$style.orange">
                任選2件$399, 任選3件$499, 任選4件$599
              </div>
            </div>
          </div>
          <grouprule
            :groupData="{ amount: momProductList.groupingAmount,
              bonus: momProductList.groupingBonus }"
            class="listmargin"
            v-if="routerName === 'groupProduct'"
          />
          <coupon class="listmargin" v-if="online !== 'employee'"/>
          <redpoint
            v-if="isMulti && routerName !== 'employeeProduct'"
            class="listmargin"
            :number="number"
            :money="childSkuData && selectedItem.newPrizeNum"
          />
          <redpoint
            v-if="!isMulti && routerName !== 'employeeProduct'"
            class="listmargin"
            :number="number"
            :money="childSkuData && childSkuData[childSkuNumberNotMulti].newPrizeNum"
          />
          <sendmoney
            class="listmargin"
            v-if="routerName !== 'employeeProduct'"
          />
          <div class="listmargin">
            <div class="listoutside">
              <p class="listtitle">規格</p>
                <select
                  v-model="childSkuNumber"
                  v-if="isMulti"
                  placeholder="請選擇"
                  :class="[$style.selectstyle, 'textlangone']"
                >
                  <option
                    v-for="(item, i) in childSkuData"
                    :key="i"
                    :value="item.comboQty"
                  >
                  {{
                    item.comboQty + '入組'
                  }}
                  </option>
                </select>
                <select
                  v-model="childSkuNumberNotMulti"
                  placeholder="請選擇"
                  v-if="!isMulti"
                  :class="[$style.selectstyle, 'textlangone']"
                >
                  <option
                    v-for="(item, i) in childSkuData"
                    :key="i"
                    :value="i"
                  >
                    {{
                      Boolean(item.name.split(momProductList.productname + '-')[1])
                        ? item.name.split(momProductList.productname + '-')[1]
                        : item.name
                    }}
                  </option>
                </select>
              </div>
            </div>
          <counter
            v-if="isMulti"
            @number="number = $event"
            :surplus="selectedItem.stock"
            class="listmargin"
          />
          <counter
            v-if="!isMulti"
            @number="number = $event"
            :surplus="childSkuData[childSkuNumberNotMulti].stock"
            class="listmargin"
          />
          <div :class="$style.outout" v-if="btnOK">
            <div
              v-if="isMulti && routerName !== 'groupProduct'"
              :class="[$style.btn, 'clickCss', {[$style.unsetting]: selectedItem.stock <= 0}]"
              @click="selectedItem.stock > 0 && offShelf ? addShopFn() : ''"
            >
                <span>加入購物車</span>
            </div>
            <div
              v-if="!isMulti && routerName !== 'groupProduct'"
              :class="[$style.btn, 'clickCss', {[$style.unsetting]: childSkuData[childSkuNumberNotMulti].stock <= 0}]"
              @click="childSkuData[childSkuNumberNotMulti].stock > 0 && offShelf ? addShopFn() : ''"
            >
                <span>加入購物車</span>
            </div>
            <div
              v-if="isMulti"
              @click="offShelf ? byShopFn(shoppingBtn.btntext) : ''"
              :class="[$style.btn2, 'clickCss', {[$style.unsetting2]: shoppingBtn.btntext === 'no' || selectedItem.stock <= 0 || !offShelf }]"
            >
              {{ shoppingBtn.text }}
            </div>
            <div
              v-if="!isMulti"
              @click="offShelf ? byShopFn(shoppingBtn.btntext) : ''"
              :class="[$style.btn2, 'clickCss', {[$style.unsetting2]: shoppingBtn.btntext === 'no' || childSkuData[childSkuNumberNotMulti].stock <= 0 || !offShelf }]"
            >
              {{ shoppingBtn.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="hr"/>
      <opengrouplist
        :groupSuccessNumber="momProductList.groupingAmount"
        @haveOpen="haveOpen = $event"
        @addShopFn="addShopFn()"
        v-if="routerName === 'groupProduct'"
        @loading="loading = $event"
      />
      <div class="hr" v-if="routerName === 'groupProduct'"/>
      <div :class="$style.area4">
        <div v-if="routerName === 'commonProduct'">
          <div v-if="isMulti" :class="$style.combocontainer">
            <div
                :class="[$style.one, $style.two]"
              >
                組合方案
            </div>
            <combo @update-childSkuNumber="updateChildSkuNumber"></combo>
          </div>
        </div>
        <div :class="$style.topbtn">
          <div
            :class="[$style.one, { [$style.two]: active === 0 }]"
            @click="active = 0"
          >
            商品介紹
          </div>
          <div
            v-if="routerName !== 'employeeProduct'"
            :class="[$style.one, { [$style.two]: active === 1 }]"
            @click="active = 1"
          >
            評價
          </div>
        </div>
        <product
          :class="$style.commodity"
          v-if="active === 0"
          :productmessage="momProductList ? momProductList.description : 'NaN'"
        />
        <assess
          :class="$style.commodity"
          v-else-if="active !== 0 && routerName !== 'employeeProduct'"
          @loading="loading = $event"
        />
      </div>
    </div>
    <recommend
      v-if="routerName !== 'employeeProduct'"
      :class="$style.recommendstyle"
    />
    <loadingCom v-if="loading"/>
    <loginpopup
      v-if="componentName === 'loginpopup'"
      @close="componentName = null"
    />
  </div>
</template>

<script>
import imgcarousel from '@/views/common/imgcarousel.vue'
import product from '@/views/common/product.vue'
import assess from '@/views/common/assess.vue'
import recommend from '@/components/recommend.vue'
import favoirtefn from '@/components/allproductplug/favoirtefn.vue'
import coupon from '@/components/allproductplug/coupon.vue'
import redpoint from '@/components/allproductplug/redpoint.vue'
import sendmoney from '@/components/allproductplug/sendmoney.vue'
import counter from '@/components/allproductplug/counter.vue'
import productimg from '@/js/productimg'
import grouprule from '@/components/grouprule.vue'
import opengrouplist from '@/components/group/opengrouplist.vue'
import { toUserShippingPage, toUserGropeShipPage } from '@/js'
import { mapGetters } from 'vuex'
import loadingCom from '@/components/outpu/loading2.vue'
import combo from './combo.vue'
import loginpopup from '@/views/login/loginpopup.vue'

export default {
  mixins: [
    productimg
  ],
  components: {
    imgcarousel,
    product,
    assess,
    recommend,
    favoirtefn,
    coupon,
    redpoint,
    sendmoney,
    counter,
    grouprule,
    opengrouplist,
    loadingCom,
    combo,
    loginpopup
  },
  data () {
    return {
      btnOK: false,
      haveOpen: null,
      childSkuNumber: 1,
      childSkuNumberNotMulti: 0,
      number: 1,
      active: 0,
      addloading: false,
      btn: [{
        name: '商品介紹'
      }, {
        name: '評價'
      }],
      selectedItem: {},
      componentName: null
    }
  },
  watch: {
    async $route () {
      await this.catchApi(this.$route.params.skuId, this.$route.params.Id)
    },
    childSkuNumber (value) {
      const array = this.childSkuData
      const found = array.find((item) => item.comboQty === value)
      this.selectedItem = found
    }
  },
  computed: {
    ...mapGetters([
      // 母sku產品
      'momProductList',
      // sku規格
      'childSkuData',
      'skuListOnlyName',
      'groupListLink'
    ]),
    isMulti () {
      const isMulti = Boolean(this.momProductList.isMulti)
      return isMulti
    },
    newPrice () {
      const newPrice = this.selectedItem.perNewPrice
      return newPrice
    },
    oldPrice () {
      const oldPrice = this.selectedItem.perOldPrice
      return oldPrice
    },
    sellNumber () {
      const sellNumber = this.selectedItem.sellNumber
      return sellNumber
    },
    offShelf () {
      // 下架
      if (this.momProductList) {
        let boo
        this.momProductList.status === 2
          ? boo = false
          : boo = true
        return boo
      } else {
        return false
      }
    },
    shoppingBtn () {
      let text
      let btntext
      if (this.routerName === 'groupProduct') {
        if (this.momProductList.groupingAmount && this.momProductList.groupingBonus) {
          if (this.$route.query.openId) {
            if (this.haveOpen) {
              text = '您已開團成功'
              btntext = 'no'
            } else if (this.online && this.online === 'common' && this.groupListLink.status === 1) {
              text = '加入' + this.groupListLink.owner_name + '的拼團'
              btntext = 'add'
            } else if (this.online && this.online === 'common') {
              text = this.groupListLink.owner_name + '目前還在開團階段喔 '
              btntext = 'no'
            } else {
              text = '請登入一般會員後再進行團購唷！'
              btntext = 'no'
            }
          } else if (this.haveOpen) {
            text = '您已開團成功'
            btntext = 'no'
          } else if (this.selectedItem.stock > 0 && this.selectedItem.isMulti) {
            text = '我要開團'
            btntext = 'open'
          } else if (this.childSkuData[this.childSkuNumberNotMulti].stock > 0 && this.childSkuData[this.childSkuNumberNotMulti].isMulti === false) {
            text = '我要開團'
            btntext = 'open'
          } else {
            text = '尚無庫存'
            btntext = 'no'
          }
        } else {
          text = '系統資料不足'
          btntext = 'no'
        }
      } else if (this.selectedItem.stock <= 0 && this.selectedItem.isMulti) {
        text = '直接購買'
        btntext = 'no'
      } else if (this.childSkuData[this.childSkuNumberNotMulti].stock <= 0 && this.childSkuData[this.childSkuNumberNotMulti].isMulti === false) {
        text = '直接購買'
        btntext = 'no'
      } else {
        text = '直接購買'
        btntext = ''
      }
      return { text, btntext }
    },
    haveToCart () {
      if (this.momProductList.isMulti) {
        let have1 = this.skuListOnlyName.skuListOnlyName.indexOf(this.selectedItem.sku)
        const data1 = have1 >= 0 ? this.skuListOnlyName.list[have1] : undefined
        have1 >= 0 ? have1 = true : have1 = false
        return { have1, data1 }
      } else {
        let have2 = this.skuListOnlyName.skuListOnlyName.indexOf(this.childSkuData[this.childSkuNumberNotMulti].sku)
        const data2 = have2 >= 0 ? this.skuListOnlyName.list[have2] : undefined
        have2 >= 0 ? have2 = true : have2 = false
        return { have2, data2 }
      }
    }
  },
  methods: {
    updateChildSkuNumber (selectedSet) {
      console.log('selectedSet/product', selectedSet)
      this.childSkuNumber = selectedSet
    },
    showChildSkuNumber () {
      console.log('momSkuData', this.momProductList)
      console.log('childSkuData', this.childSkuData)
      console.log('childSkuNumber', this.childSkuNumber)
      console.log(this.selectedItem)
    },
    async byShopFn (type) {
      if (this.shoppingBtn.btntext !== 'no') {
        if (this.online !== 'kol' && this.online) {
          this.loading = true
          const userId = this.$store.state.loginInfo.userData.id
          const cartId = this.$store.state.shopping.trueShopCarId
          if (this.routerName === 'groupProduct') {
            try {
              await this.addShopFnInner()
              localStorage.setItem('groupLink', this.$route.path)
              if (this.$route.query.openId) {
                const openId = this.groupListLink.entity_id
                const groupId = this.groupListLink.owner_customer_id
                toUserGropeShipPage('groupshoplist', userId, cartId, type, openId, groupId)
              } else {
                toUserGropeShipPage('groupshoplist', userId, cartId, type)
              }
            } catch {
              this.$notify.error('購買失敗！')
            } finally {
              this.loading = false
            }
          } else {
            this.loading = true
            try {
              await this.addShopFnInner()
              if (this.momProductList.isMulti) {
                localStorage.setItem('pickSkuId', this.selectedItem.sku)
              } else {
                localStorage.setItem('pickSkuId', this.childSkuData[this.childSkuNumberNotMulti].sku)
              }
              if (this.online === 'employee') {
                toUserShippingPage('employeeshoplist', userId, cartId)
              } else {
                toUserShippingPage('shoppinglistto', userId, cartId)
              }
            } catch {
              this.$notify.error('購買失敗！')
            } finally {
              this.loading = false
            }
          }
        } else if (this.online === 'kol' && this.online) {
          this.$notify({
            message: '請登入一般會員或是員工會員進行購買喔！',
            type: 'warning'
          })
        } else {
          this.$notify({
            title: '尚未登入',
            message: '請先登入才能將您的寶貝加入購物車喔',
            type: 'warning'
          })
        }
      }
    },
    // 購物車
    async addShopFn () {
      if (this.online && this.online !== 'kol') {
        this.loading = true
        try {
          await this.addShopFnInner()
        } catch (err) {
          this.$notify.error('加入失敗！')
          return err
        } finally {
          this.loading = false
        }
      } else {
        // this.$notify({
        //   message: '請登入一般會員或是員工會員進行購買喔！',
        //   type: 'warning'
        // })
        this.componentName = 'loginpopup'
      }
    },
    async addShopFnInner () {
      if (this.momProductList.isMulti) {
        await this.$store.dispatch({
          type: 'itemAction',
          action: this.haveToCart.have ? 'updateItem' : 'addItem',
          catchList: true,
          bodyObj: {
            parentSku: this.$route.params.skuId,
            qty: this.haveToCart.data ? Number(this.number) + Number(this.haveToCart.data.number) : this.number,
            sku: this.selectedItem.sku,
            idx: this.haveToCart.data ? this.haveToCart.data.idx : undefined
          }
        })
      } else {
        await this.$store.dispatch({
          type: 'itemAction',
          action: this.haveToCart.have ? 'updateItem' : 'addItem',
          catchList: true,
          bodyObj: {
            parentSku: this.$route.params.skuId,
            qty: this.haveToCart.data ? Number(this.number) + Number(this.haveToCart.data.number) : this.number,
            sku: this.childSkuData[this.childSkuNumberNotMulti].sku,
            idx: this.haveToCart.data ? this.haveToCart.data.idx : undefined
          }
        })
      }

      await this.$store.dispatch({
        type: 'itemAction',
        action: 'getItem',
        catchList: true
      })
      this.$message({
        message: '成功加入！',
        type: 'success'
      })
    },
    async catchApi (momsku, momId) {
      this.$emit('loading', true)
      try {
        await Promise.all([
          // 母sku
          this.$store.dispatch({
            type: 'product',
            sku: momsku
          }),
          // 所有規格(子sku)
          this.$store.dispatch({
            type: 'productChildren',
            sku: momsku
          }),
          this.$store.dispatch({
            type: 'getProductScore',
            productId: momId,
            currentPage: 1
          }),
          this.$store.dispatch({
            type: 'childStockProduct',
            sku: [momId]
          })
        ])
        this.btnOK = true
      } catch {
        this.btnOK = false
        this.$emit('loading', false)
        this.$message.error('商品載入失敗。')
      } finally {
        this.$emit('loading', false)
      }
    }
  },
  async created () {
    await this.catchApi(this.$route.params.skuId, this.$route.params.Id)
    if (this.online && this.$route.query.openId && this.routerName === 'groupProduct') {
      await this.$store.dispatch({
        type: 'getGroupFunction',
        action: 'getGroupbuyAvailableById',
        bodyObj: {
          id: this.$route.query.openId
        }
      })
    }
    if (this.momProductList.isMulti) {
      this.selectedItem = this.childSkuData[0]
    }
  },
  beforeDestory () {
    this.$store.commit({ type: 'product', product: null })
    this.$store.commit({ type: 'productChilinn', productChilinn: null })
    this.$store.commit({ type: 'groupListLink:', groupListLink: null })
    this.$store.commit({ type: 'favoriteBoolean:', favoriteBoolean: undefined })
  }
}
</script>

<style lang="scss" module>

.combocontainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.addloading {
  font-size: 30px;
  color: #ff6100;
}

.orange {
  color: #ff6100;
}

.moneyout {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 0.8rem;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  // padding-bottom: 24px;
}

.productitle {
  font-size: 24px;
  padding-bottom: 0.6rem;
  border-bottom: 3px solid #C4C4C4;
}

.oldprice {
  padding-top: 1rem;
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  font-size: 14px;
  text-decoration: line-through;
}

.combobox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0.3rem;
}

.combonum {
  padding: 0.2rem 1rem;
  border-radius: 5px;
  background-color: #ff6100;
  color: white;
}

.newpricebox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.newprice {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ff6100;
  font-size: 32px;
}

.perunit {
  display: inline-block;
  font-size: 18px;
  margin-top: 0.5rem;
  margin-left: 0.2rem;
}

@media (min-width: 1366px) {
  .rootinner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .innertop {
    padding: 80px 0;
    display: flex;
    width: 1100px;
  }

  .carousel {
    width: 450px;
    height: 450px;
  }

  .area2 {
    padding-left: 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .iconout {
    padding-top: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .area4 {
    width: 1100px;
    padding: 80px 0 40px 0;
  }

  .topbtn {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #FBFBFB;
  }

  .one {
    width: 160px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #828282;
    border-bottom: 2px solid #FBFBFB;
    padding-bottom: 16px;
    cursor: pointer;

    &.two {
      color: #000000;
      border-bottom: 2px solid #ff6100;
    }
  }

  .outout {
    padding-top: 58px;
    display: flex;
    flex: 1;
    align-items: flex-end;
  }

  .btn {
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    width: 180px;
    height: 48px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
  }

  .btn2 {
    padding: 0 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    min-width: 180px;
    height: 48px;
    border-radius: 999rem;
    background-color: #ff6100;
    border: solid 1px #ff6100;
  }

  .selectstyle {
    width: 100%;
    cursor: pointer;
    flex: 1;
    width: 100%;
    padding: 0 30px 0 10px;
    box-sizing: border-box;
    height: 40px;
    font-size: 14px;
    appearance: none;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;

    &::placeholder {
      color: red;
    }
  }

  .recommendstyle {
    width: 100%;
    height: 624px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .rootinner {
    width: 720px;
    margin: 0 auto;
  }

  .oldprice {
  padding-top: 0;
  }

  .innertop {
    padding: 0;
    display: block;
    width: unset;
  }

  .area2 {
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .moneyout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    border: 0;
  }

  .carousel {
    width: 450px;
    height: 450px;
    margin: 0 auto;
  }

  .iconout {
    margin-top: 20px;
    margin-bottom: -20px;
    padding: 11px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #FBFBFB;
    border-bottom: 2px solid #FBFBFB;
  }

  .topbtn {
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .one {
    width: 150px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #828282;
    border-bottom: 2px solid #FBFBFB;
    padding-bottom: 10px;

    &.two {
      color: #000000;
      border-bottom: 2px solid #ff6100;
      padding-bottom: 10px;
      box-sizing: border-box;
    }
  }

  .commodity {
    padding: 10px 20px 30px 20px;
    box-sizing: border-box;
  }

  .outout {
    padding: 27px 0 30px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #000000;
    width: 45%;
    height: 45px;
    border-radius: 999rem;
    border: solid 1px #ff6100;
  }

  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    width: 45%;
    height: 45px;
    border-radius: 999rem;
    background-color: #ff6100;
  }

  .selectstyle {
    flex: 1;
    width: 100%;
    padding: 0 30px 0 10px;
    box-sizing: border-box;
    height: 40px;
    font-size: 14px;
    appearance: none;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;
  }

  .area4 {
    width: 100%;
    padding: 0;
  }

  .recommendstyle {
    width: 100%;
    height: 380px;
  }
}

@media (max-width: 767px) {
  .rootinner {
    align-items: unset;
  }

  .innertop {
    padding: 0;
    display: block;
    width: unset;
  }

  .area2 {
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .moneyout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    border: 0;
  }

  .carousel {
    width: 100%;
  }

  .iconout {
    margin-top: 20px;
    padding: 11px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #FBFBFB;
    border-bottom: 2px solid #FBFBFB;
  }

  .out {
    display: grid;
    grid-template-columns: 45px 1fr;
    grid-auto-rows: 28px;
    grid-column-gap: 37px;
    width: 100%;
    padding-top: 10px;
  }

  .right {
    display: flex;
    align-items: center;

    &.right2 {
      justify-content: space-between;
    }

    &.selectout {
      display: grid;
      grid-template-columns: 45% 45%;
      grid-auto-rows: 28px;
      justify-content: space-between;
      grid-row-gap: 8px;
    }
  }

  .topbtn {
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .one {
    width: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #828282;
    border-bottom: 2px solid #FBFBFB;
    padding-bottom: 10px;

    &.two {
      color: #000000;
      border-bottom: 2px solid #ff6100;
      padding-bottom: 10px;
      box-sizing: border-box;
    }
  }

  .commodity {
    padding: 10px 20px 30px 20px;
    box-sizing: border-box;
  }

  .outout {
    padding: 27px 0 30px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #FBFBFB
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    width: 152px;
    height: 32px;
    border-radius: 36px;
    border: solid 1px #ff6100;
  }

  .btn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    width: 152px;
    height: 32px;
    border-radius: 36px;
    background-color: #ff6100;
  }

  .selectstyle {
    flex: 1;
    width: 100%;
    padding: 0 30px 0 10px;
    box-sizing: border-box;
    height: 28px;
    font-size: 14px;
    appearance: none;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    background-image: url(../../assets/img/botton_arrow.png);
    background-size: 30px 30px;
    background-position: right;
    background-repeat: no-repeat;
  }

  .area4 {
    width: 100%;
    padding: 0;
  }

  .recommendstyle {
    width: 100%;
    height: 305px;
  }

  .oldprice {
padding-top: 0;
}
}

.unsetting {
  border: 1px solid #ccc;
  cursor: not-allowed !important;
}

.unsetting2 {
  background: #ccc;
  border: 1px solid #ccc;
  cursor: not-allowed !important;
}
</style>
