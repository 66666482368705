import { request } from '@/request/request'

const hotJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    className: null
  },
  mutations: {
    className (state, payload) {
      state.className = payload.className
    }
  },
  actions: {
    async getClassName (store, payload) {
      const url = '/categories/:categoryId/products'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          categoryId: 'category_id'
        }
      })
      store.commit({ type: 'className', className: response.data })
    }
  },
  getters: {
    className: (state) => {
      if (state.className) {
        return state.className
      } else {
        return []
      }
    }
  }
}

export default {
  hotJs
}
