const listimg = {
  data () {
    return {
      carouselHeight: null
    }
  },
  methods: {
    webWatchFn () {
      if (document.body.offsetWidth > 1365) {
        this.carouselHeight = 450
        this.webwatch = true
      } else if (document.body.offsetWidth < 767) {
        const w = document.body.offsetWidth
        this.carouselHeight = w / 2
        this.webwatch = false
      } else {
        this.carouselHeight = 385
        this.webwatch = false
      }
    }
  },
  mounted () {
    this.webWatchFn()
    window.addEventListener('resize', this.webWatchFn)
  },
  beforeDestory () {
    window.removeEventListener('resize', this.webWatchFn)
  }
}

export default listimg
