<template>
  <div :class="$style.root">
    <div :class="[$style.list, { [$style.emstyle]: online === 'employee' }]">
      <div
        :class="$style.listinner"
        v-for="(item, i) in listData"
        :key="i"
      >
        <input
          v-if="item.stock[item.info.sku] > 0 && routerName !== 'groupshoplist'"
          type="checkbox"
          :class="$style.checkbox"
          v-model="item.checkbox"
        />
        <div v-else></div>
        <div
          @click="productRouter(i)"
          :class="$style.img"
          :style="{ backgroundImage: `url(${ imgUrl + item.img})` }"
        >
          <p
            v-if="item.stock[item.info.sku] <= 0"
            :class="$style.nothgin"
          >
            已售完
          </p>
        </div>
        <div :class="$style.inner">
          <div :class="$style.innertop">
            <div
              :class="$style.overflowout"
              @click="productRouter(i)"
            >
              {{ item.description }}
            </div>
            <div :class="$style.tag" v-if="online !== 'employee'">
              {{ item.tagText }}
            </div>
            <div v-else></div>
            <div
              @click="$emit('deleFn', i)"
              :class="$style.dele"
              v-if="routerName !== 'groupshoplist'"
            >
              刪除
            </div>
            <div v-else></div>
          </div>
          <div :class="$style.innerbtm">
            <div :class="$style.btmleft">
              <p style="font-size: 13px;">
                單價：$
                {{ item.info.newPrice }}
                <span style="text-decoration: line-through;">
                  $ {{ item.info.oldPrice }}
                </span>
              </p>
              <div :class="$style.selectout">
                <p :class="$style.selectitle">規格：</p>
                <p
                  @click="pick !== i ? pick = i : pick = false"
                  :class="$style.valuestyle"
                >
                  {{ Boolean(item.info.name.split(item.description + '-')[1])
                      ? item.info.name.split(item.description + '-')[1]
                      : item.info.name
                  }}
                  <i class="el-icon-arrow-down"/>
                </p>
                <pick
                  @loading="$emit('loading', $event)"
                  v-if="Number(i) === pick"
                  :childlist="item"
                  @close="pick = false"
                />
              </div>
            </div>
            <div :class="$style.bottom">
              <div :class="$style.btnn" @click="$emit('lessFn', i)">
                <i class="el-icon-minus"/>
              </div>
              <div :class="$style.numberbtn">
                {{ item.info.number }}
              </div>
              <div :class="$style.btnn" @click="$emit('addFn', i)">
                <i class="el-icon-plus"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pick from '@/components/shopping/pick.vue'
import { productPageFn } from '@/js'
export default {
  components: {
    pick
  },
  props: {
    listData: Array
  },
  data () {
    return {
      pick: null
    }
  },
  methods: {
    productRouter (listNumber) {
      const data = this.listData[listNumber]
      const product = { tag: data.tag, momSku: data.momSku, parentId: data.parentId }
      productPageFn(product)
    }
  }
}
</script>

<style lang="scss" module>
.delloading {
  font-size: 18px;
  font-weight: 900;
  color: #ff6100;
}

.nothgin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
}

@media (min-width: 768px) and (max-width: 1365px) {
  .innerbtm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .innertop {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 28px 35px;
    height: 20px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .list {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 84px;
  }

  .listinner {
    display: grid;
    grid-template-columns: 35px 56px 1fr;
    padding: 0 11px;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid #F2F2F2;
  }

  .img {
    width: 100%;
    height: 56px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .inner {
    display: flex;
    flex-direction: column;
  }

  .overflowout {
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
    font-size: 12px;
  }

  .dele {
    text-decoration: underline;
    font-size: 13px;
    color: #000000;
    justify-self: flex-end;
  }

  .checkbox {
    border-radius: 0;
    align-self: center;
  }

  .selectout {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #828282;
    padding-top: 3px;
  }

  .selectitle {
    width: 50px;
  }

  .bottom {
    width: 90px;
    height: 25px;
    display: grid;
    grid-template-columns: 25px 40px 25px;
    align-self: flex-end;
  }

  .btnn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
  }

  .numberbtn {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }
}

@media (max-width: 767px) {
  .innerbtm {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .innertop {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 28px 35px;
    height: 20px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  .list {
    width: 100%;
    min-height: 800px;
    padding: 11px 0;
  }

  .listinner {
    display: grid;
    grid-template-columns: 25px 56px 1fr;
    grid-auto-rows: minmax(83px, auto);
    padding: 5px 11px;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 1px solid #F2F2F2;
  }

  .img {
    width: 100%;
    height: 56px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .inner {
    display: flex;
    flex-direction: column;
  }

  .overflowout {
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .tag {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 30px;
    height: 18px;
    border-radius: 2px;
    background-color: #ffc000;
    font-size: 12px;
  }

  .dele {
    text-decoration: underline;
    font-size: 13px;
    color: #000000;
    justify-self: flex-end;
  }

  .checkbox {
    border-radius: 0;
    align-self: center;
    width: 16px;
    height: 16px;
  }

  .selectout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 13px;
    color: #828282;
    padding-top: 3px;
    width: 100%;
  }

  .bottom {
    width: 90px;
    height: 25px;
    display: grid;
    grid-template-columns: 25px 40px 25px;
    align-self: flex-end;
  }

  .btnn {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEEEEE;
  }

  .btmleft {
    flex: 1;
  }

  .numberbtn {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
  }

  .emstyle {
    min-height: unset;
  }

  .list {
    min-height: calc(100vh - 52px);
  }
}

</style>
