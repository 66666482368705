<template>
  <div :class="$style.root">
    <swiper
      :class="$style.outside"
      :options="swiperOptionweb"
      @slideChange="slideChange"
      ref="swiper"
    >
      <swiper-slide
        v-for="(item, i) in imgList"
        :key="i"
        :class="$style.imgout"
      >
        <div
          :class="$style.img2"
          @click="linkUrl(i)"
          :style="{ backgroundImage: `url(${ imgUrl + item.img })` }"
        />
      </swiper-slide>
      <div
        :class="['swiper-pagination', $style.co]"
        slot="pagination"
      ></div>
      <div
        :class="['swiper-button-prev', $style.bg]"
        slot="button-prev"
      />
      <div
        :class="['swiper-button-next', $style.bg]"
        slot="button-next"
      />
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  props: {
    imgList: Array
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptionweb: {
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2000
        }
      }
    }
  },
  methods: {
    linkUrl (value) {
      if (this.imgList[value].imgUrl !== 'NaN') {
        window.open('https://cvimall.net' + this.imgList[value].imgUrl)
      }
    },
    slideChange () {
      this.$emit('imageIndex', this.$refs.swiper.$swiper.realIndex)
    }
  }
}
</script>

<style lang="scss" module>
.root {
  opacity: 1;
}

.outside {
  width: 100%;
  height: 100%;
  z-index: unset !important;

  :global(.swiper-wrapper) {
    z-index: -1 !important;
  }
}

.imgout {
  width: 100%;
  height: 100%;
}

.img2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.bg {
  &:global(.swiper-button-next) {
    color: #000000;
    z-index: 1 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    opacity: .85;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    transition: .3s;
    --swiper-navigation-size: 12px;
  }

  &:global(.swiper-button-prev) {
    color: #000000;
    z-index: 1 !important;
    width: 40px;
    height: 40px;
    font-size: 1px;
    border-radius: 50%;
    background-color: #fff;
    opacity: .85;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    transition: .3s;
    --swiper-navigation-size: 12px;
  }
}

.co {
  &:global(.swiper-pagination-bullets) {
    z-index: 1 !important;
  }
}

</style>
