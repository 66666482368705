<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.bg">
        <img :class="$style.img" src="../../assets/img/loading2.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    window.document.body.style.overflow = 'hidden'
  },
  destroyed () {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss" module>
.inner {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
}

.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.img {
  width: 100px;
}

@media (max-width: 1365px) {
  .inner {
    top: 0;
  }

  .bg {
    width: 150px;
    height: 150px;
  }

  .img {
    width: 100px;
  }
}

@media (max-width: 767px) {
  .inner {
    top: 0;
  }

  .bg {
    width: 110px;
    height: 110px;
  }

  .img {
    width: 80px;
  }
}
</style>
