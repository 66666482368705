export function classList (array) {
  if (array) {
    const list = array.map(ele => {
      const value = ele.entity_id
      const name = ele.name
      return {
        value,
        name
      }
    })
    return list
  } else {
    return []
  }
}
