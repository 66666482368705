<template>
  <div :class="$style.root">
    <div :class="$style.listtitle">
      <div
        v-if="routerName !== 'groupshoplist'"
        :class="[$style.all, 'clickCss']"
        @click="$emit('pickAll', !trueBoxAll.truebox)"
      >
        <p>
          {{ trueBoxAll.truebox ? '取消' : '全選' }}
        </p>
      </div>
      <div v-else></div>
      <div>商品資料</div>
      <div :class="$style.textcenter">單價</div>
      <div :class="$style.textcenter">數量</div>
      <div :class="$style.textcenter">總價</div>
      <div :class="$style.textcenter">操作</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    trueBoxAll: Object
  }
}
</script>

<style lang="scss" module>
.listtitle {
  padding: 0 28px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 60px 570px 1fr 1.5fr 1fr 1fr;
  grid-template-rows: 58px;
  border-top: 2px solid #C4C4C4;
  border-bottom: 2px solid #C4C4C4;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.all {
  text-decoration: underline;
  cursor: pointer;
  color: #828282;
}

.textcenter {
  justify-self: center;
}
</style>
