<template>
  <div :class="$style.root">
    <div :class="$style.tab">
      <div
        @click="navFunction(item.link)"
        :class="[$style.tabtext,
          'clickCss',
          {[$style.activetext]: item.link === navVal }]
        "
        v-for="(item, i) in tab"
        :key="i"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navVal: 'common',
      tab: [{
        text: '一般',
        link: 'common'
      }, {
        text: '員工',
        link: 'employee'
      }, {
        text: 'KOL',
        link: 'kol'
      }]
    }
  },
  methods: {
    navFunction (val) {
      this.navVal = val
      this.$emit('navVal', val)
    }
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 15px;
}

@media (min-width: 1366px) {
  .rootinner {
    display: flex;
    justify-content: center;
    padding: 80px 0;
    box-sizing: border-box;
  }

  .inner {
    width: 520px;
    box-sizing: border-box;
    border-radius: 12px;
    border: solid 3px #ff6100;
  }

  .tab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 30px;
  }

  .tabtext {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    width: 120px;
    color: #828282;
    height: 100%;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
  }

  .activetext {
    color: #000;
    border-bottom: 2px solid #ff6100;
    position: relative;
    height: 100%;
  }
}

@media (max-width: 1365px) {
  .rootinner {
    max-width: 600px;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .inner {
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: solid 3px #ff6100;
  }

  .tab {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 50px;
  }

  .tabtext {
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #828282;
  }

  .activetext {
    color: #000;
    border-bottom: 2px solid #ff6100;
    position: relative;
    height: 100%;
  }
}
</style>
