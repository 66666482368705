<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.outsay" v-for="(item, i) in productScore.items" :key="i">
        <img
          :src="item.customer_image ? userPhoto + item.customer_image : require('../../assets/img/cvimall_comingsoon_small.png')"
          :class="$style.guyimg"
        />
        <div :class="$style.comright">
          <div :class="$style.name">
            {{ item.nickname === 'no' ? item.firstname : '匿名' }}
          </div>
          <div :class="$style.outout2">
            <el-rate
              :value="item.star"
              disabled
              :class="$style.star"
              :colors="['#ff6100', '#ff6100', '#ff6100']"
            />
            <div :class="$style.name2">
              {{ item.title }}
            </div>
          </div>
          <div :class="$style.textinnerrr">
            {{ item.detail }}
          </div>
          <div :class="$style.timestyle">
            {{ item.created_at }}
          </div>
        </div>
      </div>
      <div
        :class="$style.nolist"
        v-if="Number(productScore.total) <= 0"
      >
        <img
          src="../../assets/img/star.svg"
          :class="$style.img2"
        />
          此商品目前尚未有評價
      </div>
    </div>
    <page
      v-show="Number(productScore.total) > 0"
      :pageNumber="Number(productScore.total)"
      :pageSize="8"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import { mapGetters } from 'vuex'
import { pageReviewFunction } from '@/js'
export default {
  components: {
    page
  },
  computed: {
    ...mapGetters([
      'productScore'
    ])
  },
  methods: {
    pageFn (value) {
      this.$emit('loading', true)
      try {
        pageReviewFunction('getProductScore', 'getProductReviews', value, this.$route.params.Id)
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
  }
}
</script>

<style lang="scss" module>
.page {
  margin-top: 40px;
}
.nolist {
  font-size: 18px;
  text-align: center;
  color: #575757;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.img2 {
  opacity: .7;
  width: 130px;
  height: 130px;
  margin-bottom: 20px;
}

.rootinner {
  width: 100%;
}

.notext {
  padding: 80px 50px;
  border-bottom: 2px solid #FBFBFB;
  font-size: 16px;
  color: #828282;
}

.outsay {
  display: flex;
  border-bottom: 2px solid #FBFBFB;
  padding-top: 30px;
}

.guyimg {
  width: 50px;
  height: 50px;
  border-radius: 999rem;
  object-fit: contain;
  border: 2px solid #e2e2e2;
}

.name {
  display: flex;
  font-size: 16px;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
}

.guyimginner {
  width: 40px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ccc;
}

.comright {
  padding-left: 30px;
}

.img {
  width: 100%;
  height: 320px;
}

.title {
  line-height: 23px;
  font-size: 18px;
  color: #000000;
}

.money1 {
  color: #ff6100;
  font-size: 23px;
  padding-right: 10px;
}

.icon {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.money2 {
  font-size: 17.5px;
  color: #727272;
  text-decoration: line-through;
}

.buy {
  font-size: 13px;
  align-self: flex-end;
  color: #828282;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  width: 152px;
  height: 32px;
  border-radius: 36px;
  border: solid 1px #ff6100;
}

.outout2 {
  align-items: center;
  display: flex;
  padding-bottom: 16px;
}

.star {
  &:global(.el-rate) {
    height: auto;
    width: 120px;
  }

  &:global(.el-rate .el-rate__icon) {
    font-size: 22px;
    margin: 0;
    height: unset;
  }
}

.name2 {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  padding-left: 10px;
  border-left: 1px solid #828282;
}

.textinnerrr {
  padding: 0 0 19px 0;
  font-size: 16px;
  color: #000000;
}

.timestyle {
  font-size: 13px;
  color: #828282;
  padding-bottom: 30px;
}

@media (max-width: 1365px) {
  .notext {
    width: 100%;
    display: flex;
    padding: 60px 0;
    justify-content: center;
    font-size: 14px;
  }

  .outsay {
    padding: 10px 0;
  }

  .name {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .name2 {
    font-size: 12px;
  }

  .guyimginner {
    width: 34px;
    height: 34px;
  }

  .comright {
    padding-top: 0;
  }

  .outout2 {
    font-size: 12px;
    color: #828282;
  }

  .textinnerrr {
    padding: 9px 0 16px 0;
    font-size: 14px;
  }

  .timestyle {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .img2 {
    width: 80px;
    height: 80px;
  }

  .nolist {
    min-height: 250px
  }
}
</style>
