import { getGroupListBySKU, createGroupbuy, joinGroupbuy, groupApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'
import shoppingJs from '../../api/shopping/shopping.js'

const groupJs = {
  state: {
    openGroupList: null,
    groupPeople: null,
    groupListLink: null
  },
  mutations: {
    openGroupList (state, payload) {
      state.openGroupList = payload.openGroupList
    },
    groupPeople (state, payload) {
      state.groupPeople = payload.groupPeople
    },
    groupListLink (state, payload) {
      state.groupListLink = payload.groupListLink
    }
  },
  actions: {
    async openGroupList ({ commit }, { bodyObj }) {
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await getGroupListBySKU(token, bodyObj)
      commit({ type: 'openGroupList', openGroupList: data })
    },
    async createGroupBuy ({ commit }, { bodyObj }) {
      bodyObj.owner_name = loginInfoJs.loginInfoJs.state.userData.lastname
      bodyObj.order_id = shoppingJs.shoppingJs.state.settingShopPayId
      const token = loginInfoJs.loginInfoJs.state.userToken
      await createGroupbuy(token, bodyObj)
    },
    async joinGroupBuy ({ commit }, { bodyObj }) {
      bodyObj.order_id = shoppingJs.shoppingJs.state.settingShopPayId
      const token = loginInfoJs.loginInfoJs.state.userToken
      await joinGroupbuy(token, bodyObj)
    },
    // getGroupbuyAvailableById 得到分享資訊 // getGroupbuyAvailableImageById 團購的人
    async getGroupFunction ({ commit }, payload) {
      const typeObj = { getGroupbuyAvailableById: 'groupListLink',
        getGroupbuyAvailableImageById: 'groupPeople'
      }[payload.action]
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await groupApiFunciton(payload.action, token, payload.bodyObj)
      commit({ type: typeObj, [typeObj]: data })
    }
  },
  getters: {
    openGroupList: (state) => {
      return state.openGroupList
        ? state.openGroupList
        : { data: [], total: 0 }
    },
    groupPeopleList: (state) => {
      return state.groupPeople
        ? state.groupPeople
        : { data: [], total: 0 }
    },
    groupListLink: (state) => {
      return state.groupListLink
        ? state.groupListLink[0]
        : { owner_image: null, end_dt: '1970-01-01 00:00:00', link: '' }
    }
  }
}

export default {
  groupJs
}
