<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div
        :class="[$style.bg,
          'animate__animated',
          'animate__faster',
          'animate__fadeInDownBig']"
        v-if="fix"
      >
        <div :class="$style.top">
          <p :class="$style.title">更改手機號碼</p>
          <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
        </div>
        <div :class="$style.main">
          <div :class="$style.texttitle">
            現在的手機號碼
            <span :class="$style.text">{{ phone }}</span>
          </div>
          <input
            :class="$style.inputstyle"
            placeholder="請輸入更改手機號碼"
            v-model="newphone"
            type="number"
          />
          <div
            :class="$style.btn"
            @click="fixphone"
          >
            確定
          </div>
          <p :class="$style.errortext" v-if="message">{{ message }}</p>
        </div>
      </div>
      <div :class="$style.bg" v-else>
        <div :class="$style.top">
          <p :class="$style.title">更改手機號碼</p>
          <i :class="['el-icon-close', $style.title2]" @click="$emit('close')"></i>
        </div>
        <div :class="$style.main2">
          <img src="../../assets/img/fixok.png" :class="$style.imgsize1">
          <div :class="$style.text2">
            已修改成功並傳送確認信至
            <p>{{ email }}</p>
          </div>
          <div :class="$style.btn" @click="$emit('close')">確定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: null,
    name: null,
    phone: null
  },
  data () {
    return {
      newphone: '',
      message: null,
      fix: true
    }
  },
  methods: {
    async fixphone () {
      if (this.newphone) {
        this.$emit('loading', true)
        try {
          await this.$store.dispatch({
            type: 'fixphone',
            email: this.email,
            user_mobile: this.newphone,
            customerId: this.$route.params.userId,
            name: this.name
          })
          await this.$store.dispatch({
            type: 'getUserData'
          })
          this.fix = false
        } catch (err) {
          this.message = err.response.data.message
        } finally {
          this.$emit('loading', false)
        }
      } else {
        this.message = '手機不可為空。'
      }
    }
  }
}
</script>

<style lang="scss" module>
.errortext {
  text-align: center;
  color: red;
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg {
  margin: auto;
  box-sizing: border-box;
  width: 520px;
  border-radius: 12px;
  background-color: #ffffff;
}

.top {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #F7F8F9;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

.main {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  box-sizing: border-box;
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.title2 {
  cursor: pointer;
  right: 22px;
  position: absolute;
  color: #FF4B00;
  font-size: 28px;
  font-weight: 900;
}

.right {
  width: 174px;
}

.text {
  padding-top: 4px;
  padding-bottom: 22px;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}

.texttitle {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 320px;
  font-size: 14px;
  color: #000;
}

.inputstyle {
  font-size: 14px;
  padding: 0 13px;
  box-sizing: border-box;
  height: 40px;
  width: 320px;
  border: 1px solid #CCCCCC;
  border-radius: 1px;
  margin-bottom: 80px;
}

.btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 320px;
  height: 40px;
  background: #FF6100;
  border-radius: 999rem;
  font-weight: 500;
  font-size: 16px;
}

.main2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  box-sizing: border-box;
  width: 100%;
}

.imgsize1 {
  width: 140px;
  height: auto;
}

.text2 {
  padding-top: 34px;
  font-size: 14px;
  text-align: center;
  color: #828282;
  padding-bottom: 36px;
}

@media (max-width: 767px) {
  .text {
    padding: 0;
  }

  .main {
    padding: 30px 30px;
  }

  .bg {
    max-width: 300px;
    width: 80%;
  }

  .texttitle {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .inputstyle {
    width: 100%;
  }

  .btn {
    width: 100%;
    margin-top: 30px;
  }
}
</style>
