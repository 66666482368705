<template>
  <div :class="$style.root">
    <div :class="$style.inner" v-if="webwatch">
      <div :class="$style.btmtop">
        <div :class="$style.title">
          <div :class="[$style.out, $style.out165]">
            <div
              :class="$style.webfont"
            >
              聯絡我們
            </div>
            <router-link
              :to="{ name: 'contentme'}"
              :class="$style.padd"
            >
              聯絡CviMall
            </router-link>
            <router-link
              :to="{ name: 'addme'}"
              :class="$style.padd"
            >
              加入CviMall
            </router-link>
          </div>
          <div :class="[$style.out, $style.out126]">
            <div
              :class="$style.webfont"
            >
              關於 CviMall
            </div>
            <router-link
              :to="{ name: 'info'}"
              :class="$style.padd"
            >
              關於我們
            </router-link>
            <router-link
              :to="{ name: 'shoppingguide'}"
              :class="$style.padd"
            >
              購物說明
            </router-link>
            <router-link
              :to="{ name: 'problem'}"
              :class="$style.padd"
            >
              常見問題
            </router-link>
          </div>
          <div :class="[$style.out, $style.out250]">
            <div
              :class="$style.webfont"
            >
              消息中心
            </div>
            <router-link
              :to="{ name: 'policy'}"
              :class="$style.padd"
            >
              隱私權政策
            </router-link>
            <router-link
              :to="{ name: 'declaration'}"
              :class="[$style.padd, $style.padd2]"
            >
              會員權益聲明
            </router-link>
          </div>
          <div :class="$style.title2out">
            <div
              :class="$style.webfont2"
            >
              追蹤我們
            </div>
            <div :class="$style.icon">
              <img
                :class="$style.size"
                src="../assets/img/footer_icon_facebook.png"
                @click="imgFun('fb')"
              />
              <img
                :class="[$style.size, $style.size2]"
                src="../assets/img/footer_icon_youtube.png"
                @click="imgFun('youtube')"
              />
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.btmbtm">
        <p :class="$style.btmmtext">© 2021 CviMall All Rights Reserved</p>
      </div>
    </div>
    <div :class="$style.inner" v-else>
      <div :class="$style.title">
        <router-link :to="{ name: 'info'}" :class="$style.padd">關於我們</router-link>
        <router-link :to="{ name: 'shoppingguide'}" :class="$style.padd">購物說明</router-link>
        <router-link :to="{ name: 'problem'}" :class="$style.padd">常見問題</router-link>
      </div>
      <div :class="[$style.title, $style.title2]">
        <router-link :to="{ name: 'contentme'}" :class="$style.padd">聯絡CviMall</router-link>
        <router-link :to="{ name: 'addme'}" :class="$style.padd">加入CviMall</router-link>
        <router-link :to="{ name: 'policy'}" :class="$style.padd">隱私權政策</router-link>
        <router-link :to="{ name: 'declaration'}" :class="[$style.padd, $style.padd2]">會員權益聲明</router-link>
      </div>
      <div :class="$style.icon">
        <img
          :class="$style.size"
          src="../assets/img/footer_icon_facebook.png"
          @click="imgFun('fb')"
        />
        <img
          :class="[$style.size, $style.size2]"
          src="../assets/img/footer_icon_youtube.png"
          @click="imgFun('youtube')"
        />
      </div>
      <p :class="$style.btmmtext2">© 2021 CviMall All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imgFun (value) {
      if (value === 'fb') {
        window.open('https://www.facebook.com/Cvimall168/')
      } else {
        window.open('https://www.youtube.com/channel/UCV6LHsfAUBtMvR6u90G1u8w')
      }
    }
  }
}
</script>

<style lang="scss" module>
.inner {
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.btmtop {
  width: 996px;
  height: calc(100% - 67px);
  padding-top: 60px;
  box-sizing: border-box;
}

.btmbtm {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 67px;
  background: #F7F8F9;
}

.btmmtext {
  display: flex;
  align-items: center;
  width: 1106px;
  height: 100%;
  font-size: 14px;
}

.title {
  width: 100%;
  display: flex;
}

.title2out {
  display: flex;
  flex-direction: column;
}

.webfont {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 22px;
}

.webfont2 {
  padding-bottom: 20px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
}

.out {
  display: flex;
  flex-direction: column;

  &.out165 {
    padding-right: 165px;
  }

  &.out126 {
    padding-right: 126px;
  }

  &.out250 {
    padding-right: 250px;
  }
}

.padd {
  font-size: 14px;
  color: #000000;
  padding-bottom: 16px;

  &:hover {
    color: #ff6100;
  }
}

.icon {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 11px;
}

.size {
  width: 52px;
  height: 52px;
  padding-right: 16px;
  cursor: pointer;
  transition: 0.3s;

  &.size2 {
    padding-right: 0px;
  }

  &:hover {
    transition: 0.3s;
    -webkit-filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 10px);
  }
}

@media (max-width: 1365px) {
  .inner {
    justify-content: center;
    background: #fff;
    padding: 15px 0 15px 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .btmmtext2 {
    font-size: 14px;
  }

  .title {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 11px;
    font-size: 14px;

    &.title2 {
      padding: 16px 0 14px 0;
    }
  }

  .padd {
    height: 40px;
    font-size: 14px;
    line-height: unset;
    color: #000;
    text-decoration: none;
    height: 11px;
    padding: 0 1.3%;
    text-align: center;
    border-right: 1px solid #000;

    &.padd2 {
    border-right: 0px solid #000;
    }
  }

  .icon {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
  }

  .size {
    width: 38px;
    height: 38px;
    padding-right: 28px;

    &.size2 {
      padding-right: 0px;
    }
  }
}
@media (max-width: 360px){
  .inner {
    padding: 20px 15px 15px 10px;
  }

  .padd {
    color: #000;
    text-decoration: none;
    height: 11px;
    text-align: center;
    border: none;

    &.padd2 {
    }
  }

  .title {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    grid-gap: 1rem;
    justify-items: center;

    &.title2 {
      padding: 20px 0 20px 0;
      margin-top: 15px;
      border-top: 2px solid #f7f7f7;
    }
  }

  .btmmtext2 {
    text-align: center;
  }
}
</style>
