<template>
  <div :class="$style.root">
    <div
      :class="$style.out"
      @touchstart="touchstartfn"
    >
      <div :class="$style.rootinner">
        <div :class="$style.titlebar">
          <div
            :class="$style.titlebarfont"
            v-for="(item, i) in searchChildListFlat"
            :key="i"
            @click="searchClassFn(item.value)"
          >
            {{ item.name }}
          </div>
        </div>
        <div :class="$style.line"></div>
        <div :class="$style.search"
          v-for="(item, i) in searchList"
          :key="i"
        >
          <div :class="$style.searchtitle">
            {{ item.name }}
            <p
              :class="$style.remove"
              @click.prevent="remove"
              v-if="Number(i) === 1"
            >
              清除記錄
            </p>
          </div>
          <div :class="$style.keyword">
            <div
              :class="$style.keywordfont"
              v-for="(item2, i2) in item.list"
              :key="i2"
              @click="Number(i) === 0 || Number(i) === 1
                ? $emit('searchText', item2)
                : routerFun(item2.link)"
            >
              {{ Number(i) === 0 || Number(i) === 1 ? item2 : item2.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="$style.outclose"
      @click="$emit('close')"
      v-if="webwatch"
    >
    </div>
  </div>
</template>

<script>
import { routerHavePage, searchClass } from '@/js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      searchList: [{
        name: '熱門搜尋',
        list: []
      }, {
        name: '搜尋紀錄',
        list: []
      }, {
        name: '商品條件',
        list: [{
          name: 'KOL推薦',
          link: 'kolList'
        }, {
          name: '優惠中',
          link: 'discountList'
        }, {
          name: '熱門商品',
          link: 'hotlist'
        }, {
          name: '精選商品',
          link: 'commonList'
        }]
      }]
    }
  },
  watch: {
    $route () {
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters([
      'hotListText',
      'searchChildList'
    ]),
    searchChildListFlat () {
      return this.searchChildList.flat()
    }
  },
  methods: {
    touchstartfn () {
      this.$emit('closeKeyboard')
    },
    routerFun (value) {
      routerHavePage(value)
      this.$emit('close')
    },
    searchClassFn (value) {
      this.$emit('close')
      searchClass(value, this.online, 1)
    },
    remove () {
      localStorage.setItem('historySearch', JSON.stringify([]))
      this.searchList[1].list = []
    }
  },
  created () {
    this.searchList[1].list = JSON.parse(localStorage.getItem('historySearch'))
    this.searchList[0].list = this.hotListText
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  @keyframes cssA {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -5%, 0);
      transform: translate3d(0, -5%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.outclose {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 75px;
  left: 0;
}

.out {
  position: absolute;
  width: 500px;
  min-height: 500px;
  animation-name: cssA;
  animation-duration: 0.3s;
}

.rootinner {
  background: #fff;
  position: absolute;
  z-index: 999;
  border-radius: 10px 10px 30px 30px;
  background: #fff;
  padding: 13px 10px 10px 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: calc(100% - 518px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.titlebar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.titlebarfont {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 14px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #828282;
  cursor: pointer;

  &:hover {
    color: #ff6100;
  }
}

.line {
  margin: 0 0 19px;
  width: 100%;
  height: 2px;
  background: black;
}

.search {
  width: 100%;
  box-sizing: border-box;
}

.searchtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 15px;
  font-size: 16px;
  color: #000000;
  border-left: 4px solid #ff6100;
  padding-left: 13px;
  margin: 15px 0;
}

.remove {
  font-size: 14px;
  margin-right: 10px;
  color: #828282;
  cursor: pointer;
}

.keyword {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0 17px;

}

.keywordfont {
  margin-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  font-size: 14px;
  height: 26px;
  border-radius: 999rem;
  border: solid 2px #ff6100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #000;
  cursor: pointer;
}

.keywordfont:active {
  border: solid 2px #ff6100;
  background: #ff6100;
  color: #fff;
}

@media (max-width: 1365px) {

  .out {
    width: 100%;
    height: 100%;
    position: unset;
    min-height: calc(100vh - 94px);
  }

  .rootinner {
    border-radius: 0;
    background: unset;
    box-shadow: unset;
    position: absolute;
    top: 8rem;
  }
}
</style>
