import router from '@/router'
import store from '@/store'

export function toLoginPage (loginType, stateValue) {
  router.push({ name: loginType, query: { state: stateValue } })
}

export async function logout () {
  store.commit({ type: 'userToken', userToken: null })
  window.location.reload()
}
