import { request } from '@/request/request'
import path from '@/servers/path.js'

export async function getUserToken (payload) {
  const url = '/integration/customer/token'
  return (await request.post(url, {
    username: payload.username,
    password: payload.password
  })).data
}

export async function loginFunction (token) {
  const url = '/customers/me'
  const data = (await request.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })).data
  const userImg = data?.['custom_attributes']?.find(ele => ele.attribute_code === 'customer_image')?.value
  const obj = {
    gradeNumber: data?.['group_id'] ?? 0,
    birthday: data?.['dob'],
    email: data?.['email'],
    account: data?.['custom_attributes']?.find(ele => ele.attribute_code === 'user_id')?.value ?? '未有帳號',
    name: data?.lastname ?? '尚未填寫名字',
    phone: data?.['custom_attributes']?.find(ele => ele.attribute_code === 'user_mobile')?.value ?? '尚未設定手機',
    sex: data?.gender ?? 0,
    // 判斷使用者是否有照片，沒有的話為預設照片
    userimage: userImg ? path.userPhoto + userImg : require('@/assets/img/noimg.png'),
    addressList: data?.addresses ?? '尚未設定地址',
    // fakename(暱稱) / kolfb / kolig / kolutube (KOl才有)
    fakename: data?.['custom_attributes']?.find(ele => ele.attribute_code === 'user_nickname')?.value ?? '未填寫暱稱',
    kolfb: data?.['custom_attributes']?.find(ele => ele.attribute_code === 'kol_fb')?.value,
    kolig: data?.['custom_attributes']?.find(ele => ele.attribute_code === 'kol_ig')?.value,
    kolutube: data?.['custom_attributes']?.find(ele => ele.attribute_code === 'kol_utube')?.value,
    id: data?.id
  }
  let gradeText = ''
  // 身份數字
  // 1 = 一般會員, 2 = 白金會員, 3 = VIP會員, 4 = KOL, 5 = 員工, 6 = 未審核KOL
  // 7 = 員工1, 8 = 員工2, 9 = 員工3
  switch (obj.gradeNumber) {
    case 0:
      gradeText = '此會員不存在'
      break
    case 1:
      gradeText = '一般會員'
      break
    case 2:
      gradeText = 'VIP會員'
      break
    case 3:
      gradeText = '白金VIP會員'
      break
    case 4:
      gradeText = 'KOL'
      break
    case 5:
      gradeText = '一般會員'
      break
    case 6:
      gradeText = '未審核KOL'
      break
    case 7:
      gradeText = '三級會員'
      break
    case 8:
      gradeText = '兩級會員'
      break
    case 9:
      gradeText = '一級會員'
      break
  }
  return {
    ...obj,
    gradeText
  }
}
