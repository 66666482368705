import axios from 'axios'
import Vue from 'vue'

const request = axios.create({
  baseURL: 'https://shop.cvicloud-srv.net/rest/V1'
})

const requestApi = axios.create({
  baseURL: 'https://shop.cvicloud-srv.net/'
})

request.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 400) {
    console.log(error.response)
  }
  if (error.response.status === 500) {
    Vue.prototype.$message.error('伺服器忙碌中，請稍後再試。')
  }
  if (error.response.status === 401) {
    switch (error.response.config.url) {
      case '/integration/customer/token':
        Vue.prototype.$message.error('帳號密碼輸入錯誤，請確認後再進行輸入。')
        break
      case '/customers/me':
        Vue.prototype.$message.error('登入逾時，請重新登入。')
        break
      default:
        return Promise.reject(error)
    }
  }
  if (error.response.status === 404) {
    switch (error.response.config.url) {
      case '/customers/me':
        Vue.prototype.$message.error('登入逾時，請重新登入。')
        break
      case '/carts/mine/shipping-information':
        Vue.prototype.$message.error('下單失敗，請確定資料是否無誤後再進行下單。若還是無法下單，請重新整理後再次下單！謝謝。')
        break
      default:
        return Promise.reject(error)
    }
  }
  return Promise.reject(error)
})

requestApi.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 500) {
    Vue.prototype.$message.error('伺服器忙碌中，請稍後再試。')
  }
  if (error.response.status === 401) {
    switch (error.response.config.url) {
      case '/webapi.php':
        Vue.prototype.$message.error('登入逾時，請重新登入。')
    }
  }
  return Promise.reject(error)
})

export { request, requestApi }
