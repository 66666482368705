import Vue from 'vue'
import Vuex from 'vuex'
import loginInfoJs from './api/login.js'
import kolproductJs from './product/kolproduct.js'
import registerJs from './api/register.js'
import aboutmeJs from './aboutme.js'
import listJs from './product/list.js'
import productJs from './product/product.js'
import shopping from './api/shopping/shopping.js'
import couponJs from './api/user/coupon.js'
import messageJs from './api/user/message.js'
import bannerJs from './banner.js'
import ordersJs from './api/user/orderlist.js'
import favoriteJs from './api/user/favorite.js'
import infoJs from './api/user/info.js'
import pointJs from './api/user/point.js'
import hotJs from './product/hot.js'
import userJs from './api/user/user.js'
import groupJs from './api/shopping/group.js'
import openKolJs from './api/user/openlist.js'
import shareJs from './api/user/share.js'
import searchclassJs from './searchclass.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loginInfo: loginInfoJs.loginInfoJs,
    kolproductJs: kolproductJs.kolproduts,
    register: registerJs.registerJs,
    aboutme: aboutmeJs.aboutmeJs,
    list: listJs.listJs,
    product: productJs.productJs,
    shopping: shopping.shoppingJs,
    coupon: couponJs.couponJs,
    banner: bannerJs.bannerJs,
    orders: ordersJs.ordersJs,
    favorite: favoriteJs.favoriteJs,
    info: infoJs.infoJs,
    point: pointJs.pointJs,
    hot: hotJs.hotJs,
    user: userJs.userJs,
    group: groupJs.groupJs,
    message: messageJs.messageJs,
    openKol: openKolJs.openKolJs,
    share: shareJs.shareJs,
    searchclass: searchclassJs.searchclassJs
  }
})
