import { request } from '@/request/request'

const aboutmeJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    onlyHtml: null,
    listHtml: null,
    listProblemNumber: null,
    listProblem: null
  },
  mutations: {
    onlyHtml (state, payload) {
      state[`${payload.name}`] = payload.onlyHtml.map(ele => ele.data)
    }
  },
  actions: {
    // 加入cvimall-KOL => 7
    // 加入CviMall-供應商 => 18
    // 聯絡cvimall => 9
    // ----------------------------
    // 關於我們 => 5
    // 購物說明-現金說明 => 11
    // 購物說明-付款 => 19
    // 購物說明-運送 => 20
    // 購物說明-退換貨 => 21
    // 常見問題-列表 => 22
    // ----------------------------
    // 隱私權政策 => 4
    // 會員 => 14
    async getFooterOnlyHtml (store, payload) {
      const data = await Promise.all(
        payload.numberArray.map(ele => {
          const url = `/cmsPage/${ele}`
          const promise = request.get(url, {
            headers: {
              Authorization: `Bearer ${store.state.adminToken}`
            }
          })
          return promise
        })
      )
      store.commit({ type: 'onlyHtml', onlyHtml: data, name: payload.name })
    }
  },
  getters: {
    onlyHtml: (state) => {
      if (state.onlyHtml) {
        return state.onlyHtml.map(ele => ele.content)
      } else {
        return '目前沒有資料唷！'
      }
    },
    listHtml: (state) => {
      if (state.listHtml) {
        return state.listHtml.map(ele => ele.content)
      } else {
        return '目前沒有資料唷！'
      }
    },
    listProblemNumber: (state) => {
      if (state.listProblemNumber) {
        return JSON.parse(state.listProblemNumber[0].content_heading)
      } else {
        return []
      }
    },
    listProblem: (state) => {
      if (state.listProblem) {
        return state.listProblem.map(ele => {
          const title = ele.title
          const content = ele.content
          return { title, content }
        })
      } else {
        return '目前沒有資料唷！'
      }
    }
  }
}

export default {
  aboutmeJs
}
