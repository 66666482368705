 <template>
  <div :class="$style.root">
    <div id="cs-sectionTopBar">
      <a target="_blank"
        v-for="(item, i) in searchMarqueeList"
        :key="i"
        :id="item.id"
        :href="item.link"
        :style="item.style"
        >
          <div id="cs-sectionTopBar-content" class="u-tw-only" :class="$style.marquee__text">
            {{ item.name }}
          </div>
        </a>
      </div>
    <div :class="$style.inner">
      <div :class="$style.innertop">
        <!-- 漢堡 -->
        <img
          v-if="!webwatch"
          :src="tagNav
            ? require('../assets/img/menu_icon_close_default.png')
            : require('../assets/img/header_button_menu_default.png')
          "
          :class="[$style.menu, 'clickCss']"
          @click="navFn"
        />
        <!-- logo -->
        <div :class="$style.logo">
          <img
            @click="homeFn"
            src="../assets/img/logo.png"
          />
        </div>
        <!-- searchbar -->
        <searchlist
          @searchListOpen="$emit('searchListOpen', $event)"
          :class="$style.search"
          v-if="webwatch"
        />
        <div :class="$style.iconout">
          <div
            @click="userFn('shopping')"
            :class="$style.text"
            v-if="online !== 'kol'"
          >
            <img
              src="../assets/img/header_button_cart_default.png"
              :class="$style.icon"
            />
            <span v-if="webwatch">購物車</span>
            <div :class="$style.red" v-if="online && cartnumber">
              {{ cartnumber ? cartnumber : 0 }}
            </div>
          </div>
          <div
            :class="$style.text"
            v-if="!webwatch || online"
            @click="online ? userFn('userData') : logOutFn()"
          >
            <img
              src="../assets/img/header_button_member_default.png"
              :class="$style.icon2"
            />
            <span v-if="webwatch">會員</span>
          </div>
          <div
            v-if="webwatch"
            @click="logOutFn"
            :class="[$style.text, $style.text2]"
          >
            <img
              src="../assets/img/header_button_logout_default.png"
              :class="$style.icon2"
            />
            {{ online ? '登出' : '登入/註冊' }}
          </div>
        </div>
      </div>
      <div :class="$style.innerbottom" v-if="webwatch">
        <!-- <p
          :class="$style.padd"
          v-for="(item, i) in searchClassList"
          :key="i"
          @click="searchClassFunction(item.value)"
        >
          {{ item.name }}
        </p> -->

        <div
            v-for="(item, i) in searchClassList"
            :key="i"
            @mouseleave="showList = null"
          >
          <div :class="$style.menuoutsidetitle">
            <p
              @mouseover="showList === i ? showList = null : showList = i"
              @click="searchClassFunction(item.value)"
              :class="$style.titleouter"
            >
              {{ item.name }}
            </p>
          </div>
          <div
              :class="[$style.menublock ,$style.titleinner, 'animate__animated', 'animate__fadeIn', 'animate__faster']"
              v-if="showList === i"
            >
            <!-- <p :class="[$style.test2, $style.titleinner]" @click="searchClassFunction(item.value)">
              {{ item.name }}
            </p> -->
            <div
              :class="[$style.titleinner, $style.line]"
              v-for="(item2, i2) in searchChildList[i]"
              :key="i2"
            >
              <p :class="$style.searchchildtitle" @click="searchClassFunction(item2.value)">
                {{ item2.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loginpopup
      v-if="componentName === 'loginpopup'"
      @close="componentName = null"
    />
  </div>
</template>

<script>
import { searchClass, logout, userAction } from '@/js'
import searchlist from '@/components/search/searchlist.vue'
import { mapGetters } from 'vuex'
import loginpopup from '@/views/login/loginpopup.vue'
export default {
  components: {
    searchlist,
    loginpopup
  },
  props: {
    tagNav: null
  },
  data () {
    return {
      componentName: null,
      showList: null
    }
  },
  computed: {
    ...mapGetters([
      'cartnumber',
      'searchClassList',
      'searchChildList',
      'searchMarqueeList'
    ])
  },
  methods: {
    searchClassFunction (categoryId) {
      searchClass(categoryId, this.online, 1)
    },
    homeFn () {
      this.nav = false
      this.$emit('navFnOut', this.nav)
      if (this.online === 'employee') {
        this.$router.push({ name: 'employeelist', params: { current: 1 } })
      } else {
        this.$router.push({ name: 'home' })
      }
    },
    userFn (action) {
      if (this.online) {
        this.nav = false
        this.$emit('navFnOut', this.nav)
        userAction(this.online,
          action,
          this.$store.state.loginInfo.userData.id,
          this.$store.state.shopping.trueShopCarId
        )
      } else if (action === 'shopping') {
        this.$message({ message: '尚未登入', type: 'warning' })
      }
    },
    navFn () {
      this.nav = !this.nav
      this.$emit('navFnOut', this.nav)
    },
    logOutFn () {
      if (this.online) {
        logout()
      } else {
        this.componentName = 'loginpopup'
      }
    }
  },
  async created () {
    await Promise.all([
      this.$store.dispatch({
        type: 'searchClassList'
      }),
      this.$store.dispatch({
        type: 'getHotText',
        sku: 'hotsearch-1'
      }),
      this.$store.dispatch({
        type: 'searchMarqueeList'
      })
    ])
  }
}
var mq = 1
window.setInterval(() => {
  if (document.getElementById('u-tw-only-' + mq) !== null) {
    document.getElementById('u-tw-only-' + mq).style.display = 'none'
    if (document.getElementById('u-tw-only-' + (mq + 1)) === null) {
      mq = 0
    }
    document.getElementById('u-tw-only-' + (mq + 1)).style.display = 'block'
  }
  mq = mq + 1
}, 5000)
</script>

<style lang="scss" module>

.menuoutsidetitle {
  padding-right: 1.8rem;
}

// .test2 {
//   color: #828282;
//   cursor: pointer;
//   padding: 0.5rem 0;
//   font-weight: bold;
// }

.searchchildtitle {
  color: #828282;
  cursor: pointer;
}

.searchchildtitle:hover {
  color: #ff6100;
}

.menublock {
  margin-top: 0.8rem;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 30%);
}

.titleinner {
  background-color: white;
  padding: 0.5rem;
}

.titleouter {
  cursor: pointer;
}

.marquee__text {
  background-color: #f1e0cd;
  color: #4a4a4a;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2px;
}

.inner {
  margin: 0 auto;
  width: 1110px;
  height: 100%;
}

.innertop {
  width: 100%;
  height: 75px;
  padding: 0px 32px 0 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerbottom {
  display: flex;
  flex: 1; flex-wrap: wrap;
  font-size: 14px;
  color: #828282;
  padding: 0px 80px 0 220px;
}

.logo {
  width: 134px;
  height: 30px;
  padding-right: 43px;
  cursor: pointer;
}

.search {
  width: 500px;
  height: 48px;
  margin-right: 77px;
}

.icon {
  width: 28.54px;
  height: 24px;
  margin-right: 12px;
}

.red {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 999rem;
  background: #FF0000;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -9px;
  left: 12px;
}

.padd {
  padding-right: 24px;
  cursor: pointer;

  &:hover {
    color: #ff6100;
  }
}

.text {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
  margin-right: 38px;
  font-weight: 200;
  cursor: pointer;
  position: relative;

  &.text2 {
    margin-right: 0;
  }
}

.icon2 {
  width: 23.12px;
  height: 24px;
  margin-right: 12px;
}

.iconout {
  flex: 1;
  display: flex;
}

@media (max-width: 1365px){
  .innertop {
    display: contents;
  }

  .inner {
    margin: unset;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    align-items: center;
  }

  .text {
    margin: 0;
  }

  .icon {
    margin-right: 0;
    width: 25px;
    height: 25px;
  }

  .icon2 {
    margin-right: 0;
    width: 25px;
    height: 25px;
  }

  .menu {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .logo {
    justify-self: center;
    width: 90px;
    padding: 0;
  }

  .iconout {
    display: flex;
    justify-content: space-around;
  }
}
</style>
