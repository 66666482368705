<template>
  <div :class="$style.root">
    <div :class="$style.fare">
      <div v-for="(item, i) in list" :key="i">
        <div :class="$style.liststyle">
          <p>{{ item.ex }}</p>
          <p>{{ item.ex3 }}</p>
        </div>
        <div :class="$style.liststyle2">
          <p>{{ item.ex2 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [{
        ex: '超商',
        ex2: '滿＄990皆免運費',
        ex3: '＄65'
      }, {
        ex: '離島宅配',
        ex2: '滿＄990皆免運費',
        ex3: '＄180'
      }, {
        ex: '本島宅配',
        ex2: '滿＄990皆免運費',
        ex3: '＄100'
      }]
    }
  }
}
</script>

<style lang="scss" module>
.fare {
  padding: 14px 18px 4px 18px;
  box-sizing: border-box;
  border-radius: 0 10px 10px 10px;
  border: solid 2px #ffc000;
  background-color: #ffffff;
}

.liststyle {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000000;
}

.liststyle2 {
  font-size: 12px;
  color: #828282;
  padding-bottom: 10px;
}
</style>
