<template>
  <div :class="$style.root">
    <userinfo/>
    <div :class="$style.out">
      <img src="../../assets/img/cupon_icon_normal.png" :class="$style.icon">
      <p :class="$style.text3">目前可用</p>
      <span :class="$style.text4">
        {{ Number(userCouponListHavePage.total) }}
      </span>
      <span>張</span>
    </div>
    <div :class="$style.rootinner" v-if="Number(userCouponListHavePage.total) > 0">
      <div :class="$style.linetitle" v-if="webwatch">
        <div>優惠名稱</div>
        <div>到期日</div>
        <div :class="$style.text5">說明</div>
      </div>
      <div :class="$style.lineout">
        <div :class="$style.line" v-for="(item, i) in userCouponListHavePage.items" :key="i">
          <p :class="$style.text" v-if="!webwatch">
            {{ item.to_date ? item.to_date : '-' }}
          </p>
          <p :class="$style.text">{{ item.name }}</p>
          <div :class="$style.text">
            <span v-if="!webwatch">
              {{ item.from_date ? item.from_date : '-' }}
            </span>
            <span v-else>
              {{ item.to_date ? item.to_date : '-' }}
            </span>
            <p :class="[$style.text, $style.right]" @click="openFn(i)" v-if="webwatch === false">
              優惠說明
              <i class="el-icon-arrow-up" v-if="open === true && active === i"/>
              <i class="el-icon-arrow-down" v-else/>
            </p>
          </div>
          <p :class="$style.text5" @click="openFn(i)" v-if="webwatch">
            <i class="el-icon-arrow-up" v-if="open === true && active === i"/>
            <i class="el-icon-arrow-down" v-else/>
          </p>
          <p :class="$style.say" v-if="open === true && active === i">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
    <div
      :class="['noany', 'noanythingout']"
      v-if="Number(userCouponListHavePage.total) <= 0"
    >
      <img
        src="../../assets/img/coupon.svg"
        :class="['noanyimg', 'imgmargin']"
      />
      目前沒有任何優惠券唷！
    </div>
    <page
      v-show="Number(userCouponListHavePage.total) > 0"
      :pageNumber="Number(userCouponListHavePage.total)"
      :pageSize="10"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import userinfo from '@/components/user/info.vue'
// import { pageFunction } from '@/js'
import { mapGetters } from 'vuex'
import '@/css/noany.scss'
export default {
  components: {
    page,
    userinfo
  },
  data () {
    return {
      open: false,
      active: 0
    }
  },
  computed: {
    ...mapGetters([
      'userCouponListHavePage'
    ])
  },
  methods: {
    async pageFn (value) {
      this.$router.push({
        params: { current: value }
      })
      this.$emit('loading', true)
      try {
        await this.$store.dispatch({
          type: 'userCouponAction',
          action: 'getMyCouponWithPager',
          bodyObj: {
            pageSize: 10,
            currentPage: value
          }
        })
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    },
    openFn (value) {
      if (this.open === true && this.active !== value) {
        this.active = value
      } else if (this.open === true && this.active === value) {
        this.open = false
      } else if (this.open === false) {
        this.active = value
        this.open = true
      }
    }
  }
}
</script>

<style lang="scss" module>
@keyframes cssA {
  0% {
    opacity: 0.3;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.rootinner {
  width: 100%;
  height: 100%;
}

.nolist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 20px;
  min-height: 560px;
  color: #ccc;
}

.img {
  opacity: .7;
  width: 150px;
  height: 200px;
}

.line {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 545px 139px 1fr;
  grid-auto-rows: minmax(64px, auto);
  font-size: 14px;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
}

.lineout {
  width: 100%;
  min-height: 560px;
}

.text {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 29px;
  font-size: 14px;

  &.text2 {
  border-bottom: 1px solid #c4c4c4;
  }

  &.right {
    border-top: none;
    justify-content: flex-end;
    width: auto;
    padding: 0;
  }
}

.text5 {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  font-size: 14px;
  cursor: pointer;
}

.linetitle {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  min-height: 64px;
  display: grid;
  grid-template-columns: 545px 139px 1fr;
  border-top: 2px solid #C4C4C4;
  border-bottom: 2px solid #C4C4C4;
  font-size: 14px;
  align-items: center;
}

.icon {
  padding: 0 26px 0 9px;
  width: 24px;
  height: 24px;
}

.icon2 {
  width: 20px;
  height: 20px;
}

.out {
  font-size: 14px;
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 17px;
}

.text3 {
  padding-right: 20px;
  font-size: 14px;
}

.text4 {
  font-weight: 500;
  font-size: 16px;
  color: #FFC000;
  padding-right: 20px;
}

.touto {
  padding-top: 6px;
  font-size: 14px;
  padding-left: 44px;
}

.touto2 {
  color: #828282;
  font-size: 12px;
  width: 100%;
  text-align: right;
}

.say {
  width: 100%;
  height: 100%;
  padding: 16px 21px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #E3FFF4;
  font-size: 14px;
  grid-column-start: 1;
  grid-column-end: 4;
  animation-name: cssA;
  animation-duration: 0.3s;
}

@media (max-width: 1365px) {
  .lineout {
    min-height: calc(100vh - 579px);
  }

  .line {
    padding: 0;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 30px 0;
    border-bottom: 0;
  }

  .text {
    padding: 0 20px;
    box-sizing: border-box;
    border-top: 1px solid #c4c4c4;
  }

  .icon {
    padding: 0 20px 0 3px;
  }

  .say {
    width: 100%;
    padding: 10px;
    background: #E3FFF4;
  }
}

@media (max-width: 767px) {
  .lineout {
    min-height: calc(100vh - 362px);
  }
}
</style>
