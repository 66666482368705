var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('div',{class:_vm.$style.innerout},_vm._l((_vm.orderData),function(item,i){
var _obj, _obj$1;
return _c('div',{key:i,class:_vm.$style.line},[_c('div',{class:_vm.$style.list},[(_vm.webwatch)?_c('div',{class:_vm.$style.outout},[_c('div',{class:_vm.$style.webtitle},[_c('p',{class:_vm.$style.text},[_vm._v(" "+_vm._s(item.orderId)+" ")]),_c('p',{class:_vm.$style.text},[_vm._v(_vm._s(item.time))])]),_c('div',{class:[_vm.$style.text, _vm.$style.tatol]},[_vm._v(_vm._s(item.total))]),_c('p',{class:[_vm.$style.text, _vm.$style.status]},[_vm._v(_vm._s(item.statetext))])]):_vm._e(),_c('div',{class:[_vm.$style.text, _vm.$style.btnout]},[_c('div',{class:_vm.$style.btn},[(_vm.method[i].method === 'callphone')?_c('div',[_vm._v(" 請通知客服！ ")]):_vm._e(),(_vm.method[i].method === 'nomethod')?_c('div',[_vm._v(" - ")]):_vm._e(),(_vm.method[i].method === 'cancel')?_c('div',{class:_vm.$style.btntext,on:{"click":function($event){return _vm.openFn('cancel', i)}}},[_vm._v(" 取消訂單 ")]):_vm._e(),(_vm.method[i].method === 'complete' && _vm.online !== 'employee')?_c('div',{class:_vm.$style.btntext,on:{"click":function($event){return _vm.openFn('returnpage', i)}}},[_vm._v(" 退貨 ")]):_vm._e(),(_vm.method[i].method === 'complete' && _vm.online !== 'employee')?_c('div',{class:_vm.$style.btntext,on:{"click":function($event){return _vm.openFn('assess', i)}}},[_vm._v(" 評價 ")]):_vm._e(),(_vm.method[i].method === 'return' && _vm.online !== 'employee')?_c('div',{class:_vm.$style.btntext,on:{"click":function($event){return _vm.openFn('progresspage', i)}}},[_vm._v(" 退貨進度 ")]):_vm._e()]),(_vm.webwatch)?_c('div',{class:_vm.active === i && _vm.watch
              ? [_vm.$style.btnwatch,_vm.$style.btnwatchactive]
              : _vm.$style.btnwatch,on:{"click":function($event){return _vm.listwatch(i)}}},[_c('span',[_vm._v("查看")])]):_vm._e()]),(!_vm.webwatch)?_c('div',{class:_vm.$style.line},[_c('p',{class:[_vm.$style.text, _vm.$style.topline]},[_vm._v(_vm._s(item.orderId))]),_c('p',{class:_vm.$style.text},[_vm._v(_vm._s(item.time))]),_c('p',{class:_vm.$style.text},[_vm._v(_vm._s(item.total))]),_c('p',{class:[_vm.$style.text, _vm.$style.btmline]},[_vm._v(_vm._s(item.statetext))])]):_vm._e(),(!_vm.webwatch)?_c('div',{class:[_vm.$style.btnwatch,
            ( _obj = {}, _obj[_vm.$style.btnwatchactive] = _vm.active === i && _vm.watch, _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.btnend] = i === _vm.orderData.length - 1, _obj$1 )
          ],on:{"click":function($event){return _vm.listwatch(i)}}},[_c('span',[_vm._v("查看")])]):_vm._e()]),(_vm.active === i && _vm.watch)?_c('listlist',{class:[_vm.$style.listlist, 'animate__animated', 'animate__faster', 'animate__fadeIn'],attrs:{"commonUserInfo":_vm.commonUserInfo,"orderData":_vm.orderData[Number(i)]}}):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }