import { request, requestApi } from '@/request/request'

const productJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    product: null,
    productChildrenData: null,
    productChilinn: null,
    productScore: null,
    hotListText: null,
    childStockProduct: null
  },
  mutations: {
    product (state, payload) {
      state.product = payload.product
    },
    productChildrenData (state, payload) {
      state.productChildrenData = payload.productChildrenData
    },
    productChilinn (state, payload) {
      state.productChilinn = payload.productChilinn.map(ele => ele.data)
    },
    productScore (state, payload) {
      state.productScore = payload.productScore
    },
    hotListText (state, payload) {
      state.hotListText = payload.hotListText
    },
    childStockProduct (state, payload) {
      state.childStockProduct = Object.values(payload.childStockProduct)[0]
    }
  },
  actions: {
    // 母sku
    async product (store, payload) {
      const url = `/products/${payload.sku}`
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'product', product: response.data })
    },
    // 庫存
    async childStockProduct (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'getSkuAmountTree',
        parent_id: payload.sku
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'childStockProduct', childStockProduct: response.data })
    },
    async productChildren (store, payload) {
      // 找所有規格 (用母sku)
      const url = `/configurable-products/${payload.sku}/children`
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      await store.commit({ type: 'productChildrenData', productChildrenData: response.data })
      await store.dispatch('productChilinner')
    },
    async productChilinner (store, payload) {
      // 規格裡的資料
      const response = await Promise.all(
        store.state.productChildrenData.map(ele => {
          const url = `/products/${ele.sku}`
          const promise = request.get(url, {
            headers: {
              Authorization: `Bearer ${store.state.adminToken}`
            }
          })
          return promise
        })
      )
      store.commit({ type: 'productChilinn', productChilinn: response })
    },
    // 熱門搜尋 文字
    async getHotText (store, payload) {
      const url = `/products/${payload.sku}`
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
      store.commit({ type: 'hotListText', hotListText: response.data })
    },
    // 評分
    async getProductScore (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'getProductReviews',
        productId: payload.productId,
        pageSize: 10,
        currentPage: payload.currentPage
      })
      store.commit({ type: 'productScore', productScore: response.data })
    }
  },
  getters: {
    // 母sku
    momProductList: (state) => {
      if (state.product) {
        const url = state.product
        const productname = (url ? url.name : '')
        const id = (url ? url.id : '')
        const status = url.status
        const descriptionFn = (url.custom_attributes
          ? url.custom_attributes.find(ele => ele.attribute_code === 'description')
          : '')
        const groupingAmount = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'grouping_amount')
          ? url.custom_attributes.find(ele => ele.attribute_code === 'grouping_amount').value
          : undefined)
        const groupingBonus = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'grouping_bonus')
          ? url.custom_attributes.find(ele => ele.attribute_code === 'grouping_bonus').value
          : undefined)
        const sellType = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'sell_type')
          ? url.custom_attributes.find(ele => ele.attribute_code === 'sell_type').value
          : '')
        const isMulti = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'is_multi')
          ? Number(url.custom_attributes.find(ele => ele.attribute_code === 'is_multi').value)
          : 0)
        const description = (descriptionFn
          ? descriptionFn.value
          : '')
        const productImage = (url.media_gallery_entries
          ? url.media_gallery_entries.map(ele => {
            const img = ele.file
            return {
              img
            }
          })
          : [])
        const sku = (url ? url.sku : '')
        const metaTitle = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'meta_title')
          ? url.custom_attributes.find(ele => ele.attribute_code === 'meta_title').value
          : undefined)
        const metaKeyword = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'meta_keyword')
          ? url.custom_attributes.find(ele => ele.attribute_code === 'meta_keyword').value
          : undefined)
        const metaDescription = (url.custom_attributes && url.custom_attributes.find(ele => ele.attribute_code === 'meta_description')
          ? url.custom_attributes.find(ele => ele.attribute_code === 'meta_description').value
          : undefined)
        return { sku, status, productname, productImage, description, id, groupingAmount, groupingBonus, sellType, metaTitle, metaKeyword, metaDescription, isMulti }
      } else {
        return {}
      }
    },
    // sku規格
    childSkuData: (state, getters) => {
      if (state.productChilinn) {
        return state.productChilinn.map(ele => {
          const stock = state.childStockProduct[ele.sku]
          const name = ele.name
          const sku = ele.sku
          const oldPrizeNum = Math.floor(Number(ele.price))
          let newPrizeNum = ele.custom_attributes.find(ele2 => ele2.attribute_code === 'special_price')
          newPrizeNum = newPrizeNum ? Math.floor(Number(newPrizeNum.value)) : oldPrizeNum
          let sellNumber = ele.custom_attributes.find(ele2 => ele2.attribute_code === 'sell_water')
          sellNumber = sellNumber ? Math.floor(Number(sellNumber.value)) : 0

          const momProductName = getters.momProductList.productname
          const comboQty = Number(ele.name.split(momProductName + '-')[1].split('入組')[0])

          const perNewPrice = Math.floor(newPrizeNum / comboQty)
          const perOldPrice = Math.floor(oldPrizeNum / comboQty)
          const test = state.productChilinn

          return { oldPrizeNum, newPrizeNum, sellNumber, name, sku, stock, comboQty, perNewPrice, perOldPrice, test }
        })
      } else {
        return [{ oldPrizeNum: 0, newPrizeNum: 0, sellNumber: 0, name: '', sku: '', stock: 0, comboQty: 0, perNewPrice: 0, perOldPrice: 0 }]
      }
    },
    childSkuDataForCombo: (state, getters) => {
      if (state.productChilinn) {
        const array = state.productChilinn.map(ele => {
          const stock = state.childStockProduct[ele.sku]
          const name = ele.name
          const sku = ele.sku
          const oldPrizeNum = Math.floor(Number(ele.price))
          let newPrizeNum = ele.custom_attributes.find(ele2 => ele2.attribute_code === 'special_price')
          newPrizeNum = newPrizeNum ? Math.floor(Number(newPrizeNum.value)) : oldPrizeNum
          let sellNumber = ele.custom_attributes.find(ele2 => ele2.attribute_code === 'sell_water')
          sellNumber = sellNumber ? Math.floor(Number(sellNumber.value)) : 0

          const momProductName = getters.momProductList.productname
          const comboQty = Number(ele.name.split(momProductName + '-')[1].split('入組')[0])

          const perNewPrice = Math.floor(newPrizeNum / comboQty)
          const perOldPrice = Math.floor(oldPrizeNum / comboQty)

          return { oldPrizeNum, newPrizeNum, sellNumber, name, sku, stock, comboQty, perNewPrice, perOldPrice }
        })

        array.sort(function (a, b) {
          return b.comboQty - a.comboQty
        })
        return array
      } else {
        return [{ oldPrizeNum: 0, newPrizeNum: 0, sellNumber: 0, name: '', sku: '', stock: 0, comboQty: 0, perNewPrice: 0, perOldPrice: 0 }]
      }
    },
    productScore: (state) => {
      if (state.productScore) {
        const total = state.productScore.total
        const items = state.productScore.items.map(ele => {
          const star = Number(ele.rating_summary)
          return {
            ...ele,
            star
          }
        })
        return {
          items,
          total
        }
      } else {
        return { total: 0 }
      }
    },
    hotListText: (state) => {
      if (state.hotListText) {
        const url = state.hotListText.custom_attributes
        const re = /url[0-9]/
        return url.filter(ele => re.test(ele.attribute_code)).map(ele => ele.value)
      } else {
        return []
      }
    }
  }
}

export default {
  productJs
}
