<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.titleinner" v-if="webwatch">
        <div>商品資料/規格</div>
        <div :class="$style.innernumber">價格</div>
        <div :class="$style.innernumber">數量</div>
        <div :class="$style.innernumber">小計</div>
      </div>
      <div
        :class="$style.thingout"
        v-for="(item, i) in listData.child || []"
        :key="i"
      >
        <div :class="$style.imm">
          <div
            :style="{ backgroundImage: `url(${
              listData.parent[item.parent_id].image
                ? imgUrl + listData.parent[item.parent_id].image
                : require('../../../assets/img/cvimall_comingsoon_small.png')
            })` }"
            :class="$style.img"
          />
          <div :class="$style.textthing">
            <p :class="$style.textinn">
              {{ listData.parent[item.parent_id].name }}
            </p>
            <p :class="[$style.textinn, $style.format]">
              {{ item.name }}
            </p>
          </div>
        </div>
        <div :class="$style.innernumber" v-if="webwatch">
          ＄{{ Math.floor(Number(item.price)) }}
        </div>
        <div v-if="!webwatch"/>
        <div v-if="!webwatch">數量：</div>
        <div :class="$style.number">
          {{ Math.floor(Number(item.qty_ordered)) }}
        </div>
        <div :class="$style.innernumber">
          ＄{{ Math.floor(Number(item.qty_ordered)) * Math.floor(Number(item.price)) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: Object
  }
}
</script>

<style lang="scss" module>
.img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.innernumber {
  align-self: center;
  justify-self: center;
}

.top {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.thingout {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 400px 1fr 1fr 1fr;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 55px;
}

.imm {
  display: grid;
  grid-template-columns: 60px 324px;
  grid-column-gap: 16px;
}

.textthing {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(30px, auto);
  font-size: 14px;
}

.textinn {
  font-size: 14px;
  align-self: center;

  &.format {
    font-size: 13px;
    color: #828282;
  }
}

.moneyout {
  width: 100%;
  height: 100%;
  font-size: 13px;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-size: 13px;
}

.truemoney {
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.one {
  padding: 20px 0;
  display: grid;
  grid-template-columns: 24px 66px 1fr;
  grid-column-gap: 16px;
  grid-template-rows: 24px;
}

.green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  height: 100%;
  background-color: #00d77f;
}

.title3 {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.px12 {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
}

.inputstyle {
  font-size: 14px;
  text-align: left;
  border: none;
  padding: 28px;
  box-sizing: border-box;
  width: 593px;
  height: 160px;
  margin: 20px 44px 0 44px;
  background-color: #f7f7f7;
  color: #828282;
  resize: none;
}

.btn {
  display: flex;
  flex-wrap: wrap;
  padding: 0 39px;
}

.btnn {
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 999rem;
  background-color: rgba(0, 215, 127, 0.3);
  margin-right: 12px;
  font-size: 14px;
  color: #404040;
}

.textpoint {
  font-size: 14px;
  padding-left: 40px;
}

.loginbtn {
  cursor: pointer;
  width: 240px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 999rem;
  color: #fff;
  display: flex;
  margin: 30px auto;
  justify-content: center;
  align-items: center;
  background-color: #00d77f;

  &.no {
    cursor: not-allowed;
    background-color: #aaa;
  }
}

.titleinner {
  padding-bottom: 12px;
  font-size: 14px;
  border-bottom: 1px solid #C4C4C4;
  display: grid;
  grid-template-columns: 400px 1fr 1fr 1fr;
  padding: 10px 55px;
}

@media (max-width: 1365px) {
  .rootinner {
    padding: 0 30px;
    box-sizing: border-box;
  }

  .number {
    justify-content: unset;
  }

  .bg {
    padding: 15px;
    max-width: 720px;
    width: 80%;
  }

  .one {
    padding: 10px 0;
  }

  .title {
    font-size: 14px;
    font-weight: 400;
  }

  .title2 {
    color: #888;
    font-size: 26px;
    font-weight: 400;
  }

  .thingout {
    grid-template-columns: 48px 1fr 0.3fr;
    grid-auto-rows: minmax(20px, auto);
    grid-gap: 10px;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
    grid-template-rows: auto;
  }

  .textthing {
    width: 100%;
    height: 100%;
    grid-template-rows: 1.8fr 1fr;
  }

  .textinn {

    &.format {
      font-size: 13px;
      color: #828282;
    }
  }

  .green {
    font-size: 12px;
    font-weight: 400;
  }

  .title3 {
    font-size: 14px;
  }

  .px12 {
    align-items: center;
    font-size: 12px;
  }

  .inputstyle {
    font-size: 13px;
    color: #828282;
    border: none;
    padding: 10px;
    width: 100%;
    height: 100px;
    margin: 0;
  }

  .btn {
    overflow-x: auto;
    display: flex;
    padding: 0
  }

  .btnn {
    padding: 5px 10px;
    margin: 0 0 10px 10px;
    font-size: 12px;
  }

  .textpoint {
    font-size: 13px;
  }

  .loginbtn {
    margin: 40px auto 10px auto;
    max-width: 250px;
    width: 80%;
    height: 35px;
    font-weight: 400;
    font-size: 14px;
  }

  .imm {
    display: contents;
  }
}

@media (max-width: 767px) {
  .rootinner {
    padding: 0;
  }
}
</style>
