import store from '@/store'
import Resetpassword from '@/components/outpu/resetpassword.vue'

const isLogin = (to, from, next) => {
  const id = store.state.loginInfo.userIdentity
  if (id === 5 || id === 7 || id === 8 || id === 9) {
    next({ name: 'employeelist' })
  } else if (id === 1 || id === 2 || id === 3 || id === 4 || id === 6) {
    next({ name: 'home' })
  } else if (!id) {
    next()
  } else {
    next({ name: 'home' })
  }
}

export default [{
  path: 'resetpassword',
  name: 'resetpassword',
  component: Resetpassword,
  beforeEnter: isLogin
}]
