<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      人人都能當老闆-創造您的銷售團隊，打造您的黃金商店
      <br/>
      <br/>
      規則:
      <br/>
      <br/>
      分享您個人專屬QRCODE或精選商品給親朋好友 (不包含KOL精選商品與拼團專區商品)，當好友確認邀請後即可綁定成為您的銷售夥伴，爾後只要您的一級銷售夥伴與他所成功推薦的二級銷售夥伴購買商品，您都可以獲得分潤獎勵。
      <br/>
      <br/>
      <br/>
      1、您本人----分享給---> 一級銷售夥伴
      <br/><span :class="$style.padd">您本人可獲得10％分潤獎勵</span>
      <br/>
      <br/>
      2、一級銷售夥伴----分享給---> 二級銷售夥伴
      <br/><span :class="$style.padd">一級銷售夥伴可獲得10%分潤獎勵</span>
      <br/><span :class="$style.padd">您本人可獲得7%分潤獎勵</span>
      <br/>
      <br/>
      3、二級銷售夥伴----分享給---> 其他銷售夥伴
      <br/><span :class="$style.padd">二級銷售夥伴可獲得10%分潤獎勵</span>
      <br/><span :class="$style.padd">一級銷售夥伴可獲得7%分潤獎勵</span>
      <br/><span :class="$style.padd">您本人可獲得3%分潤獎勵</span>
      <br/>
      <br/>
      <br/>
      舉例：
      <br/>
      消費者B透過A的分享連結進入網站，並購買了1000元的商品，而消費者C透過B的分享連結進入網站，也購買了1000元的商品，消費者D透過C的分享連結進入網站，也購買了1000元的商品A即可獲得100+70+ 30點分潤獎勵，B可獲得100 +70點分潤獎勵，C可獲得100元的分潤獎勵。
      <br/>
      <br/>
      Ps.分潤獎勵終身有效，結帳時使用分潤獎勵可折抵現金無門檻
      <br/>
      <br/>
      <br/>
      馬上行動組合您的銷售團隊，共同創造高額分潤!
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" module>
.inner {
  border: 3px solid rgba(255, 97, 0, 0.25);
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  font-size: 14px;
}

.padd {
  padding-left: 23px;
}
</style>
