import { request, requestApi } from '@/request/request'
import loginInfoJs from '../../api/login.js'

const openKolJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    openkol: null,
    kolproductNumber: null
  },
  mutations: {
    openkol (state, payload) {
      state.openkol = payload.openkol
    },
    kolproductNumber (state, payload) {
      state.kolproductNumber = payload.kolproductNumber.map(ele => ele.data)
    }
  },
  actions: {
    async openkol (store, payload) {
      const url = '/products'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        },
        params: {
          'searchCriteria[filterGroups][0][filters][0][field]': 'sell_type',
          'searchCriteria[filterGroups][0][filters][0][value]': 5642,
          'searchCriteria[filterGroups][1][filters][0][field]': 'kol_id',
          'searchCriteria[filterGroups][1][filters][0][value]': payload.kolId,
          'searchCriteria[filterGroups][2][filters][0][field]': 'kol_promote',
          'searchCriteria[filterGroups][2][filters][0][value]': 1,
          'searchCriteria[pageSize]': 15,
          'searchCriteria[currentPage]': payload.currentPage
        }
      })
      store.commit({ type: 'openkol', openkol: response.data })
    },
    async kolproductNumber (store, payload) {
      const url = '/webapi.php'
      const skuArray = store.getters.openkol.list.map(ele => ele.sku)
      const promise = await Promise.all(
        skuArray.map(ele => {
          const promise = requestApi.post(url, {
            action: 'getProductSaleVolume',
            sku: ele
          }, {
            headers: {
              Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
            }
          })
          return promise
        })
      )
      console.log(promise)
      store.commit({ type: 'kolproductNumber', kolproductNumber: promise })
    }
  },
  getters: {
    openkol: (state) => {
      if (state.openkol) {
        const total = state.openkol.total_count
        const list = state.openkol.items.map(ele => {
          const start = ele.created_at
          const end = ele.custom_attributes.find(ele => ele.attribute_code === 'kol_promotedate').value
          const price = ele.custom_attributes.find(ele => ele.attribute_code === 'newprice').value
          const sku = ele.sku
          const name = ele.name
          const id = ele.id
          const visibilityId = ele.visibility
          return {
            start,
            end,
            sku,
            name,
            id,
            price,
            visibilityId
          }
        })
        return { total, list }
      } else {
        return { total: 0, list: [] }
      }
    },
    visibleOpenkol (_, getters) {
      const openkolList = getters.openkol.list
      const visibleOpenkolList = openkolList.filter((item) => item.visibilityId === 1)
      const visibleopenkol = { ...getters.openkol, list: visibleOpenkolList }

      return visibleopenkol
    },
    kolproductNumber: (state) => {
      if (state.kolproductNumber) {
        return state.kolproductNumber
      } else {
        return 0
      }
    }
  }
}

export default {
  openKolJs
}
