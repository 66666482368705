<template>
  <div :class="$style.root">
    <div class="topline"/>
    <div :class="$style.rootinner">
      <top
        v-if="webwatch"
        :trueBoxAll="trueBoxAll"
        @pickAll="pickAll($event)"
      />
      <div :class="'noshopping'" v-if="!trueBoxAll.allOK"/>
      <webdata
        @loading="loading = $event"
        :class="$style.datacss"
        v-else-if="webwatch && trueBoxAll.allOK"
        :listData="listData"
        @deleFn="deleFn"
        @lessFn="lessFn($event)"
        @addFn="addFn($event)"
      />
      <phonedata
        @loading="loading = $event"
        :class="$style.datacss"
        v-else-if="!webwatch && trueBoxAll.allOK"
        :listData="listData"
        @deleFn="deleFn"
        @lessFn="lessFn($event)"
        @addFn="addFn($event)"
      />
      <pluin
        v-if="online !== 'employee' && trueBoxAll.allOK"
        :trueBoxAll="trueBoxAll"
        @pickAll="pickAll($event)"
        @goShop="goShopFn"
        @loading="loading = $event"
        :class="$style.pluin"
        :listData="listData"
      />
      <employeespayment
        v-if="online === 'employee' && trueBoxAll.allOK"
        @goShopemployee="goShopemployee"
        :listData="listData"
        :class="$style.out"
      />
    </div>
    <loadingCom v-if="loading"/>
  </div>
</template>

<script>
import top from '@/components/shopping/top.vue'
import webdata from '@/components/shopping/webdata.vue'
import phonedata from '@/components/shopping/phonedata.vue'
import pluin from '@/views/shopping/shoppingpluin.vue'
import employeespayment from '@/views/employee/employeespayment.vue'
import { mapGetters } from 'vuex'
import shop from '@/components/shopping/js/shop'
import loadingCom from '@/components/outpu/loading2.vue'
export default {
  mixins: [
    shop
  ],
  components: {
    pluin,
    employeespayment,
    top,
    webdata,
    phonedata,
    loadingCom
  },
  data () {
    return {
      listData: []
    }
  },
  watch: {
    allList () {
      if (this.allList.length > 0 && this.routerName !== 'groupshoplist') {
        this.listData = this.allList
        if (localStorage.getItem('pickSkuId')) {
          this.listData.find(ele => ele.info.sku === localStorage.getItem('pickSkuId')).checkbox = true
        }
      } else if (this.allList.length > 0) {
        this.listData = this.allList.map(ele => {
          ele.checkbox = true
          return { ...ele }
        }).filter(ele => ele.tag === '5644')
      }
    },
    async stateMomSku () {
      if (this.stateMomSku.length > 0 && this.listData.length <= 0) {
        await this.apiCatch()
      }
    }
  },
  computed: {
    ...mapGetters([
      'stateMomSku',
      'stateChildSkuData',
      'childArrStockAll'
    ]),
    allList () {
      if (this.stateMomSku.length > 0 && this.stateChildSkuData.length > 0 && this.childArrStockAll) {
        return this.stateMomSku.map((ele, i) => {
          const stock = this.childArrStockAll[ele.parentId]
          const ChildSkuData = this.stateChildSkuData[i]
          return {
            ...ele,
            stock,
            ChildSkuData
          }
        })
      } else {
        return []
      }
    },
    trueBoxAll () {
      if (this.listData.length > 0) {
        const truebox = this.listData.filter(ele => ele.checkbox).length || 0
        const allbox = this.listData.length
        const money = this.listData.filter(ele => ele.checkbox).map(ele => ele.info.newPrice * ele.info.number).reduce((sta, end) => sta + end, 0) || 0
        if (truebox === allbox && allbox > 0) {
          return { truebox: true, trueboxValue: truebox, money: money, allOK: true }
        } else {
          return { truebox: false, trueboxValue: truebox, money: money, allOK: true }
        }
      } else {
        return { truebox: false, trueboxValue: 0, money: 0, allOK: false }
      }
    }
  },
  methods: {
    pickAll (value) {
      const allbox = this.listData.filter(ele => this.childArrStockAll[ele.parentId][ele.info.sku] > 0)
      allbox.map(ele => {
        ele.checkbox = value
        return {
          ...ele
        }
      })
    },
    // 去買單
    async goShopFn () {
      let link
      let list
      if (this.routerName === 'groupshoplist') {
        link = 'groupshoppinginfo'
        list = this.listData
      } else {
        link = 'shoppinginfo'
        list = this.listData.filter(ele => ele.checkbox === true)
      }
      try {
        await this.$store.dispatch({
          type: 'itemAction',
          action: 'getItem',
          catchList: true
        })
        await this.shopToCart(list, link)
      } catch {
        await this.$store.dispatch({
          type: 'delTrueShopCarList'
        })
        this.$message.error('購物車載入失敗，請稍後再試。')
        this.loading = false
      }
    },
    async goShopemployee () {
      const list = this.listData.filter(ele => ele.checkbox === true)
      if (list.length <= 0) {
        this.$notify({
          title: '未選擇商品至結帳區',
          message: '請選擇您愛的寶貝，才能至結帳區結帳唷！'
        })
      } else {
        this.loading = true
        try {
          for (let i = 0; i < list.length; i++) {
            await this.$store.dispatch({
              type: 'addTrueShopCar',
              qty: list[i].info.number,
              quote_id: this.$route.params.carId,
              momSku: list[i].momSku,
              option_id: list[i].info.optionId,
              option_value: list[i].info.optionValue
            })
          }
          await this.$store.dispatch({
            type: 'settingMethodEmploaee'
          })
          await this.$store.dispatch({
            type: 'settingShopPayEmploaee'
          })
          for (let i = 0; i < list.length; i++) {
            await this.$store.dispatch({
              type: 'itemAction',
              action: 'delItem',
              catchList: false,
              bodyObj: {
                idx: list[i].info.idxNumber
              }
            })
          }
          await this.$store.dispatch({
            type: 'getTrueShopCarId'
          })
          this.$router.push({
            name: 'employeesuccess',
            params: {
              userId: this.$route.params.userId,
              orderId: this.$store.state.shopping.settingShopPayId
            }
          })
        } catch {
          await this.$store.dispatch({
            type: 'delTrueShopCarList'
          })
          this.$message.error('購物車載入失敗，請稍後再試。')
        } finally {
          this.loading = false
        }
      }
    },
    // 刪除購物車的商品
    async deleFn (value) {
      this.loading = true
      try {
        await this.$store.dispatch({
          type: 'itemAction',
          action: 'delItem',
          catchList: false,
          bodyObj: {
            idx: this.listData[value].info.idxNumber
          }
        })
        // 離開畫面再拉一次api
        let beftor = this.listData.slice(value - 1, value)
        let after = this.listData.slice(value + 1, this.listData.length)
        this.listData = beftor.concat(after)
      } catch {
        this.$message.error('刪除失敗')
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    if (this.stateMomSku.length > 0 && this.listData.length <= 0) {
      await this.apiCatch()
    }
  },
  async beforeRouteLeave (to, form, next) {
    this.$emit('loading', true)
    try {
      localStorage.removeItem('pickSkuId')
      const group = this.listData.filter(ele => ele.tag === '5644')
      if (to.name !== 'groupshoppinginfo' && to.name !== 'shoppinginfo') {
        if (group.length > 0) {
          for (let i = 0; i < group.length; i++) {
            await this.$store.dispatch({
              type: 'itemAction',
              action: 'delItem',
              catchList: false,
              bodyObj: {
                idx: group[i].info.idxNumber
              }
            })
          }
        }
        this.listData = []
        await this.$store.dispatch({
          type: 'itemAction',
          action: 'getItem',
          catchList: true
        })
      }
      this.$store.commit({ type: 'productChildArr', productChildArr: null })
      this.$store.commit({ type: 'childArrStockAll', childArrStockAll: null })
    } catch {
      this.$message.error('載入失敗，請重新載入。')
    } finally {
      this.$emit('loading', false)
    }
    next()
  }
}
</script>

<style lang="scss" module>
.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 130px);
}

.out {
  margin-bottom: 50px;
}

@media (min-width: 1366px) {
  .rootinner {
    padding-top: 40px;
    width: 1100px;
    margin: 0 auto;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 1104px;
    height: 192px;
  }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .root {
    min-height: calc(100vh - 52px);
  }

  .rootinner {
    margin: 0 auto;
    position: relative;
    width: 720px;
    min-height: calc(100% - 1px);
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .datacss {
    flex: 1;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
}

@media (max-width: 767px) {
  .root {
    min-height: calc(100vh - 52px);
  }

  .rootinner {
    position: relative;
    min-height: calc(100% - 1px);
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .pluin {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
}

</style>
