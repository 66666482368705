<template>
  <div :class="$style.root">
    <div class="listoutside">
      <p class="listtitle">現金</p>
      <img
        :class="$style.iconsize"
        src="../../assets/img/productpage_icon_coins.png"
      />
      <span :class="$style.inner" v-if="online !== 'kol'">
        {{ name }} 下單獲得
        {{ Math.floor(number * money * Number(totalLevel.discount)) }}
        現金
      </span>
      <span :class="$style.inner" v-else>請登入一般會員或員工來進行購買喔！</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    number: null,
    money: null
  },
  computed: {
    ...mapGetters([
      'totalLevel'
    ]),
    name () {
      const id = Number(this.totalLevel.title)
      let gradeText
      switch (id) {
        case 1:
          gradeText = '一般會員'
          break
        case 2:
          gradeText = 'VIP會員'
          break
        case 3:
          gradeText = '白金VIP會員'
          break
      }
      return gradeText
    }
  },
  methods: {
  },
  created () {
    this.$store.dispatch({
      type: 'totalLevel'
    })
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .yellow {
    position: relative;
    padding: 2px 10px;
    border-radius: 999rem;
    background: #ffc000;
    font-size: 14px;
    color: #ffffff;
    margin-right: 24px;
    cursor: pointer;
  }

  .ticket {
    position: absolute;
    margin-top: 10px;
    width: 400px;
    z-index: 1;
  }

  .iconsize {
    width: 25px;
    height: 25px;
    padding-right: 17px;
  }

  .inner {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}

@media (max-width: 1365px) {
  .yellow {
    padding: 2px 10px;
    border-radius: 999rem;
    background-color: #ffc000;
    font-size: 12px;
    color: #ffffff;
    margin-right: 7px;
  }

  .ticket {
    width: 100%;
    margin-top: 10px;
  }

  .iconsize {
    width: 17px;
    height: 17px;
    padding-right: 5px;
  }

  .inner {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
</style>
