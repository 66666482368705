<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <div :class="$style.bg">
        <img src="../../assets/img/frame.png" :class="$style.pic">
        <div :class="$style.inner2">
          <div v-if="regist" :class="$style.text">
            請使用新的密碼登入，再修改密碼即可。
          </div>
          <div v-else :class="$style.text">
            使用剛註冊帳號登入即可！
          </div>
        </div>
        <div v-if="regist" :class="$style.yes" @click="[$emit('close'), goHome()]">
          確認
        </div>
        <div v-else :class="$style.yes" @click="$emit('close')">
          確認
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['regist'],
  methods: {
    goHome () {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" module>
.inner {
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 4;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg {
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 500px;
  min-height: 300px;
  background: #FFFFFF;
  border-radius: 10px;
}

.pic {
  width: 147px;
  height: 95px;
  margin-bottom: 31px;
}

.text {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #000000;
  text-align: center;
}

.mail {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: #FF6100;
  text-align: center;
}

.inner2 {
  margin-bottom: 47px;
}

.yes {
  cursor: pointer;
  width: 265px;
  height: 40px;
  background: #FF6100;
  border-radius: 35px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
