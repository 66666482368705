<template>
  <div :class="$style.root">
    <div
      :class="$style.rootinner"
      v-html="productmessage"
    />
  </div>
</template>

<script>
export default {
  props: {
    productmessage: null
  }
}
</script>

<style lang="scss" module>
img {
  width: 100%;
  height: auto;
}

.rootinner {
  width: 100%;
  padding-top: 40px;
  font-size: 14px;
}

@media (max-width: 1365px) {
  .rootinner {
    padding-top: 20px;
  }
}
</style>
