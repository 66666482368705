<template>
  <div :class="$style.root">
    <userinfo/>
    <div :class="$style.topout">
      <div :class="$style.out">
        <img src="../../assets/img/productpage_icon_coins.png" :class="$style.icon">
        <p :class="$style.text3">目前現金</p>
        <span :class="$style.text4">
          {{ redPoint }}
        </span>
        <span>點</span>
      </div>
    </div>
    <div :class="$style.rootinner" v-if="Number(usePointsHistory.total) > 0">
      <div :class="$style.linetitle" v-if="webwatch">
        <div :class="$style.title">日期 / 項目</div>
        <div :class="$style.title">點數</div>
      </div>
      <div :class="$style.minheight">
        <div
          :class="$style.line"
          v-for="(item, i) in usePointsHistory.items"
          :key="i"
        >
          <div :class="$style.textout">
            <p :class="$style.text">{{ item.created_at }}</p>
            <p :class="$style.text">
              {{ item.point_amount >= 0 ? '從訂單' : '已使用於訂單' }}
              <span :class="$style.textgreen">
                {{ item.order_id }}
              </span>
              {{ item.point_amount >= 0 ? '獲得點數' : '中'}}
            </p>
          </div>
          <p :class="[$style.text, $style.right]">
            {{ item.point_amount }}
          </p>
        </div>
      </div>
    </div>
    <div
      :class="['noanythingout', 'noany']"
      v-if="Number(usePointsHistory.total) <= 0"
    >
      <img
        src="../../assets/img/redpoint.svg"
        :class="['noanyimg', 'imgmargin']"
      />
      目前沒有任何點數唷！
    </div>
    <page
      v-show="Number(usePointsHistory.total) > 0"
      :pageNumber="Number(usePointsHistory.total)"
      :pageSize="8"
      @Number="pageFn($event)"
      :class="$style.page"
    />
  </div>
</template>

<script>
import page from '@/components/page.vue'
import userinfo from '@/components/user/info.vue'
import { mapGetters } from 'vuex'
import { pageFunction } from '@/js'
import '@/css/noany.scss'
export default {
  components: {
    page,
    userinfo
  },
  computed: {
    ...mapGetters([
      'redPoint',
      'usePointsHistory'
    ])
  },
  methods: {
    async pageFn (current) {
      this.$emit('loading', true)
      try {
        await pageFunction('usePointsHistory', 'getMyPointRecord', current, undefined)
      } catch {
        this.$message.error('載入失敗，請重新載入。')
      } finally {
        this.$emit('loading', false)
      }
    }
  },
  async created () {
    await this.$store.dispatch({
      type: 'getPointNumber',
      action: 'getMyPoint'
    })
  }
}
</script>

<style lang="scss" module>
.rootinner {
  width: 100%;
}

.line {
  padding: 22px 20px;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #C4C4C4;
  font-size: 14px;
  align-items: center;
}

.topout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.out {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.textgreen {
  color: #00D77F;
  text-decoration: underline;
  padding: 0 10px;
}

.linetitle {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  min-height: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid #C4C4C4;
  border-bottom: 2px solid #C4C4C4;
  font-size: 14px;
  align-items: center;
}

.text {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 100%;

  &.text2 {
  border-bottom: none;
  }
}

.icon {
  padding: 0 26px 0 8px;
  width: 24px;
  height: 24px;
}

.text3 {
  padding-right: 30px;
  font-size: 14px;
}

.minheight {
  width: 100%;
  min-height: 560px;
}

.text4 {
  padding-right: 30px;
  font-weight: 500;
  font-size: 16px;
  color: #FFC000;
}

.touto {
  font-size: 14px;
}

.touto2 {
  padding: 6px 0 18px 0;
  color: #828282;
  font-size: 13px;
  width: 100%;
  text-align: right;
}

.page {
  margin-top: 40px;
}

@media (max-width: 1365px) {
  .line {
    padding: 0;
    display: block;
    margin-bottom: 50px;
    border-top: 2px solid #c4c4c4;
    border-bottom: 2px solid #c4c4c4;
  }

  .text {
    padding: 0 6px;
    align-items: center;
    height: 29px;
    border-bottom: 1px solid #c4c4c4;

    &.right {
      justify-content: flex-end;
    }
  }

  .icon {
    padding: 0;
    padding-right: 20px;
  }

  .out {
    width: 100%;
    align-items: center;
  }

  .text3 {
    padding-right: 20px;
  }

  .touto {
    padding-top: 6px;
    padding-left: 44px;
  }

  .touto2 {
    padding: 20px 0;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .minheight {
    min-height: calc(100vh - 370px);
  }
}
</style>
