<template>
  <div :class="$style.root">
    <div class="topline"/>
    <div class="outroot">
      <progressshopping :step="3"/>
        <div :class="$style.area">
          <img src="../../assets/img/ok.png" :class="$style.iconstyle"/>
        </div>
        <div :class="$style.area">
          <p :class="$style.title">謝謝您！您的訂單已經成立！</p>
          <p :class="$style.text">訂單編號:
            {{ orderDataData.orderId }}
          </p>
          <p :class="$style.text">訂單確認電郵已經發送到您的電子信箱：</p>
          <p :class="$style.text">
            {{ commonUserInfo.email }}
          </p>
        </div>
        <div :class="$style.bottom">
          <div :class="[$style.gridlist, $style.text]">
            <div :class="$style.title">訂單資訊</div>
            <div/>
            <div>訂單編號</div>
            <div>{{ orderDataData.orderId }}</div>
            <div>下單時間</div>
            <div>{{ orderDataData.time }}</div>
            <div>總金額</div>
            <div>{{ orderDataData.total }}</div>
            <div>訂單狀態</div>
            <div>{{ orderDataData.statetext }}</div>
          </div>
          <div
            :class="[$style.gridlist, $style.text]"
            v-if="orderDataData.shipmentInfo"
          >
            <div :class="$style.title">
              客戶資訊
            </div>
            <div></div>
            <div>姓名</div>
            <div>
              {{ commonUserInfo.name }}
            </div>
            <div>手機號碼</div>
            <div>
              {{ commonUserInfo.phone }}
            </div>
          </div>
          <div :class="[$style.gridlist, $style.text]">
            <div :class="$style.title">送貨資訊</div>
            <div/>
            <div>送貨方式</div>
            <div>{{ orderDataData.sendMethods }}</div>
            <div>物流編號</div>
            <div>{{ orderDataData.shipmentInfo.AllPayLogisticsID }}</div>
            <div>送貨狀態</div>
            <div>{{ orderDataData.shipmentInfo.RtnMsg }}</div>
            <div>
              {{ orderDataData.sendMethods === '宅配'
                  ? '宅配地址'
                  : '門市地址'
              }}
            </div>
            <div>{{ orderDataData.shipmentInfo.ReceiverAddress }}</div>
            <div>收件人名稱</div>
            <div>{{ orderDataData.shipmentInfo.ReceiverName }}</div>
            <div :class="'red'">*需與身分證姓名相同</div>
            <div></div>
            <div>收件人手機</div>
            <div>{{ orderDataData.shipmentInfo.ReceiverCellPhone }}</div>
          </div>
          <div :class="[$style.gridlist, $style.text]">
            <div :class="$style.title">付款資訊</div>
            <div/>
            <div>付款方式 </div>
            <div>{{ orderDataData.paymentInfo.PaymentType }}</div>
            <div>付款狀態</div>
            <div>{{ orderDataData.paymentInfo.RtnMsg }}</div>
          </div>
        </div>
        <div :class="$style.title">訂單備註</div>
        <div :class="$style.remark">
          {{ orderDataData.note ? orderDataData.note : '無' }}
        </div>
        <router-link
          :to="{ name: 'home'}"
          tag="div"
          :class="$style.btn"
        >
          繼續購物
        </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import progressshopping from '@/components/shopping/progress.vue'
export default {
  components: {
    progressshopping
  },
  computed: {
    ...mapGetters([
      'orderData',
      'commonUserInfo'
    ]),
    orderDataData () {
      return this.orderData ? this.orderData[0] : { shipmentInfo: {}, paymentInfo: {} }
    }
  },
  async created () {
    this.$emit('loading', true)
    try {
      await this.$store.dispatch({
        type: 'getOrderData',
        orderId: [this.$route.query.orderId]
      })
    } finally {
      this.$emit('loading', false)
    }
  }
}
</script>

<style lang="scss" module>
.area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  box-sizing: border-box;
  border-top: 1px solid #C4C4C4;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 16px;
}

.text {
  padding-top: 20px;
  font-size: 14px;
}

.gridlist {
  width: 518px;
  display: grid;
  grid-column-gap: 26px;
  grid-row-gap: 12px;
  grid-auto-rows: minmax(23px, auto);
  grid-template-columns: 153px 1fr;
  padding-bottom: 52px;
}

.remark {
  padding-top: 24px;
  border-bottom: 2px solid #C4C4C4;
  padding-bottom: 30px;
}

.btn {
  cursor: pointer;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border-radius: 999rem;
  background-color: #ff6100;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.iconstyle {
  padding-top: 80px;
  padding-bottom: 42px;
  width: 100px;
  height: 100px;
}

@media (max-width: 1365px) {
  .iconstyle {
    padding: 40px 0;
  }

  .gridlist {
    width: 100%;
    grid-column-gap: 26px;
    grid-template-columns: 153px 1fr;
    padding-bottom: 20px;
  }

  .btn {
    font-size: 14px;
    font-weight: 400;
    margin: 25px auto;
  }
}

@media (max-width: 767px) {
  .gridlist {
    grid-template-columns: 100px 1fr;
  }

  .bottom {
    padding: 0;
  }

  .btn {
    width: 85%;
    height: 40px;
  }
}
</style>
