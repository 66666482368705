<template>
  <div :class="$style.root">
    <div :class="$style.rootinner" v-if="webwatch">
      <div :class="$style.nav">
        <router-link
          tag="div"
          v-for="(item, i) in navtable"
          :key="i"
          @click="active = Number(i)"
          :to="{ name: item.link, params: { current: 1 } }"
          :class="$style.mar"
        >
          <p
            :class="[$style.text, { [$style.textactive]: active === Number(i) }]"
            @click="active = Number(i)"
          >
            {{ item.name }}
          </p>
        </router-link>
      </div>
    </div>
    <div :class="$style.rootinner" v-else>
      <div :class="$style.nav">
        <router-link
          tag="div"
          v-for="(item, i) in navtable"
          :key="i"
          @click="active = Number(i)"
          :to="{ name: item.link, params: { current: 1 } }"
        >
          <p
            :class="[$style.text, { [$style.textactive]: active === Number(i) }]"
            @click="active = Number(i)"
          >
            {{ item.name }}
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      navtable: [{
        name: '個人資訊',
        link: 'kolinformation'
      }, {
        name: '開團紀錄',
        link: 'kolgroup'
      }]
    }
  }
}
</script>

<style lang="scss" module>
@media (min-width: 1366px) {
  .rootinner {
    position: relative;
    display: flex;
    width: 100%;
  }

  .left, .right {
    display: none;
  }

  .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 75px 0 30px 0;
    box-sizing: border-box;
    border-top: 1px solid #C4C4C4;
    border-bottom: 1px solid #C4C4C4;
  }

  .text {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #B5B5B5;
    height: 34px;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    box-sizing: border-box;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
  }

  .mar {
    margin-bottom: 41px;
  }

  .textactive {
    border-left: 10px solid #00D77F;
    border-right: 10px solid #00D77F;
    color: #000;
  }

  .left {
    position: absolute;
  }

  .right {
    right: 0;
    position: absolute;
  }

  .text2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
  }
}

@media (max-width: 1365px) {
  .rootinner {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .nav {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    font-size: 14px;
    color: #000;
    box-sizing: border-box;
  }

  .textactive::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 24px;
    background: #00D77F;
    bottom: 0px;
  }
}
</style>
