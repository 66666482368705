import { request, requestApi } from '@/request/request'
import { onlyAction } from '@/request/otherapi'
import { webApiFunciton } from '@/request'
import loginInfoJs from '../../api/login.js'
import { getTrueShopCarId } from '@/servers'

const shoppingJs = {
  state: {
    adminToken: 'l4r8x44aeqybmluffpyyxoufxsbjl06w',
    billingResult: null,
    storageShopData: null,
    productChildArr: [],
    trueShopCar: null,
    trueShopCarId: null,
    settingShopPayId: null,
    shopPayMoney: null,
    payMoneyGo: null,
    successdata: null,
    orderShipment: null,
    childArrStockAll: null
  },
  mutations: {
    // 暫存購物車
    storageShopData (state, payload) {
      state.storageShopData = payload.storageShopData
    },
    productChildArr (state, payload) {
      if (!payload.productChildArr) {
        state.productChildArr = null
      } else {
        state.productChildArr = payload.productChildArr.map(ele => ele.data)
      }
    },
    trueShopCar (state, payload) {
      state.trueShopCar = payload.trueShopCar
    },
    trueShopCarId (state, payload) {
      state.trueShopCarId = payload.trueShopCarId
    },
    settingShopPayId (state, payload) {
      state.settingShopPayId = payload.settingShopPayId
    },
    shopPayMoney (state, payload) {
      state.shopPayMoney = payload.shopPayMoney
    },
    payMoneyGo (state, payload) {
      state.payMoneyGo = payload.payMoneyGo
    },
    billingResult (state, payload) {
      state.billingResult = payload.billingResult
    },
    successdata (state, payload) {
      state.successdata = payload.successdata
    },
    orderShipment (state, payload) {
      state.orderShipment = payload.orderShipment
    },
    childArrStockAll (state, payload) {
      state.childArrStockAll = payload.childArrStockAll
    }
  },
  actions: {
    async childArrStockAll (store, payload) {
      const url = '/openwebapi.php'
      const response = await requestApi.post(url, {
        action: 'getSkuAmountTree',
        parent_id: payload.array
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'childArrStockAll', childArrStockAll: response.data })
    },
    async itemAction ({ commit }, { action, bodyObj, catchList }) {
      // action 分成 addItem updateItem delItem getItem
      const token = loginInfoJs.loginInfoJs.state.userToken
      const data = await webApiFunciton(action, token, bodyObj)
      if (catchList) {
        commit({ type: 'storageShopData', storageShopData: data })
      }
    },
    // 購物車 撈出所有子sku
    async getChildArr (store, payload) {
      const childArr = await Promise.all(
        payload.sku.map(ele => {
          const url = `${'/configurable-products/' + ele + '/children'}`
          const promise = request.get(url, {
            headers: {
              Authorization: `Bearer ${store.state.adminToken}`
            }
          })
          return promise
        })
      )
      store.commit({ type: 'productChildArr', productChildArr: childArr })
    },
    // 新增真-購物車
    async getTrueShopCarId (store, payload) {
      const data = await getTrueShopCarId(loginInfoJs.loginInfoJs.state.userToken)
      store.commit({ type: 'trueShopCarId', trueShopCarId: data })
    },
    // 得到真-購物車資訊
    async getTrueShopCar (store, payload) {
      const url = '/carts/mine'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'trueShopCar', trueShopCar: response.data })
    },
    async addTrueShopCar (store, payload) {
      const url = '/carts/mine/items'
      await request.post(url, {
        cartItem: {
          qty: payload.qty,
          quote_id: payload.quote_id,
          sku: payload.momSku,
          product_option: {
            extension_attributes: {
              configurable_item_options: [{
                option_id: payload.option_id,
                option_value: payload.option_value
              }]
            }
          }
        }
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async delTrueShopCarList () {
      // 刪除真購物車的list
      // clearShoppingcart
      const token = loginInfoJs.loginInfoJs.state.userToken
      await onlyAction('post', 'clearShoppingcart', token)
    },
    async settingMethod (store, payload) {
      // 設定送貨
      const url = '/carts/mine/shipping-information'
      const obj = { ...payload.data }
      delete obj.region
      delete obj.customer_id
      delete obj.id
      // 固定運費:flatrate
      // 宅配:homeshipping
      // 離島寄送:outlyingshipping
      // 免運:freeshipping
      // 員工 employeefree
      await request.post(url, {
        addressInformation: {
          shipping_address: {
            ...obj
          },
          shipping_method_code: payload.method,
          shipping_carrier_code: payload.method
        }
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async settingShopPay (store, payload) {
      // 設定付款、運送方式、進入送貨
      const url = '/carts/mine/payment-information'
      const response = await request.post(url, {
        paymentMethod: {
          method: 'ecpay_ecpaypayment' // 固定值
        },
        billingAddress: {
          ...payload.data
        }
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'settingShopPayId', settingShopPayId: response.data })
    },
    async settingMethodEmploaee (store, payload) {
      // 設定送貨 (員工購物都是hardcode，公司拿貨)
      const url = '/carts/mine/shipping-information'
      await request.post(url, {
        addressInformation: {
          shipping_address: {
            street: [
              '淡水區',
              '中正東路1段3巷9號9樓'
            ],
            region_id: 0, // 固定
            country_id: 'TW', // 固定
            telephone: '+886 2 26201000',
            postcode: 251,
            city: '新北市',
            firstname: '抬頭： 瀚柔國際股份有限公司' + ', ' + '統編：42717913',
            lastname: loginInfoJs.loginInfoJs.state.userData.lastname
          },
          shipping_method_code: 'employeefree',
          shipping_carrier_code: 'employeefree'
        }
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async settingShopPayEmploaee (store, payload) {
      // 設定付款、運送方式、進入送貨
      const url = '/carts/mine/payment-information'
      const response = await request.post(url, {
        paymentMethod: {
          method: 'ecpay_ecpaypayment' // 固定值
        },
        billingAddress: {
          street: [
            '淡水區',
            '中正東路1段3巷9號9樓'
          ],
          region_id: 0, // 固定
          country_id: 'TW', // 固定
          telephone: '+886 2 26201000',
          postcode: 251,
          city: '新北市',
          firstname: '抬頭： 瀚柔國際股份有限公司' + ', ' + '統編：42717913',
          lastname: loginInfoJs.loginInfoJs.state.userData.lastname
        }
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'settingShopPayId', settingShopPayId: response.data })
    },
    // 金額
    async shopPayMoney (store, payload) {
      const url = '/carts/mine/totals'
      const response = await request.get(url, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'shopPayMoney', shopPayMoney: response.data })
    },
    async payMoneyGo (store, payload) {
      const url = '/webapi.php'
      const data = new FormData()
      data.append('action', 'sendPaymentRequest') // 固定值
      data.append('orderId', store.state.settingShopPayId) // 訂單id
      data.append('period', payload.staging) // 分期
      data.append('tradeDesc', '無') // 備註
      data.append('paymentType', payload.payMethods) // 付款類型
      const response = await requestApi.post(url, data, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'payMoneyGo', payMoneyGo: response.data })
    },
    async createCvsCodOrder (store, payload) {
      console.log('createCvsCodOrder')
      // 金流成功時得到資料
      const url = '/webapi.php'
      const response = await requestApi.post(url, {
        action: 'createCvsCodOrder',
        orderId: store.state.settingShopPayId
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      if (response.data === 'success') {
        await store.commit({ type: 'billingResult', billingResult: true })
      } else {
        await store.commit({ type: 'billingResult', billingResult: false })
      }
      console.log('createCvsCodOrder billingResult', store.state.billingResult)
      console.log('createCvsCodOrder response', response)
      console.log('createCvsCodOrder response.data', response.data)
    },
    async getSuccessData (store, payload) {
      // 金流成功時得到資料
      const url = '/webapi.php'
      const response = await requestApi.post(url, {
        action: 'getOrderPayment',
        orderId: Number(payload.orderId)
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'successdata', successdata: response.data })
    },
    async getOrderShipment (store, payload) {
      // 物流成功時得到資料
      const url = '/webapi.php'
      const response = await requestApi.post(url, {
        action: 'getOrderShipment',
        orderId: Number(payload.orderId)
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'orderShipment', orderShipment: response.data })
    },
    async newSendMethods (store, payload) {
      // 新的寄送方式
      const extraData = payload.extraData
      extraData.MerchantTradeNo = store.state.settingShopPayId
      const url = '/webapi.php'
      await requestApi.post(url, {
        action: 'saveOrderShipment',
        orderId: store.state.settingShopPayId,
        shipType: payload.shipType,
        subType: payload.subType,
        extraData: JSON.stringify(payload.extraData)
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
    },
    async cancelOrder (store, payload) {
      // 取消訂單
      const url = '/webapi.php'
      const response = await requestApi.post(url, {
        action: 'cancelOrder',
        orderId: Number(payload.orderId),
        reason: payload.reason
      }, {
        headers: {
          Authorization: `Bearer ${loginInfoJs.loginInfoJs.state.userToken}`
        }
      })
      store.commit({ type: 'successdata', successdata: response.data })
    },
    // 評價
    async postProductScore (store, payload) {
      const url = '/reviews'
      await request.post(url, {
        review: {
          // 規格
          title: payload.title,
          detail: payload.detail,
          customer_id: payload.customer_id,
          nickname: payload.nickname,
          ratings: [{ 'rating_name': 'Rating', value: payload.star }],
          review_entity: 'product',
          entity_pk_value: payload.id
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
    },
    // 訂單備註
    async PostRemarks (store, payload) {
      const url = `/orders/${store.state.settingShopPayId}/comments`
      await request.post(url, {
        statusHistory: {
          comment: payload.comment, // 備註文字
          entity_name: 'customer' // 固定為customer
        }
      }, {
        headers: {
          Authorization: `Bearer ${store.state.adminToken}`
        }
      })
    }
  },
  getters: {
    // 取出arr裡面的所有母sku
    stateMomSku: (state) => {
      const list = (state.storageShopData
        ? state.storageShopData.cart
        : [])
      if (list) {
        try {
          const skuList = (list
            ? Object.keys(list).sort().map(elelist => {
              const momSku = list[elelist].parent_sku
              let img = list[elelist].parent.media_gallery_entries
              img = img ? img[0].file : undefined
              const number = list[elelist].qty
              const idxNumber = list[elelist].idx
              const sku = list[elelist].sku
              const description = list[elelist].parent.name
              const parentId = list[elelist].parent.id
              const name = list[elelist].detail.name
              const oldPrice = Math.floor(Number(list[elelist].detail.price))
              let newPrice = list[elelist].detail.custom_attributes.find(ele => ele.attribute_code === 'special_price')
              newPrice = newPrice ? Math.floor(Number(newPrice.value)) : oldPrice
              const groupHours = list[elelist].parent.custom_attributes.find(ele => ele.attribute_code === 'grouping_hours') || undefined
              const groupBonus = list[elelist].parent.custom_attributes.find(ele => ele.attribute_code === 'grouping_bonus') || undefined
              const groupAmount = list[elelist].parent.custom_attributes.find(ele => ele.attribute_code === 'grouping_amount') || undefined
              const checkbox = true
              const tag = list[elelist].parent.custom_attributes.find(ele => ele.attribute_code === 'sell_type').value
              const optionId = Number(list[elelist].detail.option_id)
              const optionValue = Number(list[elelist].detail.option_value)
              let tagText
              switch (Number(tag)) {
                case 5642:
                  tagText = 'KOL'
                  break
                case 5643:
                  tagText = '精選'
                  break
                case 5644:
                  tagText = '拼團'
                  break
                case 5646:
                  tagText = '組合'
                  break
              }
              return {
                momSku,
                description,
                tagText,
                tag,
                img,
                checkbox,
                parentId,
                groupHours,
                groupBonus,
                groupAmount,
                info: {
                  number,
                  idxNumber,
                  sku,
                  name,
                  oldPrice,
                  newPrice,
                  optionId,
                  optionValue
                }
              }
            })
            : [])
          return skuList
        } catch {
          window.location.reload()
        }
      } else {
        return []
      }
    },
    // 處理子arr裡的資料
    stateChildSkuData: (state) => {
      const list = (state.productChildArr
        ? state.productChildArr
        : [])
        .map(ele => {
          const arr = ele.map(ele2 => {
            const chirdSkuName = ele2.name
            const chirdSku = ele2.sku
            return {
              chirdSkuName,
              chirdSku
            }
          })
          return arr
        })
      return list
    },
    trueTotal: (state) => {
      const list = (state.shopPayMoney
        ? state.shopPayMoney
        : undefined)
      if (list) {
        const total = list.grand_total // 總計(扣完之後)
        const shoppingMoney = list.base_grand_total // 扣稅之前
        const discount = list.discount_amount // 折扣金額
        const shipping = list.shipping_amount // 運費
        return { total, shoppingMoney, discount, shipping }
      } else {
        return undefined
      }
    },
    cartnumber: (state) => {
      if (state.storageShopData && state.storageShopData.cart) {
        const cartArray = Object.values(state.storageShopData.cart)
          .map(ele => ele.qty)
          .reduce((sta, end) => sta + end, 0)
        return cartArray
      } else {
        return 0
      }
    },
    skuListOnlyName: (state) => {
      if (state.storageShopData && state.storageShopData.cart) {
        const skuListOnlyName = Object.keys(state.storageShopData.cart)
        const list = Object.values(state.storageShopData.cart).map(ele => {
          const idx = ele.idx
          const number = ele.qty
          return { idx, number }
        })
        return { skuListOnlyName, list }
      } else {
        return { skuListOnlyName: [] }
      }
    },
    childArrStockAll: (state) => {
      if (state.childArrStockAll) {
        return state.childArrStockAll
      } else {
        return undefined
      }
    }
  }
}

export default {
  shoppingJs
}
